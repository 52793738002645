import React from "react";
import { useLocation } from "react-router-dom";
import cross from "../../assets/Icons/Tipping/cross.svg";
import { useSelector } from "react-redux";

export default function ConfirmTransaction({
  onPress,
  nonShukranId,
  queryShukranId,
  tipData,
  method,
  submitTip,
  queryName,
}) {
  const tippingState = useSelector((state) => state.tipping);
  const locationPath = useLocation();
  return (
    <div className="absolute w-full backdrop-blur-sm flex flex-row items-center justify-center">
      <div className="py-8 px-4 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
        <div className="flex flex-row items-center justify-between">
          <span className="text-base">Confirm Tip</span>
          <div
            onClick={() => {
              //   setPhoneNumber("");
              //   setCardOption(false);
              //   setCardPopup(false);
              //   setConfirmMpesa(false);
              //   setLoggedConfirm(false);
              //   if (balanceState.generateResponse.phone) {
              //     // navigate("/queryMpesaNo");
              //     balanceState.generateResponse.phone = "";
              //   }
              onPress();
            }}
          >
            <img src={cross} alt="cross-icon" className="h-6" />
          </div>
        </div>
        <div className="mt-5 h-12">
          <label className="text-subtle text-sm">Tip Recipient</label>
          <div className="flex flex-row justify-between">
            {nonShukranId ? (
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm text-black w-36">
                  {tipData.nonShukranId}
                </span>
              </div>
            ) : queryShukranId ? (
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm text-black w-36">{queryName}</span>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-between">
                <span className="text-sm text-black w-36">
                  {tipData.receiverName + " " + tipData.receiverLastName}
                </span>
                <span className="text-sm text-gray-300">
                  {tipData.shukranId}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 h-12">
          <label className="text-subtle text-sm">Tip Amount</label>
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">
              {tipData.tipAmount} {tipData.currency}
            </span>
          </div>
          {/* <div className="flex flex-row">
            {isPayingTransaction ? (
              <div className="flex flex-row items-center">
                <span className="text-sm text-slate-400 line-through italic">
                  {currency} {tipData.tipAmount}
                </span>
                <span className="text-sm text-black font-bold ml-3">
                  {currency}{" "}
                  {Number(tipData.tipAmount) + tipData.tipAmount * 0.025}
                </span>
              </div>
            ) : (
              <div className="flex flex-row items-center">
                <span className="text-sm text-black">
                  {currency} {tipData.tipAmount}
                </span>
              </div>
            )}
          </div> */}
        </div>
        <div className="mt-3 h-24">
          <label className="text-subtle text-sm">Payment Method</label>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <span className="text-sm text-black">{method}</span>
            </div>
            {/* {!!transactionState.cardTransactions.length && cardOption && (
              <ul className="mt-3">
                <label className="text-subtle text-sm">Card Details</label>
                {transactionState.cardTransactions.map((card, idx) => (
                  <li
                    className={`flex flex-row justify-between items-center`}
                    key={idx}
                  >
                    <div className="flex flex-row items-center justify-between w-full">
                      <span className="text-sm">
                        XXXX XXXX XXXX {card.authorization_json.last4}
                      </span>
                      <span className="text-xs text-gray-500">
                        {card.authorization_json.brand}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )} */}
          </div>
        </div>
        {/* {tipData.tipAmount >= 100 ? (
          <div className="mt-3 h-12 flex flex-col items-start">
            <label className="text-subtle text-sm">Transaction Cost</label>
            <div className="flex flex-row mt-2">
              <input
                type="checkbox"
                checked={isPayingTransaction}
                className="mr-2"
                onChange={() => {
                  setIsPayingTransaction(!isPayingTransaction);
                }}
              />
              <span className="text-sm italic ">
                I will pay this cost of KES {tipData.tipAmount * 0.025}
              </span>
            </div>
          </div>
        ) : (
          <div></div>
        )} */}
        <div className="mt-6">
          {!tippingState.tippingResponse.success && (
            <button
              className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full"
              onClick={submitTip}
            >
              {locationPath.pathname === "/pay" ? "Pay" : "Send Tip"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
