import "./App.css";
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "./components-shadcn/ui/toaster";

import { Routes, Route, Navigate } from "react-router-dom";
import Registration from "./components/Registration/Registration";
import ServiceRegistration from "./components/Registration/ServiceWorkerReg";
import OnboardingPage from "./components/onboarding/OnboardStart";
import ConfirmCode from "./components/ConfirmCode/confirmCode";
import Search from "./components/tipping/Search";
import Find from "./components/onboarding/Find";
import Login from "./components/Login/Login";
import StartTipping from "./components/onboarding/StartTipping";
import OnboardFund from "./components/onboarding/OnboardFund";
import OnboardTip from "./components/onboarding/OnboardTip";
import TipAmount from "./components/tipping/TipAmount";
import SendTip from "./components/tipping/sendTip";
import Deposit from "./components/Deposit/DepositAmount";
import ConfirmDeposit from "./components/Deposit/ConfirmDeposit";
import WithdrawAmount from "./components/Withdraw/WithdrawAmount";
import ConfirmWithdraw from "./components/Withdraw/ConfirmWithdraw";
import Rate from "./components/Rating/Rate";

import RatingPop from "./components/Rating/RatingPop";
import ServiceUnavailable from "./components/ErrorPages/ServiceUnavailable";
import Dashboard from "./views/Dashboard/Dashboard";
import ProfilePage from "./views/Profile/Profile";
import ForgotPassword from "./views/Password/Forgot";
import VerifyCode from "./views/Password/VerifyCode";
import ProfileAndroidPage from "./views/Profile/ProfileAndroid";
// import UserRole from "./components/UserRole/userRole";
import ReceiveTips from "./components/onboarding/ServiceWorker/ReceiveTips";
import SaveTips from "./components/onboarding/ServiceWorker/SaveTips";
import GetLoans from "./components/onboarding/ServiceWorker/GetLoans";
import ReceiveDividends from "./components/onboarding/ServiceWorker/ReceiveDividends";
import UserType from "./components/Registration/UserType.page";
import TipperType from "./components/Registration/TipperType.page";
import PaymentOption from "./components/tipping/paymentOption";
import CreditCard from "./components/tipping/creditCard";
import MpesaNo from "./components/tipping/mpesaNo";
import Restaurant from "./components/Registration/Restaurant";
import FinalWithdrawal from "./components/Withdraw/FinalWithdraw";
import OnboardingPageSw from "./components/onboarding/OnboardStartSw";
import Explainer from "./components/Registration/Explainer";
import SplashScreen from "./components/Registration/SpashScreen";
import NewUserType from "./components/Registration/NewUserType";
import FirstPersonalInfo from "./components/Sacco/FirstPersonalInfo";
import SecondPersonalInfo from "./components/Sacco/SecondPersonalInfo";
import ThirdBusinessInfo from "./components/Sacco/ThirdBusinessInfo";
import SixthDependantsInfo from "./components/Sacco/SixthDependantsInfo";

import NinthImageUpload from "./components/Sacco/NinthImageUpload";
import TenthDisclaimer from "./components/Sacco/TenthDisclaimer";
import FourthOccupationInfo from "./components/Sacco/FourthOccupationInfo";
import FifthOccupationInfo from "./components/Sacco/FifthOccupationInfo";
import EighthLearnUs from "./components/Sacco/EighthLearnUs";
import SeventhKinInfo from "./components/Sacco/SeventhKinInfo";
import DepositOptions from "./components/Deposit/DepositOptions.js";

import DepositMpesa from "./components/Deposit/DepositMpesa";
import ActivateExplainer from "./components/Sacco/ActivateExplainer";
import SaccoDepositOptions from "./components/Deposit/SaccoDepositOptions";
import AdjustPercentage from "./components/AccessTips/AdjustPercentage";
import AccessTips from "./components/AccessTips/AccessTips";
import AccessTipAmount from "./components/AccessTips/AccessTipAmount";
import MyAccount from "./views/Profile/MyAccount";
import SaccoInfo from "./views/Profile/SaccoInfo";
import Requirements from "./components/Sacco/Requirements";
import QueryMpesaNo from "./components/tipping/QueryMpesaNo";
import MpesaNoOtp from "./components/tipping/mpesaNoOtp";
import SearchRestaurant from "./components/Reservations/SearchRestaurant";
import MakeReservation from "./components/Reservations/MakeReservation";
import CreditInputCard from "./components/tipping/creditInputCard";
import CreditOption from "./components/tipping/creditOption";
import SubmitReservation from "./components/Reservations/SubmitReservation";
import Referrals from "./components/Referrals/Referrals";
import Rewards from "./components/Referrals/Rewards";
import InviteOptions from "./components/Referrals/inviteOptions";
import TransactionDetails from "./components/Transactions/TransactionDetail";
import TransactionHistory from "./components/Transactions/TransactionHistory";
import DepositCreditCard from "./components/Deposit/DepositCreditCard";
import DownloadShukran from "./components/tipping/downloadShukran.js";
import Members from "./components/Members/Members.js";
import Confirm from "./components/Members/Confirm.js";
import AddMember from "./components/Members/AddMember.js";
import TipjarHome from "./components/Tipjar/TipjarHome.js";
import Groups from "./components/Groups/Groups.js";
import AddGroups from "./components/Groups/AddGroup.js";
import EditGroup from "./components/Groups/EditGroup.js";
import ChangeDistribution from "./components/Groups/ChangeDistribution.js";
import TipjarAmount from "./components/Tipjar/TipjarAmount.js";
import ConfirmGroupPerc from "./components/Tipjar/ConfirmGroupPerc.js";
import ConfirmMembers from "./components/Tipjar/ConfirmMembers.js";
import TransactionDetailDisbursement from "./components/Transactions/TransactionDetailDisbursement.js";
import { Provider, useDispatch, useSelector } from "react-redux";
import TipMe from "./components/TipMe/TipMe.js";
import { loadBalanceResults } from "./state/reducers/user/user.thunk.js";
import Stripe from "./components/tipping/Stripe.js";
import PayBill from "./components/BillJar/PayBill.jsx";
import SelectIndividual from "./components/tipping/TipIndividual/SelectIndividual.js";
import TransactionMain from "./components/Transactions/TransactionMain.jsx";
import { Toaster as ToasterSonner } from "./components-shadcn/ui/sonner";
import { persistor, store, storePersist } from "./state/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export default function App() {
  const dispatch = useDispatch();
  const balanceState = useSelector((state) => {
    //@ts-ignore

    return state.balance;
  }); //@ts-ignore

  const userAccount = useSelector((state) => state.account);
  useEffect(() => {
    dispatch(loadBalanceResults({}, userAccount.userDetails.token));
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store()}>
        <div className="flex flex-col items-center border-x-1 border-slate-700 h-auto">
          <Routes>
            <Route path="/onboarding" element={<OnboardingPage />} />
            <Route path="/onboardingSw" element={<OnboardingPageSw />} />
            <Route path="/registration" element={<Registration />} />
            <Route
              path="/service/registration"
              element={<ServiceRegistration />}
            />
            <Route path="/rate" element={<Rate />} />
            <Route path="/successRate" element={<RatingPop />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Navigate to="/" />} />
            <Route path="/confirmCode" element={<ConfirmCode />} />
            <Route path="/confirmMpesaNo" element={<MpesaNoOtp />} />
            <Route path="/adjustPercentage" element={<AdjustPercentage />} />
            <Route path="/accessTips" element={<AccessTips />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/invite" element={<InviteOptions />} />
            <Route path="/download" element={<DownloadShukran />} />
            <Route
              path="/transactions/detail"
              element={<TransactionDetails />}
            />
            <Route
              path="/transactions/detail/disbursement"
              element={<TransactionDetailDisbursement />}
            />
            <Route
              path="/transactions/history"
              element={<TransactionHistory />}
            />
            <Route path="/search" element={<Search />} />
            <Route path="/find" element={<Find />} />
            <Route path="/login" element={<Login />} />
            <Route path="/fund" element={<OnboardFund />} />
            <Route path="/startTipping" element={<StartTipping />} />
            <Route path="/tipOnboard" element={<OnboardTip />} />
            <Route path="/sendTip" element={<SendTip />} />
            <Route path="/tip" element={<TipAmount />} />
            {/* <Route path="/tipAmount" element={<TipAmount />} /> */}
            <Route path="/accessAmount" element={<AccessTipAmount />} />
            <Route path="/deposit" element={<Deposit />} />
            <Route path="/depositOpt" element={<DepositOptions />} />
            <Route path="/depositInputCard" element={<DepositCreditCard />} />
            <Route path="/saccoDepositOpt" element={<SaccoDepositOptions />} />
            <Route path="/confirmDeposit" element={<ConfirmDeposit />} />
            <Route path="/withdraw" element={<WithdrawAmount />} />
            <Route path="/confirmWithdraw" element={<ConfirmWithdraw />} />
            <Route path="/unavailable" element={<ServiceUnavailable />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/profile/android" element={<ProfileAndroidPage />} />
            <Route path="/profile/account" element={<MyAccount />} />
            <Route path="/profile/sacco" element={<SaccoInfo />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/verify" element={<VerifyCode />} />
            <Route path={`/:shukranId`} element={<TipAmount />} />
            {/* <Route path="/userRole" element={<UserRole />} /> */}
            <Route path="/userType" element={<UserType />} />
            <Route path="/tipperType" element={<TipperType />} />
            <Route path="/paymentOpt" element={<PaymentOption />} />
            <Route path="/pay" element={<PaymentOption />} />
            <Route path="/creditOpt" element={<CreditOption />} />
            <Route path="/paystack" element={<CreditCard />} />
            <Route path="/creditInputCard" element={<CreditInputCard />} />
            <Route path="/mpesaNo" element={<MpesaNo />} />
            <Route path="/queryMpesaNo" element={<QueryMpesaNo />} />
            <Route path="/depositMpesa" element={<DepositMpesa />} />
            <Route path="/restaurant" element={<Restaurant />} />
            <Route path="/finalWithdrawal" element={<FinalWithdrawal />} />
            <Route path="/explainer" element={<Explainer />} />
            <Route path="/activateExplainer" element={<ActivateExplainer />} />
            <Route path="/splashScreen" element={<SplashScreen />} />
            <Route path="/newUserType" element={<NewUserType />} />
            <Route path="/bill/pay" element={<PayBill />} />
            {/* Sacco registration routes */}
            <Route path="/activateSacco" element={<FirstPersonalInfo />} />
            <Route path="/stripe" element={<Stripe />} />
            <Route path="/transactions" element={<TransactionMain />} />
            <Route path="/requirements" element={<Requirements />} />
            <Route path="/secondForm" element={<SecondPersonalInfo />} />
            <Route path="/thirdForm" element={<ThirdBusinessInfo />} />
            <Route path="/fourthForm" element={<FourthOccupationInfo />} />
            <Route path="/fifthForm" element={<FifthOccupationInfo />} />
            <Route path="/sixthForm" element={<SixthDependantsInfo />} />
            <Route path="/eighthForm" element={<EighthLearnUs />} />
            <Route path="/seventhForm" element={<SeventhKinInfo />} />
            <Route path="/ninthForm" element={<NinthImageUpload />} />
            <Route path="/tenthForm" element={<TenthDisclaimer />} />
            <Route path="/tip/individual" element={<SelectIndividual />} />
            {/* Service Worker onboarding routes */}
            <Route path="/receiveTips" element={<ReceiveTips />} />
            <Route path="/saveTips" element={<SaveTips />} />
            <Route path="/getLoans" element={<GetLoans />} />
            <Route path="/receiveDividends" element={<ReceiveDividends />} />
            {/* make reservations routes */}
            <Route path="/searchRestaurant" element={<SearchRestaurant />} />
            <Route path="/makeReservation" element={<MakeReservation />} />
            <Route path="/submitReservation" element={<SubmitReservation />} />
            <Route path="/tipjar/" element={<TipjarHome />} />
            <Route path="/tipjar/members" element={<Members />} />
            <Route path="/tipjar/groups" element={<Groups />} />
            <Route path="/tipjar/groups/edit" element={<EditGroup />} />
            <Route
              path="/tipjar/groups/distribution"
              element={<ChangeDistribution />}
            />
            <Route path="/tipjar/groups/add" element={<AddGroups />} />
            <Route path="/tipjar/members/add" element={<AddMember />} />
            <Route path="/tipjar/members/confirm" element={<Confirm />} />
            <Route path="/tipjar/amount" element={<TipjarAmount />} />
            <Route path="/tipjar/confirm" element={<ConfirmGroupPerc />} />
            <Route
              path="/tipjar/confirm/members"
              element={<ConfirmMembers />}
            />
          </Routes>
          <Toaster />
          <ToasterSonner />
        </div>
      </Provider>
    </QueryClientProvider>
  );
}
