import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FacebookIcon from "../../assets/Icons/Referrals/facebook.svg";
import TwitterIcon from "../../assets/Icons/Referrals/twitter.svg";
import CopyIcon from "../../assets/Icons/Referrals/clipboard-copy.svg";
import EmailIcon from "../../assets/Icons/Referrals/email.svg";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import Share from "../Dashboard/Share";
import BackButton from "../globals/BackButton";

export default function InviteOptions() {
  const [phoneNumber, setPhoneNumber] = useState();
  const [shareUrl, setShareUrl] = useState("");
  const [emailShareUrl, setEmailShareUrl] = useState("");
  const [twitterShareUrl, setTwitterShareUrl] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [fallbackOption, setFallbackOption] = useState(false);
  const [amounts, setAmounts] = useState([
    {
      option: "share",
      label: "Send a Link",
    },
    {
      option: "tip",
      label: "Tip a Friend",
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    let emailUrl = `mailto:?subject="Have you heard of Shukran?"&body=Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit ${url}`;
    let twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${"Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit "}`;
    setShareUrl(url);
    setEmailShareUrl(emailUrl);
    setTwitterShareUrl(twitterUrl);
    return () => {};
  }, []);

  const onFacebookShare = (e) => {
    e.preventDefault();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      "facebook-share-dialog",
      "width=800,height=600"
    );
  };

  const onCopyToClipboard = (e) => {
    e.preventDefault();
    let strVal =
      "Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit " +
      shareUrl;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(strVal)
        .then(() => setIsCopied(true))
        .catch(console.error);
    }
  };

  // Restarts the copy state after a few seconds
  useEffect(() => {
    if (!isCopied) return;

    const timer = setTimeout(() => {
      setIsCopied((currentIsCopied) => !currentIsCopied);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isCopied]);

  const handleSetOption = (otpGroup) => {
    if (otpGroup.option === "tip") {
      navigate("/search");
    } else {
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const FallBackOptions = () => (
    <div className="flex flex-col items-center drop-shadow-2xl rounded-t-2xl bg-white pb-auto w-full z-30">
      <div className="flex flex-row-reverse w-full">
        <div
          className="text-base text-pink-400 rounded-2xl py-1 px-3 cursor-pointer"
          onClick={() => {
            setFallbackOption(false);
          }}
        >
          X
        </div>
      </div>
      <div className="flex flex-row bg-white items-center justify-around w-full pb-4">
        <div
          className="h-14 w-14 flex flex-col items-center justify-content"
          onClick={onFacebookShare}
        >
          <img src={FacebookIcon} alt="icon" />
          <span className="text-xs">Facebook</span>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={twitterShareUrl}
          className="h-14 w-14 flex flex-col items-center justify-content"
        >
          <img src={TwitterIcon} alt="icon" />
          <span className="text-xs">Twitter</span>
        </a>
        <div
          className="h-14 w-14 flex flex-col items-center justify-content"
          onClick={onCopyToClipboard}
        >
          <img src={CopyIcon} alt="icon" />
          <span className="text-xs text-center">
            {isCopied ? "Copied!" : "Copy to clipboard"}
          </span>
        </div>
        <a
          href={emailShareUrl}
          title="Have you heard of Shukran?"
          className="h-14 w-14 flex flex-col items-center justify-content"
        >
          <img src={EmailIcon} alt="email" />
          <span className="text-xs">Email</span>
        </a>
      </div>
    </div>
  );

  return (
    <div
      className={`
        ${fallbackOption ? "relative bg-black/30" : ""}
         w-full flex flex-col`}
    >
      <div
        className={`
          ${fallbackOption ? "blur-sm brightness-50" : ""} 
          flex flex-col px-3 w-full h-screen
        `}
      >
        {/* <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center py-2 pl-2 pr-6`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
          {/* <span className="text-base">Go back home</span> 
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div className="mx-2 mt-11 h-full">
          <span className="text-3xl font-semibold text-primary-pwa">
            How would you like to invite your friends?
          </span>
          <ul className="mt-4 bg-white">
            <Share
              setFallbackOption={setFallbackOption}
              inviteOption={"invite-tipper"}
              title="Have you heard of Shukran?"
              text="Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit"
            />
            <li
              className={`flex flex-row justify-between items-center p-4 bg-accent-light-2 rounded-md border-2 mt-2 border-gray`}
              onClick={() => handleSetOption(amounts[1])}
            >
              <div className="flex flex-row items-center">
                <div className="py-1 px-2 text-3xl bg-[#EE5582] rounded-full">
                  😉
                </div>
                <span className="ml-4">{amounts[1].label}</span>
              </div>
              <img src={RightIcon} alt="icon" />
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`${
          fallbackOption ? "fixed blur-none bottom-1" : "hidden"
        } flex flex-col items-center w-full`}
      >
        <FallBackOptions />
      </div>
    </div>
  );
}
