import React, { useState } from "react";
import PrimaryGlobalText from "../globals/PrimaryGlobalText";
import Desc from "../globals/Desc";
import { Container, Radio, Rating } from "./RatingStyle";
import { FaStar } from "react-icons/fa";

export default function RateRestaurant() {
  const [rate, setRate] = useState(0);
  return (
    <div className="w-screen h-screen absolute bg-white items-center p-4">
      <div className="w-full flex flex-row justify-end">
        {/* <p className="flex text-right" style={{ fontWeight: "700" }}>
          X
        </p> */}
        <i className="fa-solid fa-x text-lg"></i>
      </div>
      <div className="mt-10">
        <PrimaryGlobalText
          text="We value your feedback"
          fontSize="30px"
          fontWeight="700"
        />
        <div className="mt-3">
          <Desc
            color={"#404B5A"}
            text={"How was the restaurant service?"}
            fontSize={"20px"}
          />
        </div>
        <div>
          <Container>
            {[...Array(5)].map((item, index) => {
              const givenRating = index + 1;
              return (
                <label>
                  {/* <Radio
                    type="radio"
                    value={givenRating}
                    onClick={() => {
                      setRate(givenRating);
                      alert(
                        `Are you sure you want to give 
                                    ${givenRating} stars ?`
                      );
                    }}
                  /> */}
                  <Rating
                    onClick={() => {
                      setRate(givenRating);
                      console.log(index);
                      // alert(
                      //   `Are you sure you want to give
                      //               ${givenRating} stars ?`
                      // );
                    }}
                  >
                    {/* <FaStar color={"red"} /> */}
                    {givenRating < rate + 1 ? (
                      <i class="fa-solid fa-star text-accent"></i>
                    ) : (
                      <i class="fa-regular fa-star"></i>
                    )}
                  </Rating>
                </label>
              );
            })}
          </Container>
        </div>
      </div>
    </div>
  );
}
