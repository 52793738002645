const saveToSessionStorage = async (key, value) => {
    window.sessionStorage.setItem(key, value);
}

const getFromSessionStorage = (key) => {
    return window.sessionStorage.getItem(key) || '';
}

export {
    saveToSessionStorage,
    getFromSessionStorage
}