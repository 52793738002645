import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import checked from "../../assets/Icons/checked.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";

const Explainer = () => {
  const { register, handleSubmit } = useForm();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const { saccoType } = balanceState;

  useEffect(() => {
    localStorage.setItem("hasSeenOnboarding", true);
    return () => {};
  }, []);

  const handleProceed = () => {
    navigate("/finalWithdrawal");
  };

  return (
    <div className={`w-screen h-full flex flex-col bg-imag-dash`}>
      <div className={`flex w-full h-full flex-col justify-between text-black`}>
        <div className="flex flex-row justify-start mt-2 px-5">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col justify-between pt-10 bg-white rounded-t-3xl px-8 pb-3 h-4/5">
          <div className="flex flex-col">
            <span className="text-primary-green text-base mb-3">
              Hi there, 👋
            </span>
            <span className="text-primary-green text-base">
              Hi there, Please withdraw your money to your M-PESA account
            </span>
          </div>
          {/* <span className="flex flex-row items-center justify-between mt-9 mb-5">
            <img src={checked} alt="checked" className="rounded h-6 w-6" />
            <span className="text-base pl-7">
              As a service worker select receive tips to enjoy the benefits of
              receiving tips, saving tips, getting loans and earning dividends.
            </span>
          </span> */}

          {/* <span className="flex flex-row items-center justify-between mb-5">
            <img src={checked} alt="checked" className="rounded h-6 w-6" />
            <span className="text-base pl-7">
              As a tipper select give tips to directly send tips from M-pesa or
              Card to a service worker’s Shukran account.
            </span>
          </span> */}

          {/* <span className="flex flex-row items-center justify-between mb-5">
            <img src={checked} alt="checked" className="rounded h-6 w-6" />
            <span className="text-base pl-7">
              You can also select give tips and also opt into joining the SACCO
              and enjoy same benefits as service workers.{" "}
            </span>
          </span> */}

          <button
            className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
            type="submit"
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default Explainer;
