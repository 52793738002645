export const actionTypes = {
  UPDATE_PLACE_DATA: "UPDATE_PLACE_DATA",
  UPDATE_PAYLOAD_DETAILS: "UPDATE_PAYLOAD_DETAILS",
  UPDATE_RESTAURANT: "UPDATE_RESTAURANT",
  SEARCH_RESTAURANTS_START: "SEARCH_RESTAURANTS_START",
  SEARCH_RESTAURANTS_SUCCESS: "SEARCH_RESTAURANTS_SUCCESS",
  SEARCH_RESTAURANTS_ERROR: "SEARCH_RESTAURANTS_ERROR",
  GET_RESERVATION_HISTORY_START: "GET_RESERVATION_HISTORY_START",
  GET_RESERVATION_HISTORY_SUCCESS: "GET_RESERVATION_HISTORY_SUCCESS",
  GET_RESERVATION_HISTORY_ERROR: "GET_RESERVATION_HISTORY_ERROR",
  MAKE_RESERVATION_START: "MAKE_RESERVATION_START",
  MAKE_RESERVATION_SUCCESS: "MAKE_RESERVATION_SUCCESS",
  MAKE_RESERVATION_ERROR: "MAKE_RESERVATION_ERROR",
}

const updatePlaceDetails = (data) => ({
  type: actionTypes.UPDATE_PLACE_DATA,
  payload: data,
});

const updatePayloadDetails = (data) => ({
  type: actionTypes.UPDATE_PAYLOAD_DETAILS,
  payload: data,
});

const updateRestaurant = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT,
  payload: data,
});


const searchRestaurantStart = () => ({
  type: actionTypes.SEARCH_RESTAURANTS_START
})

const searchRestaurantSuccess = (data) => ({
  type: actionTypes.SEARCH_RESTAURANTS_SUCCESS,
  payload: data
})

const searchRestaurantError = (data) => ({
  type: actionTypes.SEARCH_RESTAURANTS_ERROR,
  payload: data
})

const makeReservationStart = () => ({
  type: actionTypes.MAKE_RESERVATION_START
})

const makeReservationSuccess = (data) => ({
  type: actionTypes.MAKE_RESERVATION_SUCCESS,
  payload: data
})

const makeReservationError = (data) => ({
  type: actionTypes.makeReservationError,
  payload: data
})

const getReservationHistoryStart = () => ({
  type: actionTypes.GET_RESERVATION_HISTORY_START
})

const getReservationHistorySuccess = (data) => ({
  type: actionTypes.GET_RESERVATION_HISTORY_SUCCESS,
  payload: data
})

const getReservationHistoryError = (data) => ({
  type: actionTypes.GET_RESERVATION_HISTORY_ERROR,
  payload: data
})

export const reservationActions = {
  updatePlaceDetails,
  updatePayloadDetails,
  updateRestaurant,
  searchRestaurantStart,
  searchRestaurantSuccess,
  searchRestaurantError,
  getReservationHistoryStart,
  getReservationHistorySuccess,
  getReservationHistoryError,
  makeReservationStart,
  makeReservationSuccess,
  makeReservationError
}