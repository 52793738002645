import { actionTypes } from "./business.actions";

const searchState = {
  isLoading: false,
  isUpdateLoading: false,
  results: [],
  updateResults: {},
  errorMessage: "",
};

const checkIfObj = (payload) => {
  if (payload.constructor.name === "Object") {
    let madeArr = [payload];
    console.log("array payload", madeArr);
    return madeArr;
  } else if (!payload.length) {
    return [
      {
        id: 3,
        name: "Business Two",
        address: "Langata",
        contact_email: "biz2@gmail.com",
        contact_phone: "0700000000",
        created_at: "2022-06-15T20:12:38.579608+03:00",
        updated_at: "2022-06-15T20:12:38.579614+03:00",
      },
      {
        id: 1,
        name: "Crescent",
        address: "Langata Road next to Uhuru Gardens",
        contact_email: "crescent@gmail.com",
        contact_phone: "0740867355",
        created_at: "2022-06-14T23:22:05.299037+03:00",
        updated_at: "2022-06-14T23:22:05.299053+03:00",
      },
    ];
  } else {
    return payload;
  }
};

const businessReducer = (state = searchState, { type, payload }) => {
  switch (type) {
    case actionTypes.SEARCH_BUSINESS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.SEARCH_BUSINESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: checkIfObj(payload),
      };
    case actionTypes.SEARCH_BUSINESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.GET_SW_BUSINESS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.GET_SW_BUSINESS_SUCCESS:
      console.log(payload, "get business");
      return {
        ...state,
        isLoading: false,
        results: checkIfObj(payload),
      };
    case actionTypes.GET_SW_BUSINESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.UPDATE_SW_BUSINESS_START:
      return {
        ...state,
        isUpdateLoading: true,
        errorMessage: "",
        updateResults: [],
      };
    case actionTypes.UPDATE_SW_BUSINESS_SUCCESS:
      console.log(payload, "update business");
      return {
        ...state,
        isUpdateLoading: false,
        updateResults: checkIfObj(payload),
      };
    case actionTypes.UPDATE_SW_BUSINESS_ERROR:
      return {
        ...state,
        isUpdateLoading: false,
        errorMessage: payload,
      };
    case actionTypes.SEARCH_BUSINESS_CLEAR:
      return {
        ...state,
        isLoading: false,
        results: [],
      };
    default:
      return state;
  }
};
export default businessReducer;
