export const actionTypes = {
  BALANCE_LOAD_START: "BALANCE_LOAD_START",
  BALANCE_LOAD_SUCCESS: "BALANCE_LOAD_SUCCESS",
  BALANCE_LOAD_ERROR: "BALANCE_LOAD_ERROR",
  TRANSACTION_LOAD_ERROR: "TRANSACTION_LOAD_ERROR",
  TRANSACTION_LOAD_START: "TRANSACTION_LOAD_START",
  TRANSACTION_LOAD_SUCCESS: "TRANSACTION_LOAD_SUCCESS",
  CARD_TRANSACTION_ERROR: "CARD_TRANSACTION_ERROR",
  CARD_TRANSACTION_START: "CARD_TRANSACTION_START",
  CARD_TRANSACTION_SUCCESS: "CARD_TRANSACTION_SUCCESS",
  TIPPING_ERROR: "TIPPING_ERROR",
  TIPPING_START: "TIPPING_START",
  TIPPING_SUCCESS: "TIPPING_SUCCESS",
  CHECK_TIPPING_ERROR: "CHECK_TIPPING_ERROR",
  CHECK_TIPPING_START: "CHECK_TIPPING_START",
  CHECK_TIPPING_SUCCESS: "CHECK_TIPPING_SUCCESS",
  CHECK_TIPPING_REDIRECT: "CHECK_TIPPING_REDIRECT",
  USER_DATA_START: "USER_DATA_START",
  USER_DATA_SUCCESS: "USER_DATA_SUCCESS",
  USER_DATA_ERROR: "USER_DATA_ERROR",
  USER_TYPE: "USER_TYPE",
  SACCO_TYPE: "SACCO_TYPE",
  SUBSCRIPTION_START: "SUBSCRIPTION_START",
  SUBSCRIPTION_SUCCESS: "SUBSCRIPTION_SUCCESS",
  SUBSCRIPTION_ERROR: "SUBSCRIPTION_ERROR",
  POST_SUBSCRIPTION_START: "POST_SUBSCRIPTION_START",
  POST_SUBSCRIPTION_SUCCESS: "POST_SUBSCRIPTION_SUCCESS",
  POST_SUBSCRIPTION_ERROR: "POST_SUBSCRIPTION_ERROR",
  GENERATE_TOKEN_START: "GENERATE_TOKEN_START",
  GENERATE_TOKEN_SUCCESS: "GENERATE_TOKEN_SUCCESS",
  GENERATE_TOKEN_ERROR: "GENERATE_TOKEN_ERROR",
  GENERATE_TOKEN_RESET: "GENERATE_TOKEN_RESET",
  VERIFY_TOKEN_START: "VERIFY_TOKEN_START",
  VERIFY_TOKEN_SUCCESS: "VERIFY_TOKEN_SUCCESS",
  VERIFY_TOKEN_ERROR: "VERIFY_TOKEN_ERROR",
  SEND_NOTIFICATION_START: "SEND_NOTIFICATION_START",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_ERROR: "SEND_NOTIFICATION_ERROR",
  DEACTIVATE_CARD_START: "DEACTIVATE_CARD_START",
  DEACTIVATE_CARD_SUCCESS: "DEACTIVATE_CARD_SUCCESS",
  DEACTIVATE_CARD_ERROR: "DEACTIVATE_CARD_ERROR",
  TIPJAR_DATA_START: "TIPJAR_DATA_START",
  TIPJAR_DATA_SUCCESS: "TIPJAR_DATA_SUCCESS",
  TIPJAR_DATA_ERROR: "TIPJAR_DATA_ERROR",
  TRANSACTION_TIPJAR_LOAD_START: "TRANSACTION_TIPJAR_LOAD_START",
  TRANSACTION_TIPJAR_LOAD_SUCCESS: "TRANSACTION_TIPJAR_LOAD_SUCCESS",
  TRANSACTION_TIPJAR_LOAD_ERROR: "TRANSACTION_TIPJAR_LOAD_ERROR",
};

const balanceLoadStart = () => ({
  type: actionTypes.BALANCE_LOAD_START,
});

const balanceLoadSuccess = (results) => ({
  type: actionTypes.BALANCE_LOAD_SUCCESS,
  payload: results,
});

const balanceLoadError = (errorMsg) => ({
  type: actionTypes.BALANCE_LOAD_ERROR,
  payload: errorMsg,
});

const subscriptionLoadStart = () => ({
  type: actionTypes.SUBSCRIPTION_START,
});

const subscriptionLoadSuccess = (results) => ({
  type: actionTypes.SUBSCRIPTION_SUCCESS,
  payload: results,
});

const subscriptionLoadError = (errorMsg) => ({
  type: actionTypes.SUBSCRIPTION_ERROR,
  payload: errorMsg,
});

const sendNotificationStart = () => ({
  type: actionTypes.SEND_NOTIFICATION_START,
});

const sendNotificationSuccess = (results) => ({
  type: actionTypes.SEND_NOTIFICATION_SUCCESS,
  payload: results,
});

const sendNotificationError = (errorMsg) => ({
  type: actionTypes.SEND_NOTIFICATION_ERROR,
  payload: errorMsg,
});

const transactionLoadStart = () => ({
  type: actionTypes.TRANSACTION_LOAD_START,
});

const transactionLoadSuccess = (results) => ({
  type: actionTypes.TRANSACTION_LOAD_SUCCESS,
  payload: results,
});

const transactionLoadError = (errorMsg) => ({
  type: actionTypes.TRANSACTION_LOAD_ERROR,
  payload: errorMsg,
});

const cardTransactionsStart = () => ({
  type: actionTypes.CARD_TRANSACTION_START,
});

const cardTransactionsSuccess = (results) => ({
  type: actionTypes.CARD_TRANSACTION_SUCCESS,
  payload: results,
});

const cardTransactionsError = (errorMsg) => ({
  type: actionTypes.CARD_TRANSACTION_ERROR,
  payload: errorMsg,
});

const postSubscriptionStart = () => ({
  type: actionTypes.POST_SUBSCRIPTION_START,
});

const postSubscriptionSuccess = (results) => ({
  type: actionTypes.POST_SUBSCRIPTION_SUCCESS,
  payload: results,
});

const postSubscriptionError = (errorMsg) => ({
  type: actionTypes.POST_SUBSCRIPTION_ERROR,
  payload: errorMsg,
});

const tippingStart = () => ({
  type: actionTypes.TIPPING_START,
});

const tippingSuccess = (results) => ({
  type: actionTypes.TIPPING_SUCCESS,
  payload: results,
});

const tippingError = (errorMsg) => ({
  type: actionTypes.TIPPING_ERROR,
  payload: errorMsg,
});
const checkTippingStart = () => ({
  type: actionTypes.CHECK_TIPPING_START,
});

const checkTippingRedirect = (msg) => ({
  type: actionTypes.CHECK_TIPPING_REDIRECT,
  payload: msg,
});

const checkTippingSuccess = (results) => ({
  type: actionTypes.CHECK_TIPPING_SUCCESS,
  payload: results,
});

const checkTippingError = (errorMsg) => ({
  type: actionTypes.CHECK_TIPPING_ERROR,
  payload: errorMsg,
});
const getUserDataStart = () => ({
  type: actionTypes.USER_DATA_START,
});

const getUserDataSuccess = (results) => ({
  type: actionTypes.USER_DATA_SUCCESS,
  payload: results,
});

const getUserDataError = (errorMsg) => ({
  type: actionTypes.USER_DATA_ERROR,
  payload: errorMsg,
});
const getTipJarDataStart = () => ({
  type: actionTypes.TIPJAR_DATA_START,
});

const getTipJarDataSuccess = (results) => ({
  type: actionTypes.TIPJAR_DATA_SUCCESS,
  payload: results,
});

const getTipJarDataError = (errorMsg) => ({
  type: actionTypes.TIPJAR_DATA_ERROR,
  payload: errorMsg,
});

const generateTokenStart = () => ({
  type: actionTypes.GENERATE_TOKEN_START,
});

const generateTokenSuccess = (results) => ({
  type: actionTypes.GENERATE_TOKEN_SUCCESS,
  payload: results,
});

const generateTokenError = (errorMsg) => ({
  type: actionTypes.GENERATE_TOKEN_ERROR,
  payload: errorMsg,
});
const generateTokenReset = () => ({
  type: actionTypes.GENERATE_TOKEN_RESET,
});

const verifyTokenStart = () => ({
  type: actionTypes.VERIFY_TOKEN_START,
});
const verifyTokenSuccess = (results) => ({
  type: actionTypes.VERIFY_TOKEN_SUCCESS,
  payload: results,
});
const verifyTokenError = (errorMsg) => ({
  type: actionTypes.VERIFY_TOKEN_ERROR,
  payload: errorMsg,
});
const setUserType = (payload) => ({
  type: actionTypes.USER_TYPE,
  payload: payload,
});

const setSaccoType = (payload) => ({
  type: actionTypes.SACCO_TYPE,
  payload: payload,
});

const deactivateCardStart = () => ({
  type: actionTypes.DEACTIVATE_CARD_START,
});
const deactivateCardSuccess = (results) => ({
  type: actionTypes.DEACTIVATE_CARD_SUCCESS,
  payload: results,
});
const deactivateCardError = (errorMsg) => ({
  type: actionTypes.DEACTIVATE_CARD_ERROR,
  payload: errorMsg,
});
const getTipjarTransactionsStart = () => ({
  type: actionTypes.TRANSACTION_TIPJAR_LOAD_START,
});

const getTipjarTransactionsSuccess = (results) => ({
  type: actionTypes.TRANSACTION_TIPJAR_LOAD_SUCCESS,
  payload: results,
});

const getTipjarTransactionsError = (errorMsg) => ({
  type: actionTypes.TRANSACTION_TIPJAR_LOAD_ERROR,
  payload: errorMsg,
});

export const balanceActions = {
  balanceLoadStart,
  balanceLoadSuccess,
  balanceLoadError,
  transactionLoadStart,
  transactionLoadSuccess,
  transactionLoadError,
  cardTransactionsStart,
  cardTransactionsSuccess,
  cardTransactionsError,
  tippingStart,
  tippingSuccess,
  tippingError,
  checkTippingStart,
  checkTippingSuccess,
  checkTippingError,
  checkTippingRedirect,
  getUserDataStart,
  getUserDataSuccess,
  getUserDataError,
  getTipJarDataStart,
  getTipJarDataSuccess,
  getTipJarDataError,
  subscriptionLoadStart,
  subscriptionLoadSuccess,
  subscriptionLoadError,
  postSubscriptionStart,
  postSubscriptionSuccess,
  postSubscriptionError,
  setUserType,
  setSaccoType,
  generateTokenStart,
  generateTokenSuccess,
  generateTokenError,
  verifyTokenStart,
  verifyTokenSuccess,
  verifyTokenError,
  generateTokenReset,
  sendNotificationStart,
  sendNotificationSuccess,
  sendNotificationError,
  deactivateCardStart,
  deactivateCardSuccess,
  deactivateCardError,
  getTipjarTransactionsStart,
  getTipjarTransactionsSuccess,
  getTipjarTransactionsError,
};
