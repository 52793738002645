import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OnboardWelcome from "../../assets/Images/onboarding/Welcome.png";
import BaseOnboard from "./BoilerPlate/BaseOnboard";
import { getFromSessionStorage } from "../../Services/Storage";
import { useSelector } from "react-redux";

export default function OnboardingPageSw() {
  const userRole = getFromSessionStorage("role");
  const userData = useSelector((state) => state.balance);
  const { userType } = userData;
  const textContent =
    "We at Shukran SACCO, empower you to reach your financial goals";
  const contentTitle = "KARIBU";

  return (
    <BaseOnboard
      pageImage={OnboardWelcome}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/receiveTips"
      navigatePrev="/userRole"
    />
  );
}
