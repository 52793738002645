import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import camera from "../../assets/Icons/camera.svg";
import nineOfTen from "../../assets/Illustrations/sacco/nine_of_ten.svg";
import fourOfFive from "../../assets/Illustrations/sacco/fiveOfFive.svg";
import NoFileIcon from "../../assets/Icons/no_file.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import { useDispatch, useSelector } from "react-redux";
import ReactImageUploading from "react-images-uploading";
import {
  getUserData,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const NinthImageUpload = () => {
  const [imageURLs, setImageURLs] = useState([]);
  const [kraError, setKraError] = useState();
  const [idError, setIdError] = useState();
  const [backError, setBackError] = useState();
  const [passportError, setPassportError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userAccount = useSelector((state) => state.account);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const [nationalIdImage, setNationalIdImage] = useState([]);

  const [nationalIdImageBack, setNationalIdImageBack] = useState([]);
  const [kraImage, setKraImage] = useState([]);
  const [passportImage, setPassportImage] = useState([]);
  const [nationalIdUrl, setNationalIdUrl] = useState([]);
  const [nationalIdUrlBack, setNationalIdUrlBack] = useState([]);
  const [kraUrl, setKraUrl] = useState([]);
  const [passportUrl, setPassportUrl] = useState([]);

  const maxNumber = 1;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, "national ID image");
    console.log(addUpdateIndex, "national ID image idx");
    if (imageList.length) {
      let fileSize = formatBytes(imageList[0].file.size);
      console.log("file size", fileSize);
      fileSize = fileSize.split(" ");
      if (fileSize[1] === "KB") {
        setNationalIdImage(imageList);
        setIdError("");
      } else {
        if (Number(fileSize[0]) <= 6 && fileSize[1] === "MB") {
          setNationalIdImage(imageList);
          setIdError("");
        } else {
          setIdError("Please select a file smaller than 6 MB");
        }
      }
    } else {
      setNationalIdImage("");
    }
  };

  const onNationalIdBackChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, "national ID image");
    console.log(addUpdateIndex, "national ID image idx");
    if (imageList.length) {
      let fileSize = formatBytes(imageList[0].file.size);
      console.log("file size", fileSize);
      fileSize = fileSize.split(" ");
      if (fileSize[1] === "KB") {
        setNationalIdImageBack(imageList);
        setBackError("");
      } else {
        if (Number(fileSize[0]) <= 6 && fileSize[1] === "MB") {
          setNationalIdImageBack(imageList);
          setBackError("");
        } else {
          setBackError("Please select a file smaller than 6 MB");
        }
      }
    } else {
      setNationalIdImageBack("");
    }
  };

  const onKraChange = (imageList, addUpdateIndex) => {
    // data for submit
    if (imageList.length) {
      let fileSize = formatBytes(imageList[0].file.size);
      console.log("file size", fileSize);
      fileSize = fileSize.split(" ");
      if (fileSize[1] === "KB") {
        setKraImage(imageList);
        setKraError("");
      } else {
        if (Number(fileSize[0]) <= 6 && fileSize[1] === "MB") {
          setKraImage(imageList);
          setKraError("");
        } else {
          setKraError("Please select a file smaller than 6 MB");
        }
      }
    } else {
      setKraImage("");
    }
  };
  const onPassportChange = (imageList, addUpdateIndex) => {
    if (imageList.length) {
      let fileSize = formatBytes(imageList[0].file.size);
      console.log("file size", fileSize);
      fileSize = fileSize.split(" ");
      if (fileSize[1] === "KB") {
        setPassportImage(imageList);
        setPassportError("");
      } else {
        if (Number(fileSize[0]) <= 6 && fileSize[1] === "MB") {
          setPassportImage(imageList);
          setPassportError("");
        } else {
          setPassportError("Please select a file smaller than 6 MB");
        }
      }
    } else {
      setPassportImage("");
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saccoState = useSelector((state) => state.sacco);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    dispatch(getUserData(params, userAccount.userDetails.token));

    return () => {};
  }, []);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
    ? localStorage.getItem("origin")
    : "false";
    originState = JSON.parse(originState)
    if (balanceState.errorMessage === 403 && !originState) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  const handleSkip = () => {
    navigate("/ninthForm");
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      console.log("sacco details", saccoState.saccoDetails.id_passport_photo);
      console.log(
        "sacco details",
        saccoState.saccoDetails.id_passport_back_photo
      );
      setValue(
        "kra",
        saccoState.saccoDetails.kra_pin !== "null"
          ? saccoState.saccoDetails.kra_pin
          : ""
      );
      if (typeof saccoState.saccoDetails.id_passport_photo !== "string") {
        setNationalIdImage(
          saccoState.saccoDetails.id_passport_photo
            ? saccoState.saccoDetails.id_passport_photo
            : []
        );
      } else {
        setNationalIdUrl(saccoState.saccoDetails.id_passport_photo);
      }
      if (typeof saccoState.saccoDetails.id_passport_back_photo !== "string") {
        setNationalIdImageBack(
          saccoState.saccoDetails.id_passport_back_photo
            ? saccoState.saccoDetails.id_passport_back_photo
            : []
        );
      } else {
        setNationalIdUrlBack(saccoState.saccoDetails.id_passport_back_photo);
      }

      // if (typeof saccoState.saccoDetails.kra_pin_photo !== "string") {
      //   setKraImage(
      //     saccoState.saccoDetails.kra_pin_photo
      //       ? saccoState.saccoDetails.kra_pin_photo
      //       : []
      //   );
      // } else {
      //   setKraUrl(saccoState.saccoDetails.kra_pin_photo);
      // }
      if (typeof saccoState.saccoDetails.passport_photo !== "string") {
        setPassportImage(
          saccoState.saccoDetails.passport_photo
            ? saccoState.saccoDetails.passport_photo
            : []
        );
      } else {
        setPassportUrl(saccoState.saccoDetails.passport_photo);
      }
    }
    dispatch(saccoActions.saccoActivateError(""));
    return () => {
      setIdError("");
      setKraError("");
      setPassportError("");
    };
  }, []);

  const handleImageUploadErrors = () => {
    // let msg = !nationalIdImage.length
    //   ? "Please upload the FRONT of your national id"
    //   : "";

    // let msgBack = !nationalIdImage.length
    //   ? "Please upload the BACK of your national id"
    //   : "";
    let kraMsg = !kraImage.length ? "Please upload your KRA document" : "";
    let passportMsg = !passportImage.length
      ? "Please upload your passport photo"
      : "";
    // setIdError(msg);
    // setBackError(msgBack);
    setKraError(kraMsg);
    setPassportError(passportMsg);
  };

  const handleNinthImageUpload = (data) => {
    console.log("sacco details submit", data);
    if (
      // !nationalIdImage.length ||
      // !nationalIdImageBack.length ||
      // !kraImage.length ||
      !passportImage.length
    ) {
      handleImageUploadErrors();
    } else {
      let payload = {
        // kra_pin: data.kra,
        id_passport_photo: nationalIdImage,
        id_passport_back_photo: nationalIdImageBack,
        kra_pin_photo: nationalIdImage,
        passport_photo: passportImage,
        e_signature_photo: passportImage,
      };
      dispatch(saccoActions.updateSaccoDetails(payload));
      navigate("/tenthForm");
    }
  };

  const SaccoActivationError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage.msg}
        </p>
      )}
      {saccoState.errorMessage.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage.data.data)[0]}
        </p>
      )}
      {saccoState.errorMessage.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/");
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation details have been partially submitted"
          : ""}
      </p>
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/");
        }}
      >
        Home
      </button>
    </div>
  );

  return (
    <div
      className={`
    ${isLoading ? "relative" : ""} w-full flex flex-col h-screen 
     ${saccoState.isLoading ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""}
    `}
    >
      <div
        className={`
          ${isLoading ? "blur-sm" : ""}
           ${saccoState.isLoading ? "blur-sm" : ""}
         ${saccoState.errorMessage ? "blur-sm" : ""}
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={ShukranLogoSacco}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={fourOfFive}
            alt="nine of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 pb-20 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">Upload Documents</h2>
          <span className="text-base my-1 flex flex-row">
            Kindly take a picture{" "}
            <img
              src={camera}
              alt="logo of shukran"
              className="rounded ml-2 h-5 w-5"
            />{" "}
          </span>
          <span> of your documents or add from your camera roll </span>
          <form
            className="flex flex-col justify-between h-full mt-3"
            onSubmit={handleSubmit(handleNinthImageUpload)}
          >
            <div>
              {/* <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">KRA number *</label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="A10H03XXXXX"
                  {...register("kra", {
                    required: true,
                    maxLength: 12,
                  })}
                />
                {errors.kra && (
                  <p className="text-red-600 text-sm">
                    Ensure you have entered in a KRA pin
                  </p>
                )}
              </div> */}
              <div className="flex flex-col">
                <div className="flex flex-row mt-1 items-center">
                  <div className="flex flex-col w-full">
                    <label className="mt-2 mb-2 text-label text-xs">
                      National ID (front) *
                    </label>
                    <ReactImageUploading
                      allowNonImageType
                      value={nationalIdImage}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png", "pdf"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="flex flex-row justify-between">
                          <div
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-1/2 p-2 text-black outline-none flex flex-col"
                            {...dragProps}
                          >
                            Choose File
                          </div>
                          &nbsp;
                          {imageList?.length > 0 ? (
                            <></>
                          ) : (
                            <div className="flex flex-row-reverse items-center w-full">
                              <span>No File Selected</span>
                              <img
                                src={NoFileIcon}
                                className="w-8 h-8"
                                alt="Previous"
                              />
                            </div>
                          )}
                          {imageList?.map((image, index) => (
                            <div
                              key={index}
                              className="flex flex-col w-1/3 items-center"
                            >
                              <div className="flex flex-row justify-between mt-2">
                                <div
                                  className="bg-primary-green p-1 mr-2 rounded-sm text-white"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </div>
                                <div
                                  className="bg-primary-green p-1 rounded-sm text-white"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </div>
                              </div>
                              <img
                                src={image.data_url}
                                alt=""
                                className="h-auto w-full mt-3"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                    {nationalIdImage[0]?.file?.type === "application/pdf" && (
                      <div className="mt-1 pb-1 flex flex-row-reverse w-full">
                        <div className="text-black text-sm w-1/3 ml-3 break-all bg-yellow-100 rounded-md px-2 py-1">
                          {nationalIdImage[0]?.file?.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {idError && (
                  <p className="text-red-600 text-sm mt-2">
                    {idError
                      ? idError
                      : "Ensure you have uploaded the front of your National ID"}
                  </p>
                )}
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row mt-1 items-center">
                  <div className="flex flex-col w-full">
                    <label className="mt-2 mb-2 text-label text-xs">
                      National ID (Back) *
                    </label>
                    <ReactImageUploading
                      allowNonImageType
                      value={nationalIdImageBack}
                      onChange={onNationalIdBackChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png", "pdf"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="flex flex-row justify-between">
                          <div
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-1/2 p-2 text-black outline-none flex flex-col"
                            {...dragProps}
                          >
                            Choose File
                          </div>
                          &nbsp;
                          {imageList?.length > 0 ? (
                            <></>
                          ) : (
                            <div className="flex flex-row-reverse items-center w-full">
                              <span>No File Selected</span>
                              <img
                                src={NoFileIcon}
                                className="w-8 h-8"
                                alt="Previous"
                              />
                            </div>
                          )}
                          {imageList?.map((image, index) => (
                            <div
                              key={index}
                              className="flex flex-col w-1/3 items-center"
                            >
                              <div className="flex flex-row justify-between mt-2">
                                <div
                                  className="bg-primary-green p-1 mr-2 rounded-sm text-white"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </div>
                                <div
                                  className="bg-primary-green p-1 rounded-sm text-white"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </div>
                              </div>
                              <img
                                src={image.data_url}
                                alt=""
                                className="h-auto w-full mt-3"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                    {nationalIdImageBack[0]?.file?.type ===
                      "application/pdf" && (
                      <div className="mt-1 pb-1 flex flex-row-reverse w-full">
                        <div className="text-black text-sm w-1/3 ml-3 break-all bg-yellow-100 rounded-md px-2 py-1">
                          {nationalIdImageBack[0]?.file?.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {backError && (
                  <p className="text-red-600 text-sm mt-2">
                    {backError
                      ? backError
                      : "Ensure you have uploaded the back of your National ID"}
                  </p>
                )}
              </div>
              {/* <div className="flex flex-col">
                <div className="flex flex-row mt-1 items-center">
                  <div className="flex flex-col w-full">
                    <label className="mt-2 mb-1 text-label text-xs">
                      KRA certificate *
                    </label>
                    <ReactImageUploading
                      allowNonImageType
                      value={kraImage}
                      onChange={onKraChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png", "pdf"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="flex flex-row justify-between">
                          <div
                            style={isDragging ? { color: "red" } : null}
                            onClick={() => onImageUpload()}
                            className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-1/2 p-2 text-black outline-none flex flex-col"
                            {...dragProps}
                          >
                            Choose File
                          </div>
                          &nbsp;
                          {imageList?.length > 0 ? (
                            <></>
                          ) : (
                            <div className="flex flex-row-reverse items-center w-full">
                              <span>No File Selected</span>
                              <img
                                src={NoFileIcon}
                                className="w-8 h-8"
                                alt="Previous"
                              />
                            </div>
                          )}
                          {imageList?.map((image, index) => (
                            <div
                              key={index}
                              className="flex flex-col w-1/3 items-center"
                            >
                              <div className="flex flex-row justify-between mb-2">
                                <div
                                  className="bg-primary-green p-1 mr-2 rounded-sm text-white"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </div>
                                <div
                                  className="bg-primary-green p-1 rounded-sm text-white"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </div>
                              </div>
                              <img
                                src={image.data_url}
                                alt=""
                                className="h-auto w-full mt-3"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                    {kraImage[0]?.file?.type === "application/pdf" && (
                      <div className="mt-1 pb-1 flex flex-row-reverse w-full">
                        <span className="text-black text-sm w-1/3 ml-3 break-all bg-yellow-100 rounded-md px-2 py-1">
                          {kraImage[0]?.file?.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {kraError && (
                  <p className="text-red-600 text-sm mt-2">
                    {kraError
                      ? kraError
                      : "Ensure you have uploaded your KRA certificate"}
                  </p>
                )}
              </div> */}
              <div className="flex flex-col">
                <div className="flex flex-row mt-1 items-center">
                  <div className="flex flex-col w-full">
                    <label className="mt-2 mb-2 text-label text-xs">
                      Passport photo *
                    </label>
                    <ReactImageUploading
                      allowNonImageType
                      value={passportImage}
                      onChange={onPassportChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png", "pdf"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="flex flex-row justify-between">
                          <div
                            style={isDragging ? { color: "red" } : null}
                            onClick={() => onImageUpload()}
                            className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-1/2 p-2 text-black outline-none flex flex-col"
                            {...dragProps}
                          >
                            Take Photo
                          </div>
                          &nbsp;
                          {imageList?.length > 0 ? (
                            <></>
                          ) : (
                            <div className="flex flex-row-reverse items-center w-full">
                              <span>No File Selected</span>
                              <img
                                src={NoFileIcon}
                                className="w-8 h-8"
                                alt="Previous"
                              />
                            </div>
                          )}
                          {imageList?.map((image, index) => (
                            <div
                              key={index}
                              className="flex flex-col w-1/3 items-center"
                            >
                              <div className="flex flex-row justify-between mt-2">
                                <div
                                  className="bg-primary-green p-1 mr-2 rounded-sm text-white"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </div>
                                <div
                                  className="bg-primary-green p-1 rounded-sm text-white"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </div>
                              </div>
                              <img
                                src={image.data_url}
                                alt=""
                                className="h-auto w-full"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                    {passportImage[0]?.file?.type === "application/pdf" && (
                      <div className="mt-1 pb-1 flex flex-row-reverse w-full">
                        <span className="text-black text-sm w-1/3 ml-3 break-all bg-yellow-100 rounded-md px-2 py-1">
                          {passportImage[0]?.file?.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {passportError && (
                  <p className="text-red-600 text-sm mt-2">
                    {passportError
                      ? passportError
                      : "Ensure you have uploaded your passport photo"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between my-2 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
            {/* <button
              className="rounded-full py-2 my-4 bg-primary-green text-white text-base w-full"
              type="button"
              onClick={handleSubmit(handleSavePartially)}
            >
              SAVE AND CONTINUE LATER
            </button> */}
          </form>
        </div>
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="bg-white py-5 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default NinthImageUpload;
