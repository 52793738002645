import React from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import chevron from "../../assets/Icons/tipjar/chevron.svg";
import "./members.css";
import {
  getTipjarGroups,
  getTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import BackButton from "../globals/BackButton";
import userStore from "../../store/user.store";
import { useMutation } from "@tanstack/react-query";
import { BASE_URL } from "../../constants/network";
import { postJson } from "../../utils/fetch.utils";
export default function TipjarHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const tipjar = useSelector((state) => state.tipjar);

  const { data: tipJarInfo, mutate: tipJarInfocreateMutation } = useMutation({
    mutationKey: "tipjar-creation-details",
    mutationFn: async () => {
      try {
        const resp = await postJson(BASE_URL + "api/v1/tipjar/");
        return resp;
      } catch (error) {
        return error;
      }
    },
  });
  const userAccount = useSelector((state) => state.account);
  function getCount(group) {
    let countof = tipjar?.members.filter((memb) => {
      if (memb?.group?.id === group?.id) {
        return true;
      } else {
        return false;
      }
    });
    return countof.length;
  }
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
    tipJarInfocreateMutation();
  }, []);
  useEffect(() => {
    dispatch(getTipjarMembers({}, userAccount?.userDetails?.token));
  }, []);
  useEffect(() => {
    if (tipjar?.id) {
      dispatch(getTipjarGroups(tipjar?.id, userAccount?.userDetails?.token));
    }
  }, [tipJarInfo?.id]);
  const styles = {
    backgroundColor: {
      backgroundColor:
        (tipjar?.members.length < 0 && tipjar?.groups?.length) < 0 &&
        "lightgray",
    },
  };
  return (
    <div className="flex flex-col px-3 w-full h-screen px-2">
      {/* <div
        className="flex flex-row items-center mt-9 w-full"
        style={{ width: "100%" }}
      >
        <div
          className={`flex flex-row items-center py-2  pr-6`}
          onClick={() => navigate("/")}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div> */}
      <div className="w-screen p-0">
        <BackButton
          onPress={() => {
            navigate("/");
          }}
        />
      </div>
      <div className=" mt-6 w-full" style={{ width: "100%" }}>
        <h2 className="text-3xl font-semibold text-primary-pwa">TipJAR</h2>
        <p className="text-md font-semibold text-gray-400">
          Manage members and choose how tips are divided across individuals
        </p>

        <div className="mt-6">
          {tipjar?.groups?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h5>Groups ({tipjar?.groups?.length})</h5>
              <div
                className="flex flex-row bg-[#64C8CE] align-center justify-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
                onClick={() => navigate("/tipjar/groups/")}
              >
                <p
                  className="mr-2"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  All Groups
                </p>
                <img src={chevron} className="chevron" alt="chevron" />
              </div>
            </div>
          ) : (
            <h5>Groups </h5>
          )}
          <div className="mt-6 empty">
            {tipjar?.groups?.length > 0 ? (
              <>
                {tipjar?.groups?.map((group, index) => (
                  <React.Fragment key={group?.id}>
                    {index < 2 && (
                      <div
                        key={group?.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="border-2 border-gray w-full h-65 mb-3"
                          style={{
                            height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            className="w-1/2 px-2"
                          >
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#50A2A7 ",
                              }}
                            ></div>
                            <div className="ml-2">
                              <p style={{ color: "#404B5A", fontSize: "12px" }}>
                                {group?.name}
                              </p>
                              <p style={{ color: "#929FB1", fontSize: "10px" }}>
                                {getCount(group)} Members
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                            className="w-1/2 px-2"
                          >
                            <a
                              style={{ fontSize: "12px" }}
                              className="text-sm text-white bg-accent px-2 py-1"
                              onClick={() => {
                                navigate(
                                  `/tipjar/groups/edit?group=${group?.id}`
                                );
                              }}
                            >
                              Edit Group
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <button
                onClick={() => navigate("/tipjar/groups")}
                className="add_member "
              >
                <img src={add} className="add" alt="add member" />
                <h6 className="ml-2">Add Group</h6>
              </button>
            )}
          </div>
        </div>
        <div className="mt-6">
          {tipJarInfo?.data?.members?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h5>Members ({tipJarInfo?.data?.members?.length})</h5>
              <div
                className="flex flex-row bg-[#64C8CE] align-center justify-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px",
                }}
                onClick={() => navigate("/tipjar/members/")}
              >
                <p
                  className="mr-2"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  All Members
                </p>
                <img src={chevron} className="chevron" alt="chevron" />
              </div>
            </div>
          ) : (
            <h5>Members </h5>
          )}
          <div className="mt-6 empty">
            {tipJarInfo?.data?.members.length > 0 ? (
              <>
                {tipJarInfo?.data?.members?.map((member, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        className="border-2 border-gray w-full h-65 mb-3"
                        style={{
                          height: "60px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          className="w-1/2 px-2"
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "15px",
                              backgroundColor: "#50A2A7 ",
                            }}
                          ></div>
                          <div className="ml-2">
                            <p style={{ color: "#404B5A", fontSize: "12px" }}>
                              {member?.user?.first_name !== "N/A"
                                ? `${member?.user?.first_name} ${member?.user?.last_name}`
                                : member?.user?.phone}
                            </p>
                            <p style={{ color: "#929FB1", fontSize: "10px" }}>
                              {member?.user?.shukran_id &&
                                member?.user?.shukran_id}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          className="w-1/2 px-2"
                        ></div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <button
                onClick={() => navigate("/tipjar/members")}
                className="add_member"
              >
                <img src={add} className="add" alt="add member" />
                <h6 className="ml-2">Add Member</h6>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4 mt-6">
        <button
          className={`rounded-full py-3 bg-${
            tipJarInfo?.data?.members.length > 0 &&
            tipJarInfo?.data?.groups?.length > 0
              ? "accent"
              : "[#666]"
          } px-3 text-white text-m mx-auto mt-4 w-full`}
          onClick={() => navigate("/tipjar/amount")}
          type="button"
          style={styles.backgroundColor}
        >
          Send Tips
        </button>
      </div>
    </div>
  );
}
