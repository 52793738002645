import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import loader from "../../assets/Icons/Registration/loader.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import successIcon from "../../assets/Icons/Rating/success.svg";
import clearIcon from "../../assets/Icons/Rating/clear.svg";
import searchIcon from "../../assets/Icons/Rating/search.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  getUserBusiness,
  searchBusiness,
  updateServiceWorkerBusiness,
} from "../../state/reducers/search/search.thunk";
import { businessActions } from "../../state/reducers/business/business.actions";
import { ratingActions } from "../../state/reducers/rating/rating.actions";

export default function Restaurant() {
  const [comment, setComment] = useState("");
  const [defaultView, setDefaultView] = useState(true);
  const [rating, setRating] = useState(0);
  const [errorView, setErrorView] = useState();
  const [successView, setSuccessView] = useState(false);
  const tokenInfo = useSelector((state) => {
    let token = state.account.userDetails.token;
    let id = state.account.userDetails.shukranId;
    // let receiverId = state.tipData.shukranId;
    // let receiverName = state.tipData.receiverName;
    // let receiverLastName = state.tipData.receiverLastName;

    return {
      token: token ? token : "",
      shukran_id: id ? id : "",
      receiverId: state.tipData.shukranId,
      receiverName: state.tipData.receiverName,
      receiverLastName: state.tipData.receiverLastName,
    };
  });
  const userState = useSelector((state) => state.account.userDetails);
  const ratingState = useSelector((state) => {
    return state.rating;
  });
  const businessState = useSelector((state) => {
    return state.business;
  });
  let userInfo = tokenInfo;

  useEffect(() => {
    let params = {
      business: 1,
    };
    dispatch(getUserBusiness(userState.token));
    dispatch(ratingActions.submitRatingError(""));
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //   navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addShukranId } = bindActionCreators(actions, dispatch);
  const handleChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (businessState.errorMessage === 403) {
      localStorage.setItem("hasSeenOnboarding", false);
      navigate("/login");
    }
    console.log(
      "use effect restaurant page",
      businessState.errorMessage.status
    );
    if (businessState.errorMessage.status === 403) {
      localStorage.setItem("hasSeenOnboarding", false);
      navigate("/login");
    }
    return () => {};
  }, [businessState, navigate]);

  const handleBusinessSearch = (data) => {
    if (!data.searchParam) {
    } else {
      console.log("userState", userState);
      let param = data.searchParam;
      dispatch(searchBusiness(param, userState.token));
    }
  };

  const handleSelectBusiness = (business) => {
    console.log("restaurant business", business);
    let param = {
      business: business.id,
    };
    dispatch(updateServiceWorkerBusiness(param, userState.token));
    // dispatch(businessActions.searchBusinessClear());
  };

  const SuccessView = () => {
    return (
      <>
        <div className="bg-[#F0FEFF] mt-7 rounded-md p-5 mx-4">
          <div className="flex flex-row justify-between">
            <img src={NotFoundIcon} alt="Not found icon" className="" />
          </div>
        </div>
      </>
    );
  };

  const SearchError = () => (
    <div className="flex flex-col items-center bg-white drop-shadow-2xl mt-7 rounded-md p-5 mx-4">
      <img className="h-20" src={NotFoundIcon} alt="Not found icon" />
      <span className="text-base text-slate-400 mt-1 text-center">
        {businessState.errorMessage.status !== 403
          ? businessState.errorMessage.data?.user[0]
          : businessState.errorMessage.data?.detail}
      </span>
      <button
        className="drop-shadow-md rounded-full bg-accent h-12 text-primary-pwa text-lg w-full mt-3 py-2 flex justify-center items-center"
        onClick={() => {
          dispatch(businessActions.updateServiceWorkerBusinessError({}));
          navigate("/");
        }}
      >
        Go To Dashboard
      </button>
    </div>
  );

  const SuccessUpdateBusiness = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(businessActions.updateServiceWorkerBusinessSuccess({}));
          navigate("/");
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-lg text-center py-2">
        Business for service worker{" "}
        {tokenInfo.receiverName + " " + tokenInfo.receiverLastName} updated!
      </p>
    </div>
  );

  return (
    <div
      className={`${businessState.isUpdateLoading ? "relative" : ""} 
      ${businessState.errorMessage.data ? "relative" : ""}
      ${businessState.updateResults.business ? "relative" : ""}
      flex w-full flex-col`}
    >
      <div
        className={` 
        ${businessState.isUpdateLoading ? "blur-sm" : ""} 
        ${businessState.errorMessage.data ? "blur-sm" : ""}
        ${businessState.updateResults.business ? "blur-sm" : ""}
        flex flex-col px-5 w-full h-screen`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/"} className="ml-2">
            <img src={back} className="" alt="rate-star" />
          </Link>
        </div>
        <p className="text-lg mx-4 my-5">
          What restaurant do you provide services for?
        </p>
        <div className="px-4 flex flex-col">
          {!userInfo.receiverId ? (
            <form
              onSubmit={handleSubmit(handleBusinessSearch)}
              className={`flex flex-row shadow-lg`}
            >
              <input
                type="text"
                className={`text-sm w-5/6 px-8 
                  ${defaultView ? "focus:outline-primary-pwa" : ""}
                  ${errorView ? "focus:outline-red-300" : ""}
                `}
                placeholder="Enter Business Name"
                {...register("searchParam", {
                  required: true,
                })}
                onFocus={(e) => {
                  if (e.currentTarget === e.target) {
                    dispatch(businessActions.searchBusinessError(""));
                  }
                }}
              />
              <div className="p-4 flex justify-center items-center">
                {defaultView ? (
                  <button type="submit">
                    <img src={searchIcon} alt="search icon" className="" />
                  </button>
                ) : errorView ? (
                  <div onClick={() => setComment("")}>
                    <img src={clearIcon} alt="Clear Icon" className="" />
                  </div>
                ) : successView ? (
                  <div>
                    <img src={successIcon} alt="Success Icon" className="" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center h-16 border-b-2 border-b-[#B9F5F947]">
                <div className="flex flex-row items-center">
                  <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                    <p className="text-white text-xs">{userInfo.receiverId}</p>
                  </div>
                  <div className="flex flex-col justify-between ml-4">
                    <span className="text-sm">{userInfo.receiverName}</span>
                    <span className="text-xs text-gray-500">
                      {userInfo.receiverLastName}
                    </span>
                  </div>
                </div>
                <div
                  className="pr-2 pl-5 text-xl cursor-pointer"
                  onClick={() => {
                    setDefaultView(true);
                    addShukranId({});
                    dispatch(
                      ratingActions.getBusinessRatingSuccess({
                        ratings: [],
                      })
                    );
                  }}
                >
                  X
                </div>
              </div>
            </div>
          )}
        </div>
        {defaultView && <></>}
        {businessState.isLoading && (
          <div className="flex flex-row items-center justify-center px-4 mt-7">
            <img
              src={loader}
              alt="loader"
              className={`animate-spin h-8 mx-auto text-sm mt-1`}
            />
          </div>
        )}
        {!!businessState.results[0]?.results.length ? (
          <div>
            {businessState.results[0]?.results.map((business, idx) => (
              <div
                key={idx}
                onClick={() => {
                  handleSelectBusiness(business);
                }}
                className="flex flex-row cursor-pointer px-2 items-center rounded-sm bg-[#B9F5F93D] mx-4 mt-7 h-16 border-2 border-[#B9F5F947]"
              >
                <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                  <p className="text-white text-xs">{business.id}</p>
                </div>
                <div className="flex flex-col justify-between ml-4">
                  <span className="text-sm">{business.name}</span>
                  <span className="text-xs text-gray-500">
                    {business.address}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : businessState.isLoading ? (
          <></>
        ) : (
          <div className="flex flex-row cursor-pointer px-2 items-center rounded-sm bg-[#B9F5F93D] mx-4 mt-7 h-16 border-2 border-[#B9F5F947]">
            <div className="flex flex-col justify-between ml-4">
              <span className="text-sm">No business associated to you</span>
              <span className="text-xs text-gray-500">
                Enter a name to search for the business you work for
              </span>
            </div>
          </div>
        )}
        {ratingState.isLoadingBusinessReviews && (
          <div className="flex flex-row items-center justify-center px-4 mt-7">
            <img
              src={loader}
              alt="loader"
              className={`animate-spin h-8 mx-auto text-sm mt-1`}
            />
          </div>
        )}
        {successView && <SuccessView />}
      </div>
      <div
        className={`${
          businessState.updateResults.business ? "absolute blur-none" : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <SuccessUpdateBusiness />
      </div>
      <div
        className={`${
          businessState.errorMessage.status >= 400
            ? "absolute blur-none"
            : "hidden"
        } flex flex-col justify-center items-center w-full h-full `}
      >
        <SearchError />
      </div>
    </div>
  );
}
