import { toast } from "sonner";
import { AuthErrorResponse, MissingPostDataResponse } from "../types/global";
interface ValidationError {
  [key: string]: string[];
}

interface AltErrorResponse {
  data: {
    [key: string]: ValidationError;
  };
  error: boolean;
  msg: string;
}
export function handleErrorResponse<T>(
  resp: AuthErrorResponse | MissingPostDataResponse | Record<string, any>
) {
  //@ts-ignore

  if (resp?.detail?.length) {
    //@ts-ignore

    return toast.error(resp?.detail ?? "Something went wrong, please login");
  } else {
    if (resp.data?.non_field_errors) {
      let combinedErrors: string[] = [];

      if (resp.data.non_field_errors) {
        combinedErrors = combinedErrors.concat(resp.data.non_field_errors);
      }

      if (resp.data.msg) {
        combinedErrors.push(resp.data.msg);
      }
      return toast.error(combinedErrors.join(", "));
      // showToast({
      //   title: "Error",
      //   variant: "destructive",
      //   description: combinedErrors.join(", "),
      // });
    } else {
      if (resp.error && typeof resp.error !== "boolean") {
        const errors: string[] = [];

        Object.keys(resp.data).forEach((field) => {
          const fieldErrors = resp.data[field];
          const formattedErrors = fieldErrors.map(
            (error: string) => `${field}: ${error}`
          );
          errors.push(...formattedErrors);
        });

        return toast.error(errors.join("\n"));
      }
      if ("message" in resp && !resp.success) {
        if ("data" in resp && resp.data && typeof resp.data === "object") {
          const errorFields: string[] = [];

          for (const key in resp.data) {
            if (Array.isArray(resp.data[key]) && resp.data[key]?.length > 0) {
              errorFields.push(`${key}: ${resp.data[key].join(", ")}`);
            }
          }

          if (errorFields?.length) {
            toast.error(
              `Fields are required or may not be blank: ${errorFields.join(
                "; "
              )}`
            );
            return;
          }
        } else if (typeof resp.message === "string") {
          toast.error(resp.message);
        }
        return;
      }
    }

    if (typeof resp?.msg === "string") {
      toast.error(resp.msg);
      return;
    }
  }
  console.log(resp.data);

  // showToast("Unknown Error Occurred, Please try again", "danger");
}
export function formatValidationErrors(errorsObject: any) {
  let formattedErrors = "";

  for (const key in errorsObject) {
    if (errorsObject.hasOwnProperty(key)) {
      formattedErrors += `${key}: ${errorsObject[key]}\n`;
    }
  }

  return formattedErrors.trim();
}
interface ValidationErrors {}
