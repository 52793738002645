// action Types
export const actionTypes = {
  DEPOSIT_START: "DEPOSIT_START",
  DEPOSIT_SUCCESS: "DEPOSIT_SUCCESS",
  DEPOSIT_ERROR: "DEPOSIT_ERROR",
  CHECK_DEPOSIT_START: "CHECK_DEPOSIT_START",
  CHECK_DEPOSIT_SUCCESS: "CHECK_DEPOSIT_SUCCESS",
  CHECK_DEPOSIT_ERROR: "CHECK_DEPOSIT_ERROR",
  CHECK_DEPOSIT_REDIRECT: "CHECK_DEPOSIT_REDIRECT",
};

// actions
const depositLoadStart = () => ({
  type: actionTypes.DEPOSIT_START,
});

const depositLoadSuccess = (results) => ({
  type: actionTypes.DEPOSIT_SUCCESS,
  payload: results,
});

const depositLoadError = (errorMsg) => ({
  type: actionTypes.DEPOSIT_ERROR,
  payload: errorMsg,
});

const checkTransactionStart = () => ({
  type: actionTypes.CHECK_DEPOSIT_START,
});

const checkTransactionSuccess = (results) => ({
  type: actionTypes.CHECK_DEPOSIT_SUCCESS,
  payload: results,
});

const checkTransactionError = (errorMsg) => ({
  type: actionTypes.CHECK_DEPOSIT_ERROR,
  payload: errorMsg,
});

const checkTransactionRedirect = (msg) => ({
  type: actionTypes.CHECK_DEPOSIT_REDIRECT,
  payload: msg,
});

export const depositActions = {
  depositLoadStart,
  depositLoadSuccess,
  depositLoadError,
  checkTransactionStart,
  checkTransactionSuccess,
  checkTransactionError,
  checkTransactionRedirect,
};
