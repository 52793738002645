import React, {useEffect} from "react";
import logo from "../../assets/Logos/ShukranIcon.png";
import loader from "../../assets/Icons/Registration/loader.svg";
import { useSelector, useDispatch } from "react-redux";
import { getUserSubscription, postUserSubscription } from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";

export default function SafariNotificationPrompt({
  isSubscribed,
  setHasSubscribed
}) {
  const dispatch = useDispatch();

  const userAccount = useSelector((state) => state.account);
  const subscriptionState = useSelector((state) => {
    return state.subscription;
  });

  useEffect(() => {
    let hasSubscribedBool = localStorage.getItem('hasSubscribed')
    setHasSubscribed(!!hasSubscribedBool);
    // dispatch(getUserSubscription(userAccount.userDetails.token))
    return () => {}
  }, []);
  
  const onClickAskUserPermission = () => {
    let payload = {
      endpoint: "https://fcm.googleapis.com/fcm/send/c1KrmpTuRm…",
      expirationTime: null,
      keys: {
          p256dh: "secret-key-lace",
          auth: "secret-key-auth"
      },
      is_ios: true
    }
    dispatch(postUserSubscription(payload, userAccount.userDetails.token));
    dispatch(getUserSubscription(userAccount.userDetails.token));
  };

  const onClickAskUserDecline = () => {
    localStorage.setItem('hasSubscribed', true);
    setHasSubscribed(true)
  }

  return (
		<div className={`bg-white rounded-lg px-1 py-2 border-slate-200 border-2 drop-shadow-2xl flex flex-col w-4/5
    ${subscriptionState.postSubscriptionRes?.subscription_status? "hidden" : ""}
    ${isSubscribed? "hidden" : "absolute blur-none top-2 right-2"}
    `}>
      <div className="flex flex-row-reverse">
        <button
          className="text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.subscriptionLoadSuccess({
              subscription_status: true
            }))
          }}
        >
          X
        </button>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-1/6 ml-3">
          <img
            src={logo}
            className="rounded-sm bg-white"
            height="72"
            width="72"
            alt="iOS notification"
          />
        </div>
        <div className="flex flex-col items-center justify-between px-2 w-[75%]">
          <p className="text-sm mb-2">Give <strong>SHUKRAN</strong> on iOS permission to send notifications?</p>
         <div className="flex flex-row w-full">
         {!subscriptionState.isLoading && <div
            // disabled={!pushNotificationSupported || isConsentGranted}
            onClick={onClickAskUserPermission}
            className="rounded-full bg-primary-pwa p-2 text-white text-center text-sm w-1/2 mx-1 mb-2"
          >
            Allow
          </div>}
          { !subscriptionState.isLoading && <div
            // disabled={!pushNotificationSupported || isConsentGranted}
            onClick={onClickAskUserDecline}
            className="rounded-full border:bg-primary-pwa p-2 text-black text-center text-sm w-1/2 mx-1 mb-2"
          >
            Decline
          </div>}
         </div>
          {subscriptionState.isLoading && <img
            src={loader}
            alt="loader"
            className={`animate-spin h-8 mx-auto text-sm my-1`}
          />}
        </div>
      </div>
    </div>
  );
}