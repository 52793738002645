import deposit from "../../assets/Illustrations/ErrorPages/unavailable_sadface.svg";
import { Link } from "react-router-dom";

export default function serviceUnavailable() {
  return (
    <div className="unavailable_container m-6 w-96 flex flex-col items-center">
      <img className="pt-6 pb-6" src={deposit} alt="button" />

      <p className=" text-xl pb-5"> This service is unavailable </p>

      <p className="text-center text-base pb-6">
        The good news is we've credited 1000 worth of test credits just for you!
        Start Tipping.
      </p>

      <Link to="/">
        <button className="rounded-full  bg-[#dea310] p-3 text-white text-base w-80 ">
          Back to Dashboard
        </button>
      </Link>
    </div>
  );
}
