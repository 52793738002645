import React, { useRef, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";

import { getFromSessionStorage } from "../../Services/Storage";
import actions from "../../state/index";
import back from "../../assets/Icons/Rating/back_icon.svg";
import arrowButtonPrev from "../../assets/Icons/Onboarding/ArrowButtonPrev.svg";
import squareEdit from "../../assets/Icons/Profile/green_square_edit.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  changePassword,
  getRatings,
  getUserData,
  getUserSubscription,
  updateProfile,
} from "../../state/reducers/user/user.thunk";

import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import useIsIOS from "../../components/popups/useIsIos";
import axios from "axios";
import { testUrl } from "../../helper";

let sampleReviews = [];

const MyAccount = () => {
  const [isReview, setIsReview] = useState(true);
  const [isPasswordForm, setIsPasswordForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [confirmError, setConfirmError] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedNew, setIsClickedNew] = useState(false);
  const [isClickedConfirm, setIsClickedConfirm] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState();

  const [isIOS] = useIsIOS();
  const navigate = useNavigate();

  const subscriptionState = useSelector((state) => state.subscription);
  const userAccount = useSelector((state) => state.account);
  const userInfo = useSelector((state) => state.balance.userInfo);
  const passwordReset = useSelector((state) => state.reset);
  const profileState = useSelector((state) => state.profile);
  let ratingsState = useSelector((state) => state.rating);
  let ratings =
    ratingsState.ratings?.rateddata?.length > 0
      ? ratingsState.ratings.rateddata.slice(0, 6)
      : sampleReviews;
  console.log("ratings", ratings);
  const {
    // userDetails: { firstName, lastName, shukranId, phoneNumber },
    isAuthenticated,
  } = userAccount;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  // password form state
  const currPasswordRef = useRef();

  const dispatch = useDispatch();
  const { logOut } = bindActionCreators(actions, dispatch);

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    if (!isAuthenticated) {
      navigate("/login");
    }

    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    let hasSubscribedBool = localStorage.getItem("hasSubscribed");
    console.log("profile user data token", userAccount.userDetails.token);
    if (isAuthenticated) {
      dispatch(getUserData(params, userAccount.userDetails.token));
      dispatch(getRatings(params, userAccount.userDetails.token));
      setHasSubscribed(!!hasSubscribedBool);
      dispatch(getUserSubscription(userAccount.userDetails.token));
    }
  }, []);

  useEffect(() => {
    if (userInfo.user_type) {
      setValue(
        "role",
        userInfo.user_type === "SERVICE WORKER"
          ? "service-worker"
          : userInfo.user_type === "TIP_JAR"
          ? "tip-jar"
          : "tipper"
      );
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (profileState.errorMessage === 403) {
      navigate("/login");
    }
    if (profileState.results.success) {
      setTimeout(() => {
        dispatch(resetActions.updateProfileSuccess({}));
        navigate("/");
      }, 3000);
    }
    return () => {};
  }, [profileState, navigate]);

  const handleProfileSave = (data) => {
    let payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role,
      email: data.email,
      sacco_type: data.role === "service-worker" ? "SERVICE WORKER" : "TIPPER",
      user_type:
        data.role === "service-worker"
          ? "SERVICE WORKER"
          : data.role === "tip-jar"
          ? "TIP_JAR"
          : "TIPPER",
    };
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    console.log("profile token", userAccount.userDetails.token);
    dispatch(updateProfile(payload, userAccount.userDetails.token));
    dispatch(getUserData(params, userAccount.userDetails.token));
    if (data.role === "tip-jar") {
      axios
        .post(`${testUrl}tipjar/`, {
          primary_member_percentage: 0,
          default_group_percentage: 40,
        })
        .then((res) => {
          console.log(res, "tipjar");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlePasswordSave = (data) => {
    if (data.newPass !== data.confirmPass) {
      setConfirmError("New and Confirm values don't match");
    } else {
      let params = {
        // shukran_id: getFromSessionStorage("ShukranId"),
        old_password: data.currPass,
        new_password: data.newPass,
        // token: getFromSessionStorage("token"),
      };
      console.log("change password", params);
      dispatch(changePassword(params, userAccount.userDetails.token));
    }
  };

  const PasswordForm = () => (
    <form
      onSubmit={handleSubmit(handlePasswordSave)}
      className={`
        ${isPasswordForm ? "absolute blur-none" : "hidden"} mt-20
        w-4/5 bg-white rounded-md drop-shadow-2xl py-5 px-5
      `}
    >
      <div className="flex flex-row justify-between items-center">
        <span className="text-base">Change Password</span>
        <div
          className=""
          onClick={() => {
            setIsPasswordForm(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="flex justify-center items-center">
        {passwordReset.isLoading && (
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-6 mx-auto text-sm mb-1`}
          />
        )}
        {passwordReset.results?.msg && (
          <div className="px-7 py-2 bg-green-600 rounded-sm text-white text-xs">
            {passwordReset.results?.msg}
          </div>
        )}
        {passwordReset.errorMessage.msg && (
          <div className="px-7 py-1 bg-red-400 text-white">
            {passwordReset.errorMessage.msg}
          </div>
        )}
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Current Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            ref={currPasswordRef}
            type={isClicked ? "text" : "password"}
            placeholder={isClicked ? "password123" : "*******"}
            {...register("currPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClicked(!isClicked)}
          >
            {isClicked ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.currPass && (
          <p className="text-red-600 text-sm mt-2">This field is required</p>
        )}
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">New Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            type={isClickedNew ? "text" : "password"}
            placeholder={isClickedNew ? "password123" : "*******"}
            {...register("newPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClickedNew(!isClickedNew)}
          >
            {isClickedNew ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.newPass && (
          <p className="text-red-600 text-sm mt-2">This field is required</p>
        )}
      </div>
      <div className="flex flex-col mt-4 h-18 mb-2">
        <label className="text-subtle italic text-sm">Confirm Password</label>
        <div className="flex flex-row items-center">
          <input
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            // ref={confirmPasswordRef}
            // onChange={(e) => setConfirmPass(e.target.value)}
            onFocus={() => setConfirmError("")}
            type={isClickedConfirm ? "text" : "password"}
            placeholder={isClickedConfirm ? "password123" : "*******"}
            {...register("confirmPass", {
              required: true,
            })}
          />
          <div
            className="text-l -ml-6"
            onClick={() => setIsClickedConfirm(!isClickedConfirm)}
          >
            {isClickedConfirm ? (
              <img src={eye} alt="eye" className="h-6" />
            ) : (
              <img src={eyeClosed} alt="eye" className="h-6" />
            )}
          </div>
        </div>
        {errors.confirmPass && (
          <p className="text-red-600 text-sm mt-2">
            This field doesn't match new password
          </p>
        )}
        {confirmError && (
          <p className="text-red-600 text-sm mb-1 w-full">{confirmError}</p>
        )}
      </div>
      <button
        type="submit"
        // onClick={handlePasswordSave}
        className="rounded-full bg-[#64C8CE] text-white text-base w-full mt-7 px-10 py-4"
      >
        Change Password
      </button>
    </form>
  );

  const EditForm = () => (
    <form onSubmit={handleSubmit(handleProfileSave)} className="mt-3 w-full">
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">First Name</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("first_name", {
              required: true,
              value: userInfo.first_name,
            })}
            placeholder="Jane"
          />
        </div>
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Last Name</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("last_name", {
              required: true,
              value: userInfo.last_name,
            })}
            placeholder="Doe"
          />
        </div>
      </div>
      {/* <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Role</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("role", {
              required: true,
              value: userInfo.user_type,
            })}
            placeholder="Tipper"
          />
        </div>
      </div> */}
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Role</label>
        <div className="flex flex-row items-center">
          <select
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("role", {
              required: true,
            })}
            placeholder="Service Worker"
          >
            <option value="tipper" className="w-10/12">
              Tipper
            </option>
            <option value="service-worker" className="w-10/12">
              Service Worker
            </option>
            <option value="tip-jar" className="w-10/12">
              Tip Jar
            </option>
          </select>
        </div>
        {errors.role && (
          <p className="text-red-600 text-sm">Please select an option</p>
        )}
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Email</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("email", {
              required: true,
              value: userInfo.email,
            })}
            placeholder="joe@gmail.com"
          />
        </div>
        {errors.email && (
          <p className="text-red-600 text-sm">Please enter your email</p>
        )}
      </div>
      <button
        type="submit"
        className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-10 px-20 py-4"
      >
        Save
      </button>
    </form>
  );

  const SuccessRating = () => (
    <div className="my-auto p-7 shadow bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-lg text-center py-2">
        {profileState.results.msg}
      </p>
    </div>
  );

  return (
    <div
      className={`
      ${isPasswordForm ? "relative" : ""}
      ${profileState.isLoading ? "relative" : ""}
      ${profileState.results.success ? "relative" : ""}
      ${
        subscriptionState.subscriptionRes?.subscription_status ? "" : "relative"
      }
      w-full flex flex-col justify-center items-center
    `}
    >
      <div
        className={`
        ${isPasswordForm ? "blur-sm" : ""}
        ${profileState.isLoading ? "blur-sm" : ""}
        ${profileState.results.success ? "blur-sm" : ""}
        w-full h-full bg-imag-dash
      `}
      >
        {/* Top navigation bar */}
        <div className="flex flex-row justify-between mt-8 items-center px-5">
          <Link to={"/profile/android"} className="">
            <img src={arrowButtonPrev} className="" alt="rate-star" />
          </Link>
          <p className="text-primary text-lg">Account</p>
          <span></span>
        </div>
        {/* User Details */}
        <div className=" bg-white h-full rounded-t-3xl p-5 flex flex-col items-center mt-40 w-full relative">
          <p className="text-white bg-black rounded-full p-5 absolute -top-6">
            {" "}
            {userInfo.shukran_id ? userInfo.shukran_id.split("", 2) : ""}{" "}
          </p>
          {!isEditForm ? (
            <div className="flex flex-col h-4/5 items-start justify-between w-full pt-5">
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full">
                  <div className="flex flex-col">
                    <span className="text-base font-bold mb-1">Name</span>
                    <span className="mb-2 text-sm">
                      {userInfo.first_name} {userInfo.last_name}
                    </span>
                  </div>

                  <div
                    className="flex flex-col items-center"
                    onClick={() => setIsEditForm(true)}
                  >
                    <img src={squareEdit} className="" alt="rate-star" />
                  </div>
                </div>
                <span className="text-base font-bold mb-1">Shukran ID</span>
                <span className="pb-2 text-sm">{userInfo.shukran_id}</span>
                <span className="text-base font-bold mb-1">Phone Number</span>
                <span className="pb-2 text-sm">{userInfo.phone}</span>
              </div>
              <button
                onClick={() => setIsPasswordForm(true)}
                className="rounded-full bg-[#64C8CE] text-white text-lg w-full px-20 py-4"
              >
                Change Password
              </button>
            </div>
          ) : (
            <EditForm />
          )}
        </div>
      </div>
      {isPasswordForm && <PasswordForm />}

      <div
        className={`${
          profileState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-4/5 h-full`}
      >
        <SuccessRating />
      </div>
      <div
        className={`${
          profileState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
    </div>
  );
};

export default MyAccount;
