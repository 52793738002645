import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import actions from "../../state/index";
import { useForm } from "react-hook-form";

import successIcon from "../../assets/Icons/Rating/success.svg";
import clearIcon from "../../assets/Icons/Rating/clear.svg";
import searchIcon from "../../assets/Icons/Rating/search.svg";
import searchBeer from "../../assets/Icons/Dashboard/search_botttle.svg";
import ExclamationIcon from "../../assets/Icons/Tipping/exclamation.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";

import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";

import {
  loadMoreSearchResults,
  loadSearchResults,
} from "../../state/reducers/search/search.thunk";
import { searchActions } from "../../state/reducers/search/search.actions";
import { testUrl } from "../../helper";
import { debounce, filter } from "lodash";
import axios from "axios";
import {
  getTipjarData,
  getTipjarMembers,
  postTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../globals/BackButton";

const API_URL = testUrl + "accounts/auth/search/?shukran_id=";
const DEBOUNCE = 1000;

/**
 * This is the debounced function that we will run once the user hit a key
 * lodash debounce return a function, that can be invoked any time
 * this function takes a function, searchFunction, and a debounce time
 * in this way we guarantee that the we only fetch after certain time and we don't spam the api with calls every time the user hits a key
 */

const searchFun = (queryParam, dispatch, token) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["XAT"] = `U`;
  axios.defaults.headers.common["X-IDT"] = `A`;
  return axios
    .get(`${API_URL}${queryParam}`)
    .then((res) => {
      console.log("searchFun res", res.data);
      dispatch(searchActions.searchLoadSuccess(res.data));
      // setResults(res.data.data);
    })
    .catch((err) => {
      let errorMsg = "";
      if (err.response.status === 403) {
        errorMsg = err.response.status;
      } else {
        errorMsg = err.response.data;
      }
      console.log("errorMsg", errorMsg);
      dispatch(searchActions.searchLoadError(errorMsg));
    });
};

const debouncedSearch = debounce(searchFun, DEBOUNCE);
export default function AddMember() {
  const [defaultView] = useState(true);
  const [errorView] = useState();
  const [searchPhone, setSearchPhone] = useState(false);
  const [searchId, setSearchId] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [perc, setPerc] = useState(false);
  const [phoneData, setPhoneData] = useState([]);
  const [userPayload, setUserPayload] = useState();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const handleInput1Focus = () => {
    setFocusedInput("input1");
  };

  const handleInput2Focus = () => {
    setFocusedInput("input2");
  };
  const [expressUser, setExpressUser] = useState(false);

  const { addShukranId, addNonShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  let userState = useSelector((state) => state.account.userDetails);
  const nonShukranId = useSelector((state) => state.tipData.nonShukranId);
  const tipjar = useSelector((state) => state.tipjar);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const userAccount = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const loginWithPhone = (phone) => {
    setLoading(true);
    axios
      .post(`${testUrl}accounts/auth/express-recipient/add/`, {
        phone: phone?.phoneNumber,
        first_name: firstname,
        last_name: lastname,
      })
      .then((res) => {
        console.log(res);
        if (res.data?.success) {
          const body = {
            shukran_id: res.data?.data?.shukran_id,
            first_name: res?.data?.data?.first_name,
            last_name: res?.data?.data?.last_name,
            id: res?.data?.data?.id,
          };
          const filteredNumbers = tipjar?.members?.filter((filteredNumber) => {
            if (body?.name === filteredNumber?.user?.phone) {
              return true;
            } else {
              return false;
            }
          });
          if (filteredNumbers <= 0) {
            const member = localStorage.getItem("members");
            const members = JSON.parse(member);
            let arr = members?.length > 0 ? members.concat(body) : [body];

            const finalArr = removeDuplicates(arr, (a, b) => a.id === b.id);
            localStorage.setItem("members", JSON.stringify(finalArr));
            navigate("/tipjar/members/confirm");
          } else {
            toast.warning("Already in tipjar, try another user!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        } else {
          toast.warning("Could not add member, try another user!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   dispatch(getTipjarMembers({}, userAccount.userDetails.token));
  // }, []);
  // user input handler
  useEffect(() => {
    console.log("isLoading", searchState.isLoading);
    return () => {};
  }, [searchState.isLoading]);
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    if (inputRef2.current) {
      inputRef2.current.focus();
      inputRef2.current.setSelectionRange(lastname.length, lastname.length);
    }
  }, [lastname]);
  useEffect(() => {
    if (inputRef1.current) {
      inputRef1.current.focus();
      inputRef1.current.setSelectionRange(firstname.length, firstname.length);
    }
  }, [firstname]);
  useEffect(() => {
    if (nonShukranId) {
      setValue("phoneNumber", nonShukranId);
    } else if (searchState.searchId) {
      setSearchId(searchState.searchId);
      setSearchPhone(false);
    } else {
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    return () => {};
  }, [searchState, dispatch, navigate]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "phoneNumber" &&
        searchState.errorMessage?.msg
      ) {
        console.log(value, name, type);
        dispatch(searchActions.searchLoadError(""));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, searchState, dispatch]);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePhoneProceed = (data) => {
    if (
      data?.phoneNumber?.split("")?.length === 10 &&
      (data?.phoneNumber?.startsWith("07") ||
        data?.phoneNumber?.startsWith("01"))
    ) {
      setPhoneData(data);
      setExpressUser(true);

      //loginWithPhone(data);

      let userPhone = userState.phoneNumber;
      userPhone = userPhone.substring(3);
      let submittedPhone = data.phoneNumber;
      submittedPhone =
        submittedPhone.length === 9
          ? submittedPhone
          : submittedPhone.substring(1);

      addNonShukranId({
        non_shukran_id: data.phoneNumber,
      });
      addShukranId({});
      dispatch(searchActions.searchLoadSuccess(""));
      dispatch(searchActions.searchLoadError(""));
    } else {
      dispatch(
        searchActions.searchLoadError({
          error: true,
          msg: "Enter valid number",
          title: "Unacceptable Shukran ID",
        })
      );
    }
  };
  // handle tip receiver
  function removeDuplicates(array, comparator) {
    const uniqueArray = array?.filter(
      (value, index, self) =>
        self.findIndex((v) => comparator(v, value)) === index
    );
    return uniqueArray;
  }
  const handleSelectRecipient = (payload) => {
    const member = localStorage.getItem("members");
    const members = JSON.parse(member);
    addNonShukranId("");
    addShukranId(payload);
    const filteredCurrent = tipjar?.members?.filter((filteredMember) => {
      if (filteredMember?.user?.id === payload?.id) {
        return true;
      } else {
        return false;
      }
    });
    if (filteredCurrent?.length <= 0) {
      dispatch(searchActions.updateSearchQuery(searchId));
      let arr = members?.length > 0 ? members.concat(payload) : [payload];
      console.log(members);
      const finalArr = removeDuplicates(
        arr,
        (a, b) => a.shukran_id === b.shukran_id
      );

      localStorage.setItem("members", JSON.stringify(finalArr));
      navigate("/tipjar/members/confirm");
    } else {
      toast.warning("Member already in tipjar, try another user!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handlePrevPage = (link) => {
    dispatch(loadMoreSearchResults(link, userState.token));
  };

  const handleNextPage = (link) => {
    dispatch(loadMoreSearchResults(link, userState.token));
  };

  //Handle enter key input
  function handleEnterKey(e) {
    if (e.keyCode === 13) {
      // setIsFocused(false);
      // setToggleSearch(true)
      let userId = userState.shukranId;
      if (userId === searchId) {
        dispatch(
          searchActions.searchLoadError({
            error: true,
            msg: "A User cannot tip their own Shukran ID",
            title: "Unacceptable Shukran ID",
          })
        );
      } else {
        console.log("search empty", searchId);

        if (searchId) {
          dispatch(loadSearchResults(searchId, userState.token));
        } else {
          dispatch(
            searchActions.searchLoadError({
              error: true,
              msg: "Please enter a shukran id",
              title: "Empty Field",
            })
          );
        }
      }
    }
  }

  function handleSearch() {
    // setIsFocused(false);
    // setToggleSearch(true)
    let userId = userState.shukranId;
    if (userId === searchId) {
      dispatch(loadSearchResults([]));
      dispatch(
        searchActions.searchLoadError({
          error: true,
          msg: "A User cannot tip their own Shukran ID",
          title: "Unacceptable Shukran ID",
        })
      );
    } else {
      console.log("search empty", searchId);
      dispatch(loadSearchResults(searchId, userState.token));
    }
  }

  //   handleChangePin
  const handleChangePin = (e) => {
    setSearchId(e.target.value);
    let v = e.target.value;
    const search = debouncedSearch;
    if (!v) {
      // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
      debouncedSearch.cancel();
      dispatch(searchActions.searchLoadSuccess([]));
      // setIsLoading(false);
    } else {
      dispatch(searchActions.searchLoadStart());
      search(v, dispatch, userState.token);
    }
  };
  const CreateExpressUser = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="my-auto px-4 drop-shadow-lg absolute bg-[white] blur-none w-4/5 pt-10 flex flex-col pb-4 rounded-3xl z-50">
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <img
                src={loader}
                className={`animate-spin h-10 w-10 mx-auto text-sm mt-1`}
              />
            </div>
          ) : (
            <>
              <button
                onClick={() => setExpressUser(false)}
                className="absolute right-6 top-2  text-[black] text-2xl"
              >
                x
              </button>
              <form
                className="flex flex-col justify-between h-2/3 mt-5"
                // onSubmit={handleSubmit(handleFirstPersonalInfo)}
              >
                <div>
                  <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      First Name *
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full py-2 pr-20 text-black p-3 outline-none"
                      value={firstname}
                      onChange={(e) => {
                        setFirstname(e.target.value);
                      }}
                      placeholder="First Name"
                      ref={inputRef1}
                    />

                    {errors.firstName && (
                      <p className="text-red-600 text-sm">
                        Please select an option
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      Last Name *
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full py-2 pr-20 p-3 text-black outline-none"
                      value={lastname}
                      onChange={(e) => {
                        //setReferralType(e.target.value);
                        setLastname(e.target.value);
                      }}
                      ref={inputRef2}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="w-full p-2 mt-5 flex items-center justify-center">
                    <button
                      onClick={() => {
                        loginWithPhone(phoneData);
                      }}
                      type="button"
                      className="flex flex-row bg-accent p-3 rounded-full text-white"
                    >
                      PROCEED
                    </button>
                  </div>
                  {/* <motion.div
              key={search?.isLoading}
              initial={{ opacity: 0, scaleY: 0.8 }}
              animate={{ opacity: 1, scaleY: 1 }}
              transition={{ duration: 0.4, type: "spring" }}
              className={`flex flex-col bg-[white] w-3/4 p-1 mt-1 overflow-scroll ${
                search?.results[0]?.results?.length > 0 || search?.isLoading
                  ? "h-200"
                  : "h-0"
              } absolute z-100`}
            >
              {search.isLoading ? (
                <div className="w-full flex items-center justify-center">
                  <img
                    src={loader}
                    className={`animate-spin h-10 w-10 mx-auto text-sm mt-1`}
                  />
                </div>
              ) : (
                <div className="mb-4">
                  {search?.results[0]?.results?.map((searchRes) => {
                    return (
                      <div
                        key={searchRes?.shukran_id}
                        onClick={() => {
                          setReferralCode(searchRes?.shukran_id);
                          dispatch(searchActions.searchLoadSuccess(""));
                        }}
                        className="p-2 bg-[#F0FEFF] mt-2 drop-shadow-md flex flex-col w-full items-start justify-start"
                      >
                        <p className="text-md">
                          {searchRes?.first_name?.split("")?.length > 0
                            ? searchRes?.first_name
                            : searchRes?.shukran_id}{" "}
                          {searchRes?.last_name}
                        </p>
                        <p className="text-xs">{searchRes?.shukran_id}</p>
                        {/* <button>
                          <i class="fa-regular fa-circle-check"></i>
                        </button> 
                      </div>
                    );
                  })}
                </div>
              )}
            </motion.div>
            {showOtherField && (
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Please Specify *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:italic placeholder:pl-2 placeholder:text-sm w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="learnt about shukran at an event"
                  {...register("staffNumber", {
                    required: true,
                  })}
                />
                {errors.staffNumber && (
                  <p className="text-red-600 text-sm">Please specify other</p>
                )}
              </div>
            )} */}
                </div>
                {/* <div className="flex flex-row justify-between my-2 pt-3">
            <div
              className={`flex flex-row items-center`}
              onClick={() => setExpressUser(false)}
            >
              <img src={ArrowButtonPrev} className="w-12 h-12" alt="Previous" />
              <span className="ml-2">Previous</span>
            </div>
            <button
              type="button"
              onClick={() => {
                let payload = {
                  referral_type: referralType,
                  referral_code: referralCode,
                  other_referral: "",
                };
                dispatch(saccoActions.updateSaccoDetails(payload));
                if (
                  fullName?.split("")?.length >=
                  payloadToSend?.firstName?.split("").length +
                    payloadToSend?.lastName?.split("").length
                ) {
                  //console.log(payloadToSend);
                  setRefferal(false);
                  dispatch(saccoActions.updateSaccoDetails(payloadToSend));
                  handleSavePartially(payloadToSend);
                }
              }}
              className="flex flex-row items-center"
            >
              <span className="mr-2">Submit</span>
              <img
                src={ArrowButtonNext}
                className="w-12 h-12"
                alt="Next Page"
                type="button"
              />
            </button>
          </div> */}
              </form>
            </>
          )}
        </div>
      </div>
    );
  };
  const SearchHelpText = () => (
    <div className="bg-white mt-64 rounded-md p-1 drop-shadow-md flex flex-row">
      <div className="flex flex-row">
        <img src={ExclamationIcon} alt="Not found icon" className="" />
      </div>
      <div className="flex flex-col ml-1">
        <span className="text-sm">
          {searchPhone ? "How does it work" : "What is a Shukran ID?"}
        </span>
        <span className="text-xs text-slate-400">
          {searchPhone
            ? "Service worker will receive a notification from Shukran of the amount sent and a a link to access and withdraw the tip."
            : `A unique identifier of a user on the Shukran App.`}
        </span>
      </div>
    </div>
  );

  const PhoneHelpText = () => (
    <div className="bg-white mt-56 rounded-md p-1 drop-shadow-md flex flex-row">
      <div className="flex flex-row">
        <img src={ExclamationIcon} alt="Not found icon" className="" />
      </div>
      <div className="flex flex-col ml-1">
        <span className="text-sm">How does it work</span>
        <span className="text-xs text-slate-400">
          Service worker will receive an SMS from Shukran to access the tip.
        </span>
      </div>
    </div>
  );

  const ShukranUserText = () => (
    <div className="pt-4 flex flex-row items-center text-sm">
      <span className="text-primary">OR enter</span>
      <div
        className="border-none rounded-md px-1 py-1 bg-primary-green text-white ml-2"
        onClick={(e) => {
          setSearchPhone(false);
          dispatch(searchActions.searchLoadError(""));
        }}
      >
        their Shukran ID
      </div>
    </div>
  );

  const NonShukranUserText = () => (
    <div className="py-4 flex flex-row items-center text-sm">
      <span className="text-primary">OR enter</span>
      <div
        className="border-none rounded-md px-1 py-1 bg-primary-green text-white ml-2"
        onClick={(e) => {
          setSearchPhone(true);
          dispatch(searchActions.searchLoadError(""));
        }}
      >
        their Phone Number
      </div>
    </div>
  );

  const PhoneOptionSearchError = () => (
    <div className="pt-8 flex flex-row items-center text-sm">
      <span className="text-primary">Click here to enter </span>
      <div
        className="border-none rounded-md px-1 py-1 bg-primary-green text-white ml-2"
        onClick={(e) => {
          setSearchPhone(true);
          dispatch(searchActions.searchLoadError(""));
        }}
      >
        their Phone number
      </div>
    </div>
  );

  const SearchError = () => (
    <div className="flex flex-col">
      <div className="text-[#EF5F89] my-3 bg-white rounded-md flex flex-col justify-between items-center">
        Looks like this Shukran ID does not exist. Please confirm its in the
        format MW0000 or use their phone number.
      </div>
      <PhoneOptionSearchError />
    </div>
  );

  const PhoneSubmitError = () => (
    <div className="flex flex-col">
      <div className="text-[#EF5F89] my-3 bg-white rounded-md flex flex-col items-start">
        {searchState.errorMessage.msg ? searchState.errorMessage.msg : ""}
      </div>
      <ShukranUserText />
    </div>
  );
  const SelectPercentModal = () => {
    return (
      <div
        id="perc"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="w-2/3 h-2/3 bg-white drop-shadow-xl">
          <button
            onClick={() => setPerc(false)}
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          >
            X
          </button>

          <div className="inputRange mt-8" style={{ width: "100%" }}>
            <button
              className="py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-1/5"
              onClick={() => {
                if (percentage > 10) {
                  setPercentage(percentage - 10);
                }
              }}
              type="button"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              -
            </button>
            <input
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              //   onKeyDown={(e) => handleEnterKey(e)}
              placeholder={"0%"}
              className={`text-sm block p-4 pl-4 w-2/5 mt-3 focus:outline-none drop-shadow-xl`}
              onFocus={(e) => {
                if (e.currentTarget === e.target) {
                  // setIsFocused(true);
                  //dispatch(searchActions.searchLoadError(""));
                }
              }}
              onBlur={(e) => {
                // setIsFocused(false);
              }}
              style={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              readOnly
            />
            <button
              className="py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-1/5"
              onClick={() => {
                if (percentage <= 90 && percentage !== "") {
                  setPercentage(percentage + 10);
                  console.log(percentage);
                } else if (percentage === "") {
                  setPercentage(10);
                }
              }}
              type="button"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              +
            </button>
          </div>
          <div className="mb-2 mt-5 px-4">
            <button
              className="rounded-full py-3 bg-white px-3 text-accent border-2 border-accent text-m mx-auto mt-4 w-full"
              onClick={() => {
                const member = localStorage.getItem("members");

                let newmember = JSON.parse(member);
                let arr = {};
                arr = { ...userPayload, percentage: percentage };
                newmember = { ...userPayload, percentage: percentage };
                localStorage.setItem("members", JSON.stringify(arr));

                navigate("/tipjar/members/confirm");
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="button"
            >
              SET MEMBER % &nbsp;
            </button>
            <button
              className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-full mb-2"
              onClick={() => {
                const member = localStorage.getItem("members");

                let newmember = JSON.parse(member);
                let arr = {};
                arr = { ...userPayload, percentage: 0 };

                newmember = { ...userPayload, percentage: 0 };
                localStorage.setItem("members", JSON.stringify(arr));

                navigate("/tipjar/members/confirm");
                // dispatch(
                //   postTipjarMembers({
                //     tipjar_id: tipjar.tipjarId,
                //     user_id: userPayload?.id,
                //     percentage: 0,
                //     group_id: null,
                //   })
                // );
                // if (tipjar.memberPostSuccess === true) {
                //   console.log(tipjar.tipjarMemberResponse);
                // } else {
                //   toast.warning("Cannot add member, try again later!", {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                //   });
                // }
              }}
              type="button"
            >
              CONTINUE WITHOUT %
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col px-3 w-full h-screen">
      <div className={(perc || expressUser) && "blur-sm"}>
        {/* <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center py-2 pl-2 pr-6`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        {!searchPhone && (
          <form className="mx-4 mt-11 h-full">
            <label className="text-3xl font-semibold text-primary-pwa">
              Enter Member's Shukran ID
            </label>
            <div
              className={`
              flex flex-row drop-shadow-xl mt-5 justify-between relative
              ${defaultView ? "focus:outline-primary-pwa" : ""}
              `}
            >
              <div className="p-4 flex justify-center items-center absolute inset-y-0 left-0 pointer-events-none z-50">
                {defaultView && !searchState.errorMessage ? (
                  <div onClick={() => handleSearch()}>
                    <img src={searchIcon} alt="search icon" className="" />
                  </div>
                ) : searchState.results.length ? (
                  <div>
                    <img src={successIcon} alt="Success Icon" className="" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <input
                value={searchId}
                onChange={(e) => handleChangePin(e)}
                onKeyDown={(e) => handleEnterKey(e)}
                placeholder={searchPhone ? "Phone Number" : "Search Shukran ID"}
                className={`text-sm block p-4 pl-10 w-full ${
                  searchState.errorMessage &&
                  !searchState.isLoading &&
                  !searchState.results.length
                    ? "focus:outline-[#EF5F89]"
                    : ""
                }`}
                onFocus={(e) => {
                  if (e.currentTarget === e.target) {
                    // setIsFocused(true);
                    dispatch(searchActions.searchLoadError(""));
                  }
                }}
                onBlur={(e) => {
                  // setIsFocused(false);
                }}
              />
              {/* <div className="flex justify-center items-center absolute inset-y-0 right-0 pointer-events-none z-50">
              <img src={searchBeer} alt="search icon" className="" />
             </div> */}
              <div className="flex justify-center items-center">
                {searchState.errorMessage &&
                !searchState.isLoading &&
                !searchState.results.length ? (
                  <div
                    onClick={() => {
                      console.log("clicked clear");
                      setSearchId("");
                      dispatch(searchActions.searchLoadError(""));
                    }}
                    className="p-4"
                  >
                    <img src={clearIcon} alt="Clear Icon" className="h-5" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {!searchState.isLoading &&
              !searchState.results.length &&
              !searchState.errorMessage &&
              !searchPhone && <NonShukranUserText />}
            {!searchState.isLoading &&
              !searchState.errorMessage &&
              !searchState.results.length && <SearchHelpText />}
            {!!searchState.results.length && (
              <div className="flex flex-col mt-7 pb-3 w-full">
                <span className="text-sm">Search Results:</span>
                <ul className="bg-[#F0FEFF] pt-1 px-2 mb-2 mt-2 rounded-md">
                  {searchState.results?.[0].results?.map((result, idx) => (
                    <a
                      key={idx}
                      className="flex flex-row items-center h-16 border-b-2 border-b-[#B9F5F947]"
                      onClick={() => handleSelectRecipient(result)}
                    >
                      <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                        <p className="text-white text-xs">
                          {result.shukran_id?.split("", 2)}
                        </p>
                      </div>
                      <div className="flex flex-col justify-between ml-4">
                        <span className="text-sm">
                          {result.first_name + " " + result.last_name}
                        </span>
                        <span className="text-xs text-gray-500">
                          {result.shukran_id}
                        </span>
                      </div>
                    </a>
                  ))}
                </ul>
                <div className="flex flex-row justify-between my-2 pt-3">
                  <div
                    className={`flex flex-row items-center ${
                      searchState.results?.[0].previous
                        ? "visible"
                        : "invisible"
                    }`}
                    onClick={() =>
                      handlePrevPage(searchState.results?.[0].previous)
                    }
                  >
                    <img
                      src={ArrowButtonPrev}
                      className="w-12 h-12"
                      alt="Previous-search-page"
                    />
                    <span className="ml-2">Previous</span>
                  </div>
                  <div
                    className={`flex flex-row items-center ${
                      searchState.results?.[0].next ? "visible" : "invisible"
                    }`}
                    onClick={() =>
                      handleNextPage(searchState.results?.[0].next)
                    }
                  >
                    <span className="mr-2">Next</span>
                    <img
                      src={ArrowButtonNext}
                      className="w-12 h-12"
                      alt="Next-search-page"
                    />
                  </div>
                </div>
              </div>
            )}
            {searchState.isLoading && (
              <img
                src={loader}
                alt="loader"
                className="animate-spin h-8 text-base mt-7 mx-auto"
              />
            )}
            {searchState.errorMessage &&
              !searchState.isLoading &&
              !searchState.results.length && <SearchError />}
          </form>
        )}
        {searchPhone && (
          <form
            onSubmit={handleSubmit(handlePhoneProceed)}
            className="mx-4 mt-11 h-full"
          >
            <label className="text-3xl font-semibold text-primary-pwa">
              Enter Service Worker's Phone Number
            </label>
            <div className={`flex flex-col justify-between`}>
              <div
                className={`flex flex-row drop-shadow-xl mt-5 justify-between relative`}
              >
                <input
                  className={`
                  ${defaultView ? "focus:outline-primary-pwa" : ""}
                  ${errorView ? "focus:outline-red-300" : ""}
                  text-sm w-full p-4`}
                  type="number"
                  placeholder="Phone Number"
                  onFocus={(e) => {
                    if (e.currentTarget === e.target) {
                      // setIsFocused(true);
                      dispatch(searchActions.searchLoadError(""));
                    }
                  }}
                  {...register("phoneNumber", {
                    required: true,
                    // maxLength: 10,
                    // pattern:
                    //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                  })}
                />
                {/* <div className="flex justify-center items-center absolute inset-y-0 right-0 pointer-events-none z-50">
                <img src={searchBeer} alt="search icon" className="" />
              </div> */}
              </div>
              {errors.phoneNumber && (
                <p className="text-red-600 pt-2 text-sm">
                  Please fill in a valid phone number
                </p>
              )}
              {!searchState.isLoading &&
                !searchState.results.length &&
                !searchState.errorMessage &&
                searchPhone && <ShukranUserText />}
            </div>
            {!searchState.isLoading &&
              !searchState.errorMessage &&
              !searchState.results.length && <PhoneHelpText />}
            {searchState.errorMessage && <PhoneSubmitError />}
            <button
              className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-full"
              onClick={() => handlePhoneProceed()}
              type="submit"
            >
              PROCEED
            </button>
          </form>
        )}
      </div>
      {perc && <SelectPercentModal />}
      {expressUser && <CreateExpressUser />}
      <ToastContainer />
    </div>
  );
}
