import React, { useState } from "react";
import Header from "../../globals/Header";
import AmountInput from "../../globals/AmountInput";
import SearchInput from "./components/SearchInput";

function SelectIndividual() {
  const [search, setSearch] = useState("");
  return (
    <div className="mx-5">
      <Header title={"Select the individual(s) you want to tip"} />
      <SearchInput />
    </div>
  );
}

export default SelectIndividual;
