import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";

import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import "./members.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
  postTipjarGroups,
} from "../../state/reducers/user/user.thunk";
import { ToastContainer } from "react-toastify";
import BackButton from "../globals/BackButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchJson, postJson } from "../../utils/fetch.utils";
import { BASE_URL } from "../../constants/network";
export default function AddGroups() {
  const [group, setGroup] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const userAccount = useSelector((state) => state.account);
  const tipjar = useSelector((state) => state.tipjar);
  const [perc, setPerc] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: tipJarInfo, mutate: tipJarInfocreateMutation } = useMutation({
    mutationKey: "tipjar-creation-details",
    mutationFn: async () => {
      try {
        const resp = await postJson(BASE_URL + "api/v1/tipjar/");

        return resp.data;
      } catch (error) {
        return error;
      }
    },
  });

  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
    tipJarInfocreateMutation();
  }, []);
  // useEffect(() => {
  //   dispatch(getTipjarData({}, userAccount.userDetails.token));
  //   dispatch(getTipjarMembers({}, userAccount.userDetails.token));
  // }, []);
  // useEffect(() => {
  //   if (tipjar?.tipjarId) {
  //     dispatch(
  //       getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
  //     );
  //   }
  // }, [tipjar?.tipjarId]);

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, []);
  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, [percentage]);

  const handlePerc = (value) => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });

    const add = count + parseInt(value);
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(Math.max(value, minValue), maxValue);

    setPercentage(String(limitedValue));
  };
  const addPerc = () => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);

    if (isNaN(percentage)) {
      percentage = 0;
    }
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(
      Math.max(percentage + 10, minValue),
      maxValue
    );

    setPercentage(limitedValue);
  };
  const substractPerc = () => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);

    if (isNaN(percentage)) {
      percentage = 0;
    }
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(
      Math.max(percentage - 10, minValue),
      maxValue
    );

    setPercentage(limitedValue);
  };
  useEffect(() => {
    if (tipjar.tipjargroupResponse.id) {
      navigate(`/tipjar/groups/edit?group=${tipjar.tipjargroupResponse.id}`);
      tipjar.tipjargroupResponse = {};
    }
  }, [tipjar.tipjargroupResponse]);

  return (
    <div
      className="flex flex-col px-3 w-full h-screen mb-6"
      style={{ width: "100%" }}
    >
      {/* <div
        className="flex flex-row items-center mt-9 w-full"
        style={{ width: "100%" }}
      >
        <div
          className={`flex flex-row items-center py-2 pl-2 pr-6`}
          onClick={() => handleBack()}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div> */}
      <div className="w-screen p-3">
        <BackButton onPress={handleBack} />
      </div>
      <div className="px-3 mt-6 w-full" style={{ width: "100%" }}>
        <h2 className="text-3xl font-semibold text-primary-pwa">
          Create Group
        </h2>
        <p
          className="text-md font-semibold text-gray-400"
          style={{ width: "95%" }}
        >
          Create group and set up distribution percent for Tips
        </p>

        <div
          className={`
              flex flex-row drop-shadow-xl mt-8 justify-between relative 
              focus:outline-primary-pwa 
              `}
          style={{ width: "100%" }}
        >
          <div style={{ flexDirection: "column", width: "100%" }}>
            <h6 style={{ color: "#ADB6C0" }} className="mb-2">
              Group Name
            </h6>
            <input
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              //   onKeyDown={(e) => handleEnterKey(e)}
              placeholder={"Enter group name"}
              className={`text-sm block p-4 pl-10 w-full focus:outline-none`}
              onFocus={(e) => {
                if (e.currentTarget === e.target) {
                  // setIsFocused(true);
                  //dispatch(searchActions.searchLoadError(""));
                }
              }}
              onBlur={(e) => {
                // setIsFocused(false);
              }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }} className="mt-6">
          <h6 style={{ color: "#ADB6C0" }} className="mb-2">
            Set Distribution Percentage
          </h6>
          <div className="inputRange" style={{ width: "100%" }}>
            <button
              className="py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-1/5"
              onClick={() => {
                // const minValue = 0;
                // const maxValue = 100 - perc;
                // const limitedValue = Math.min(Math.max(10, minValue), maxValue);
                // if (percentage > 10 && limitedValue > 0) {
                //   setPercentage(percentage - 10);
                // }
                substractPerc();
              }}
              type="button"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              -
            </button>
            <input
              value={percentage.toString().replace(/^0+/, "")}
              onChange={(e) => handlePerc(e.target.value)}
              //   onKeyDown={(e) => handleEnterKey(e)}
              placeholder={"0%"}
              className={`text-sm block p-4 pl-4 w-2/5 mt-3 focus:outline-none drop-shadow-xl`}
              onFocus={(e) => {
                if (e.currentTarget === e.target) {
                  // setIsFocused(true);
                  //dispatch(searchActions.searchLoadError(""));
                }
              }}
              onBlur={(e) => {
                // setIsFocused(false);
              }}
              style={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              min={0}
              max={perc + parseInt(percentage)}
              type="number"
            />
            <button
              className="py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-1/5"
              onClick={() => {
                addPerc();
              }}
              type="button"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              +
            </button>
          </div>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="flex text-[#EE5582] mt-3"
          >
            {perc + parseInt(percentage) > 100 && "Percentage already 100%"}
          </p>
        </div>
        <div
          onClick={() => setIsOpen(!isOpen)}
          style={isOpen ? { width: "100%" } : { width: "100%", height: "17%" }}
          className="mt-8 mb-5 flex flex-col py-5 px-5 bg-[#FFF9ED] rounded-sm"
        >
          <div className="flex flex-row justify-between items-center">
            <span className="text-base">How does it work</span>
            <img src={QuestionIcon} className="" alt="question-mark" />
          </div>
          {isOpen && (
            <span className="text-sm mt-2">
              The distribution percent is the portion from the total that is
              assigned to this group. Example, a 20% distribution means this
              group will get 20% of the total tips which is then divided across
              the members.
            </span>
          )}
        </div>
      </div>
      <div className="mb-4 mt-2">
        <button
          // disabled={tipjar.isLoading}
          className={`rounded-full py-3 bg-${
            perc + percentage > 90 ? "accent" : "accent"
          } px-3 text-white text-m mx-auto mt-2 w-full`}
          onClick={() => {
            dispatch(
              postTipjarGroups(
                {
                  name: group,
                  percentage,
                  tipjar_id: tipJarInfo?.id,
                },
                userAccount.userDetails.token
              )
            );
          }}
          // disabled={perc >= 100}
          type="button"
        >
          {tipjar?.isLoading ? "Loading..." : "Proceed to Add Members"}{" "}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}
