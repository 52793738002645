export const actionTypes = {
  UPDATE_SACCO_DETAILS: "UPDATE_SACCO_DETAILS",
  SACCO_ACTIVATE_START: "SACCO_ACTIVATE_START",
  SACCO_ACTIVATE_SUCCESS: "SACCO_ACTIVATE_SUCCESS",
  SACCO_ACTIVATE_ERROR: "SACCO_ACTIVATE_ERROR",
  ADJUST_ACCESS_START: "ADJUST_ACCESS_START",
  ADJUST_ACCESS_SUCCESS: "ADJUST_ACCESS_SUCCESS",
  ADJUST_ACCESS_ERROR: "ADJUST_ACCESS_ERROR",
  ACCESS_TIP_START: "ACCESS_TIP_START",
  ACCESS_TIP_SUCCESS: "ACCESS_TIP_SUCCESS",
  ACCESS_TIP_ERROR: "ACCESS_TIP_ERROR",
  CHECK_ACCESS_TIP_START: "CHECK_ACCESS_TIP_START",
  CHECK_ACCESS_TIP_SUCCESS: "CHECK_ACCESS_TIP_SUCCESS",
  CHECK_ACCESS_TIP_REDIRECT: "CHECK_ACCESS_TIP_REDIRECT",
  CHECK_ACCESS_TIP_ERROR: "CHECK_ACCESS_TIP_ERROR",
  GET_BALANCE_START: "GET_BALANCE_START",
  GET_BALANCE_SUCCESS: "GET_BALANCE_SUCCESS",
  GET_BALANCE_ERROR: "GET_BALANCE_ERROR",
  GET_SACCO_INFO_START: "GET_SACCO_INFO_START",
  GET_SACCO_INFO_SUCCESS: "GET_SACCO_INFO_SUCCESS",
  GET_SACCO_INFO_ERROR: "GET_SACCO_INFO_ERROR",
  GET_LOANS_START: "GET_LOANS_START",
  GET_LOANS_SUCCESS: "GET_LOANS_SUCCESS",
  GET_LOANS_ERROR: "GET_LOANS_ERROR",
  GET_REFERRALS_START: "GET_REFERRALS_START",
  GET_REFERRALS_SUCCESS: "GET_REFERRALS_SUCCESS",
  GET_REFERRALS_ERROR: "GET_REFERRALS_ERROR",
  GET_REDEEMABLE_REFERRALS_START: "GET_REDEEMABLE_REFERRALS_START",
  GET_REDEEMABLE_REFERRALS_SUCCESS: "GET_REDEEMABLE_REFERRALS_SUCCESS",
  GET_REDEEMABLE_REFERRALS_ERROR: "GET_REDEEMABLE_REFERRALS_ERROR",
  REDEEM_REWARD_START: "REDEEM_REWARD_START",
  REDEEM_REWARD_SUCCESS: "REDEEM_REWARD_SUCCESS",
  REDEEM_REWARD_ERROR: "REDEEM_REWARD_ERROR",
};

const getReferralsStart = () => ({
  type: actionTypes.GET_REFERRALS_START,
});
const getReferralsSuccess = (data) => ({
  type: actionTypes.GET_REFERRALS_SUCCESS,
  payload: data,
});
const getReferralsError = (data) => ({
  type: actionTypes.GET_REFERRALS_ERROR,
  payload: data,
});

const getRedeemableReferralsStart = () => ({
  type: actionTypes.GET_REDEEMABLE_REFERRALS_START,
});
const getRedeemableReferralsSuccess = (data) => ({
  type: actionTypes.GET_REDEEMABLE_REFERRALS_SUCCESS,
  payload: data,
});
const getRedeemableReferralsError = (data) => ({
  type: actionTypes.GET_REDEEMABLE_REFERRALS_ERROR,
  payload: data,
});

const redeemRewardStart = () => ({
  type: actionTypes.REDEEM_REWARD_START,
});
const redeemRewardSuccess = (data) => ({
  type: actionTypes.REDEEM_REWARD_SUCCESS,
  payload: data,
});
const redeemRewardError = (data) => ({
  type: actionTypes.REDEEM_REWARD_ERROR,
  payload: data,
});

const updateSaccoDetails = (data) => ({
  type: actionTypes.UPDATE_SACCO_DETAILS,
  payload: data,
});
const saccoActivateStart = () => ({
  type: actionTypes.SACCO_ACTIVATE_START,
});
const saccoActivateSuccess = (data) => ({
  type: actionTypes.SACCO_ACTIVATE_SUCCESS,
  payload: data,
});
const saccoActivateError = (data) => ({
  type: actionTypes.SACCO_ACTIVATE_ERROR,
  payload: data,
});
const accessTipStart = () => ({
  type: actionTypes.ACCESS_TIP_START,
});
const accessTipSuccess = (data) => ({
  type: actionTypes.ACCESS_TIP_SUCCESS,
  payload: data,
});
const accessTipError = (data) => ({
  type: actionTypes.ACCESS_TIP_ERROR,
  payload: data,
});
const checkAccessTipStart = () => ({
  type: actionTypes.CHECK_ACCESS_TIP_START,
});
const checkAccessTipSuccess = (data) => ({
  type: actionTypes.CHECK_ACCESS_TIP_SUCCESS,
  payload: data,
});
const checkAccessTipRedirect = (data) => ({
  type: actionTypes.CHECK_ACCESS_TIP_REDIRECT,
  payload: data,
});
const checkAccessTipError = (data) => ({
  type: actionTypes.CHECK_ACCESS_TIP_ERROR,
  payload: data,
});
const adjustAccessStart = () => ({
  type: actionTypes.ADJUST_ACCESS_START,
});
const adjustAccessSuccess = (data) => ({
  type: actionTypes.ADJUST_ACCESS_SUCCESS,
  payload: data,
});
const adjustAccessError = (data) => ({
  type: actionTypes.ADJUST_ACCESS_ERROR,
  payload: data,
});
const getBalanceStart = () => ({
  type: actionTypes.GET_BALANCE_START,
});
const getBalanceSuccess = (data) => ({
  type: actionTypes.GET_BALANCE_SUCCESS,
  payload: data,
});
const getBalanceError = (data) => ({
  type: actionTypes.GET_BALANCE_ERROR,
  payload: data,
});

const getLoansStart = () => ({
  type: actionTypes.GET_LOANS_START,
});
const getLoansSuccess = (data) => ({
  type: actionTypes.GET_LOANS_SUCCESS,
  payload: data,
});
const getLoansError = (data) => ({
  type: actionTypes.GET_LOANS_ERROR,
  payload: data,
});

const getSaccoInfoStart = () => ({
  type: actionTypes.GET_SACCO_INFO_START,
});
const getSaccoInfoSuccess = (data) => ({
  type: actionTypes.GET_SACCO_INFO_SUCCESS,
  payload: data,
});
const getSaccoInfoError = (data) => ({
  type: actionTypes.GET_SACCO_INFO_ERROR,
  payload: data,
});

export const saccoActions = {
  updateSaccoDetails,
  saccoActivateStart,
  saccoActivateSuccess,
  saccoActivateError,
  accessTipStart,
  accessTipSuccess,
  accessTipError,
  checkAccessTipStart,
  checkAccessTipSuccess,
  checkAccessTipError,
  checkAccessTipRedirect,
  adjustAccessStart,
  adjustAccessSuccess,
  adjustAccessError,
  getBalanceStart,
  getBalanceSuccess,
  getBalanceError,
  getLoansStart,
  getLoansSuccess,
  getLoansError,
  getSaccoInfoStart,
  getSaccoInfoSuccess,
  getSaccoInfoError,
  getReferralsStart,
  getReferralsSuccess,
  getReferralsError,
  getRedeemableReferralsStart,
  getRedeemableReferralsSuccess,
  getRedeemableReferralsError,
  redeemRewardStart,
  redeemRewardSuccess,
  redeemRewardError
};
