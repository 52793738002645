import React from "react";
import { globalTheme } from "../../../globals/global_css/globalsTheme";

export default function DestinationBtn({
  active,
  title,
  img,
  Icon,
  onPress,
  dest,
}) {
  return (
    <div
      onClick={() => onPress()}
      className={`${
        active ? "bg-primary-pwa" : "bg-[#F2F2F2]"
      } flex flex-row items-center justify-center h-10 p-3 rounded-3xl `}
      style={{ width: "40%" }}
    >
      {Icon}
      <p
        className={`${active ? "text-white" : ""} ml-2`}
        style={{
          fontWeight: "600",
          color: active ? "white" : globalTheme.global.descText,
          fontSize: "14px",
        }}
      >
        {title}
      </p>
    </div>
  );
}
