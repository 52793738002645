import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import cross from "../../assets/Icons/Tipping/cross.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkDepositStatus, checkSaccoTipStatus, depositAmount, sendTip } from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { PaystackButton } from "react-paystack";
import { depositActions } from "../../state/reducers/deposit/deposit.actions";

export default function DepositCreditCard() {
  const [cardEmail, setCardEmail] = useState("");
  const [cardResponse, setCardResponse] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.balance.userInfo);
  const depositState = useSelector((state) => state.deposit);
  const userData = useSelector((state) => state.account.userDetails);
  const depositData = useSelector((state) => state.account.depositedAmount);

  const [amount, setAmount] = useState('')
  const [saveCard, setSaveCard] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const Text = () => (
      <div className="mt-12 rounded-3xl py-2 bg-primary-pwa text-white text-base uppercase w-52 mx-auto">
        Confirm
      </div>
  )
  const componentProps = {
    email: cardEmail,
    currency: 'KES',
    amount: depositData.amount * 100,
    metadata: {
      name,
      phone,
    },
    publicKey: depositState.results.data?.key,
    reference: `${depositState.results.data?.reference}`,
    text: <Text />,
    onSuccess: (res) => {
      console.log('card success', res);
      setCardResponse(true);
      // dispatch(balanceActions.tippingSuccess({}));
      setTimeout(() => {
        dispatch(checkDepositStatus(res.reference, userData.token));
      }, 10000);
    },
    onClose: () => {
      dispatch(depositActions.depositLoadSuccess({}));
      console.log('closed',)
    },
  }

  useEffect(() => {
    let depositNo = localStorage.getItem("depositNumber")
      ? localStorage.getItem("depositNumber")
      : userData.phoneNumber;
    setPhoneNumber(depositNo);

    return () => {};
  }, []);

  useEffect(() => {
    if (depositState.errorMessage.status === 403) {
      navigate("/login");
      dispatch(depositActions.depositLoadError(""));
    }
    return () => {};
  }, [depositState, dispatch, navigate]);

  // useEffect(() => {
  //   if (
  //     depositState.results.success 
  //   ) {
  //     setTimeout(() => {
  //       dispatch(depositActions.depositLoadSuccess({}));
  //       dispatch(depositActions.checkTransactionError(""));
  //       navigate("/");
  //     }, 3000);
  //   }
  //   return () => {};
  // }, [depositState,]);
  

  const handleBack = () => {
    navigate(-1);
  };

  const handleUserEmail = (data) => {
    console.log("submit", data);
    setSaveCard(data.saveCard)
    setCardEmail(data.email)
    submitTip(data)
  };

  const submitTip = (data) => {
    let depositPhone = userInfo.phone;
    if (depositPhone) {
      depositPhone = "0" + depositPhone.slice(3);
    }
    setPhone(depositPhone)
    setAmount(Number(depositData.amount))
    let payload = {
      amount: Number(depositData.amount),
      email: data.email,
      method: "paystack",
      user_card_id: "",
      save_card_details: !!data.saveCard
    };
    localStorage.setItem('payload', payload);
    dispatch(depositAmount(payload, userData.token));
    setCardResponse("");
  };

  const ConfirmDepositPopup = () => (
    <div className="px-4 mt-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">Confirm Deposit</span>
        <div
          onClick={() => {
            // setCardEmail('');
            // setCardResponse(false)
            dispatch(depositActions.depositLoadSuccess({}));
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">From</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">{phoneNumber}</span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">To</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black">
              Sacco Account ~ {userData.shukranId}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Deposit Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-lg text-black">Ksh {depositData.amount}</span>
          </div>
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">Payment Method</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">
              {cardEmail ? "Card Payment" : "MPESA"}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-row items-center justify-center">
          {/* <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            onClick={submitTip}
          >
            Proceed
          </button> */}
          <PaystackButton  {...componentProps} />
      </div>
    </div>
  );

  const DepositTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!depositState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(depositActions.depositLoadStart({}));
            dispatch(depositActions.checkTransactionError(""));
            
            // setTogglePopup(false);
            
          }}
        >
          X
        </button>
      )}

      {/* TransactionCard Icons */}

      {depositState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1`}
        />
      )}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-32" />
      )}
      {(depositState.errorTransactionStatusMessage ||
        depositState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-32" />
      )}
      {depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          Input your PIN on the Mpesa prompt to make a deposit
        </p>
      )}

      {depositState.results.success && !depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )}
      {depositState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage.msg}
        </p>
      )}
      {depositState.errorMessage?.data?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage?.data?.msg}
        </p>
      )}
      {depositState.errorMessage?.data?.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage?.data?.detail}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Deposit transaction{" "}
          {depositState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${depositState.errorMessage.success ? "relative" : ""}
      ${depositState.errorMessage.error ? "relative" : ""}
      ${depositState.isLoading ? "relative" : ""}
      ${depositState.results.success ? "relative" : ""}
      ${depositState.errorTransactionStatusMessage ? "relative" : ""}  
      ${cardResponse ? "relative" : ""}
      w-full flex flex-col`}
    >
      <div
        className={`
        ${depositState.errorMessage.success ? "blur-sm" : ""}
        ${depositState.errorMessage.error ? "blur-sm" : ""}
        ${depositState.results.success ? "blur-sm" : ""}
        ${depositState.isLoading ? "blur-sm" : ""}
        ${depositState.errorTransactionStatusMessage ? "blur-sm" : ""} 
        ${cardResponse ? "blur-sm" : ""}
        flex flex-col px-3 w-full h-full`}
      >
        <div className="flex flex-row items-center mt-9">
          <div
            onClick={() => handleBack()}
            className=""
          >
            <img src={back} className="" alt="rate-star" />
          </div>
        </div>
        <div className="mx-3 mt-11 mb-5 h-full">
          <span className="text-base font-semibold text-black">
            Please enter your email to receive card payments receipts.
          </span>
        </div>
        <form
          className="flex flex-col justify-between m-3 h-full"
          onSubmit={handleSubmit(handleUserEmail)}
        >
          <div className="flex flex-col mt-1">
            <label className="my-2 text-label text-xs">Email *</label>
            <input
              className="text-sm block p-4 pl-10 w-full drop-shadow-lg"
              type="email"
              placeholder="jane@gmail.com"
              {...register("email", {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
            />
            {errors.email && (
              <p className="text-red-600 text-sm pt-3">
                Please enter your email
              </p>
            )}
          </div>
          <div className="flex flex-col mt-5">
            <div className="flex flex-row mt-2 text-sm items-center ">
              <input
                type="checkbox"
                className="mr-6 active:bg-primary-pwa"
                {...register("saveCard", {
                  required: false,
                })}
              />
              <p className="text-label text-xs">Save card for future use (Secured by Paystack - Regulated by CBK)</p>
            </div> 
            {errors.saveCard && (
              <p className="text-red-600 text-sm pt-1">
                Please enter your consent
              </p>
            )}
           </div>
          <button
            className="rounded-full py-2 mt-6 bg-accent text-white text-base w-full"
            type="submit"
          >
            CONTINUE
          </button>
        </form>
      </div>
      <div
        className={`${cardResponse ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${depositState.isLoading ? "absolute blur-none" : "hidden"
          } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div>
      <div
        className={`${depositState.results.success &&
            depositState.results.data?.key && !cardResponse
            ? "absolute blur-none"
            : "hidden"
          } flex flex-col justify-center items-center w-full h-full`}
      >
        <ConfirmDepositPopup />
      </div>
      <div
        className={`${depositState.errorMessage ||
          depositState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
          } flex justify-center items-center w-full h-screen`}
      >
        <DepositTransactionCard />
      </div>
    </div>
  );
}
