import React from "react";

export default function Button({ text, color, txtColor, onPress }) {
  return (
    <button
      style={{ color: txtColor }}
      onClick={() => onPress()}
      className={`${
        color?.startsWith("#") ? `bg-[${color}]` : `bg-${color}`
      } w-20 h-30 rounded-3xl p-2`}
    >
      {text}
    </button>
  );
}
