import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useForm } from "react-hook-form";
import actions from "../../state/index";
import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";

import checkCircle from "../../assets/Icons/Popups/check_circle.svg";

import back from "../../assets/Icons/Rating/back_icon.svg";
import { generateTokenOtp } from "../../state/reducers/user/user.thunk";
import { searchActions } from "../../state/reducers/search/search.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";
import useIsIOS from "../popups/useIsIos";
import BackButton from "../globals/BackButton";

export default function QueryMpesaNo() {
  const [isIOS, setIsIOS] = useIsIOS();
  const dispatch = useDispatch();
  const { loginUser, addMpesaNo } = bindActionCreators(actions, dispatch);
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const tippingState = useSelector((state) => state.tipping);
  const searchState = useSelector((state) => state.search);
  const [showConfirm, setShowConfirm] = useState(false);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  // values from react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleBack = () => {
    navigate(-1);
  };

  // user input handler
  useEffect(() => {
    console.log("isLoading", searchState.isLoading);
    return () => {};
  }, [searchState.isLoading]);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    return () => {};
  }, [searchState]);

  useEffect(() => {
    if (balanceState.errorMessage.msg) {
      setTimeout(() => {
        dispatch(balanceActions.generateTokenError(""));
      }, 4000);
    }
    return () => {};
  }, [balanceState.errorMessage, dispatch, navigate]);

  const handlePhone = (data) => {
    console.log("mpesa no submit", data);
    let payload = {
      phone: data.phoneNumber,
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
    //dispatch(generateTokenOtp(payload));
    addMpesaNo({
      mpesa_no: data.phoneNumber,
    });
  };

  const TokenGenerationCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          balanceState.generateResponse.phone = "";
          dispatch(balanceActions.generateTokenReset());
          loginUser(balanceState.userInfo);
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-32" />
      <p className="justify-self-center	text-base text-center py-2">
        Phone no. submitted successfully
      </p>
      <button
        className="mt-12 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
        onClick={() => {
          dispatch(balanceActions.generateTokenReset());
          loginUser(balanceState.userInfo);
          navigate(-1);
        }}
      >
        Proceed
      </button>
    </div>
  );

  const ErrorCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-8 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.generateTokenError(""));
        }}
      >
        X
      </button>
      <img src={cross} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-base text-center py-2">
        {balanceState.errorMessage.msg}
      </p>
    </div>
  );

  return (
    <div
      className={`
        ${balanceState.errorMessage.error ? "relative" : ""}
        ${balanceState.errorMessage.msg ? "relative" : ""}
        ${balanceState.success ? "relative" : ""} 
        ${balanceState.isLoading ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={`
          ${
            balanceState.errorMessage.msg &&
            balanceState.errorMessage !== "Undefined Error"
              ? "blur-sm"
              : ""
          }
          ${balanceState.success ? "blur-sm" : ""}
          ${balanceState.isLoading ? "blur-sm" : ""}
          w-full flex flex-col px-5`}
      >
        {/* <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
          {/* <span className="text-base">Go back home</span> 
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div className="mx-4 mt-11 h-full flex flex-col justify-between">
          <form onSubmit={handleSubmit(handlePhone)} className="flex flex-col">
            <div className="flex flex-col">
              <span className="text-2xl font-semibold text-primary-pwa">
                Enter Your Mpesa Number
              </span>
              <input
                className="drop-shadow-md border-solid border-[#50a3a7]/70 outline h-12 placeholder:text-base w-full p-2 text-black outline-none focus:outline-primary-pwa mt-4"
                type="number"
                placeholder="0700000000"
                {...register("phoneNumber", {
                  required: true,
                  maxLength: 15,
                  pattern: /^(?:\+254|0)[1-9]\d{8}$/,
                  onChange: (e) => {
                    setPhone(e.target.value);
                  },
                  // /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                })}
              />
              {errors.phoneNumber && (
                <p className="text-red-600 text-sm mt-2">
                  Kindly check if your number is an Mpesa number
                </p>
              )}
            </div>
            <div className="mt-5 flex flex-col py-5 px-5 bg-[#F3F0FF] rounded-sm">
              <div className="flex flex-row justify-between items-center">
                <span className="text-base">How does it work</span>
                <img src={QuestionIcon} className="" alt="question-mark" />
              </div>
              <span className="text-sm mt-2">
                You will receive an STK Push anytime you want to tip a service
                worker when you choose the Mpesa option
              </span>
            </div>
            <button
              className="rounded-full py-3 bg-accent px-3 text-white text-md mx-auto mt-24 mb-12 w-full"
              type="button"
              onClick={() => {
                addMpesaNo({
                  mpesa_no: phone,
                });
                navigate("/paymentOpt", { state: { showMpesa: true } });
              }}
            >
              PROCEED
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          balanceState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            SHUKRAN is generating an access key for submitted number
          </span>
        </div>
      </div>
      <div
        className={`${
          balanceState.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TokenGenerationCard />
      </div>
      <div
        className={`${
          balanceState.errorMessage.msg &&
          balanceState.errorMessage !== "Undefined Error"
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <ErrorCard />
      </div>
    </div>
  );
}
