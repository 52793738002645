import { actionTypes } from "./sacco.actions";

const saccoState = {
  isLoading: false,
  results: [],
  errorMessage: "",
  saccoDetails: {
    another_sacco_name: "",
    business_address: "",
    business_name: "",
    business_type: "",
    country: "",
    country_city: "",
    date_of_birth: "",
    e_signature_photo: "",
    email: "",
    employment_type: "employed",
    first_name: "",
    gender: "",
    gross_monthly_income: "",
    id_passport_back_photo: "",
    id_passport_photo: "",
    is_in_another_sacco: false,
    is_tip_income: false,
    is_under_eighteen: false,
    kra_pin: "",
    kra_pin_photo: "",
    middle_name: "",
    mode_of_remittance: "",
    monthly_contribution: "",
    national_id_passport: "",
    nominees: "",
    other_income: "",
    other_referral: "",
    passport_photo: "",
    phone_number: "",
    physical_address: "",
    postal_address: "",
    postal_code: "",
    primary_dob_day: "",
    primary_dob_month: "",
    primary_dob_year: "",
    referral_code: "",
    referral_type: "",
    surname: "",
    user_count: 0,
    weekly_tip_income: 0,
  },
  balance: [],
  referralRes: [],
  redeemableRes: [],
  redeemRes: [],
  loans: [],
  redirect: "",
  navigate: "",
};

const saccoReducer = (state = saccoState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_SACCO_DETAILS:
      return {
        saccoDetails: {
          ...state.saccoDetails,
          ...payload,
        },
        results: payload,
      };
    case actionTypes.SACCO_ACTIVATE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.SACCO_ACTIVATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    case actionTypes.SACCO_ACTIVATE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.GET_REFERRALS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        referralRes: [],
      };
    case actionTypes.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        referralRes: payload.data,
      };
    case actionTypes.GET_REFERRALS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.GET_REDEEMABLE_REFERRALS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        redeemableRes: [],
      };
    case actionTypes.GET_REDEEMABLE_REFERRALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redeemableRes: payload.data,
      };
    case actionTypes.GET_REDEEMABLE_REFERRALS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.REDEEM_REWARD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        redeemRes: [],
      };
    case actionTypes.REDEEM_REWARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        redeemRes: payload,
      };
    case actionTypes.REDEEM_REWARD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.ACCESS_TIP_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.ACCESS_TIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isTransactionLoading: true,
        results: payload,
      };
    case actionTypes.ACCESS_TIP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.CHECK_ACCESS_TIP_START:
      return {
        ...state,
        errorTransactionStatusMessage: "",
        transactionStatus: [],
      };
    case actionTypes.CHECK_ACCESS_TIP_SUCCESS:
      return {
        ...state,
        isTransactionLoading: false,
        results: payload,
      };
    case actionTypes.CHECK_ACCESS_TIP_REDIRECT:
      return {
        ...state,
        navigate: payload,
      };
    case actionTypes.CHECK_ACCESS_TIP_ERROR:
      return {
        ...state,
        isTransactionLoading: false,
        isLoading: false,
        results: [],
        errorTransactionStatusMessage: payload,
      };
    case actionTypes.ADJUST_ACCESS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.ADJUST_ACCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    case actionTypes.ADJUST_ACCESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.GET_SACCO_INFO_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        balance: [],
      };
    case actionTypes.GET_SACCO_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
        saccoInfo: payload,
      };
    case actionTypes.GET_SACCO_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };

    case actionTypes.GET_BALANCE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        balance: [],
      };
    case actionTypes.GET_BALANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        balance: payload,
      };
    case actionTypes.GET_BALANCE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.GET_LOANS_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        loans: [],
      };
    case actionTypes.GET_LOANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loans: payload,
      };
    case actionTypes.GET_LOANS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default saccoReducer;
