import React from "react";
import PageImage from "../../assets/Images/onboarding/Find.png";
import BaseOnboard from "./BoilerPlate/BaseOnboard";

export default function onboardingPage() {
  const textContent =
    "Ask the service worker for their Shukran ID or phone number";
  const contentTitle = "FIND";

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/tipOnboard"
      navigatePrev="/fund"
    />
  );
}
