import React, { useState, useEffect } from "react";
import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./paymentOpt.css";
import VisaIcon from "../../assets/Icons/Tipping/visa.svg";
import MastercardIcon from "../../assets/Icons/Tipping/mastercard.svg";
import AmericanExpress from "../../assets/Icons/Tipping/american_express.png";
import CardIcon from "../../assets/Icons/Tipping/card.svg";
import MpesaIcon from "../../assets/Icons/Tipping/mpesa.svg";
import stripeIcon from "../../assets/Icons/stripe/stripe.png";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import confetti from "../../assets/Icons/Tipping/confetti.gif";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import wallet from "../../assets/Icons/wallet.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import launchpadBrand from "../../assets/brands/launchpad.png";
import { searchActions } from "../../state/reducers/search/search.actions";
import {
  getCardTransactions,
  getUserData,
  sendTip,
} from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { getFromSessionStorage } from "../../Services/Storage";
import { testUrl } from "../../helper";
import axios from "axios";
import { bindActionCreators } from "redux";
import actions from "../../state";
import { toast, ToastContainer } from "react-toastify";
import BackButton from "../globals/BackButton";
import CardPayment from "./components/CardPayment";

export default function PaymentOption() {
  const [phoneNumber, setPhoneNumber] = useState();
  const [cardOption, setCardOption] = useState(false);
  const [cardPopup, setCardPopup] = useState(false);
  const [accessTipError, setAccessTipError] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [message, setMsg] = useState("");
  const [redirectTo, setRedirectTo] = useState("");
  const dispatch = useDispatch();
  const { addTipAmount, addQueryShukranId } = bindActionCreators(
    actions,
    dispatch
  );

  const [confirmMpes, setConfirmMpesa] = useState(false);

  const [amounts, setAmounts] = useState([
    {
      option: "card",
      label: "Credit/Debit card",
    },
    {
      option: "mpesa",
      label: "Mpesa",
    },
  ]);
  const locationPath = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    let redirect = searchParams.get("redirect");

    setRedirectTo(redirect);

    console.log(locationPath.pathname, "here bro");
    if (locationPath.pathname === "/pay") {
      setIsPayment(true);
      console.log(searchParams.get("amount"), "amount herex");
      setMsg(searchParams.get("message"));
      if (searchParams.get("amount") === null) {
        navigate(
          `/tip?shukranId=${searchParams.get(
            "shukranId"
          )}&name=${searchParams.get("name")}`
        );
      }
      addTipAmount(searchParams.get("amount"));
      addQueryShukranId({
        query_shukran_id: searchParams.get("shukranId"),
        query_name: searchParams.get("name"),
        currency: searchParams.get("currency"),
      });
    } else {
      setIsPayment(false);
    }
  }, [dispatch]);
  useEffect(() => {
    if (searchParams.get("amount") === null) {
      // navigate(
      //   `/tip?shukranId=${searchParams.get(
      //     "shukranId"
      //   )}&name=${searchParams.get("name")}`
      // );
    }
  }, [isPayment]);
  useEffect(() => {
    dispatch(balanceActions.tippingError(""));
  }, [dispatch]);
  const { state } = locationPath;
  const [showMpesa, setShowMpesa] = useState(false);

  const [loggedConfirm, setLoggedConfirm] = useState(false);
  const userInfo = useSelector((state) => state.balance.userInfo);
  let searchState = useSelector((state) => state.search);
  const senderData = useSelector((state) => state.account.userDetails);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const tipData = useSelector((state) => state.tipData);
  let receiverShukranId = tipData.shukranId;
  let amount = tipData.tipAmount;
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  let queryName = tipData.queryName;
  let currency = tipData.currency;
  let loginState = localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : "false";
  let isLoggedIn = JSON.parse(loginState);
  const [isPayingTransaction, setIsPayingTransaction] = useState(
    receiverShukranId === "CL2428" || queryShukranId === "CL2428" ? true : false
  );
  const tippingState = useSelector((state) => state.tipping);
  const userAccount = useSelector((state) => state.account);
  useEffect(() => {
    const res = balanceState.generateResponse;
  }, []);
  useEffect(() => {
    setShowMpesa(state?.showMpesa || false);
    if (locationPath.pathname !== "/pay") {
      if (tipData.tipAmount === undefined) {
        navigate(-1);
      }
    }
  }, []);
  const transactionState = useSelector((state) => {
    let uniqueIds = [];
    let arrFil = state.transactions?.cardTransactions?.data
      ? state.transactions?.cardTransactions?.data
      : [];
    let unique = arrFil.filter((element) => {
      let isDuplicate = uniqueIds.includes(element.authorization_json.last4);

      if (!isDuplicate) {
        uniqueIds.push(element.authorization_json.last4);
        return true;
      }

      return false;
    });
    return {
      cardTransactions: unique,
    };
  });

  useEffect(() => {
    if (tippingState.navigate.navigate === "/" && !cardOption) {
      setTimeout(() => {
        if (queryShukranId) {
          navigate("/download");
        } else if (redirectTo === "bill") {
          navigate("/bill/pay");
        } else {
          navigate("/download");
        }

        dispatch(balanceActions.checkTippingRedirect(""));
      }, 3000);
    }
    if (tippingState.tippingResponse.success && cardOption) {
      setTimeout(() => {
        dispatch(balanceActions.tippingSuccess({}));
        dispatch(balanceActions.checkTippingSuccess(""));
        dispatch(balanceActions.checkTippingRedirect(""));
        setCardOption("");
        if (queryShukranId) {
          navigate("/download");
        } else if (redirectTo === "bill") {
          navigate("/bill/pay");
        } else {
          navigate("/download");
        }
      }, 3000);
    }
    return () => {};
  }, [tippingState, cardOption, dispatch, navigate]);
  //Handle enter key input

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    console.log("queryShukranId", !queryShukranId);
    let params = {
      shukran_id: shukran_id,
    };
    if (!queryShukranId) {
      dispatch(getUserData(params, userAccount.userDetails.token));
      dispatch(getCardTransactions(userAccount.userDetails.token));
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (
      balanceState.errorMessage === 403 &&
      !queryShukranId &&
      locationPath.pathname !== "/pay" &&
      isLoggedIn
    ) {
      // if (queryShukranId) {
      //   navigate("/registration");
      // } else {
      navigate("/");
      localStorage.setItem("isLoggedIn", JSON.stringify(false));
      // }
    }
    return () => {};
  }, [balanceState, queryShukranId, navigate]);

  useEffect(() => {
    if (
      searchState.errorMessage === 403 &&
      locationPath.pathname !== "/pay" &&
      !queryShukranId &&
      isLoggedIn
    ) {
      //navigate("/login");
      localStorage.setItem("isLoggedIn", JSON.stringify(false));
      dispatch(searchActions.searchLoadError(""));
    }
    if (
      tippingState.errorMessage === 403 &&
      locationPath.pathname !== "/pay" &&
      !queryShukranId &&
      isLoggedIn
    ) {
      console.log("/redirect to login");
      //navigate("/login");
      localStorage.setItem("isLoggedIn", JSON.stringify(false));
      dispatch(balanceActions.tippingError(""));
    }
    return () => {};
  }, [searchState, tippingState, navigate, dispatch]);

  const handleSetOption = (otpGroup) => {
    console.log("option", otpGroup);
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    let depositPhone = userInfo?.phone
      ? userInfo?.phone
      : senderData?.phoneNumber;

    console.log("depositNumber", typeof depositPhone);
    // if (depositPhone?.length > 10) {
    //   depositPhone = "0" + depositPhone.slice(3);
    // }
    console.log("depositNumber", depositPhone);
    console.log("userInfo", userInfo);
    if (otpGroup.option === "card") {
      if (isLoggedIn) {
        if (
          !!transactionState.cardTransactions.length &&
          isLoggedIn &&
          locationPath.pathname !== "/pay"
        ) {
          //setCardOption(true);
          setCardPopup(true);
          //navigate("/creditInputCard");
        } else {
          navigate("/creditInputCard");
        }
      } else {
        navigate("/creditInputCard");
      }
      // } else {
      //   setAccessTipError(true);
      // }
    } else {
      if (tipData?.currency === "USD") {
        toast.warning("For USD use card payment option", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        if (depositPhone && locationPath.pathname !== "/pay") {
          setPhoneNumber(depositPhone);
        } else {
          navigate("/mpesaNo");
        }
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };
  const emailAuthentication = async () => {
    const res = await axios.post(
      `${testUrl}accounts/auth/signup-phone-only/otp/`
    );
    console.log(res.data);
  };

  const submitTip = () => {
    setShowMpesa(false);
    console.log(userAccount?.userDetails?.token, "here is the token");
    let cardEmail = !!transactionState.cardTransactions.length
        ? transactionState.cardTransactions[0].customer_json.email
        : "",
      cardId = !!transactionState.cardTransactions.length
        ? transactionState.cardTransactions[0].id
        : "";

    let depositPhone = userInfo.phone ? userInfo.phone : senderData.phoneNumber;
    if (depositPhone?.length > 10) {
      depositPhone = "0" + depositPhone.slice(3);
    }
    let payload = {
      sender: depositPhone ? depositPhone : balanceState.generateResponse.phone,
      charge_tipper: true,
      currency: currency === "USD" ? "USD" : "KES",
    };
    let token = localStorage.getItem("token");
    localStorage.setItem("token", balanceState.generateResponse.token);
    if (
      phoneNumber ||
      depositPhone ||
      (balanceState.generateResponse.phone && nonShukranId)
    ) {
      payload = {
        ...payload,
        receivers: [
          {
            receiver_shukran_id: nonShukranId
              ? nonShukranId
              : receiverShukranId
              ? receiverShukranId
              : queryShukranId,
            amount: tipData.tipAmount,
          },
        ],

        method: "mpesa",
        currency: currency === "USD" ? "USD" : "KES",
      };
      if (loggedConfirm) {
        dispatch(sendTip(payload, userAccount?.userDetails?.token));
        setPhoneNumber("");
        setLoggedConfirm(false);
        balanceState.generateResponse.phone = "";
      } else {
        dispatch(sendTip(payload, balanceState.generateResponse.token));
        setPhoneNumber("");
        balanceState.generateResponse.phone = "";
      }
    } else if (
      phoneNumber ||
      depositPhone ||
      (balanceState.generateResponse.phone && receiverShukranId)
    ) {
      payload = {
        ...payload,
        receiver_shukran_id: receiverShukranId,
        method: "mpesa",
        currency: currency === "USD" ? "USD" : "KES",
      };
      dispatch(sendTip(payload, balanceState.generateResponse.token));
      setPhoneNumber("");
      balanceState.generateResponse.phone = "";
    } else if (
      phoneNumber ||
      depositPhone ||
      (balanceState.generateResponse.phone && queryShukranId)
    ) {
      payload = {
        ...payload,
        receiver_shukran_id: queryShukranId,
        method: "mpesa",
        currency: currency === "USD" ? "USD" : "KES",
      };
      dispatch(sendTip(payload, balanceState.generateResponse.token));
      setPhoneNumber("");
      balanceState.generateResponse.phone = "";
    } else if (cardOption && nonShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: nonShukranId,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId,
        currency: currency === "USD" ? "USD" : "KES",
      };

      dispatch((payload, userAccount?.userDetails?.token));
      setCardPopup(false);
    } else if (cardOption && receiverShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: receiverShukranId,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId,
        currency: currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, userAccount?.userDetails?.token));
      setCardPopup(false);
    } else if (cardOption && queryShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: queryShukranId,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId,
        currency: currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, userAccount?.userDetails?.token));
      // setCardOption("");
    } else {
    }
  };

  const ConfirmTipPopup = () => (
    <div className="py-8 px-4 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">
          Confirm {tipData?.queryShukranId === "LP1261" ? "Pay" : "Tip"}
        </span>
        <div
          onClick={() => {
            setPhoneNumber("");
            setCardOption(false);
            setCardPopup(false);
            setConfirmMpesa(false);
            setShowMpesa(false);
            setLoggedConfirm(false);
            if (balanceState.generateResponse.phone) {
              // navigate("/queryMpesaNo");
              balanceState.generateResponse.phone = "";
            }
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">
          {tipData?.queryShukranId === "LP1261" ? "Pay" : "Tip"} Recipient
        </label>
        <div className="flex flex-row justify-between">
          {nonShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-50">
                {tipData.nonShukranId}
              </span>
            </div>
          ) : queryShukranId ? (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-50">{queryName}</span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <span className="text-sm text-black w-36">
                {tipData.receiverName + " " + tipData.receiverLastName}
              </span>
              <span className="text-sm text-gray-300">{tipData.shukranId}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 h-12">
        <label className="text-subtle text-sm">
          {tipData?.queryShukranId === "LP1261" ? "" : "Tip"} Amount
        </label>
        <div className="flex flex-row">
          {isPayingTransaction ? (
            <div className="flex flex-row items-center">
              <span className="text-sm text-slate-400 line-through italic">
                {currency} {tipData.tipAmount}
              </span>
              <span className="text-sm text-black font-bold ml-3">
                {currency}{" "}
                {Number(tipData.tipAmount) + tipData.tipAmount * 0.025}
              </span>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <span className="text-sm text-black">
                {currency} {tipData.tipAmount}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 h-24">
        <label className="text-subtle text-sm">Payment Method</label>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <span className="text-sm text-black">
              {cardOption ? "Card Payment" : "MPESA"}
            </span>
          </div>
          {!!transactionState.cardTransactions.length && cardOption && (
            <ul className="mt-3">
              <label className="text-subtle text-sm">Card Details</label>
              {transactionState.cardTransactions.map((card, idx) => (
                <li
                  className={`flex flex-row justify-between items-center`}
                  key={idx}
                >
                  <div className="flex flex-row items-center justify-between w-full">
                    <span className="text-sm">
                      XXXX XXXX XXXX {card.authorization_json.last4}
                    </span>
                    <span className="text-xs text-gray-500">
                      {card.authorization_json.brand}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {tipData.tipAmount >= 100 ? (
        <div className="mt-3 h-12 flex flex-col items-start">
          <label className="text-subtle text-sm">Transaction Cost</label>
          <div className="flex flex-row mt-2">
            <input
              type="checkbox"
              checked={isPayingTransaction || tipData.shukranId == "CS1331"}
              className="mr-2"
              onChange={() => {
                setIsPayingTransaction(!isPayingTransaction);
              }}
            />
            <span className="text-sm italic ">
              I will pay this cost of KES {tipData.tipAmount * 0.03}
            </span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="mt-6">
        {!tippingState.tippingResponse.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full"
            onClick={submitTip}
          >
            {tipData?.queryShukranId === "LP1261" ? "Pay" : "Send Tip"}
          </button>
        )}
      </div>
    </div>
  );

  const TippingTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.tippingSuccess({}));
            dispatch(balanceActions.checkTippingSuccess(""));
            // addShukranId({})
            if (queryShukranId) {
              navigate("/download");
            } else if (redirectTo === "bill") {
              navigate("/bill/pay");
            } else {
              navigate("/download");
            }
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {tippingState.tippingResponse?.success &&
        !tippingState.isTransactionLoading && (
          <div className="flex flex-row justify-between">
            <img src={confetti} alt="confetti" className="h-28 -scale-x-100" />
            <img
              src={checkCircle}
              alt="error-img"
              className="h-24 left-1/3 absolute"
            />
            <img src={confetti} alt="confetti" className="h-28" />
          </div>
        )}

      {tippingState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {!!transactionState.cardTransactions.length && cardOption
            ? "Processing card transaction..."
            : "Input your PIN on the Mpesa prompt to tip"}
        </p>
      )}
      {tippingState.tippingResponse?.success &&
        !tippingState.isTransactionLoading && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {tippingState.tippingResponse?.msg}
          </p>
        )}
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <div className="flex flex-col items-center">
          <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
          <p className="justify-self-center	text-base text-center py-2 px-12">
            Tip to {receiverShukranId ? receiverShukranId : nonShukranId}{" "}
            Cancelled
          </p>
        </div>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  const TippingTransactionError = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.checkTippingError(""));
          dispatch(balanceActions.tippingError(""));
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  const AccessError = () => (
    <div className="px-16 drop-shadow-xl w-11/12 bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setAccessTipError(false);
        }}
      >
        X
      </button>
      <img src={wallet} alt="error-img" className="h-40" />
      <p className="justify-self-center text-lg text-center py-2"></p>
      <p className="justify-self-center	text-lg text-center py-2">
        Complete registration to access card payments
      </p>
      <button
        type="button"
        onClick={() => {
          navigate("/registration");
          setAccessTipError(false);
        }}
        className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-2 px-10 py-4"
      >
        Register now
      </button>
    </div>
  );

  return (
    <div
      className={`
        ${tippingState.errorMessage.error ? "relative" : ""}
        ${tippingState.errorMessage?.msg ? "relative" : ""}
        ${tippingState.tippingResponse.success ? "relative" : ""}
        ${tippingState.isLoading ? "relative" : ""}
        ${tippingState.errorTransactionStatusMessage ? "relative" : ""} 
        ${phoneNumber ? "relative" : ""}
        ${loggedConfirm ? "relative" : ""}
        ${cardPopup ? "relative" : ""}
        ${accessTipError ? "relative" : ""}
        ${showMpesa ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={` ${tippingState.errorMessage.error ? "blur-sm" : ""}
          ${tippingState.errorMessage?.msg ? "blur-sm" : ""}
          ${tippingState.errorMessage.detail ? "blur-sm" : ""}
          ${tippingState.tippingResponse.success ? "blur-sm" : ""}
          ${tippingState.errorTransactionStatusMessage ? "blur-sm" : ""} 
          ${tippingState.isLoading ? "blur-sm" : ""}
          ${phoneNumber ? "blur-sm" : ""}
          ${loggedConfirm ? "blur-sm" : ""}
          ${balanceState.generateResponse.phone ? "blur-sm" : ""}
          ${confirmMpes ? "blur-sm" : ""}
          ${cardPopup ? "blur-sm" : ""} 
          ${showMpesa ? "blur-sm" : ""} 
           ${accessTipError ? "blur-sm" : ""} 
          flex flex-col px-3 w-full h-screen`}
      >
        {/* <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center py-2 pl-2 pr-6`}
            onClick={() => {
              dispatch(balanceActions.tippingError(""));
              dispatch(balanceActions.getUserDataError(""));
              handleBack();
            }}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
          {/* <span className="text-base">Go back home</span> 
        </div> */}

        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>

        <div className="mx-2 mt-11 h-full">
          <span className="text-3xl font-semibold text-primary-pwa">
            {isPayment
              ? message
              : `How would you like to send ${currency} ${tipData.tipAmount} tip?`}
          </span>
          <ul className="mt-4 bg-white">
            <li
              className={`flex flex-col justify-between p-4 bg-accent-light-2 rounded-md border-2 border-gray`}
              onClick={() => {
                dispatch(balanceActions.tippingError(""));
                if (currency === "USD") {
                  if (tipData?.tipAmount > 0.5) {
                    navigate("/stripe");
                  } else {
                    toast.warning("Amount should be more than 0.5USD!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  }
                } else {
                  if (tipData?.tipAmount >= 70) {
                    navigate("/stripe");
                  } else {
                    toast.warning("Amount should be more than 70KES!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  }
                }
              }}
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center">
                  <img
                    src={CardIcon}
                    alt="icon"
                    className="bg-[#404B5A] p-2 rounded-full"
                  />
                  <div className="pl-12 sm:pl-3 flex flex-row items-center py-2">
                    <img src={VisaIcon} alt="icon" className="payIcon" />
                    <div className="italic text-sm mx-2">{" & "}</div>
                    <img src={MastercardIcon} alt="icon" className="payIcon" />
                    <div className="italic text-sm mx-2">{" & "}</div>
                    <img
                      src={AmericanExpress}
                      alt="icon"
                      style={{ width: 45, height: 27 }}
                      className="payIcon"
                    />
                    {/* <div className="italic text-sm ml-2">{" supported"}</div> */}
                  </div>
                </div>
                <img src={RightIcon} alt="icon" />
              </div>
            </li>

            {/* <li
                className={`flex flex-col justify-between p-4 bg-accent-light-2 rounded-md border-2 mt-3 border-gray`}
                onClick={() => {
                  dispatch(balanceActions.tippingError(""));
                  // handleSetOption(amounts[0]);
                  navigate("/stripe");
                }}
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row items-center">
                    <img
                      src={stripeIcon}
                      alt="icon"
                      className="bg-[#404B5A] p-2 rounded-full w-12 h-12"
                    />
                    <div className="pl-12 sm:pl-3 flex flex-row items-center py-2">
                      {/* <img src={VisaIcon} alt="icon" className="payIcon" /> */}
            {/* <div className="italic text-md mx-2">
              {" International payments "}
            </div> */}
            {/* <img
                        src={MastercardIcon}
                        alt="icon"
                        className="payIcon"
                      />
                      <div className="italic text-sm mx-2">{" & "}</div>
                      <img
                        src={AmericanExpress}
                        alt="icon"
                        style={{ width: 45, height: 27 }}
                        className="payIcon"
                      /> */}
            {/* <div className="italic text-sm ml-2">{" supported"}</div> 
                    </div>
                  </div>
                  <img src={RightIcon} alt="icon" />
                </div>
              </li> */}

            <li
              className={`flex flex-row justify-between items-center p-4 bg-accent-light-2 rounded-md border-2 mt-2 border-gray`}
              onClick={() => {
                if (tipData?.currency === "USD") {
                  toast.warning("For USD use card payment option", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                } else {
                  if (isLoggedIn && locationPath.pathname !== "/pay") {
                    setLoggedConfirm(true);
                  } else {
                    navigate("/queryMpesaNo");
                    setConfirmMpesa(false);
                  }
                }
              }}
            >
              <div className="flex flex-row items-center">
                <img src={MpesaIcon} alt="icon" />
                <span className="ml-12">{amounts[1].label}</span>
              </div>
              <img src={RightIcon} alt="icon" />
            </li>
          </ul>
        </div>
        {tipData?.queryShukranId === "LP1261" && (
          <img
            src={launchpadBrand}
            className="w-2/3 h-1/3 absolute right-0 bottom-0"
            alt="brand_launch_pad"
          />
        )}
      </div>
      <div
        className={`${
          accessTipError ? "absolute blur-none" : "hidden"
        } flex flex-col items-center pt-36 w-full h-full`}
      >
        <AccessError />
      </div>
      <div
        className={`${
          phoneNumber ||
          showMpesa ||
          confirmMpes ||
          (balanceState.generateResponse.phone &&
            !tippingState.tippingResponse.success) ||
          loggedConfirm
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmTipPopup />
      </div>

      {cardPopup && (
        <div className="absolute flex flex-col justify-center items-center w-full h-full blur-none">
          <CardPayment setCardPopup={setCardPopup} />
        </div>
      )}

      {/* <div
        className={`${cardPopup ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmTipPopup />
      </div> */}
      {/* <div
        className={`${
          tippingState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div> */}
      {/* <div
        className={`${
          tippingState.tippingResponse.success &&
          !!tippingState.tippingResponse.data?.authorization_url
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            {cardOption
              ? "Redirecting to card details form..."
              : "Input your PIN on the MPESA prompt to tip"}
          </span>
        </div>
      </div> */}
      <div
        className={`${
          tippingState.tippingResponse.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionCard />
      </div>
      {/* <div
        className={`${
          !!tippingState.tippingResponse.data?.authorization_url
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <iframe
          title="SHUKRAN CARD"
          height={"100%"}
          width={"100%"}
          src={!!tippingState.tippingResponse.data?.authorization_url}
        ></iframe>
      </div> */}
      <ToastContainer />
      <div
        className={`${
          tippingState.errorTransactionStatusMessage ||
          tippingState.errorMessage?.msg ||
          tippingState.errorMessage?.error ||
          tippingState.errorMessage?.detail
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionError />
      </div>
    </div>
  );
}
