import { actionTypes } from "../user/user.actions";

const initialState = {
  isLoading: false,
  tippingResponse: {},
  errorMessage: "",
  errorTransactionStatusMessage: "",
  cardResponse: {},
  navigate: "",
};

const tippingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.TIPPING_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        tippingResponse: [],
      };
    case actionTypes.TIPPING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isTransactionLoading: !!payload.data?.key ? false : true,
        tippingResponse: payload,
      };
    case actionTypes.TIPPING_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.DEACTIVATE_CARD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        cardResponse: [],
      };
    case actionTypes.DEACTIVATE_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardResponse: payload,
      };
    case actionTypes.DEACTIVATE_CARD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.CHECK_TIPPING_START:
      return {
        ...state,
        errorTransactionStatusMessage: "",
        transactionStatus: [],
      };
    case actionTypes.CHECK_TIPPING_SUCCESS:
      return {
        ...state,
        isTransactionLoading: false,
        tippingResponse: payload,
      };
    case actionTypes.CHECK_TIPPING_REDIRECT:
      return {
        ...state,
        navigate: payload,
      };
    case actionTypes.CHECK_TIPPING_ERROR:
      return {
        ...state,
        isTransactionLoading: false,
        isLoading: false,
        tippingResponse: [],
        errorTransactionStatusMessage: payload,
      };
    default:
      return state;
  }
};
export default tippingReducer;
