import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { persistor, store, storePersist } from "./state/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { PersistGate } from "redux-persist/integration/react";

Sentry.init({
  dsn: "https://4ba8e2e04e2c4aa2976adfe2fe4c7d2b@o461119.ingest.sentry.io/4505194064904192",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function FallbackComponent() {
  return (
    <div className="flex justify-center align-center text-red-600 font-bolder uppercase">
      <span>An error has occurred</span>
    </div>
  );
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store()}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={myFallback} showDialog>
          <App />
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </BrowserRouter>,

  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
