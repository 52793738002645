import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import "./members.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTipjarGroup,
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { ClipLoader, FadeLoader, MoonLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { testUrl } from "../../helper";
import BackButton from "../globals/BackButton";
import { useMutation } from "@tanstack/react-query";
import { postJson } from "../../utils/fetch.utils";
import { BASE_URL } from "../../constants/network";
export default function Groups() {
  const { data: tipJarInfo, mutate: tipJarInfocreateMutation } = useMutation({
    mutationKey: "tipjar-creation-details",
    mutationFn: async () => {
      try {
        const resp = await postJson(BASE_URL + "api/v1/tipjar/");
        return resp.data;
      } catch (error) {
        return error;
      }
    },
  });
  const navigate = useNavigate();
  const userAccount = useSelector((state) => state.account);
  const [count, setCount] = useState([]);
  const tipjar = useSelector((state) => state.tipjar);

  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groups, setGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [check, setCheck] = useState(false);
  useEffect(() => {
    if (tipjar.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (check === true) {
        if (tipjar.memberPostSuccess === true) {
          toast.success("Group Deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setConfirmDelete(false);
          dispatch(
            getTipjarGroups(tipJarInfo?.id, userAccount.userDetails.token)
          );
          tipjar.memberPostSuccess = false;
        } else {
        }
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, [check]);
  const handleBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getTipjarMembers({}, userAccount.userDetails.token));
  // }, []);
  useEffect(() => {
    tipJarInfocreateMutation();
  }, []);

  useEffect(() => {
    if (tipJarInfo?.id != null) {
      dispatch(getTipjarGroups(tipjar?.id, userAccount.userDetails.token));
    }
  }, [tipJarInfo?.id]);
  const deleteGroup = (params) => {
    setIsLoading(true);
    fetch(`${testUrl}tipjar/groups/${params}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userAccount.userDetails.token}`,
        "Content-type": "application/json",
        XAT: `U`,
        "X-IDT": `A`,
      },
    })
      .then((res) => {
        setConfirmDelete(false);

        dispatch(
          getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
        );
        toast.success("group deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        if (err) {
          toast.warning("Cannot delete group, try again later!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function getCount(group) {
    let countof = tipjar?.members.filter((memb) => {
      if (memb?.group?.id === group?.id) {
        return true;
      } else {
        return false;
      }
    });
    return countof.length;
  }
  const ConfirmDelete = () => {
    return (
      <section
        id="confirmdelete"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="w-2/3 h-1/3 bg-white drop-shadow-xl">
          <button
            onClick={() => setConfirmDelete(false)}
            className="absolute top-2 text-gray-400 right-4 px-2 text-2xl "
          >
            X
          </button>
          <div
            className="flex mt-9 px-1 py-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h5 style={{ fontSize: "13px" }}>
              Delete {selectedGroup && selectedGroup?.name} group ?
            </h5>
          </div>
          <div className="mb-2 mt-1 px-2 flex flex-row">
            <button
              className="rounded-full py-2 bg-[#50A2A7] px-1 text-white  text-m mx-auto mt-4 w-1/3 h-1/3"
              onClick={() => {}}
              type="button"
            >
              cancel
            </button>
            {isLoading ? (
              <div
                className="rounded-full py-2  px-1 mx-auto mt-3 w-1/3 h-1/3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader size={25} loading={true} color="#6CCBD1" />
              </div>
            ) : (
              <button
                className="rounded-full py-2 bg-accent px-1 text-white text-m mx-auto mt-4 w-1/3 h-1/3"
                onClick={() => {
                  deleteGroup(selectedGroup?.id);
                }}
                disabled={isLoading}
                type="button"
              >
                delete
              </button>
            )}
          </div>
        </div>
      </section>
    );
  };
  return (
    <div
      className="flex flex-col px-3 w-full h-screen"
      style={{ width: "100%" }}
    >
      <div
        className={`${confirmDelete && "blur-sm"} flex flex-col `}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <div
          className="flex flex-row items-center mt-9 w-full"
          style={{ width: "100%" }}
        >
          <div
            className={`flex flex-row items-center py-2 pr-6`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div className="mx-4 mt-6 h-full w-full" style={{ width: "100%" }}>
          <h2 className="text-3xl font-semibold text-primary-pwa">Groups</h2>
          <p className="text-md font-semibold text-gray-400">
            Manage groups and choose how tips are divided across each
          </p>

          <div className="add_box mt-6">
            <button
              onClick={() => navigate("/tipjar/groups/add")}
              className="add_member"
            >
              <img src={add} className="add" alt="add member" />
              <h6 className="ml-2">Add Group</h6>
            </button>
          </div>
          <div className="mt-6" style={{ width: "100%" }}>
            <h5>Groups ({tipjar?.groups?.length})</h5>
            <div className="mt-2 ">
              {tipjar?.groups?.length > 0 ? (
                <>
                  {tipjar?.groups?.map((group) => (
                    <div className=" empty" key={group?.id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="border-2 border-gray  h-65 mb-3"
                          style={{
                            height: "60px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            className="w-1/2 px-2"
                          >
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#50A2A7 ",
                              }}
                            ></div>
                            <div className="ml-2">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{ color: "#404B5A", fontSize: "12px" }}
                                >
                                  {group?.name}
                                </p>
                                <div
                                  className="ml-1"
                                  style={{
                                    border: "1px solid #6CCBD1",
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "12.5px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#F0FEFF",
                                    color: "#6CCBD1",
                                    fontSize: "8px",
                                  }}
                                >
                                  {group?.percentage}%
                                </div>
                              </div>
                              <p style={{ color: "#929FB1", fontSize: "10px" }}>
                                {getCount(group)} Members
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                            className="w-1/2 px-2"
                          >
                            <button
                              style={{ fontSize: "12px" }}
                              className="text-sm text-white bg-accent px-2 py-1"
                              onClick={() =>
                                navigate(
                                  `/tipjar/groups/edit?group=${group?.id}`
                                )
                              }
                            >
                              change
                            </button>

                            <a
                              style={{
                                fontSize: "12px",
                                backgroundColor: "#EE5582",
                              }}
                              onClick={() => {
                                setConfirmDelete(true);
                                setSelectedGroup(group);
                              }}
                              className="text-sm text-white px-2 py-1"
                              href="#confirmdelete"
                            >
                              remove
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="mt-6 empty">
                  <img src={user} />
                  <p className="w-40 mt-6 text-center desctxt">
                    No groups available Create to add members
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className=" mt-6">
            <button
              className="rounded-full mr-3 py-3 bg-accent px-3 mb-6 text-white text-m mx-auto mt-2 w-full"
              onClick={() => {
                navigate("/tipjar/groups/distribution");
              }}
              type="button"
            >
              CHANGE DISTRIBUTION
            </button>
          </div>
        </div>
      </div>

      {confirmDelete && <ConfirmDelete />}
      <ToastContainer />
    </div>
  );
}
