import React from "react";

export const Indicator = () => (
  <span className="border-2 rounded-full border-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1">
    {" "}
  </span>
);

export const ActiveIndicator = () => (
  <span className="border-2 rounded-full border-[#F2C773] bg-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1">
    {" "}
  </span>
);
