import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import popup from "../../assets/Icons/Popups/x_circle.svg";

export default function Popup(props) {
  let { setIsClicked, isClicked, setIsInsufficient } = props;
  const navigate = useNavigate();
  return (
    <div className="fixed w-[90%] mx-auto h-screen  top-0 left-5 flex flex-col ">
      <div className="  my-auto p-4 bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            setIsClicked(!isClicked);
            setIsInsufficient(false);
          }}
        >
          X
        </button>

        <img src={popup} alt="error-img" className="h-32" />

        <p className="justify-self-center	text-lg text-center py-2">
          You have insufficient funds in your Shukran wallet
        </p>

        <button className="rounded-full bg-accent h-12 text-white text-xl w-full py-4 flex justify-center items-center mt-2"
           onClick={() => navigate("/deposit")}
        >
          Deposit
        </button>
      </div>
    </div>
  );
}
