import React from "react";
import { convertToAmPm  } from "../../util.js";

const ReferralCard = ({ referral }) => {
  return (
    <div className={`my-1 rounded-xl p-2.5 flex flex-row items-center justify-start bg-[#FFFAF0]
    `}>
      {/* 'bg-[#FCF7EA]': 'bg-[#FFFAF0]' */}
      <div className="flex flex-col justify-start items-start">
          <div className="w-10 h-10 bg-accent flex justify-center items-center rounded-full">
            <p className="text-lg text-primary">{referral.first_name[0]+referral.surname[0]}</p>
          </div>
      </div>
      <div className="flex flex-row w-full justify-between px-2">
        <div className="flex flex-col">
          <p className="text-sm">
            {referral.first_name+' '+referral.surname}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <p className="text-xs text-slate-300">
            {referral?.created_at.split("T")[0].split('-').reverse().join('/')}
          </p>
          <p className="text-xs ml-1 text-slate-300">
            {convertToAmPm(
              referral?.created_at.split("T")[1].split(':')[0],
              referral?.created_at.split("T")[1].split(':')[1]
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReferralCard;
