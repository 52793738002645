import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import "./members.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTipjarGroups,
  getTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { MoonLoader } from "react-spinners";
import { testUrl } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../globals/BackButton";
import { useToast } from "../../components-shadcn/ui/use-toast";
import { fetchDelete } from "../../utils/fetch.utils";
import { BASE_URL } from "../../constants/network";
import { useMutation } from "@tanstack/react-query";
export default function Members() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleBack = () => {
    navigate("/tipjar");
  };
  const dispatch = useDispatch();
  const tipjar = useSelector((state) => state.tipjar);
  const userAccount = useSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [changeGroup, setChangeGroup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    dispatch(getTipjarMembers({}, userAccount?.userDetails?.token));
  }, []);
  // useEffect(() => {
  //   if (tipjar?.tipjarId) {
  //     dispatch(
  //       getTipjarGroups(tipjar?.tipjarId, userAccount?.userDetails?.token)
  //     );
  //   }
  // }, [tipjar?.tipjarId]);

  const deleteMemberMutation = useMutation({
    mutationFn: async (member) => {
      const resp = await fetchDelete(
        BASE_URL + `api/v1/tipjar/members/${member}/`
      );
      if (resp?.msg) {
        setConfirmDelete(false);
        toast({
          title: resp.msg,
        });
        dispatch(getTipjarMembers({}, userAccount?.userDetails?.token));
      }
      // if (resp?.success) {
      //   setConfirmDelete(false);
      //   toast({
      //     variant: "default",
      //     title: "Member Deleted",
      //   });
      // } else {
      //   setConfirmDelete(false);
      //   toast({
      //     variant: "destructive",
      //     title: "An error occurred while deleting member",
      //   });
      // }

      return resp.data;
    },
  });
  const handleGroupChange = (e, member) => {
    setIsLoading(true);

    fetch(`${testUrl}tipjar/members/${member?.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userAccount.userDetails.token}`,
        "Content-type": "application/json",
        XAT: `U`,
        "X-IDT": `A`,
      },
      body: JSON.stringify({
        tipjar_id: tipjar.tipjarId,
        user_id: member?.user?.id,
        percentage: member?.percentage,
        group_id: e.target.value,
      }),
    })
      .then((res) => {
        console.log(res);
        dispatch(getTipjarMembers({}, userAccount.userDetails.token));
        dispatch(
          getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
        );

        toast({
          title: "Group Changed",
          description: "Group Changed Successfully",
        });
        // toast.success("group changed!", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
      })
      .catch((err) => {
        if (err) {
          toast({
            title: "Error",
            description: "Group Change Failed",
          });
          // toast.warning("Cannot Change Group, Try Again Later!", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setChangeGroup(false);
      });

    // setCheckSuccess(true);
  };
  const deleteMember = (memberId) => {
    deleteMemberMutation.mutate(memberId);
  };
  const ConfirmDelete = () => {
    return (
      <section
        id="confirmdelete"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "10px" }}
          className="w-2/3 h-1/3 bg-white drop-shadow-xl"
        >
          <button
            onClick={() => setConfirmDelete(false)}
            className="absolute top-2 text-gray-400 right-4 px-2 text-2xl "
          >
            X
          </button>
          <div
            className="flex mt-9 px-1 py-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h5 style={{ fontSize: "13px" }}>
              Delete {selectedMember && selectedMember?.user?.first_name} from
              Members ?
            </h5>
          </div>
          <div className="mb-2 mt-1 px-2 flex flex-row">
            <button
              className="rounded-full py-2 bg-[#50A2A7] px-1 text-white  text-m mx-auto mt-4 w-1/3 h-1/3"
              onClick={() => {
                setConfirmDelete(false);
              }}
              type="button"
            >
              cancel
            </button>
            {deleteMemberMutation.isPending ? (
              <div
                className="rounded-full py-2  px-1 mx-auto mt-3 w-1/3 h-1/3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader size={25} loading={true} color="#6CCBD1" />
              </div>
            ) : (
              <button
                className="rounded-full py-2 bg-accent px-1 text-white text-m mx-auto mt-4 w-1/3 h-1/3"
                onClick={() => {
                  deleteMember(selectedMember?.id);
                }}
                disabled={isLoading}
                type="button"
              >
                delete
              </button>
            )}
          </div>
        </div>
      </section>
    );
  };
  const ConfirmChange = () => {
    return (
      <section
        id="confirmchange"
        style={{
          width: window.innerWidth,
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "10px" }}
          className="w-2/3 h-1/3 bg-white drop-shadow-xl"
        >
          <button
            onClick={() => setChangeGroup(false)}
            className="absolute top-2 text-gray-400 right-4 px-2 text-2xl "
          >
            X
          </button>
          <div
            className="flex px-1 py-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "60px",
              width: "100%",
            }}
          >
            {!isLoading ? (
              <select
                className="border-2 border-gray h-65 mb-3 "
                onChange={(e) => handleGroupChange(e, selectedMember)}
                style={{
                  height: "60px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  color: "#BCBCBC",
                  borderRadius: "10px",
                  outline: "none",
                }}
              >
                <option
                  value=""
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  {selectedMember?.group
                    ? `${selectedMember?.group?.name} `
                    : " Select Group"}
                </option>
                {tipjar?.groups?.map((group) => (
                  <>
                    {group?.id !== selectedMember?.group?.id && (
                      <option
                        value={group?.id}
                        style={{ backgroundColor: "white", color: "black" }}
                      >
                        {group?.name}
                      </option>
                    )}
                  </>
                ))}
              </select>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader size={25} loading={true} color="#6CCBD1" />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  };
  return (
    <div
      className="flex flex-col h-screen"
      style={{ width: window.innerWidth }}
    >
      <div
        className={`${
          (changeGroup || confirmDelete) && "blur-sm"
        } flex flex-col items-center justify-center px-3`}
      >
        {/* <div
          className="flex flex-row items-center mt-9 w-full"
          style={{ width: "100%" }}
        >
          <div
            className={`flex flex-row items-center py-2  pr-6`}
            onClick={() => navigate("/tipjar/")}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div className="mx-4 mt-6 h-full w-full" style={{ width: "100%" }}>
          <h2 className="text-3xl font-semibold text-primary-pwa">Members</h2>
          <p
            className="text-md font-semibold text-gray-400"
            style={{ width: "100%" }}
          >
            Add or remove members from restaurant TipJAR list
          </p>

          <div className="add_box mt-6">
            <button
              onClick={() => navigate("/tipjar/members/add")}
              className="add_member"
            >
              <img src={add} className="add" alt="add member" />
              <h6 className="ml-2">Add Member</h6>
            </button>
          </div>
          <div className="mt-6">
            <h5>Members ({tipjar?.members?.length})</h5>
            {tipjar?.members.length > 0 ? (
              <div className="mt-6 empty">
                {tipjar?.members?.map((member) => (
                  <div
                    key={member?.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      className="border-2 border-gray w-full h-65 mb-3"
                      style={{
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        className="w-1/2 px-2"
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "15px",
                            backgroundColor: "#50A2A7 ",
                          }}
                        ></div>
                        <div className="ml-2">
                          <p style={{ color: "#404B5A", fontSize: "12px" }}>
                            {member?.user?.first_name !== "N/A"
                              ? `${member?.user?.first_name} ${member?.user?.last_name}`
                              : member?.user?.phone}
                          </p>
                          <p style={{ color: "#929FB1", fontSize: "10px" }}>
                            {member?.user?.shukran_id &&
                              member?.user?.shukran_id}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        className="w-1/2 px-2"
                      >
                        <a
                          style={{ fontSize: "12px" }}
                          className="text-sm text-white bg-accent px-2 py-1"
                          href="#confirmchange"
                          onClick={() => {
                            setSelectedMember(member);
                            setChangeGroup(true);
                          }}
                        >
                          Change
                        </a>
                        <a
                          style={{
                            fontSize: "12px",
                            backgroundColor: "#EE5582",
                          }}
                          className="text-sm text-white px-2 py-1"
                          onClick={() => {
                            setSelectedMember(member);
                            setConfirmDelete(true);
                          }}
                          href="#confirmdelete"
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-6 empty">
                <img src={user} />
                <p className="w-40 mt-6 text-center desctxt">
                  No members available Add new
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      {changeGroup && <ConfirmChange />}
      {confirmDelete && <ConfirmDelete />}
    </div>
  );
}
