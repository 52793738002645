import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";

import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import "./members.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTipjarData,
  getTipjarGroup,
  getTipjarGroups,
  getTipjarMembers,
  postTipjarGroups,
  updateTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { MoonLoader } from "react-spinners";
import { testUrl } from "../../helper";
import axios from "axios";
import BackButton from "../globals/BackButton";
import { useToast } from "../../components-shadcn/ui/use-toast";
export default function EditGroup() {
  const [group, setGroup] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const userAccount = useSelector((state) => state.account);
  const tipjar = useSelector((state) => state.tipjar);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [changeGroup, setChangeGroup] = useState(false);
  const [selectFrom, setSelectFrom] = useState(true);
  const [proceed, setProceed] = useState("");
  const dispatch = useDispatch();
  const { toast } = useToast();

  useEffect(() => {
    let paramGroupId = searchParams.get("group");
    console.log(paramGroupId);
    dispatch(getTipjarGroup(paramGroupId, userAccount.userDetails.token));
    // dispatch(getTipjarMembers({}, userAccount.userDetails.token));

    // dispatch(getTipjarData({}, userAccount.userDetails.token));
  }, []);
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    let paramGroupId = searchParams.get("group");
    console.log(paramGroupId);
    // dispatch(getTipjarGroup(paramGroupId, userAccount.userDetails.token));
  }, [dispatch, navigate]);
  useEffect(() => {}, []);
  const handleBack = () => {
    navigate(-2);
  };
  useEffect(() => {
    // if (tipjar?.tipjarId) {
    //   dispatch(
    //     getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
    //   );
    // }
  }, [tipjar?.tipjarId]);
  const handleGroupChange = (e, member) => {
    setIsLoading(true);

    fetch(`${testUrl}tipjar/members/${member?.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userAccount.userDetails.token}`,
        "Content-type": "application/json",
        XAT: `U`,
        "X-IDT": `A`,
      },
      body: JSON.stringify({
        tipjar_id: tipjar.tipjarId,
        user_id: member?.user?.id,
        percentage: member?.percentage,
        group_id: e.target.value,
      }),
    })
      .then((res) => {
        console.log(res);
        dispatch(getTipjarMembers({}, userAccount.userDetails.token));
        dispatch(
          getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
        );
        toast({
          title: "Group Changed",
          description: "Group Changed Successfully",
        });
      })
      .catch((err) => {
        if (err) {
          toast({
            title: "Group Change Failed",
            description: "Group Change Failed",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        setChangeGroup(false);
      });

    // setCheckSuccess(true);
  };
  const deleteGroup = (params) => {
    setIsLoading(true);
    fetch(`${testUrl}tipjar/members/${params}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userAccount.userDetails.token}`,
        "Content-type": "application/json",
        XAT: `U`,
        "X-IDT": `A`,
      },
    })
      .then((res) => {
        setConfirmDelete(false);

        dispatch(getTipjarMembers({}, userAccount.userDetails.token));
        toast({
          title: "Member Deleted",
          description: "Member Deleted Successfully",
        });
      })
      .catch((err) => {
        if (err) {
          toast({
            title: "Member Delete Failed",
            description: "Member Delete Failed",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const putMembers = (member) => {
    const body = {
      tipjar_id: tipjar?.tipjarId,
      user_id: member?.user?.id,
      percentage: member?.percentage,
      group_id: tipjar?.group?.id,
    };
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userAccount.userDetails.token}`;
    axios.defaults.headers.common["XAT"] = `U`;
    axios.defaults.headers.common["X-IDT"] = `A`;
    fetch(`${testUrl}tipjar/members/${member?.id}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userAccount.userDetails.token}`,
        "Content-type": "application/json",
        XAT: `U`,
        "X-IDT": `A`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        dispatch(getTipjarMembers({}, userAccount.userDetails.token));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addToGroup = (member) => {
    setIsLoading(true);
    if (tipjar?.tipjarId) {
      putMembers(member);
    } else {
      dispatch(getTipjarMembers({}, userAccount.userDetails.token));
      putMembers(member);
    }
  };
  useEffect(() => {
    const newMember = tipjar?.members?.filter((member) => {
      if (member?.group?.id === tipjar?.group?.id) {
        return member;
      } else {
        return false;
      }
    });
    setMembers(newMember);
  }, [tipjar?.members]);

  const ConfirmDelete = () => {
    return (
      <section
        id="confirmdelete"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="w-2/3 h-1/3 bg-white drop-shadow-xl">
          <button
            onClick={() => setConfirmDelete(false)}
            className="absolute top-2 text-gray-400 right-4 px-2 text-2xl "
          >
            X
          </button>
          <div
            className="flex mt-9 px-1 py-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h5 style={{ fontSize: "13px" }}>
              Delete {selectedMember && selectedMember?.user?.first_name} from
              Members ?
            </h5>
          </div>
          <div className="mb-2 mt-1 px-2 flex flex-row">
            <button
              className="rounded-full py-2 bg-[#50A2A7] px-1 text-white  text-m mx-auto mt-4 w-1/3 h-1/3"
              onClick={() => {
                setConfirmDelete(false);
              }}
              type="button"
            >
              cancel
            </button>
            {isLoading ? (
              <div
                className="rounded-full py-2  px-1 mx-auto mt-3 w-1/3 h-1/3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader size={25} loading={true} color="#6CCBD1" />
              </div>
            ) : (
              <button
                className="rounded-full py-2 bg-accent px-1 text-white text-m mx-auto mt-4 w-1/3 h-1/3"
                onClick={() => {
                  deleteGroup(selectedMember?.id);
                }}
                disabled={isLoading}
                type="button"
              >
                delete
              </button>
            )}
          </div>
        </div>
      </section>
    );
  };
  const ConfirmChange = () => {
    return (
      <section
        id="confirmchange"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ borderRadius: "10px" }}
          className="w-2/3 h-1/3 bg-white drop-shadow-xl"
        >
          <button
            onClick={() => setChangeGroup(false)}
            className="absolute top-2 text-gray-400 right-4 px-2 text-2xl "
          >
            X
          </button>
          <div
            className="flex px-1 py-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "60px",
              width: "100%",
            }}
          >
            {!isLoading ? (
              <select
                className="border-2 border-gray h-65 mb-3 "
                onChange={(e) => handleGroupChange(e, selectedMember)}
                style={{
                  height: "60px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  color: "#BCBCBC",
                  borderRadius: "10px",
                }}
              >
                <option value="" style={{ backgroundColor: "white" }}>
                  Select Group
                </option>
                {tipjar?.groups?.map((group) => (
                  <option
                    value={group?.id}
                    key={group?.id}
                    style={{ backgroundColor: "white" }}
                  >
                    {group?.name}
                  </option>
                ))}
              </select>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MoonLoader size={25} loading={true} color="#6CCBD1" />
              </div>
            )}
          </div>
        </div>
      </section>
    );
  };
  const SelectFromPop = () => {
    return (
      <div
        id="confirmchange"
        style={{
          width: "95%",
          height: "100dvh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          alignSelf: "center",
        }}
        className="drop-shadow-xl "
      >
        <div className="w-screen p-3 absolute top-0">
          <BackButton onPress={handleBack} />
        </div>
        <div
          style={{
            bottom: 0,
            position: "absolute",
            height: "350px",
            width: "100%",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
          }}
          className="bg-white drop-shadow-xl "
        >
          <div className="flex flex-col mt-2 py-3 px-3">
            <h3 style={{ display: "flex", alignSelf: "flex-start" }}>
              Add group member
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "40px",
                width: "100%",
              }}
              className="flex flex-col"
            >
              <div
                className="border-2 border-gray w-full h-65 mb-3 flex flex-row px-2"
                style={{
                  height: "60px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setProceed("exist");
                  setSelectFrom(false);
                }}
              >
                <h4>Choose from existing members</h4>
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...(proceed === "exist" && { backgroundColor: "#6CCBD1" }),
                  }}
                  className="border-2 border-gray"
                >
                  {proceed === "exist" && (
                    <p
                      style={{
                        fontSize: "8px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      &#x2713;
                    </p>
                  )}
                </div>
              </div>
              <div
                className="border-2 border-gray w-full h-65 mb-3 flex flex-row px-2"
                style={{
                  height: "60px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setProceed("new");
                  navigate("/tipjar/members/add");
                }}
              >
                <h4>Add new member</h4>
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "8px",
                    ...(proceed === "new" && { backgroundColor: "#6CCBD1" }),
                  }}
                  className="border-2 border-gray"
                >
                  {proceed === "new" && (
                    <p
                      style={{
                        fontSize: "8px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      &#x2713;
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* <button
              className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-6 w-full"
              onClick={() => {
                if (proceed === "exist") {
                  setSelectFrom(false);
                } else if (proceed === "new") {
                  navigate("/tipjar/members/add");
                }
              }}
              type="button"
            >
              PROCEED TO ADD MEMBERS
            </button> */}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={`flex flex-col w-full h-screen`} style={{ width: "100%" }}>
      <div
        className={`${
          (confirmDelete || selectFrom || changeGroup) && "blur-sm"
        } flex flex-col px-4`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <div
          className="flex flex-row items-center mt-9 mb-2 w-full"
          style={{ width: "100%" }}
        >
          <div
            className={`flex flex-col items-center py-1  pr-6`}
            onClick={() => handleBack()}
          >
            <img
              src={back}
              style={{ display: "flex", alignSelf: "flex-start" }}
              className=""
              alt="rate-star"
            />
          </div>
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div
          className="mx-4 mt-2 mb-6 h-full w-full"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div className="">
            <h2 className="text-3xl font-semibold text-primary-pwa">
              Add Group Members
            </h2>
            <p
              className="text-md font-semibold text-gray-400"
              style={{ width: "95%" }}
            >
              Easily add and move members to the group from here
            </p>
          </div>
          <div className="mt-6">
            <h5>
              {tipjar?.group?.name}{" "}
              {tipjar?.group?.name && `(${members.length})`}
            </h5>
            {tipjar?.members.length > 0 && members.length && !selectFrom > 0 ? (
              <div className="mt-6 empty">
                {tipjar?.members?.map((member) => {
                  if (member?.group?.id === tipjar?.group?.id) {
                    return (
                      <div
                        key={member?.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="border-2 border-gray h-65 mb-3"
                          style={{
                            height: "60px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            className="w-1/2 px-2"
                          >
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#50A2A7 ",
                              }}
                            ></div>
                            <div className="ml-2">
                              <p style={{ color: "#404B5A", fontSize: "12px" }}>
                                {member?.user?.first_name !== "N/A"
                                  ? `${member?.user?.first_name} ${member?.user?.last_name}`
                                  : member?.user?.phone}
                              </p>
                              <p style={{ color: "#929FB1", fontSize: "10px" }}>
                                {member?.user?.shukran_id &&
                                  member?.user?.shukran_id}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                            className="w-1/2 px-2"
                          >
                            <a
                              style={{ fontSize: "12px", borderRadius: "10px" }}
                              className="text-sm text-white bg-accent px-2 py-1"
                              onClick={() => {
                                setChangeGroup(true);
                                setSelectedMember(member);
                              }}
                              href="#confirmchange"
                            >
                              change group &#9998;
                            </a>
                            {/* <a
                              style={{
                                fontSize: "12px",
                                backgroundColor: "#EE5582",
                              }}
                              onClick={() => {
                                setConfirmDelete(true);
                                setSelectedMember(member);
                              }}
                              href="#confirmdelete"
                              className="text-sm text-white px-2 py-1"
                            >
                              remove
                            </a> */}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <div
                className="mt-1 empty"
                style={{ display: "flex", alignSelf: "flex-start" }}
              >
                <p className="w-full mt-6 text-center desctxt">
                  No members available Add below
                </p>
              </div>
            )}
          </div>
          <div className="mt-6" style={{ width: "100%" }}>
            <h5>All Members ({tipjar?.members?.length})</h5>
            {tipjar?.members.length > 0 && !selectFrom ? (
              <div className="mt-6 empty">
                {tipjar?.members?.map((member) => {
                  if (member?.group?.id !== tipjar?.group?.id) {
                    return (
                      <div
                        key={member?.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          className="border-2 border-gray h-65 mb-3"
                          style={{
                            height: "60px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            className="w-1/2 px-2"
                          >
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                                backgroundColor: "#50A2A7 ",
                              }}
                            ></div>
                            <div className="ml-2">
                              <p style={{ color: "#404B5A", fontSize: "12px" }}>
                                {member?.user?.first_name !== "N/A"
                                  ? `${member?.user?.first_name} ${member?.user?.last_name}`
                                  : member?.user?.phone}
                              </p>
                              <p style={{ color: "#929FB1", fontSize: "10px" }}>
                                {member?.user?.shukran_id &&
                                  member?.user?.shukran_id}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                            className="w-1/2 px-2"
                          >
                            {member?.group?.id !== tipjar?.group?.id ? (
                              <>
                                {tipjar?.isLoading ? (
                                  <MoonLoader
                                    size={25}
                                    loading={true}
                                    color="#6CCBD1"
                                  />
                                ) : (
                                  <button
                                    style={{
                                      fontSize: "12px",
                                      borderRadius: "10px",
                                    }}
                                    className="text-sm text-white bg-[#6CCBD1] px-2 py-1"
                                    onClick={() => {
                                      addToGroup(member);
                                    }}
                                  >
                                    Add to group
                                  </button>
                                )}
                              </>
                            ) : (
                              <a
                                style={{ fontSize: "12px" }}
                                className="text-sm text-white bg-accent px-2 py-1"
                                onClick={() => {
                                  setChangeGroup(true);
                                  setSelectedMember(member);
                                }}
                                href="#confirmchange"
                              >
                                change group &#9998;
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              <div className="mt-6 empty">
                <img src={user} />
                <p className="w-40 mt-6 text-center desctxt">
                  No members available Add new
                </p>
              </div>
            )}
          </div>
        </div>

        {/* <button
          className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-6 w-full"
          onClick={() => {
            dispatch(
              postTipjarGroups({
                name: group,
                percentage,
                tipjar_id: tipjar.tipjarId,
              })
            );
          }}
          type="button"
        >
          PROCEED TO ADD MEMBERS
        </button> */}
      </div>
      {confirmDelete && <ConfirmDelete />}
      {changeGroup && <ConfirmChange />}

      {selectFrom && <SelectFromPop />}
    </div>
  );
}
