// import React from 'react'
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import { useSelector, useDispatch } from "react-redux";
import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";
import { useNavigate } from "react-router-dom";
import ArrowBackBtn from "../Buttons/ArrowBackBtn";
import Avatar from "../Profile/avatar";
import { getFromSessionStorage } from "../../Services/Storage";
import { Link } from "react-router-dom";
import { loadBalanceResults } from "../../state/reducers/user/user.thunk";

const axios = require("axios");

export default function DashBoardHeader({ navigateLink }) {
  const [isClicked, setIsClicked] = useState(false);
  const [avatarId, setAvatarId] = useState("");

  // navigate function
  let navigate = useNavigate();
  const dispatch = useDispatch();
  // const { upDateBalance } = bindActionCreators(actions, dispatch);

  const globalState = useSelector((state) => {
    // console.log('balance in tip amount', state)
    return state;
  });

  const { account, balance } = globalState;
  // const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

  const loadBalance = () => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let token = getFromSessionStorage("token");
    console.log("shukran id", shukran_id);
    let payload = {
      shukran_id: shukran_id ? shukran_id : null,
      // token: token ? token : null,
    };
    console.log("payload tipAmount", token);
    setAvatarId(shukran_id);
    dispatch(loadBalanceResults(payload, token));
  };

  useEffect(() => {
    if (!account.isAuthenticated) {
      navigate("/login");
    } else {
      loadBalance();
    }
  }, [account.isAuthenticated, navigate]);

  // gets data from redux store
  return (
    <div className="px-4 bg-header-bg-img bg-border h-64 pb-2 flex flex-col">
      <div className="flex flex-row justify-between items-center pt-6">
        <Link to={`/${navigateLink}`}>
          <ArrowBackBtn />
        </Link>
        <Link to="/profile" className="">
          {avatarId && <Avatar shukranId={avatarId} />}
        </Link>
      </div>
      <div className="flex flex-col AccountBalance items-center mt-7">
        <p className="AccountBalance_Label text-grey text-sm">
          SHUKRAN BALANCE (KES)
        </p>
        {isClicked ? (
          <p className="text-3xl text-rose-400 py-3 blur">
            {balance.balance ? balance.balance : 0.0}
          </p>
        ) : (
          <p className="text-3xl text-rose-400 py-3 AccountBalance_Value">
            {balance.balance ? balance.balance : 0.0}
          </p>
        )}
        <button
          className="focus:outline-none focus-visible:ring"
          onClick={() => {
            setIsClicked(!isClicked);
          }}
        >
          <img
            src={!isClicked ? eyeClosed : eye}
            alt="eye"
            className="h-7 w-5"
          />
        </button>
      </div>
    </div>
  );
}
