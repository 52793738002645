import { actionTypes } from "../reset/reset.actions";

const resetState = {
  isLoading: false,
  results: [],
  errorMessage: "",
};

const checkIfObj = (payload) => {
  if (payload.constructor.name === "Object") {
    let madeArr = [payload];
    console.log("array payload", madeArr);
    return madeArr;
  } else {
    return payload;
  }
};

const updateProfileReducer = (state = resetState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_PROFILE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
        // isChangePassLoading: true
      };
    case actionTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default updateProfileReducer;
