import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import logo from "../../assets/Logos/shukran-logo-dark.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import fourOfTen from "../../assets/Illustrations/sacco/four_of_ten.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

const FourthOccupationInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInOtherSacco, setIsInOtherSacco] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({});

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      if (name === "otherSacco" && value.otherSacco === "yes") {
        setIsInOtherSacco(true);
      }
      if (name === "otherSacco" && value.otherSacco === "no") {
        setIsInOtherSacco(false);
      }
    });
    return () => {
      subscription.unsubscribe();
      setIsInOtherSacco(false);
    };
  }, [watch]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saccoState = useSelector((state) => state.sacco);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      setValue("gmi", saccoState.saccoDetails.gross_monthly_income);
      setValue("otherIncome", saccoState.saccoDetails.other_income);
      setValue(
        "otherSacco",
        saccoState.saccoDetails.is_in_another_sacco ? "yes" : "no"
      );
      setValue("otherSaccoName", saccoState.saccoDetails.another_sacco_name);
    }
    return () => {};
  }, []);

  const handleIsInOtherSacco = (data) => {
    console.log("target value", data);
    // if (e.target.value === "yes") setIsInOtherSacco(true);
  };
  const handleFirstPersonalInfo = (data) => {
    // console.log("sacco details submit", data);
    let payload = {
      gross_monthly_income: data.gmi,
      weekly_tip_income: 0,
      is_tip_income:
        data.otherIncome === "tip" ||
        data.otherIncome === "tipping" ||
        data.otherIncome === "tips"
          ? true
          : false,
      is_in_another_sacco: data.otherSacco === "yes" ? true : false,
      other_income: data.otherIncome ? data.otherIncome : "N/A",
      another_sacco_name: data.otherSaccoName ? data.otherSaccoName : "",
    };
    console.log("sacco details submit", payload);
    dispatch(saccoActions.updateSaccoDetails(payload));
    navigate("/fifthForm");
  };
  return (
    <div
      className={`${
        isLoading ? "relative" : ""
      } w-screen flex flex-col h-screen`}
    >
      <div
        className={`
       
          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={fourOfTen}
            alt="four of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            Tell us about your income
          </h2>
          <p className="text-base my-1">
            This details will be used for your SACCO account and linked to your
            Shukran account.
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-5"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Gross Monthly Income (Ksh) *
                </label>
                <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("gmi", {
                    required: true,
                  })}
                  placeholder="50000"
                >
                  <option value="10000-">Below 10000</option>
                  <option value="20000">10000 - 20000</option>
                  <option value="30000">20000 - 30000</option>
                  <option value="40000">30000 - 40000</option>
                  <option value="50000">40000 - 50000</option>
                  <option value="60000">50000 - 60000</option>
                  <option value="70000">60000 - 70000</option>
                  <option value="70000+">70000+</option>
                </select>
                {errors.gmi && (
                  <p className="text-red-600 text-sm">
                    Please select a income gap
                  </p>
                )}
              </div>
              {/* <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Weekly Tip Amount (Ksh)
                </label>
                <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("wti", {
                    required: true,
                  })}
                  placeholder="500"
                >
                  <option value="500">500</option>
                  <option value="2000">2000</option>
                  <option value="4000">4000</option>
                  <option value="5000+">5000+</option>
                </select>
                {errors.wti && (
                  <p className="text-red-600 text-sm">
                    Please select a weekly tip amount
                  </p>
                )}
              </div> */}
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">Other Income</label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="business"
                  {...register("otherIncome", {})}
                />
                {errors.otherIncome && (
                  <p className="text-red-600 text-sm">
                    Please another source of income if any
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label
                  htmlFor="is-other-sacco"
                  className="mt-2 text-label text-xs"
                >
                  Are you a member of another Sacco
                </label>
                <select
                  id="is-other-sacco"
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("otherSacco", {
                    required: true,
                  })}
                  placeholder="No"
                >
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
                {errors.otherSacco && (
                  <p className="text-red-600 text-sm">
                    Please select an option
                  </p>
                )}
              </div>
              {isInOtherSacco && (
                <div className="flex flex-col mt-2">
                  <label className="mt-2 text-label text-xs">
                    If yes, give name of SACCO
                  </label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="business"
                    {...register("otherSaccoName", {})}
                  />
                  {errors.otherSaccoName && (
                    <p className="text-red-600 text-sm">
                      Please enter name of other sacco
                    </p>
                  )}
                </div>
              )}

              <div className="flex flex-row justify-between my-2 pt-3">
                <div
                  className={`flex flex-row items-center`}
                  onClick={() => handleBack()}
                >
                  <img
                    src={ArrowButtonPrev}
                    className="w-12 h-12"
                    alt="Previous"
                  />
                  <span className="ml-2">Previous</span>
                </div>
                <button type="submit" className="flex flex-row items-center">
                  <span className="mr-2">Next</span>
                  <img
                    src={ArrowButtonNext}
                    className="w-12 h-12"
                    alt="Next Page"
                    type="button"
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default FourthOccupationInfo;
