import React, { useState, useEffect } from "react";

import FacebookIcon from "../../assets/Icons/Referrals/facebook.svg";
import TwitterIcon from "../../assets/Icons/Referrals/twitter.svg";
import CopyIcon from "../../assets/Icons/Referrals/clipboard-copy.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import EmojiOne from "../../assets/Icons/Referrals/EmojiOne.svg";
import EmojiTwo from "../../assets/Icons/Referrals/EmojiTwo.svg";
import EmojiThree from "../../assets/Icons/Referrals/EmojiThree.svg";
import EmojiFour from "../../assets/Icons/Tipping/emoji_four.svg";
import EmojiFive from "../../assets/Icons/Referrals/EmojiFive.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import dropDownIcon from "../../assets/Icons/Dashboard/dropdown_icon.svg";
import EmailIcon from "../../assets/Icons/Referrals/email.svg";
import { RWebShare } from "react-web-share";
import { useNavigate, Link } from "react-router-dom";
import BackButton from "../../components/globals/BackButton";
import { useSelector, useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import {
  getRedeemableReferrals,
  getReferrals,
  redeemReward,
} from "../../state/reducers/user/user.thunk";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import Share from "../Dashboard/Share";
import ReferralCard from "../Card/ReferralCard";

export default function Referrals() {
  const [toggleShow, setToggleShow] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [emailShareUrl, setEmailShareUrl] = useState("");
  const [twitterShareUrl, setTwitterShareUrl] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [fallbackOption, setFallbackOption] = useState(false);

  const {
    formState: { errors },
  } = useForm({});

  const userAccount = useSelector((state) => state.account);
  const saccoState = useSelector((state) => state.sacco);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tippingState = useSelector((state) => state.tipping);
  const transactionState = useSelector((state) => state.transactions);

  useEffect(() => {
    dispatch(getRedeemableReferrals(userAccount.userDetails.token));
    dispatch(getReferrals(userAccount.userDetails.token));
    return () => {};
  }, []);

  useEffect(() => {
    if (saccoState.redeemRes.success) {
      setTimeout(() => {
        dispatch(saccoActions.redeemRewardSuccess({}));
        navigate("/");
      }, 3000);
    }

    return () => {};
  }, [saccoState]);

  useEffect(() => {
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    let emailUrl = `mailto:?subject="Have you heard of Shukran?"&body=Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit ${url}`;
    let twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${"Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit "}`;
    setShareUrl(url);
    setEmailShareUrl(emailUrl);
    setTwitterShareUrl(twitterUrl);
    return () => {};
  }, []);

  const onFacebookShare = (e) => {
    e.preventDefault();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      "facebook-share-dialog",
      "width=800,height=600"
    );
  };

  const onCopyToClipboard = (e) => {
    e.preventDefault();
    let strVal =
      "Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit " +
      shareUrl;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(strVal)
        .then(() => setIsCopied(true))
        .catch(console.error);
    }
  };

  // Restarts the copy state after a few seconds
  useEffect(() => {
    if (!isCopied) return;

    const timer = setTimeout(() => {
      setIsCopied((currentIsCopied) => !currentIsCopied);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isCopied]);

  const FallBackOptions = () => (
    <div className="flex flex-col items-center drop-shadow-2xl rounded-t-2xl bg-white pb-auto w-full z-30">
      <div className="flex flex-row-reverse w-full">
        <div
          className="text-base text-pink-400 rounded-2xl py-1 px-3 cursor-pointer"
          onClick={() => {
            setFallbackOption(false);
          }}
        >
          X
        </div>
      </div>
      <div className="flex flex-row bg-white items-center justify-around w-full pb-4">
        <div
          className="h-14 w-14 flex flex-col items-center justify-content"
          onClick={onFacebookShare}
        >
          <img src={FacebookIcon} alt="icon" />
          <span className="text-xs">Facebook</span>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={twitterShareUrl}
          className="h-14 w-14 flex flex-col items-center justify-content"
        >
          <img src={TwitterIcon} alt="icon" />
          <span className="text-xs">Twitter</span>
        </a>
        <div
          className="h-14 w-14 flex flex-col items-center justify-content"
          onClick={onCopyToClipboard}
        >
          <img src={CopyIcon} alt="icon" />
          <span className="text-xs text-center">
            {isCopied ? "Copied!" : "Copy to clipboard"}
          </span>
        </div>
        <a
          href={emailShareUrl}
          title="Have you heard of Shukran?"
          className="h-14 w-14 flex flex-col items-center justify-content"
        >
          <img src={EmailIcon} alt="email" />
          <span className="text-xs">Email</span>
        </a>
      </div>
    </div>
  );

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");

    if (saccoState.errorMessage.status === 403 && isLoggedIn) {
      navigate("/login");
    }

    return () => {};
  }, [saccoState, navigate]);

  const ErrorCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-8 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.redeemRewardError(""));
          dispatch(getRedeemableReferrals(userAccount.userDetails.token));
        }}
      >
        X
      </button>
      <img src={cross} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.errorMessage.msg}
      </p>
    </div>
  );

  const TippingTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(saccoActions.redeemRewardError({}));
            dispatch(getRedeemableReferrals(userAccount.userDetails.token));
            dispatch(saccoActions.redeemRewardSuccess({}));
            // navigate("/");
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {saccoState.redeemRes.success && !saccoState.isLoading && (
        <img src={checkCircle} alt="error-img" className="h-24" />
      )}
      {saccoState.redeemRes?.success && !saccoState.isLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {saccoState.redeemRes?.msg}
        </p>
      )}
      {!saccoState.redeemRes?.success && !saccoState.isLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {saccoState.redeemRes?.msg}
        </p>
      )}
      {saccoState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {saccoState?.errorMessage?.msg}
        </p>
      )}
    </div>
  );
  return (
    <div
      className={`
        ${saccoState.isLoading ? "relative" : ""}
        ${saccoState.errorMessage?.msg ? "relative" : ""}
        ${saccoState.redeemReward?.success ? "relative" : ""}
        ${fallbackOption ? "relative" : ""}
        ${saccoState.results.success ? "relative" : ""}
         w-screen flex flex-col h-full
      `}
    >
      <div
        className={` 
        ${saccoState?.isLoading ? "blur-sm" : ""}
        ${saccoState?.results?.success ? "blur-sm" : ""}
        ${saccoState?.errorMessage?.msg ? "blur-sm" : ""}
        ${saccoState?.redeemReward?.success ? "blur-sm" : ""}
        ${fallbackOption ? "blur-sm brightness-50" : ""} 
        flex flex-col w-full h-full px-5`}
      >
        <div className="flex flex-row items-center mt-9">
          <div>
            <BackButton onPress={() => navigate("/")} />
          </div>
          {/* <Link to={"/"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link> */}
        </div>
        <div className="bg-white flex flex-col items-center">
          <p className="mt-5 text-3xl font-semibold text-primary-pwa">
            Invite friends and WIN!
          </p>
          <p className="mt-5 text-lg font-semibold text-black-300">
            Invite 2 friends to join and win!
          </p>
          <div className=" mt-7 p-2 bg-[#C5EAEC]/30 w-full rounded-lg flex flex-col items-center">
            <p className="mt-5 text-lg font-semibold text-black-300">
              Activate Two Friends
            </p>
            <div
              id="tickmarks"
              className="flex mt-3 flex-row justify-evenly w-3/4"
            >
              <img
                src={
                  saccoState?.redeemableRes?.length === 0
                    ? EmojiOne
                    : EmojiThree
                }
                className="h-5 w-5"
                alt="emoji"
              ></img>
              <img src={EmojiFour} className="h-5 w-5" alt="emoji"></img>
              {/* <img src={EmojiThree} className="h-5 w-5" alt="emoji"></img>
              <img src={EmojiFour} className="h-5 w-5" alt="emoji"></img>
              <img src={EmojiFive} className="h-5 w-5" alt="emoji"></img> */}
            </div>
            <div className="bg-gray-300 mt-3 rounded-full w-3/5 ">
              <div
                className={`
                    ${
                      saccoState?.redeemableRes?.length === 1
                        ? "w-3/5"
                        : saccoState?.redeemableRes?.length === 2
                        ? "w-5/5"
                        : // : saccoState.redeemableRes.length === 3
                        // ? "w-3/5"
                        // : saccoState.redeemableRes.length === 4
                        // ? "w-4/5"
                        // : saccoState.redeemableRes.length === 5
                        "w-full"
                        ? "w-1/6"
                        : ""
                    }
                    bg-primary-pwa
                    p-2
                    font-bold
                    text-center
                    rounded-full
                    text-white
                  `}
              >
                {saccoState?.redeemableRes?.length}/2
              </div>
            </div>
            <p className="mt-5 text-lg font-semibold text-black-300">
              Win Kshs. 400 🎁
            </p>
            <button
              className={`
                  ${
                    saccoState?.redeemableRes?.length <= 2
                      ? "text-gray-300 bg-[#F0F0F0]"
                      : "drop-shadow-xl text-black bg-accent"
                  }
                  rounded-full py-2 px-3 text-base my-3
                `}
              type="button"
              disabled={saccoState?.redeemableRes?.length < 5 ? true : false}
              onClick={() => {
                if (saccoState?.redeemableRes?.length >= 5) {
                  // navigate('/rewards')
                  dispatch(redeemReward(userAccount.userDetails.token));
                }
              }}
            >
              Collect Reward
            </button>
          </div>
          <Share
            setFallbackOption={setFallbackOption}
            inviteOption={"invite-service"}
            title="Have you heard of Shukran?"
            text="Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit"
          />

          <div className="mt-10 pb-2 flex flex-col w-full">
            <div className="flex flex-row justify-between items-center w-full">
              <div className="text-sm">HIGHLIGHTS</div>
              <div
                className="text-primary-pwa self-end flex flex-row items-center text-sm"
                onClick={() => setToggleShow(!toggleShow)}
              >
                {toggleShow ? "SHOW LESS" : "SHOW MORE"}
                <img
                  src={dropDownIcon}
                  alt="clock-icon"
                  className={`
                      w-3 h-3 text-white ml-2
                      ${toggleShow ? "" : "rotate-180"}
                    `}
                />
              </div>
            </div>
            {saccoState.referralRes &&
              toggleShow &&
              saccoState?.referralRes?.map((transaction, idx) => (
                <ReferralCard key={idx} referral={transaction} />
              ))}
            {!!saccoState.referralRes?.length && !toggleShow && (
              <ReferralCard key={1} referral={saccoState.referralRes[0]} />
            )}
            {!saccoState.referralRes?.length && !transactionState.isLoading && (
              <div className="mt-3 bg-accent-light py-4 px-3 flex flex-col justify-center items-center">
                <span>No referrals yet</span>
              </div>
            )}
            {transactionState.isLoading &&
              (!saccoState?.referralRes?.length ||
                !saccoState?.redeemableRes?.length) && (
                <div className="my-1 bg-[#FFFAF0] rounded-xl p-2.5 w-full flex flex-row justify-center">
                  <img
                    src={loader}
                    alt="loader"
                    className={`animate-spin h-8 mx-auto text-sm mt-1`}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        className={`${
          saccoState.redeemRes.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionCard />
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <ErrorCard />
      </div>
      <div
        className={`${
          saccoState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
      <div
        className={`${
          fallbackOption ? "fixed blur-none bottom-1" : "hidden"
        } flex flex-col items-center w-full`}
      >
        <FallBackOptions />
      </div>
    </div>
  );
}
