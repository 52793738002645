import React, { useState, useEffect } from "react";
import popup from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Tipping/cross.svg"
import { useNavigate } from "react-router-dom";

export default function SuccessPop(props) {
  let { message, title, setIsInsufficient, isInsufficient } = props;

  //   navigate
  const navigate = useNavigate();

  return (
    <div className="shadow pb-8 w-80 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div
        className="self-end mt-4 mr-4"
        onClick={() =>  { 
          // console.log('isClicked', isInsufficient)
          // setIsInsufficient(!isInsufficient)
            navigate('/')
          }
        }
      >
        <img src={cross} alt="cross-icon" className="h-6" />
      </div>
      <img src={popup} alt="error-img" className="h-32 mt-8" />
      <h2 className={title ? "text-center text-black	text-lg mt-10" : "hidden"}>
        {title}
      </h2>

      <p
        className={
          message ? "text-base text-primary text-center mt-3 bg px-8" : "hidden"
        }
      >
        {message}
      </p>
    </div>
  );
}
