import React from "react";
import { useNavigate } from "react-router-dom";
import back from "../../assets/Icons/Rating/back_icon.svg";

export default function Header({ title, to }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full">
      <div className=" items-start mt-9" style={{}}>
        <div
          className={`flex flex-row items-start py-2 pr-6`}
          onClick={() => navigate(to)}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div>
      <div className="mt-4">
        <label className="text-3xl font-semibold text-primary-pwa">
          {title}
        </label>
      </div>
    </div>
  );
}
