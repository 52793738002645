import React from "react";
import reservationCard from "../../assets/Images/reservation_bg.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendNotification } from "../../state/reducers/user/user.thunk";
import { balanceActions } from "../../state/reducers/user/user.actions";

export default function ReservationCard({ setReservationBanner }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAccount = useSelector((state) => state.account);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const handleStartTipper = () => {
    dispatch(sendNotification(userAccount.userDetails.token));
  };

  return (
    <div className="bg-white rounded-xl mt-28 pb-auto w-full">
      <div className="w-1/6 flex flex-row-reverse absolute right-2">
        <div
          className="text-base text-white bg-pink-400 rounded-2xl py-1 px-3 m-3 cursor-pointer"
          onClick={() => {
            setReservationBanner(false);
            dispatch(balanceActions.sendNotificationSuccess({}));
          }}
        >
          Close
        </div>
      </div>
      <img
        src={reservationCard}
        alt="reservation card"
        className="w-full h-2/5 mx-auto"
      />
      <div className="mx-4 absolute bottom-12 flex flex-col justify-start">
        <div className="flex flex-col justify-center text-center mb-3">
          <h1 className="text-2xl ">Coming Soon</h1>
          {balanceState.isLoading ? (
            <img
              src={loader}
              alt="loader"
              className={`animate-spin h-8 mx-auto text-sm mt-1`}
            />
          ) : balanceState.sendNotificationRes?.success ? (
            <button className="rounded-full py-2 bg-primary-pwa text-white text-base w-full mb-4 mt-3">
              {balanceState.sendNotificationRes?.msg}
            </button>
          ) : (
            <button
              className="rounded-full py-2 bg-primary-pwa text-white text-base w-full mb-4 mt-3"
              onClick={handleStartTipper}
            >
              Click to get notified
            </button>
          )}
          <div className="text-sm mx-8">
            Efficiently make reservations in restaurants anywhere across the
            country!
          </div>
        </div>
      </div>
    </div>
  );
}
