// action Types
export const actionTypes = {
  REQUEST_OTP_START: "REQUEST_OTP_START",
  REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
  REQUEST_OTP_ERROR: "REQUEST_OTP_ERROR",
	CONFIRM_OTP_START: "CONFIRM_OTP_START",
  CONFIRM_OTP_SUCCESS: "CONFIRM_OTP_SUCCESS",
  CONFIRM_OTP_ERROR: "CONFIRM_OTP_ERROR",
};

// actions
const requestOtpStart = () => ({
  type: actionTypes.REQUEST_OTP_START,
});

const requestOtpSuccess = (results) => ({
  type: actionTypes.REQUEST_OTP_SUCCESS,
  payload: results,
});

const requestOtpError = (errorMsg) => ({
  type: actionTypes.REQUEST_OTP_ERROR,
  payload: errorMsg,
});


const confirmOtpStart = () => ({
  type: actionTypes.CONFIRM_OTP_START,
});

const confirmOtpSuccess = (results) => ({
  type: actionTypes.CONFIRM_OTP_SUCCESS,
  payload: results,
});

const confirmOtpError = (errorMsg) => ({
  type: actionTypes.CONFIRM_OTP_ERROR,
  payload: errorMsg,
});
export const registrationActions = {
  requestOtpStart,
  requestOtpSuccess,
  requestOtpError,
	confirmOtpStart,
  confirmOtpSuccess,
  confirmOtpError,
};
