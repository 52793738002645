import React, { useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import chevron from "../../assets/Icons/tipjar/chevron.svg";
import correct from "../../assets/Icons/tipjar/correct.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import axios from "axios";
import "./members.css";
import {
  CheckDisbursement,
  Disburse,
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
  getTipjarMembersSelected,
  postTipjarMembersSelected,
} from "../../state/reducers/user/user.thunk";
import { useDispatch, useSelector } from "react-redux";
import confetti from "../../assets/Icons/Tipping/confetti.gif";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import wallet from "../../assets/Icons/wallet.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useEffect } from "react";
import { tipjarActions } from "../../state/reducers/tipjar/tipjar.actions";
import BackButton from "../globals/BackButton";
import { testUrl } from "../../helper";
import { method } from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { handleErrorResponse } from "../../utils/error.utils";
import { postJson } from "../../utils/fetch.utils";
import { BASE_URL } from "../../constants/network";
import { toast } from "sonner";
import { Button } from "../../components-shadcn/ui/button";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import { IoMdCloseCircleOutline } from "react-icons/io";

export default function ConfirmMembers() {
  // const calculateDisbursementMutation = useMutation({
  //   mutationFn: async (data) => {
  //     const resp = await postJson(
  //       `${BASE_URL}/v1/tipjar/calculate-disbursement/`,
  //       {}
  //     );
  //     if (resp.success) {
  //       return resp.data;
  //     } else {
  //       handleErrorResponse(resp);
  //     }
  //   },
  // });

  // {"data":{"distributions":[{"user_id":481,"shukran_id":"AT0481","first_name":"Alvin","last_name":"Otuya","amount_to_receive":970,"amount_sent":1000},{"user_id":220,"shukran_id":"FS0220","first_name":"Felix","last_name":"Osumo","amount_to_receive":970,"amount_sent":1000},{"user_id":829,"shukran_id":"MN0829","first_name":"Martin","last_name":"Njau","amount_to_receive":970,"amount_sent":1000},{"user_id":399,"shukran_id":"NA0399","first_name":"Nimi","last_name":"Akinyi","amount_to_receive":970,"amount_sent":1000},{"user_id":839,"shukran_id":"FOS839","first_name":"Felix","last_name":"Okoth","amount_to_receive":5820,"amount_sent":6000}],"disbursement_amount":9700,"charged_amount":300},"success":true,"msg":"Resource successfully created"}
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tipjar = useSelector((state) => state.tipjar);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [all, setAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [len, setLen] = useState([]);
  const [member, setMember] = useState();
  const tipData = useSelector((state) => state.tipData);
  const [disbursing, setDisbursing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calculations, setCalculations] = useState([]);
  const userDetails = useSelector((state) => state.account.userDetails);
  const [calculatingDisbursement, setCalculatingDisbursement] = useState(false);
  const [tipDistributions, setTipDistributions] = useState();
  const calculateDistribution = async (allSelectedMembers) => {
    if (allSelectedMembers?.length == 1) {
      return;
    }
    setCalculatingDisbursement(true);

    try {
      const resp = await postJson(
        `${BASE_URL}api/v1/tipjar/calculate-disbursement/`,
        {
          tipjar_id: tipjar.tipjarId,
          total_amount: Number(tipData?.tipAmount),
          distribution: allSelectedMembers,
        }
      );

      if (resp.success) {
        setTipDistributions(resp.data?.distributions);
        const arr = [];
        resp.data?.distributions?.map((member, index) => {
          arr.push({ user_id: member?.user_id });
        });
        setSelectedMembers(arr);
        setCalculatingDisbursement(false);

        return resp.data;
      } else {
        setCalculatingDisbursement(false);

        handleErrorResponse(resp);
      }
    } catch (error) {
      setCalculatingDisbursement(false);
      //
      handleErrorResponse(error);
    }
  };
  const disburseTips = async () => {
    setDisbursing(true);

    try {
      const resp = await postJson(`${BASE_URL}api/v1/tipjar/disburse-funds/`, {
        tipjar_id: tipjar.tipjarId,
        total_amount: Number(tipData?.tipAmount),
        distributions: selectedMembers,
      });

      if (resp.success) {
        toast.success(resp.msg ?? "Tips distributed successfully");

        setDisbursing(false);
        setConfirm(false);
        navigate("/");
        return resp.data;
      } else {
        setDisbursing(false);

        handleErrorResponse(resp);
      }
    } catch (error) {
      setDisbursing(false);

      handleErrorResponse(error);
    }
  };

  const handleBack = () => {
    navigate("/tipjar/confirm");
  };
  useEffect(() => {
    let arr = [];
    tipDistributions?.map((member, index) => {
      arr.push({ user_id: member?.id, amount: member?.amount_sent });
    });
    setSelected(arr);
  }, [tipjar?.groups, tipDistributions]);
  useEffect(() => {
    axios
      .post(`${testUrl}tipjar/disburse/calculate/`, {
        tipjar_id: tipjar.tipjarId,
        total_amount: 200,
      })
      .then((res) => {
        console.log(res, "calc");
        setCalculations(res?.data?.distribution);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const userAccount = useSelector((state) => state.account);

  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (tipjar?.tippingResponse?.id) {
      setLoading(true);
      setConfirm(false);
      setTimeout(() => {
        dispatch(
          CheckDisbursement(
            tipjar?.tippingResponse?.disbursement_detail[0]?.transaction?.id,
            userAccount?.userDetails?.token
          )
        );
        dispatch(tipjar?.tippingResponse({}));
        navigate("/");
      }, 20000);
    }
  }, [tipjar?.tippingResponse]);
  useEffect(() => {
    if (tipjar?.isTransactionSuccess) {
      setTimeout(() => {
        //tipjar?.isTransactionLoading = false;
        tipjarActions?.tipjarCheckTippingSuccess({});
        dispatch(tipjar?.transactionResponse({}));
        navigate("/");
      }, 2000);
    }
  }, [tipjar?.isTransactionSuccess]);
  useEffect(() => {
    if (tipjar?.errorMessage?.length > 0) {
      toast.warning(`${tipjar?.errorMessage[0]}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [tipjar?.errorMessage]);
  useEffect(() => {
    // dispatch(getTipjarMembers({}, userAccount?.userDetails?.token));
    // dispatch(getTipjarData({}, userAccount.userDetails.token));
  }, []);
  useEffect(() => {
    if (!tipData?.tipAmount) {
      navigate("/tipjar/amount");
    }
  }, [confirm]);
  useEffect(() => {
    if (tipjar?.selected?.length === tipDistributions?.length) {
      setAll(true);
    } else {
      setAll(false);
    }
  }, [fetch]);
  useEffect(() => {
    // if (tipjar?.tipjarId) {
    //   dispatch(
    //     getTipjarGroups(tipjar?.tipjarId, userAccount?.userDetails?.token)
    //   );
    // }
    // fetch(`${testUrl}tipjar/disburse/calculate/`, {
    //   method: "GET",
    //   header
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // axios({
    //   url: `${testUrl}tipjar/disburse/calculate/`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-IDT": "U",
    //     XAT: "A",
    //   },
    //   data: {
    //     tipjar_id: tipjar?.tipjarId,
    //     total_amount: 11,
    //   },
    // });
  }, [tipjar?.tipjarId]);
  useEffect(() => {
    calculateDistribution([]);
  }, []);
  const removeFrom = (id) => {
    const newm = tipjar?.selected?.filter((select) => {
      return select?.user_id !== id;
    });
    console.log(newm, "neww");
    dispatch(postTipjarMembersSelected(newm));
  };

  const ConfirmTipPopup = () => (
    <div
      id="confirm"
      className="py-8 px-4 mr-2 drop-shadow-lg mt-10 bg-white flex flex-col rounded-3xl   align-center"
      style={{ width: "90%" }}
    >
      <div className="flex flex-row items-center justify-between">
        <span className="text-base">Confirm Details</span>
        <div
          onClick={() => {
            setConfirm(false);
            // setPhoneNumber("");
            // setCardOption(false);
            // setCardPopup(false);
            // setConfirmMpesa(false);
            // if (balanceState.generateResponse.phone) {
            //   // navigate("/queryMpesaNo");
            //   balanceState.generateResponse.phone = "";
            // }
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">Amount:</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-sm text-primary-pwa w-36">
              KSH {tipData.tipAmount}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">Group distribution:</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-sm text-primary-pwa w-36">
              {tipjar?.groups?.length} Groups
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">Members distribution:</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-sm text-primary-pwa w-36">
              {tipDistributions?.length} Members
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">From:</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-sm text-primary-pwa w-36">
              {tipjar?.tipjar?.first_name} {tipjar?.tipjar?.last_name}
            </span>
          </div>
        </div>
      </div>
      {tipData.tipAmount >= 100 ? (
        <div className="mt-3 h-12 flex flex-col items-start">
          <label className="text-subtle text-sm">
            Transaction Cost will 3% of KES {tipData?.tipAmount}
          </label>
          {/* <div className="flex flex-row mt-2">
            <input
              type="checkbox"
              checked={isPayingTransaction}
              className="mr-2"
              onChange={() => {
                setIsPayingTransaction(!isPayingTransaction);
              }}
            />
            {/* <span className="text-sm italic ">
              I will pay this cost of KES {tipData.tipAmount * 0.025}
            </span> 
          </div> */}
        </div>
      ) : (
        <div></div>
      )}
      <div className="mt-2">
        <button
          disabled={disbursing}
          className="mt-4 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full"
          onClick={() => {
            disburseTips();
            // dispatch(
            //   Disburse({
            //     tipjar_id: tipjar?.tipjarId,
            //     total_amount: tipData?.tipAmount,
            //   })
            // );
          }}
        >
          {disbursing ? "Disbursing..." : "Send Tips"}
        </button>
      </div>
    </div>
  );
  const TippingTransactionCard = () => (
    <div
      className="my-auto px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50"
      style={{ width: "90%" }}
    >
      {tipjar.transactionResponse.status && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(tipjarActions?.tipjarTippingSuccess({}));
            dispatch(tipjarActions.tipjarCheckTippingSuccess(""));
            dispatch(tipjarActions?.tipjarCheckTippingSuccess({}));
            navigate("/");
            //dispatch(tipjar.checkTippingSuccess(""));
            // addShukranId({})
            // if (queryShukranId) {
            //   navigate("/download");
            // } else {
            //   navigate("/download");
            // }
          }}
        >
          X
        </button>
      )}
      {tipjar?.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {tipjar?.transactionResponse?.status && (
        <div className="flex flex-row justify-between">
          <img src={confetti} alt="confetti" className="h-28 -scale-x-100" />
          <img
            src={checkCircle}
            alt="error-img"
            className="h-24 left-1/3 absolute"
          />
          <img src={confetti} alt="confetti" className="h-28" />
        </div>
      )}

      {tipjar?.isTransactionLoading && (
        <p
          style={{ fontSize: "13px" }}
          className="justify-self-center	text-base text-sm text-center py-2 px-12"
        >
          We are processing the TipJar payments now. Kindly be patient. Thank
          you.
        </p>
      )}
      {tipjar?.tippingResponse?.success && !tipjar?.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tipjar.tippingResponse?.msg}
        </p>
      )}
      {tipjar?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tipjar?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tipjar?.errorTransactionStatusMessage.msg && (
        <div className="flex flex-col items-center">
          <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {/* Tip to {receiverShukranId ? receiverShukranId : nonShukranId}{" "} */}
            Cancelled
          </p>
        </div>
      )}
      {tipjar?.errorTransactionStatusMessage.status &&
        !tipjar?.isTransactionSuccess === 404 && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            Tip transaction pending. Kindly be patient as we process your tip
          </p>
        )}
      {tipjar?.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {tipjar?.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  const TippingTransactionError = () => (
    <div
      className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50"
      style={{ width: "90%" }}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(tipjarActions?.tipjarCheckTippingError(""));
          dispatch(tipjarActions?.tipjarTippingError(""));
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
      {tipjar?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {tipjar?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tipjar.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tipjar.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tipjar.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {tipjar?.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );
  const selectAll = () => {
    tipDistributions?.map((member, index) => {
      const newValues = [...selected];
      newValues[index] = member;
      setSelected(newValues);
      console.log(selected);
    });
  };
  const removeAll = () => {
    tipDistributions?.map((member, index) => {
      const newValues = [...selected];
      newValues[index] = {};
      setSelected(newValues);
      console.log(selected);
    });
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div
        className={`${
          (confirm ||
            tipjar?.tippingResponse.id ||
            tipjar?.errorTransactionStatusMessage ||
            tipjar?.errorMessage?.msg ||
            tipjar?.errorMessage?.error ||
            tipjar?.transactionResponse?.status ||
            tipjar?.errorMessage?.detail) &&
          "blur-sm"
        } flex flex-col w-full h-screen`}
      >
        {/*  */}

        {/* <div
          className="flex flex-row items-center mt-9 w-full"
          style={{ width: "100%" }}
        >
          <div
            className={`flex flex-row items-center py-2 pl-2 pr-6`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
        </div> */}
        <div className="w-screen p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div
          className=" mt-6  w-full px-4"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <h2 className="text-3xl font-semibold text-primary-pwa">
            Confirm member picks
          </h2>
          <p className="text-md font-semibold text-gray-400">
            Select members to include in the TipJar distribution of KES.{" "}
            {tipData?.tipAmount}
          </p>

          <div className="mt-6">
            {tipDistributions?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                className="#members"
              >
                <h5>Members ({tipDistributions?.length})</h5>
                {/* <div
                  className="flex flex-row "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    if (tipDistributions?.length === selected?.length) {
                      setSelected([]);
                      setAll(false);
                    } else {
                      setAll(true);
                      const newValues = [];
                      tipDistributions?.map((member, index) => {
                        tipjar?.groups?.map((group, index) => {
                          if (group?.id === member?.group?.id) {
                            newValues[index] = {
                              user_id: member?.id,
                            };
                          }
                        });
                      });
                      setSelected(newValues);
                      console.log(newValues);
                    }
                  }}
                >
                  <p style={{ fontSize: "16px" }}>select all</p>
                  {tipDistributions?.length === selected?.length && all ? (
                    <img
                      className="ml-1"
                      src={correct}
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "7.5px",
                      }}
                    />
                  ) : (
                    <div
                      className="ml-1"
                      style={{
                        border: "1px solid #999",
                        width: "15px",
                        height: "15px",
                        borderRadius: "7.5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#F0FEFF",
                        color: "#6CCBD1",
                        fontSize: "8px",
                      }}
                    ></div>
                  )}
                </div> */}
              </div>
            ) : (
              <h5>Members </h5>
            )}
            {calculatingDisbursement ? (
              <div>
                {
                  //skeletons
                  Array.from({ length: 5 }).map((_, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Skeleton className="w-full h-14 my-2" />
                    </div>
                  ))
                }
              </div>
            ) : (
              <div className="mt-6 empty" style={{ overflow: "scroll" }}>
                {tipDistributions?.length > 0 ? (
                  <>
                    {tipDistributions?.map((member, index) => (
                      <>
                        <div
                          key={member?.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                          onClick={() => {
                            console.log(selected);
                            const membersInGroup = tipDistributions?.filter(
                              (fil) => {
                                if (member?.group?.id === fil?.group?.id) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                            );
                            if (
                              selected?.length > 0 &&
                              selected[index]?.user_id === member?.id
                            ) {
                              const newValues = [...selected];
                              newValues[index] = {};
                              setAll(false);
                              setSelected(newValues);
                            } else if (
                              selected?.length > 0 &&
                              selected[index]?.user_id !== member?.id
                            ) {
                              const newValues = [...selected];
                              newValues[index] = {
                                user_id: member?.id,
                                amount: Math.trunc(
                                  (tipData?.tipAmount *
                                    member?.group?.percentage) /
                                    100 /
                                    membersInGroup?.length
                                ),
                              };
                              setSelected(newValues);
                              const check = newValues?.filter(
                                (newValue, index) => {
                                  if (member[index]?.id === newValue?.user_id) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                }
                              );
                              console.log(newValues);
                              if (check?.length === 0) {
                                setAll(true);
                              } else {
                                setAll(false);
                              }
                            } else {
                              const newValues = [...selected];
                              newValues[index] = {
                                user_id: member?.id,
                                amount:
                                  (tipData?.tipAmount *
                                    member?.group?.percentage) /
                                  100 /
                                  membersInGroup?.length,
                              };
                              setSelected(newValues);
                            }
                          }}
                        >
                          <div
                            className="border-2 border-gray w-full h-65 mb-3"
                            style={{
                              height: "60px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              className="w-full px-2"
                            >
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "15px",
                                  backgroundColor: "#50A2A7 ",
                                }}
                              ></div>
                              <div className="ml-2">
                                <p
                                  style={{ color: "#404B5A", fontSize: "12px" }}
                                >
                                  {member?.first_name !== "N/A" ? (
                                    <>
                                      {member?.first_name} {member?.last_name}
                                    </>
                                  ) : (
                                    <>{member?.phone}</>
                                  )}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#929FB1",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {member?.shukran_id}
                                  </p>
                                  <p
                                    className="text-accent ml-2"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {" "}
                                    ~ KES {member?.amount_sent}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                              }}
                              onClick={() => {
                                //toggle the selected member  use the setSelectedMembers to update the selected members
                                const newValues = [...selectedMembers];

                                if (
                                  selectedMembers[index]?.user_id ===
                                  member?.user_id
                                ) {
                                  newValues[index] = {};
                                  setTimeout(() => {
                                    calculateDistribution(newValues);
                                  }, 200);
                                } else {
                                  newValues[index] = {
                                    user_id: member?.user_id,
                                  };
                                  setSelectedMembers(newValues);
                                }
                              }}
                              className="w-1/2 px-2"
                            >
                              {selectedMembers[index]?.user_id ===
                              member?.user_id ? (
                                <IoMdCloseCircleOutline />
                              ) : (
                                <IoMdCloseCircleOutline />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <button
                    onClick={() => navigate("/tipjar/groups")}
                    className="add_member "
                  >
                    <img src={add} className="add" alt="add member" />
                    <h6 className="ml-2">Add Member</h6>
                  </button>
                )}
              </div>
            )}
          </div>
          <a
            className="mb-4 flex flex-col align-center justify-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setConfirm(true)}
            href="#confirm"
          >
            <button className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-full">
              PROCEED
            </button>
          </a>
          <Button
            onClick={() => {
              calculateDistribution([]);
            }}
            variant={"outline"}
            className="rounded-full py-3 px-3 text-white text-m mx-auto mt-4 w-full mb-2"
          >
            Select All
          </Button>
          {/* <div>{JSON.stringify(selectedMembers)}</div> */}
        </div>
      </div>
      {confirm && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
          className="flex align-center"
        >
          <ConfirmTipPopup />
        </div>
      )}
      <div
        className={`${
          tipjar?.isTransactionLoading || tipjar?.transactionResponse?.status
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionCard />
      </div>
      <div
        className={`${
          tipjar?.errorTransactionStatusMessage ||
          tipjar?.errorMessage?.msg ||
          tipjar?.errorMessage?.error ||
          tipjar?.errorMessage?.detail
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionError />
      </div>
    </div>
  );
}
