import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran_logo.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";

const SplashScreen = () => {
  const { register, handleSubmit } = useForm();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const { saccoType } = balanceState;

  useEffect(() => {
    setTimeout(() => {
      navigate("/newUserType");
    }, 3000);
    return () => {};
  }, []);

  useEffect(() => {
    if (balanceState.errorMessage === 403) {
      dispatch(balanceActions.getUserDataError(""));
    }
    return () => {};
  }, [balanceState, dispatch]);

  return (
    <div className="bg-gradient-to-r from-[#DEEDED] to-[#EFEDDC] flex flex-col justify-center items-center h-screen w-full">
      <img src={logo} alt="splash-screen" className="rounded h-18" />
    </div>
  );
};

export default SplashScreen;
