const Avatar = (props) => {
  const initials = props.shukranId ? props.shukranId.split("", 2) : 0;
  return (
    <div className="border-primary-pwa border-2 rounded-full flex justify-center items-center h-12 w-12">
      <span className="text-xl text-primary-pwa">{initials}</span>
    </div>
  );
};

export default Avatar;
