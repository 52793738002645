// action Types
export const actionTypes = {
  TIPJAR_DATA_START: "TIPJAR_DATA_START",
  TIPJAR_DATA_SUCCESS: "TIPJAR_DATA_SUCCESS",
  TIPJAR_DATA_ERROR: "TIPJAR_DATA_ERROR",
  TIPJAR_MEMBER_START: "TIPJAR_MEMBER_START",
  TIPJAR_MEMBER_SUCCESS: "TIPJAR_MEMBER_SUCCESS",
  TIPJAR_MEMBER_ERROR: "TIPJAR_MEMBER_ERROR",
  TIPJAR_GROUP_START: "TIPJAR_GROUP_START",
  TIPJAR_GROUP_SUCCESS: "TIPJAR_GROUP_SUCCESS",
  TIPJAR_GROUP_ERROR: "TIPJAR_GROUP_ERROR",
  TIPJAR_GROUP_CREATE_START: "TIPJAR_GROUP_CREATE_START",
  TIPJAR_GROUP_CREATE_SUCCESS: "TIPJAR_GROUP_CREATE_SUCCESS",
  TIPJAR_GROUP_CREATE_ERROR: "TIPJAR_GROUP_CREATE_ERROR",
  TIPJAR_MEMBER_CREATE_START: "TIPJAR_MEMBER_CREATE_START",
  TIPJAR_MEMBER_CREATE_SUCCESS: "TIPJAR_MEMBER_CREATE_SUCCESS",
  TIPJAR_MEMBER_CREATE_ERROR: "TIPJAR_MEMBER_CREATE_ERROR",
  TIPJAR_INDIVIDUAL_GROUP_START: "TIPJAR_INDIVIDUAL_GROUP_START",
  TIPJAR_INDIVIDUAL_GROUP_SUCCESS: "TIPJAR_INDIVIDUAL_GROUP_SUCCESS",
  TIPJAR_INDIVIDUAL_GROUP_ERROR: "TIPJAR_INDIVIDUAL_GROUP_ERROR",
  TIPJAR_GROUP_DELETE_START: "TIPJAR_GROUP_DELETE_START",
  TIPJAR_GROUP_DELETE_SUCCESS: "TIPJAR_GROUP_DELETE_SUCCESS",
  TIPJAR_GROUP_DELETE_ERROR: "TIPJAR_GROUP_DELETE_ERROR",
  TIPJAR_MEMBER_POST_SELECT_START: "TIPJAR_MEMBER_POST_SELECT_START",
  TIPJAR_MEMBER_POST_SELECT_SUCCESS: "TIPJAR_MEMBER_POST_SELECT_SUCCESS",
  TIPJAR_MEMBER_POST_SELECT_ERROR: "TIPJAR_MEMBER_POST_SELECT_ERROR",
  TIPJAR_MEMBER_GET_SELECT_START: "TIPJAR_MEMBER_GET_SELECT_START",
  TIPJAR_MEMBER_GET_SELECT_SUCCESS: "TIPJAR_MEMBER_GET_SELECT_SUCCESS",
  TIPJAR_MEMBER_GET_SELECT_ERROR: "TIPJAR_MEMBER_GET_SELECT_ERROR",
  TIPJAR_TIPPING_START: "TIPJAR_TIPPING_START",
  TIPJAR_TIPPING_SUCCESS: "TIPJAR_TIPPING_SUCCESS",
  TIPJAR_TIPPING_ERROR: "TIPJAR_TIPPING_ERROR",
  TIPJAR_CHECK_TIPPING_START: "TIPJAR_CHECK_TIPPING_START",
  TIPJAR_CHECK_TIPPING_SUCCESS: "TIPJAR_CHECK_TIPPING_SUCCESS",
  TIPJAR_CHECK_TIPPING_ERROR: "TIPJAR_CHECK_TIPPING_ERROR",
};

// actions
const getTipJarDataStart = () => ({
  type: actionTypes.TIPJAR_DATA_START,
});

const getTipJarDataSuccess = (results) => ({
  type: actionTypes.TIPJAR_DATA_SUCCESS,
  payload: results,
});

const getTipJarDataError = (errorMsg) => ({
  type: actionTypes.TIPJAR_DATA_ERROR,
  payload: errorMsg,
});
const getTipJarMembersStart = () => ({
  type: actionTypes.TIPJAR_MEMBER_START,
});

const getTipJarMembersSuccess = (results) => ({
  type: actionTypes.TIPJAR_MEMBER_SUCCESS,
  payload: results,
});

const getTipJarMembersError = (errorMsg) => ({
  type: actionTypes.TIPJAR_MEMBER_ERROR,
  payload: errorMsg,
});
const getTipJarGroupsStart = () => ({
  type: actionTypes.TIPJAR_GROUP_START,
});

const getTipJarGroupsSuccess = (results) => ({
  type: actionTypes.TIPJAR_GROUP_SUCCESS,
  payload: results,
});

const getTipJarGroupsError = (errorMsg) => ({
  type: actionTypes.TIPJAR_GROUP_ERROR,
  payload: errorMsg,
});
const getTipJarGroupStart = () => ({
  type: actionTypes.TIPJAR_INDIVIDUAL_GROUP_START,
});

const getTipJarGroupSuccess = (results) => ({
  type: actionTypes.TIPJAR_INDIVIDUAL_GROUP_SUCCESS,
  payload: results,
});

const getTipJarGroupError = (errorMsg) => ({
  type: actionTypes.TIPJAR_INDIVIDUAL_GROUP_ERROR,
  payload: errorMsg,
});
const postTipJarGroupsStart = () => ({
  type: actionTypes.TIPJAR_GROUP_CREATE_START,
});

const postTipJarGroupsSuccess = (results) => ({
  type: actionTypes.TIPJAR_GROUP_CREATE_SUCCESS,
  payload: results,
});

const postTipJarGroupsError = (errorMsg) => ({
  type: actionTypes.TIPJAR_GROUP_CREATE_ERROR,
  payload: errorMsg,
});
const postTipJarMembersStart = () => ({
  type: actionTypes.TIPJAR_MEMBER_CREATE_START,
});

const postTipJarMembersSuccess = (results) => ({
  type: actionTypes.TIPJAR_MEMBER_CREATE_SUCCESS,
  payload: results,
});

const postTipJarMembersError = (errorMsg) => ({
  type: actionTypes.TIPJAR_MEMBER_CREATE_ERROR,
  payload: errorMsg,
});
const deleteTipJarGroupStart = () => ({
  type: actionTypes.TIPJAR_GROUP_DELETE_START,
});

const deleteTipJarGroupSuccess = (results) => ({
  type: actionTypes.TIPJAR_GROUP_DELETE_SUCCESS,
  payload: results,
});

const deleteTipJarGroupError = (errorMsg) => ({
  type: actionTypes.TIPJAR_GROUP_DELETE_ERROR,
  payload: errorMsg,
});
const postTipjarMemberSelectedStart = () => ({
  type: actionTypes.TIPJAR_MEMBER_POST_SELECT_START,
});

const postTipjarMemberSelectedSuccess = (results) => ({
  type: actionTypes.TIPJAR_MEMBER_POST_SELECT_SUCCESS,
  payload: results,
});

const postTipjarMemberSelectedError = (errorMsg) => ({
  type: actionTypes.TIPJAR_MEMBER_POST_SELECT_ERROR,
  payload: errorMsg,
});
const tipjarTippingStart = () => ({
  type: actionTypes.TIPJAR_TIPPING_START,
});

const tipjarTippingSuccess = (results) => ({
  type: actionTypes.TIPJAR_TIPPING_SUCCESS,
  payload: results,
});

const tipjarTippingError = (errorMsg) => ({
  type: actionTypes.TIPJAR_TIPPING_ERROR,
  payload: errorMsg,
});
const tipjarCheckTippingStart = () => ({
  type: actionTypes.TIPJAR_CHECK_TIPPING_START,
});

const tipjarCheckTippingSuccess = (results) => ({
  type: actionTypes.TIPJAR_CHECK_TIPPING_SUCCESS,
  payload: results,
});

const tipjarCheckTippingError = (errorMsg) => ({
  type: actionTypes.TIPJAR_CHECK_TIPPING_ERROR,
  payload: errorMsg,
});

const getTipjarMemberSelectedStart = () => ({
  type: actionTypes.TIPJAR_MEMBER_GET_SELECT_START,
});

const getTipjarMemberSelectedSuccess = (results) => ({
  type: actionTypes.TIPJAR_MEMBER_GET_SELECT_SUCCESS,
  payload: results,
});

const getTipjarMemberSelectedError = (errorMsg) => ({
  type: actionTypes.TIPJAR_MEMBER_GET_SELECT_ERROR,
  payload: errorMsg,
});

export const tipjarActions = {
  getTipJarDataStart,
  getTipJarDataSuccess,
  getTipJarDataError,
  getTipJarMembersStart,
  getTipJarMembersSuccess,
  getTipJarMembersError,
  getTipJarGroupsStart,
  getTipJarGroupsSuccess,
  getTipJarGroupsError,
  postTipJarGroupsStart,
  postTipJarGroupsSuccess,
  postTipJarGroupsError,
  postTipJarMembersStart,
  postTipJarMembersSuccess,
  postTipJarMembersError,
  getTipJarGroupStart,
  getTipJarGroupSuccess,
  getTipJarGroupError,
  deleteTipJarGroupStart,
  deleteTipJarGroupSuccess,
  deleteTipJarGroupError,
  postTipjarMemberSelectedStart,
  postTipjarMemberSelectedSuccess,
  postTipjarMemberSelectedError,
  getTipjarMemberSelectedError,
  getTipjarMemberSelectedSuccess,
  getTipjarMemberSelectedStart,
  tipjarTippingStart,
  tipjarTippingSuccess,
  tipjarTippingError,
  tipjarCheckTippingStart,
  tipjarCheckTippingSuccess,
  tipjarCheckTippingError,
};
