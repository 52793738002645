import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import question from "../../assets/Icons/question_mark.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";

const TipperType = () => {
  const [tipperError, setTipperError] = useState();
  const { register, handleSubmit } = useForm();

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = async (data) => {
    console.log("form selection", data);
    if (data.tipperType === "no" || data.tipperType === "not now") {
      dispatch(balanceActions.setSaccoType(data.tipperType));
      navigate("/finalWithdrawal");
    } else if (data.tipperType === "yes") {
      dispatch(balanceActions.setSaccoType(data.tipperType));
      navigate("/onboardingSw");
    } else {
      setTipperError(true);
    }
  };
  const handleMoreInfo = () => {
    console.log("click");
  };

  const TipperError = () => (
    <div className="p-7 w-4/5 bg-white drop-shadow-md pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setTipperError(false);
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20 my-3" />
      <p className="justify-self-center	text-base text-center mx-8 py-2">
        Please select one of the three options
      </p>
    </div>
  );

  return (
    <div
      className={`${
        tipperError ? "relative" : ""
      } w-screen flex flex-col h-full`}
    >
      <div
        className={` ${
          tipperError ? "blur-sm" : ""
        } w-screen h-full flex flex-col px-5 bg-imag-dash`}
      >
        <div className={`flex w-full h-full flex-col text-black`}>
          <div className="flex flex-row justify-start mt-2">
            <img
              src={logo}
              alt="logo of shukran"
              className="rounded py-3 h-16 mt-16 mb-4"
            />
          </div>
          <div className="input-div flex flex-col	pt-4 h-full">
            <h2 className="text-2xl font-medium text-primary-pwa">
              Would you like to open a Shukran SACCO Account?
            </h2>
            <form
              className="flex flex-col justify-between h-full mb-8"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="flex flex-col mt-2">
                <div className="flex items-center bg-accent-light-2 border-b-2 border-gray rounded-t-md">
                  <label
                    htmlFor="field-yes"
                    className="text-sm font-medium text-gray-900 w-full flex justify-between my-6 mx-3"
                  >
                    Yes
                    <input
                      {...register("tipperType")}
                      id="field-yes"
                      type="radio"
                      value="yes"
                      className="h-4 w-4 border-accent focus:ring-1"
                    />
                  </label>
                </div>

                <div className="flex items-center bg-accent-light-2 border-b-2 border-gray rounded-b-md">
                  <label
                    htmlFor="field-no"
                    className="text-sm font-medium text-gray-900 w-full flex justify-between my-6 mx-3"
                  >
                    No
                    <input
                      {...register("tipperType")}
                      id="field-no"
                      type="radio"
                      value="no"
                      className="h-4 w-4 border-accent focus:ring-1"
                    />
                  </label>
                </div>
                <div
                  className="flex flex-row items-center justify-between bg-accent-light-2 border-2 border-gray rounded-md mt-4 p-5"
                  onClick={handleMoreInfo}
                >
                  <span className="text-sm">
                    Find out more about Shukran SACCO
                  </span>
                  <img
                    src={question}
                    alt="question mark"
                    className="rounded h-6 w-6"
                  />
                </div>
              </div>
              <button
                className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
                type="submit"
              >
                Proceed
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${
          tipperError ? "absolute blur-none" : "hidden"
        } flex justify-center items-center flex-col w-full h-full`}
      >
        <TipperError />
      </div>
    </div>
  );
};

export default TipperType;
