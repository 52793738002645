import { actionTypes } from "../user/user.actions";

const initialState = {
	isLoading: false,
	subscriptionRes: 0,
	postSubscriptionRes: [],
	errorMessage: ''
}

const subscriptionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
			case actionTypes.SUBSCRIPTION_START:
				return {
					...state,
					isLoading: true,
					errorMessage: '',
					subscriptionRes: [] 
				}
			case actionTypes.SUBSCRIPTION_SUCCESS:
				return {
					...state,
					isLoading: false,
					subscriptionRes: payload 
				}
			case actionTypes.SUBSCRIPTION_ERROR:
				return {
					...state,
					isLoading: false,
					errorMessage: payload,
				}
				case actionTypes.POST_SUBSCRIPTION_START:
					return {
						...state,
						isLoading: true,
						errorMessage: '',
						postSubscriptionRes: [] 
					}
				case actionTypes.POST_SUBSCRIPTION_SUCCESS:
					return {
						...state,
						isLoading: false,
						postSubscriptionRes: payload 
					}
				case actionTypes.POST_SUBSCRIPTION_ERROR:
					return {
						...state,
						isLoading: false,
						errorMessage: payload,
					}
		default:
			return state;
	}
}
export default subscriptionReducer;