import React from "react";
import { globalTheme } from "../../../globals/global_css/globalsTheme";

export default function PercSelector({ active, setActive, value }) {
  return (
    <div
      onClick={() => setActive(value)}
      className={`${
        active ? `bg-accent` : `bg-[#F2F2F2]`
      } perc w-20 h-12 rounded-3xl flex items-center justify-center`}
    >
      <p
        className={`${
          active ? `text-white` : `${globalTheme.global.descText}`
        }`}
        style={{ fontWeight: "700" }}
      >
        {`${value === "0" ? "No Tip" : `${value}%`}`}
      </p>
    </div>
  );
}
