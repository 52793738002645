import React, { useState } from "react";
import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";

export default function RegInput({
  setValue,
  setPassword,
  setIsFocused,
  setError,
  isFocused,
}) {
  const [showPass, setShowPass] = useState(false);

  return (
    <div className="flex flex-row">
      <input
        className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 w-full py-2 text-black placeholder:text-base"
        type={showPass ? "text" : "password"}
        placeholder={showPass ? "pass123" : "*******"}
        onChange={(e) => {
          let value = e.target.value;
          setValue("confirmpassword", value);
          setPassword(value);
          setError("password", "");
        }}
        onFocus={(e) => {
          if (e.currentTarget === e.target) {
            setIsFocused(!isFocused);
          }
        }}
        onBlur={(e) => {
          setIsFocused(!isFocused);
        }}
      />
      <div className="text-l -ml-6" onClick={() => setShowPass(!showPass)}>
        <img src={showPass ? eye : eyeClosed} alt="eye" className="h-6" />
      </div>
    </div>
  );
}
