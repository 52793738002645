import { actionTypes } from "./withdraw.actions";

const searchState = {
  isLoading: false,
  isTransactionLoading: false,
  isCheckTransaction: "",
  results: [],
  transactionStatus: [],
  errorMessage: "",
  errorTransactionStatusMessage: "",
  navigate: "",
};

const withdrawReducer = (state = searchState, { type, payload }) => {
  switch (type) {
    case actionTypes.WITHDRAW_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.WITHDRAW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isTransactionLoading: true,
        results: payload,
      };
    case actionTypes.WITHDRAW_ERROR:
      return {
        ...state,
        isLoading: false,
        // isCheckTransaction: "STOP",
        errorMessage: payload,
      };
    case actionTypes.CHECK_WITHDRAW_START:
      return {
        ...state,
        // isTransactionLoading: true,
        errorTransactionStatusMessage: "",
        transactionStatus: [],
      };
    case actionTypes.CHECK_WITHDRAW_SUCCESS:
      return {
        ...state,
        isTransactionLoading: false,
        // isCheckTransaction: "STOP",
        results: payload,
      };
    case actionTypes.CHECK_WITHDRAW_REDIRECT:
      return {
        ...state,
        navigate: payload,
      };
    case actionTypes.CHECK_WITHDRAW_STOP:
      return {
        ...state,
      };
    case actionTypes.CHECK_WITHDRAW_ERROR:
      return {
        ...state,
        isTransactionLoading: false,
        isLoading: false,
        results: {},
        // isCheckTransaction: "STOP",
        errorTransactionStatusMessage: payload,
      };
    default:
      return state;
  }
};
export default withdrawReducer;
