import React from "react";
import { RWebShare } from "react-web-share";
import PersonIcon from "../../assets/Icons/person_icon.svg";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";
import { useSelector } from "react-redux";

const Share = ({ text, title, setFallbackOption, inviteOption }) => {
  const canonical = document.querySelector("link[rel=canonical]");
  let url = canonical ? canonical.href : document.location.href;
  const shareDetails = { url, title, text };
  const userDetails = useSelector((state) => state.account.userDetails);
  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        setFallbackOption(true);
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      setFallbackOption(true);
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  return (
    <div>
      {inviteOption === "invite-tipper" ? (
        <li
          className={`flex flex-row justify-between items-center p-4 bg-accent-light-2 rounded-md border-2 mt-2 border-gray`}
          onClick={handleSharing}
        >
          <div className="flex flex-row items-center">
            <div className="py-1 px-2 text-3xl bg-[#64C8CE] rounded-full">
              🤝
            </div>
            <span className="ml-4">{"Send a Link"}</span>
          </div>
          <img src={RightIcon} alt="icon" />
        </li>
      ) : inviteOption === "invite-service" ? (
        <RWebShare
          data={{
            text: "Join me on Shukran, and experience banking the 21st century style",
            url: `https://app.shukran.co/registration?referrer=${userDetails?.shukranId}`,
            title: "Join Shukran SACCO",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button
            className="rounded-lg py-2 bg-primary-green text-white text-xl px-4 mt-10 mb-3"
            type="button"
            onClick={() => {}}
          >
            Click To Invite
          </button>
        </RWebShare>
      ) : (
        <div onClick={handleSharing} className="Small_Button">
          <div className="smallCircle bg-inactivated ">
            <img src={PersonIcon} alt="whatsapp-icon" className="h-10" />
          </div>
          <span className="test-sm mt-2 w-16 text-center">Invite a Friend</span>
        </div>
      )}
    </div>
  );
};

export default Share;
