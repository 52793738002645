// action Types
export const actionTypes = {
	SUBMIT_RATING_START: 'SUBMIT_RATING_START',
	SUBMIT_RATING_SUCCESS: 'SUBMIT_RATING_SUCCESS',
	SUBMIT_RATING_ERROR: 'SUBMIT_RATING_ERROR',
	GET_RATING_START: 'GET_RATING_START',
	GET_RATING_SUCCESS: 'GET_RATING_SUCCESS',
	GET_RATING_ERROR: 'GET_RATING_ERROR',
	GET_BUSINESS_RATING_START: 'GET_BUSINESS_RATING_START',
	GET_BUSINESS_RATING_SUCCESS: 'GET_BUSINESS_RATING_SUCCESS',
	GET_BUSINESS_RATING_ERROR: 'GET_BUSINESS_RATING_ERROR'
}

// actions
const submitRatingStart = () => ({
	type: actionTypes.SUBMIT_RATING_START
})

const submitRatingSuccess = (results) => ({
	type: actionTypes.SUBMIT_RATING_SUCCESS,
	payload: results
})

const submitRatingError = (errorMsg) => ({
	type: actionTypes.SUBMIT_RATING_ERROR,
	payload: errorMsg
})

const getRatingStart = () => ({
	type: actionTypes.GET_RATING_START
})

const getRatingSuccess = (results) => ({
	type: actionTypes.GET_RATING_SUCCESS,
	payload: results
})

const getRatingError = (errorMsg) => ({
	type: actionTypes.GET_RATING_ERROR,
	payload: errorMsg
})

const getBusinessRatingStart = () => ({
	type: actionTypes.GET_BUSINESS_RATING_START
})

const getBusinessRatingSuccess = (results) => ({
	type: actionTypes.GET_BUSINESS_RATING_SUCCESS,
	payload: results
})

const getBusinessRatingError = (errorMsg) => ({
	type: actionTypes.GET_BUSINESS_RATING_ERROR,
	payload: errorMsg
})

export const ratingActions ={
	submitRatingStart,
	submitRatingSuccess,
	submitRatingError,
	getRatingSuccess,
	getRatingStart,
	getRatingError,
	getBusinessRatingSuccess,
	getBusinessRatingStart,
	getBusinessRatingError
}