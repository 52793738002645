import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import "./members.css";
import { useDispatch } from "react-redux";
import {
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
  postTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { MoonLoader } from "react-spinners";
import { testUrl } from "../../helper";
import BackButton from "../globals/BackButton";
import { useForm } from "react-hook-form";
import { useToast } from "../../components-shadcn/ui/use-toast";
import { useMutation } from "@tanstack/react-query";
import { postJson } from "../../utils/fetch.utils";
import { BASE_URL } from "../../constants/network";
export default function Confirm() {
  const [members, setMembers] = useState();
  const [updatedembers, setNewMembers] = useState();
  const [doUpdate, setDoUpdate] = useState(false);
  const [grouppPerc, setGroupPerc] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tipjar = useSelector((state) => state.tipjar);
  const userAccount = useSelector((state) => state.account);
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorDetect, setErrorDetect] = useState(false);
  let hasError = false;
  useEffect(() => {
    if (doUpdate) {
      localStorage.setItem("members", JSON?.stringify(updatedembers));
      setMembers(updatedembers);
    }
  }, [updatedembers, doUpdate]);
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  const { toast } = useToast();
  // useEffect(() => {
  //   if (tipjar?.tipjarId) {
  //     dispatch(
  //       getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
  //     );
  //   }
  // }, [tipjar?.tipjarId]);
  // useEffect(() => {
  //   dispatch(getTipjarData({}, userAccount?.userDetails?.token));
  // }, [navigate, dispatch]);
  // useEffect(() => {
  //   dispatch(getTipjarMembers({}, userAccount.userDetails.token));
  // }, []);

  const addMembersMutation = useMutation({
    mutationFn: async (payload) => {
      const resp = await postJson(BASE_URL + "api/v1/tipjar/members/", payload);
      if (resp?.success) {
        toast({
          variant: "default",
          title: `Member${members?.length > 1 ? "s" : ""} Added successfully`,
        });
      } else {
        toast({
          variant: "destructive",
          title:
            resp?.msg ||
            `An error ocurred while trying to add member${
              members?.length > 1 ? "s" : ""
            } `,
        });
      }
      return resp.data;
    },
  });

  const createOneGroup = (member) => {
    return new Promise((resolve, reject) => {
      fetch(`${testUrl}tipjar/add-member/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userAccount.userDetails.token}`,
          "Content-type": "application/json",
          XAT: `U`,
          "X-IDT": `A`,
        },
        body: JSON.stringify({
          tipjar_id: tipjar.tipjarId,
          user_id: member?.id,
          group_id: grouppPerc[member?.id]?.id ? grouppPerc[member?.id]?.id : 0,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setTimeout(() => {
            if (res?.data === "success") {
              resolve(`Data for ${res?.data}`);

              if (members[members?.length - 1].id === member?.id) {
                localStorage.removeItem("members");
                navigate("/tipjar/members");
              }
              setErrorDetect(false);
              hasError = false;
            } else {
              reject(new Error("An error occurred"));

              hasError = true;
              setErrorDetect(true);
            }
          }, 1000);
          // if (res?.data === "success") {
          //   dispatch(getTipjarMembers({}, userAccount.userDetails.token));

          //   localStorage.removeItem("members");
          //   setErrorDetect(false);
          //   setTimeout(() => {
          //     navigate("/tipjar/members/");
          //   }, 1000);
          // } else {
          //   setErrorDetect(true);
          // }
        })
        .catch((err) => {
          setErrorDetect(true);
          hasError = true;
          if (err) {
            console.log(err, "check err");
            reject(new Error("An error occurred"));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };
  const createGroup = async (event) => {
    event.preventDefault();
    // setIsLoading(true);
    console.log(members);
    console.log(tipjar.tipjarId);
    const membersIds = members.map((user) => user?.id);

    addMembersMutation.mutate({
      group_id: selectedGroup?.id,
      user_ids: membersIds,
    });

    // for (let i = 0; i < members.length; i++) {
    //   let item = members[i];
    //   try {
    //     // Using Promise.all to handle multiple asynchronous POST requests
    //     const results = await createOneGroup(item);
    //     // Process the results array
    //     if (results?.length) {
    //       toast({
    //         variant: "default",
    //         title: "Member added successfully",
    //       });
    //     } else {
    //       toast({
    //         variant: "destructive",
    //         title: "Error adding member",
    //       });
    //     }
    //     console.log("results", results);
    //   } catch (error) {
    //     // Handle the error (stop further processing if needed)
    //     console.error("Error:", error.message);
    //     setErrorDetect(true);
    //   }
    // }
  };
  function getFromDuplicates(array1, array2, property) {
    const combinedArray = array1.concat(array2);
    //const { register, handleSubmit, formState } = useForm();
    // Use a Set to keep track of unique values based on the specified property
    const uniqueSet = new Set();
    const uniqueArray = combinedArray.filter((item) => {
      const key = item[property];
      if (!uniqueSet.has(key)) {
        uniqueSet.add(key);
        return true;
      }
      return false;
    });

    return uniqueArray;
  }
  useEffect(() => {
    const membersL = localStorage.getItem("members");
    //let temp = JSON.parse(membersL);
    let temp = JSON.parse(membersL);
    const filtered = tipjar?.members?.filter((member, index) => {
      if (temp && temp[index]?.id !== member?.user?.id) {
        return true;
      } else {
        return false;
      }
    });

    console.log(filtered, "filtered");
    setMembers(JSON?.parse(membersL));

    const unique = [];
    console.log(members);
    // members?.forEach((member) => {
    //   if (member.shukran_id !== ) {
    //     console.log(member);
    //   }
    // });
  }, []);
  const handleGroupChange = (e, member) => {
    // dispatch(
    //   postTipjarMembers({
    //     tipjar_id: tipjar.tipjarId,
    //     user_id: member?.id,
    //     percentage: member?.percentage,
    //     group_id: e.target.value,
    //   })
    // );
    // setCheckSuccess(true);
    setSelectedGroup(e.target.value);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (checkSuccess === true) {
        if (tipjar.memberPostSuccess === true) {
          toast.success("Member created Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.warning("Cannot add member, try again later!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, [checkSuccess]);
  useEffect(() => {
    dispatch(getTipjarData({}, userAccount.userDetails.token));
  }, []);
  useEffect(() => {
    if (tipjar.errorMessage === 403) {
      navigate("/login");
    }
  }, [tipjar]);
  useEffect(() => {
    dispatch(
      getTipjarGroups(tipjar?.tipjarId, userAccount?.userDetails?.token)
    );
  }, [tipjar?.tipjarId]);

  return (
    <form
      className="flex flex-col px-3 w-full h-screen mb-6"
      style={{ width: "100%" }}
      onSubmit={createGroup}
    >
      <div className="flex flex-col mt-9 w-full px-3" style={{ width: "100%" }}>
        {/* <div
          className={`flex flex-row  py-2 pr-6`}
          style={{ alignItems: "flex-start" }}
          onClick={() => handleBack()}
        >
          <img src={back} className="" alt="rate-star" />
        </div> */}
        <div className="w-screen p-0">
          <BackButton onPress={handleBack} />
        </div>
        <h2 className="text-3xl font-semibold text-primary-pwa mt-6">
          Confirm details for new members
        </h2>
      </div>
      <div
        className="mt-2
         w-full px-3"
        style={{ width: "100%", overflow: "scroll" }}
      >
        <div className="mb-3">
          {members?.map((member, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="mt-2"
            >
              <div
                className="border-2 border-gray w-3/4 h-65 mb-3"
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="w-full px-4"
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "15px",
                      backgroundColor: "#50A2A7 ",
                    }}
                  ></div>
                  <div className="ml-2">
                    <p style={{ color: "#404B5A", fontSize: "12px" }}>
                      {member?.first_name
                        ? `${member?.first_name} ${member?.last_name}`
                        : member?.name}
                    </p>
                    <p style={{ color: "#929FB1", fontSize: "10px" }}>
                      {member?.shukran_id && member?.shukran_id}
                    </p>
                  </div>
                </div>
              </div>
              <select
                className="border-2 border-gray w-1/3 h-65 mb-3 ml-2"
                onChange={(e) => {
                  grouppPerc[member?.id] = JSON.parse(e.target.value);
                  const groupDetails = JSON.parse(e.target.value);
                  setGroupPerc(grouppPerc);
                  setSelectedGroup(groupDetails);
                }}
                value={selectedGroup?.name}
                style={{
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  color: "#BCBCBC",
                  borderRadius: "10px",
                }}
              >
                <option value="">
                  {grouppPerc[member?.id]?.name
                    ? grouppPerc[member?.id]?.name
                    : "Select Group"}
                </option>
                {tipjar?.groups?.map((group) => (
                  <option key={group?.id} value={JSON.stringify(group)}>
                    {group?.name}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-2 mt-6">
        <button
          className="rounded-full py-3 bg-white px-3 text-accent border-2 border-accent text-m mx-auto mt-4 w-full"
          onClick={() => navigate(-1)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          type="button"
        >
          ADD NEW MEMBER &nbsp;
          <img src={add} className="add" alt="add member" />
        </button>
        {addMembersMutation.isPending ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="mt-3"
          >
            <MoonLoader size={24} color="#50A2A7" />
          </div>
        ) : (
          <button
            className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-full mb-2"
            type="submit"
          >
            SAVE MEMBER
          </button>
        )}
      </div>
      <ToastContainer />
    </form>
  );
}
