// action Types
export const actionTypes = {
  SEARCH_LOAD_START: "SEARCH_LOAD_START",
  SEARCH_LOAD_SUCCESS: "SEARCH_LOAD_SUCCESS",
  SEARCH_LOAD_ERROR: "SEARCH_LOAD_ERROR",
  UPDATE_SEARCH_QUERY: "UPDATE_SEARCH_QUERY",
};

// actions
const searchLoadStart = () => ({
  type: actionTypes.SEARCH_LOAD_START,
});
const updateSearchQuery = (query) => ({
  type: actionTypes.UPDATE_SEARCH_QUERY,
  payload: query,
});

const searchLoadSuccess = (results) => ({
  type: actionTypes.SEARCH_LOAD_SUCCESS,
  payload: results,
});

const searchLoadError = (errorMsg) => ({
  type: actionTypes.SEARCH_LOAD_ERROR,
  payload: errorMsg,
});

export const searchActions = {
  searchLoadStart,
  searchLoadSuccess,
  searchLoadError,
  updateSearchQuery,
};
