import React from "react";

export default function BackButton({ onPress }) {
  return (
    <div
      style={{ width: "80px", height: "40px", borderRadius: "12px" }}
      className="flex items-center mt-5 justify-center  bg-primary-pwa"
      onClick={() => onPress()}
    >
      <i
        style={{ fontSize: "18px" }}
        className="fa-solid fa-arrow-left text-white"
      ></i>
    </div>
  );
}
