import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import reducers from "./reducers/accountReducer.js";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage";

const loggerMiddleware = createLogger();

const middleware = [thunk];
const persistConfig = {
  key: "root",
  storage,
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// For Redux Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = (preloadedState) => {
  return createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
};
const storePersist = createStore(persistedReducer);
const persistor = persistStore(storePersist);
export { store, storePersist, persistor };
// applyMiddleware(thunk, logger)
