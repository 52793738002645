import React, { useState, useRef, useEffect } from "react";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowButtonNext.svg";
import ExclamationIcon from "../../assets/Icons/Tipping/exclamation_green.svg";
import EmojiOne from "../../assets/Icons/Tipping/emoji_one.svg";
import EmojiTwo from "../../assets/Icons/Tipping/emoji_two.svg";
import EmojiThree from "../../assets/Icons/Tipping/emoji_three.svg";
import EmojiFour from "../../assets/Icons/Tipping/emoji_four.svg";
import EmojiFive from "../../assets/Icons/Tipping/emoji_five.svg";
import EmojiSix from "../../assets/Icons/Tipping/emoji_six.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import Next from "../../assets/Icons/tipjar/accent_circle.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import Popup from "../popups/Popup";
import { useForm } from "react-hook-form";
import {
  getTipjarData,
  getTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import BackButton from "../globals/BackButton";

export default function TipjarAmount() {
  const [amounts] = useState([1000, 500, 200]);
  const [chezsonia] = useState([10000, 5000, 3000]);
  const [cabanas] = useState([1500, 1000, 500]);
  const [customAmountError, setCustomAmountError] = useState();
  const [isInsufficient, setIsInsufficient] = useState(false);
  const [emojiStage, setEmojiStage] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  // state for popup
  const [isClicked, setIsClicked] = useState(false);
  const [queryId, setQueryId] = useState("");
  const [queryName, setQueryName] = useState("");
  const receiverName = useSelector((state) => state.tipData.receiverName);
  const receiverLastName = useSelector(
    (state) => state.tipData.receiverLastName
  );
  const tipjar = useSelector((state) => state.tipjar);
  const userAccount = useSelector((state) => state.account);
  const tipAmount = useSelector((state) => state.tipData.tipAmount);
  const balanceData = useSelector((state) => state.balance);
  const balance = balanceData.balance;
  const nonShukranId = useSelector((state) => state.tipData.nonShukranId);
  const dispatch = useDispatch();
  const { addTipAmount, addQueryShukranId } = bindActionCreators(
    actions,
    dispatch
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});
  useEffect(() => {
    dispatch(getTipjarData({}, userAccount.userDetails.token));
    dispatch(getTipjarMembers({}, userAccount.userDetails.token));
  }, []);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    let paramShukranId = searchParams.get("shukranId");
    let paramName = searchParams.get("name");
    console.log("shukran id param", paramShukranId);
    console.log("name param", paramName);
    setQueryId(paramShukranId);
    setQueryName(paramName);
    return () => {};
  }, []);

  useEffect(() => {
    if (tipAmount) {
      setValue("customAmount", tipAmount);
    }
    return () => {};
  }, []);

  useEffect(() => {
    const subscription = watch((value, config) => {
      let customAmt = Number(value.customAmount);
      console.log(config, customAmt);
      if (config.name === "customAmount" && value.customAmount.length > 0) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
      if (config.name === "customAmount") {
        if ((customAmt >= 10) & (customAmt <= 100)) {
          setEmojiStage(1);
        } else if ((customAmt >= 101) & (customAmt <= 200)) {
          setEmojiStage(2);
        } else if ((customAmt >= 201) & (customAmt <= 300)) {
          setEmojiStage(3);
        } else if ((customAmt >= 301) & (customAmt <= 500)) {
          setEmojiStage(4);
        } else if ((customAmt >= 501) & (customAmt <= 1000)) {
          setEmojiStage(5);
        } else if (customAmt >= 1000) {
          setEmojiStage(6);
        } else {
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleBack = () => {
    navigate("/");
  };

  const handleNext = (data) => {
    if (data.customAmount > 0 && data.customAmount < tipjar?.tipjar?.balance) {
      addTipAmount(data.customAmount);
      if (queryId) {
        addQueryShukranId({
          query_shukran_id: queryId,
          query_name: queryName,
        });
        navigate("/tipjar/confirm");
      } else {
        navigate("/tipjar/confirm");
      }
    } else {
      dispatch(getTipjarData({}, userAccount.userDetails.token));
    }
  };

  const handleSetAmount = (amount) => {
    if (amount > balance) {
      setIsInsufficient(true);
    }
    let tipAmount = amount.toString();
    addTipAmount(tipAmount);
    if (queryId) {
      addQueryShukranId({
        query_shukran_id: queryId,
        query_name: queryName,
      });
      navigate("/tipjar/confirm");
    } else {
      navigate("/tipjar/confirm");
    }
  };

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceData.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (balanceData.errorMessage === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {
      // dispatch(balanceActions.tippingSuccess({}));
      // dispatch(searchActions.searchLoadSuccess([]));
      // addShukranId({});
      // addNonShukranId({})
    };
  }, [balanceData, navigate]);

  return (
    <div
      className={`${
        isInsufficient ? "relative items-center" : ""
      } w-screen flex flex-col`}
    >
      <div
        className={`
          ${isInsufficient ? "blur-sm" : ""}
          flex flex-col w-full px-4`}
      >
        {/* <DashBoardHeader /> */}
        {/* <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center py-2 pr-6`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
          {/* <span className="text-base">Go back home</span> 
        </div> */}
        <div className="w-screen p-0">
          <BackButton onPress={handleBack} />
        </div>
        <div className="bg-white flex flex-col ">
          <p className="mt-5 text-3xl font-semibold text-primary-pwa">
            How much in tips would you like to distribute
          </p>

          <form
            className="flex flex-col justify-between h-4/5 mt-5 pb-4"
            onSubmit={handleSubmit(handleNext)}
          >
            <div
              className={`flex flex-rowitems-center mt-12 p-2 h-14 w-full text-black border-2 relative${
                isFocused ? "border-primary-pwa" : "border-[#50A2A7]"
              } rounded-full`}
              style={{ backgroundColor: "rgba(108, 203, 209, 0.40)" }}
            >
              <input
                type="number"
                className="placeholder:text-base w-full outline-none ml-9 italic placeholder:italic"
                placeholder="Enter amount"
                {...register("customAmount", {
                  required: true,
                })}
                style={{ backgroundColor: "transparent" }}
              />
              <button type="submit" className="h-9 top-0 right-2">
                <img src={Next} className="ArrowButton" alt="Next Page" />
              </button>
            </div>
            {errors?.customAmount && (
              <p className="text-red-600 text-sm text-center">
                Ensure you have entered an amount
              </p>
            )}
          </form>
          <div className=" mt-4 rounded-md p-1 drop-shadow-md flex flex-row align-center justify-center">
            <p className="text-[#404B5A]" style={{ fontSize: "15px" }}>
              Tipjar Amount:{" "}
            </p>
            <p className="text-[#6CCBD1]">
              &nbsp;KES {Math.trunc(tipjar?.tipjar?.balance)}
            </p>
          </div>
          {amounts.length !== 0 ? (
            <ul className="mt-9 bg-white py-4 flex flex-col items-center w-full rounded-3xl m-0">
              <li>Or pre-select a percent below:</li>
              <li
                className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                onClick={() =>
                  handleSetAmount(Math.trunc(tipjar?.tipjar?.balance))
                }
              >
                <button
                  type="button"
                  className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                >
                  100% - KES. {Math.trunc(tipjar?.tipjar?.balance)}
                </button>
              </li>
              <li
                className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                onClick={() =>
                  handleSetAmount(Math.trunc(tipjar?.tipjar?.balance * 0.5))
                }
              >
                <button
                  type="button"
                  className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                >
                  50% - KES. {Math.trunc(tipjar?.tipjar?.balance * 0.5)}
                </button>
              </li>
            </ul>
          ) : (
            ""
          )}

          {customAmountError && (
            <div className="mx-auto mt-5 py-3 px-2 text-center rounded-md bg-red-200">
              <span className="text-black text-large font-bold">
                {customAmountError}
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${isInsufficient ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-96
        `}
      >
        <Popup
          setIsInsufficient={setIsInsufficient}
          setIsClicked={setIsClicked}
          isClicked={isClicked}
        />
      </div>
    </div>
  );
}
