import { actionTypes } from "./tipjar.actions";
import { saveToSessionStorage } from "../../../Services/Storage";
const resetState = {
  isLoading: false,
  tipjar: {},
  members: [],
  groups: [],
  selected: [],
  group: {},
  errorMessage: "",
  tipjargroupResponse: {},
  tipjarMemberResponse: {},
  tipjarId: "",
  memberPostSuccess: false,
  isTransactionSuccess: false,
  transactionResponse: {},
  tippingResponse: {},
  errorTransactionStatusMessage: "",
};

const getTipJarInfo = (state = resetState, { type, payload }) => {
  switch (type) {
    case actionTypes.TIPJAR_DATA_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.TIPJAR_DATA_SUCCESS:
      saveToSessionStorage("tipjar", payload);
      return {
        ...state,
        isLoading: false,
        tipjar: payload,
        // isChangePassLoading: true
      };
    case actionTypes.TIPJAR_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_MEMBER_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
        tipjarId: "",
      };
    case actionTypes.TIPJAR_MEMBER_SUCCESS:
      saveToSessionStorage("tipjar_members", payload.members);
      return {
        ...state,
        isLoading: false,
        members: payload.members,
        tipjarId: payload.id,
        // isChangePassLoading: true
      };
    case actionTypes.TIPJAR_MEMBER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_GROUP_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.TIPJAR_GROUP_SUCCESS:
      saveToSessionStorage("tipjar_groups", payload);
      return {
        ...state,
        isLoading: false,
        groups: payload,
        // isChangePassLoading: true
      };
    case actionTypes.TIPJAR_GROUP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_INDIVIDUAL_GROUP_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.TIPJAR_INDIVIDUAL_GROUP_SUCCESS:
      saveToSessionStorage("tipjar_group", payload);
      return {
        ...state,
        isLoading: false,
        group: payload,
        // isChangePassLoading: true
      };
    case actionTypes.TIPJAR_INDIVIDUAL_GROUP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_GROUP_CREATE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        tipjargroupResponse: [],
      };
    case actionTypes.TIPJAR_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tipjargroupResponse: payload,
      };
    case actionTypes.TIPJAR_GROUP_CREATE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_MEMBER_CREATE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        tipjarMemberResponse: [],
      };
    case actionTypes.TIPJAR_MEMBER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tipjarMemberResponse: payload,
        memberPostSuccess: true,
      };
    case actionTypes.TIPJAR_MEMBER_CREATE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        memberPostSuccess: false,
      };
    case actionTypes.TIPJAR_GROUP_DELETE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        tipjarMemberResponse: [],
      };
    case actionTypes.TIPJAR_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberPostSuccess: true,
      };
    case actionTypes.TIPJAR_GROUP_DELETE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        memberPostSuccess: false,
      };
    case actionTypes.TIPJAR_MEMBER_GET_SELECT_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        selected: [],
      };
    case actionTypes.TIPJAR_MEMBER_GET_SELECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: payload,
      };
    case actionTypes.TIPJAR_MEMBER_GET_SELECT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        selected: [],
      };
    case actionTypes.TIPJAR_MEMBER_POST_SELECT_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        selected: [],
      };
    case actionTypes.TIPJAR_MEMBER_POST_SELECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: payload,
      };
    case actionTypes.TIPJAR_MEMBER_POST_SELECT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        selected: [],
      };
    case actionTypes.TIPJAR_TIPPING_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: {},
        tippingResponse: [],
      };
    case actionTypes.TIPJAR_TIPPING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isTransactionLoading: payload?.id ? true : false,
        tippingResponse: payload,
        isTransactionSuccess: false,
      };
    case actionTypes.TIPJAR_TIPPING_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_CHECK_TIPPING_START:
      return {
        ...state,
        errorTransactionStatusMessage: "",
        transactionStatus: [],
        transactionResponse: [],
      };
    case actionTypes.TIPJAR_CHECK_TIPPING_SUCCESS:
      return {
        ...state,
        isTransactionLoading: false,
        transactionResponse: payload,
      };
    case actionTypes.TIPJAR_CHECK_TIPPING_ERROR:
      return {
        ...state,
        isLoading: false,
        isTransactionLoading: false,
        isLoading: false,
        tippingResponse: [],
        transactionResponse: [],
        errorTransactionStatusMessage: payload,
      };
    default:
      return state;
  }
};
export default getTipJarInfo;
