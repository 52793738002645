import React from "react";

const Div = ({ children, className, status, type }) => {
  return (
    <div
      className={`${
        className
          ? className
          : `w-10 h-10 ${
              status === "Success" && type !== "Debit"
                ? status === "Pending"
                  ? "bg-[#F2C773]"
                  : "bg-[#6CD1A1]"
                : type === "Debit"
                ? "bg-[#6CCBD1]"
                : "bg-[#FFF]"
            } flex justify-center items-center rounded-full`
      }`}
    >
      {children}
    </div>
  );
};

export default Div;
