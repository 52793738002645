import React from "react";
import PageImage from "../../../assets/Images/onboarding/getLoans.svg";
import BaseOnboard from "../BoilerPlate/BaseOnboard";

export default function onboardingPage() {
  const textContent =
    "Get access to low-interest loans to support your development";
  const contentTitle = "GET LOANS";

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/receiveDividends"
      navigatePrev="/saveTips"
    />
  );
}
