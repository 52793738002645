import axios from "axios";
import { testUrl } from "../helper";

// let sampleUrl = 'https://a8a4-105-163-2-226.ngrok.io/'
// let config = {
// headers: {
// 	"Authorization": `Bearer ${getFromSessionStorage('token')}`,
// }
// }

class SearchService {
  getSearchResults = (params, token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    console.log("search", params);
    return axios.get(`${testUrl}accounts/auth/search/?shukran_id=${params}`);
  };
  getMoreSearchResults = (params, token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    let parsedParam = params.split("/accounts/")[1];
    parsedParam = testUrl + "accounts/" + parsedParam;
    console.log("split param", parsedParam);
    return axios.get(`${parsedParam}`);
  };
  getBusinessResults = (param, token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${testUrl}businesses/?name=${param}`);
  };
  getUserBusiness = (token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${testUrl}businesses/service-worker/`);
  };
  updateServiceWorkerBusiness = (param, token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.post(`${testUrl}businesses/service-worker/`, param);
  };
  // getBusinessResults = () => axios.get('https://e7a9-41-80-98-115.ngrok.io/businesses/', {}, config)
} //https://e7a9-41-80-98-115.ngrok.io/

export default new SearchService();
