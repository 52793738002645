import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logos/shukran-logo-dark.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import fourOfFive from "../../assets/Illustrations/sacco/fourOfFive.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import { anonActivateSacco, postActivateSacco } from "../../state/reducers/user/user.thunk";

const EighthLearnUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showIdField, setShowIdField] = useState(true);
  const [showOtherField, setShowOtherField] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const saccoState = useSelector((state) => state.sacco);
  const userAccount = useSelector((state) => state.account);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({});

  useEffect(() => {
    setShowIdField(true);
    setShowOtherField(false);
    return () => {};
  }, []);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
    ? localStorage.getItem("origin")
    : "false";
    originState = JSON.parse(originState)
    if (saccoState.errorMessage?.status === 403  && !originState) {
      dispatch(saccoActions.saccoActivateError(""));
      navigate("/");
    }
    return () => {};
  }, [saccoState]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
      if (
        name === "referralType" &&
        (value.referralType === "sacco-member" ||
          value.referralType === "shukran-staff")
      ) {
        setShowIdField(true);
        setShowOtherField(false);
      }
      if (
        name === "referralType" &&
        (value.referralType === "friend" ||
          value.referralType === "family" ||
          value.referralType === "social-media")
      ) {
        setShowIdField(false);
        setShowOtherField(false);
      }
      if (name === "referralType" && value.referralType === "other") {
        setShowOtherField(true);
        setShowIdField(false);
      }
    });
    return () => {
      subscription.unsubscribe();
      setShowIdField(true);
    };
  }, [watch]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      setValue("referralType", saccoState.saccoDetails.referral_type);
      setValue(
        "staffNumber",
        saccoState?.saccoDetails?.referral_code !== "null"
          ? saccoState?.saccoDetails?.referral_code
          : ""
      );
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (saccoState.results.success && isNext) {
      // navigate("/requirements");
      navigate("/ninthForm");
      dispatch(saccoActions.saccoActivateSuccess({}));
      setIsNext(false);
    }
    return () => {};
  }, [saccoState, isNext, dispatch, navigate]);

  const handleFirstPersonalInfo = (data) => {
    console.log("sacco details submit", data);
    let payload = {
      referral_type: data.referralType,
      referral_code: data.staffNumber,
      other_referral: "",
    };
    dispatch(saccoActions.updateSaccoDetails(payload));
    setIsNext(true);
    handleSavePartially(data);
  };

  const handleSavePartially = (data) => {
    let originState = localStorage.getItem("origin")
    ? localStorage.getItem("origin")
    : "false";
    originState = JSON.parse(originState)
    let formDetails = saccoState.saccoDetails;
    let payload = {
      ...formDetails,
      business_address: saccoState.saccoDetails.business_address
          ? saccoState.saccoDetails.business_address.split(" ",4) : '',
      // nominees: JSON.stringify(formDetails.nominees),
      referral_type: data.referralType,
      referral_code: data.staffNumber,
      other_referral: "",
      kyc_stage: 8
    };
    let payloadData = new FormData();
    for (const key in payload) {
      if (
        key === "kra_pin_photo" ||
        key === "passport_photo" ||
        key === "id_passport_photo" ||
        key === "e_signature_photo" ||
        key === "id_passport_back_photo"
      ) {
        // payloadData.append(key, payload[key][0]?.file);
      } else if (
        key.includes("primary_") ||
        key.includes("dob") ||
        key === "guardian_mn" ||
        key === "user_count" ||
        key === "is_under_eighteen"
      ) {
        console.log("primary", key);
      } else {
        payloadData.append(key, payload[key]);
      }
    }
    console.log("form details", payload);
    if (originState) {
      dispatch(anonActivateSacco(payloadData));
    } else {
      dispatch(postActivateSacco(payloadData, userAccount.userDetails.token)); 
    }
  };

  const SaccoActivationError = () => (
    <div
      className={`my-auto mb-28 px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions?.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState?.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState?.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState?.errorMessage?.msg}
        </p>
      )}
      {saccoState?.errorMessage?.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage.data.data)[0]}
        </p>
      )}
      {saccoState.errorMessage?.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {isNext ? (
        <></>
      ) : (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(saccoActions.saccoActivateSuccess({}));
            navigate("/");
          }}
        >
          X
        </button>
      )}
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation details have been partially submitted"
          : ""}
      </p>
      {isNext ? (
        <></>
      ) : (
        <button
          className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
          type="submit"
          onClick={() => {
            dispatch(saccoActions.saccoActivateSuccess({}));
            navigate("/");
          }}
        >
          Home
        </button>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${isLoading ? "relative" : ""} w-screen flex flex-col h-screen
        ${saccoState.isLoading ? "relative" : ""}
        ${saccoState.results.success ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""} 
      `}
    >
      <div
        className={`
          ${saccoState.isLoading ? "blur-sm" : ""}
          ${saccoState.errorMessage ? "blur-sm" : ""}
          ${isLoading ? "blur-sm" : ""}
          ${saccoState.results.success ? "blur-sm" : ""}
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={fourOfFive}
            alt="seven of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            How did you learn about Shukran
          </h2>
          <p className="text-base my-1">
            This details will be used for your SACCO account and linked to your
            Shukran account.
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-5"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Referral type *
                </label>
                <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full py-2 pr-20 text-black outline-none"
                  {...register("referralType", {
                    required: true,
                  })}
                  placeholder="Shukran Staff"
                >
                  <option value="shukran-staff" className="w-10/12">
                    Shukran Staff
                  </option>
                  <option value="social-media" className="w-10/12">
                    Social Media
                  </option>
                  <option value="sacco-member" className="w-10/12">
                    Sacco Member
                  </option>
                  <option value="friend" className="w-10/12">
                    Friend
                  </option>
                  <option value="family" className="w-10/12">
                    Family
                  </option>
                  <option value="other" className="w-10/12">
                    Other
                  </option>
                </select>
                {errors.referralType && (
                  <p className="text-red-600 text-sm">
                    Please select an option
                  </p>
                )}
              </div>
              {showIdField && (
                <div className="flex flex-col mt-1">
                  <label className="mt-2 text-label text-xs">
                    Member ID / Staff Number *
                  </label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="3103XXX"
                    {...register("staffNumber", {
                      required: true,
                    })}
                  />
                  {errors.staffNumber && (
                    <p className="text-red-600 text-sm">
                      Please enter a member / staff ID
                    </p>
                  )}
                </div>
              )}
              {showOtherField && (
                <div className="flex flex-col mt-1">
                  <label className="mt-2 text-label text-xs">
                    Please Specify *
                  </label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:italic placeholder:pl-2 placeholder:text-sm w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="learnt about shukran at an event"
                    {...register("staffNumber", {
                      required: true,
                    })}
                  />
                  {errors.staffNumber && (
                    <p className="text-red-600 text-sm">Please specify other</p>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-row justify-between my-2 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex flex-col-reverse items-center w-full h-full
        `}
      >
        <div className="bg-white mb-28 py-16 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex flex-col-reverse items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex flex-col-reverse items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
    </div>
  );
};

export default EighthLearnUs;
