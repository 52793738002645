import React, { useEffect } from "react";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";
import PlayStoreIcon from "../../assets/Icons/Tipping/playstore.svg";
import IosIcon from "../../assets/Icons/Tipping/ios.svg";
import NewApp from "../../assets/Images/download.png";
import logo from "../../assets/Images/logoU.png";
import rate from "../../assets/Images/rating.png";

const DownloadShukran = () => {
  const handleSetOption = (otpGroup) => {};

  return (
    <div className="bg-blue-50 flex flex-col items-center h-screen overflow-scroll  w-full ">
      <div className="px-8 w-full lg:w-1/4 xl:w-1/4 h-1/2">
        <img src={NewApp} style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="w-full flex item-center justify-center mt-10">
        <a
          href="https://bit.ly/GetShukranTip"
          className="flex item-center justify-center"
          style={{ width: "100%", height: "50px" }}
          target="_blank"
        >
          <button
            className="bg-amber-500 text-white rounded-full "
            style={{ width: "90%", height: "50px", fontWeight: "bold" }}
          >
            GET THE APP
          </button>
        </a>
      </div>
      <div className="mt-2 h-1/2 w-full px-8  flex-col">
        <h4 className="text-teal-800 mt-10 font-bold text-2xl md:text-3xl lg:text-10xl xl:text-5xl">
          Transaction successful, Download Shukran Tip!
        </h4>
        <p className="flex justify-start flex-start text-2xl mt-4 font-bold">
          Our new app features:
        </p>
        <p className="flex font-bold item-center mt-6">
          <p className="text-amber-500 mr-2">&#x2713;</p> Secure & faster log-in
        </p>
        <p className="flex font-bold item-center mt-6">
          <p className="text-amber-500 mr-1">&#x2713;</p> Seamless user
          experience
        </p>
        <p className="flex font-bold item-center mt-6">
          <p className="text-amber-500 mr-1">&#x2713;</p> Bugs improvement
        </p>
      </div>
      <div className="w-full flex item-center  flex-row px-8 mt-8 mb-8">
        <div
          className="border-b border-gray-200 flex item-center  flex-row"
          style={{ width: "100%" }}
        >
          <div
            className=" bg-white flex item-center justify-center align-center p-2"
            style={{ width: "80px", height: "80px", borderRadius: "15px" }}
          >
            <img src={logo} style={{ width: "60px", height: "60px" }} />
          </div>
          <div className="flex flex-col item-center ml-3 ">
            <h4 className="text-teal-800 font-bold ml-4 text-2xl md:text-3xl lg:text-10xl xl:text-5xl">
              Shukran Tip
            </h4>
            <img src={rate} style={{ width: "180px", height: "60px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadShukran;
