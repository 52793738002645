import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useForm } from "react-hook-form";
import actions from "../../state/index";
import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import popup from "../../assets/Icons/Popups/check_circle.svg";

import back from "../../assets/Icons/Rating/back_icon.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { depositAmount, sendTip } from "../../state/reducers/user/user.thunk";
import { searchActions } from "../../state/reducers/search/search.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { depositActions } from "../../state/reducers/deposit/deposit.actions";

export default function DepositMpesa() {
  const [phoneNo, setPhoneNo] = useState("");
  const [showHelpMsg, setShowHelpMsg] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { addNonShukranId, addShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  // fetch information from tipping data
  const tipData = useSelector((state) => state.tipData);

  let receiverShukranId = tipData.shukranId;
  let amount = tipData.tipAmount;
  let receiverName = tipData.receiverName;
  let nonShukranId = tipData.nonShukranId;

  const tippingState = useSelector((state) => state.tipping);

  const depositData = useSelector((state) => state.account.depositedAmount);
  const depositState = useSelector((state) => state.deposit);

  const userData = useSelector((state) => state.account.userDetails);
  let searchState = useSelector((state) => state.search);
  const senderData = useSelector((state) => state.account.userDetails);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  // values froom react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  // user input handler
  useEffect(() => {
    console.log("isLoading", searchState.isLoading);
    return () => {};
  }, [searchState.isLoading]);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceState.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (depositState.errorMessage.status === 403) {
      console.log("forbidden deposit");
      navigate("/login");
    }
    return () => {};
  }, [balanceState, depositState, navigate]);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    return () => {};
  }, [searchState]);

  useEffect(() => {
    if (depositState.navigate.navigate === "/") {
      navigate("/");
      dispatch(depositActions.checkTransactionRedirect(""));
    }
    return () => {};
  }, [depositState]);

  const handlePhone = (data) => {
    console.log("deposit mpesa no submit", data);
    let phoneStr = data.phoneNumber;
    phoneStr = "254" + phoneStr.slice(1);
    setPhoneNo(phoneStr);
  };
  useEffect(() => {
    // setPhoneNo(userData.depositNumber);
    let phoneStr = userData.depositNumber;
    phoneStr = "0" + phoneStr.slice(3);
    if (
      /(\+?254|0|^){1}[-. ]?[7]{1}([0-2]{1}[0-9]{1}|[9]{1}[0-2]{1})[0-9]{6}\z/.test(
        phoneStr
      )
    ) {
      setValue("phoneNumber", phoneStr);
    }
    return () => {};
  }, []);

  const handleDeposit = () => {
    console.log("userData.token", depositData);
    let payload = {
      shukran_id: userData.shukranId,
      amount: depositAmount.amount,
      // amount: Number(10),
      phone: phoneNo,
      token: userData.token,
    };
    localStorage.setItem("depositNumber", phoneNo);
    dispatch(depositAmount(payload, userData.token));
  };

  const ConfirmDepositPopup = () => (
    <div className="shadow py-8 px-6 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg">Confirm Deposit</span>
        <div
          onClick={() => {
            setPhoneNo("");
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">From</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">{phoneNo}</span>
          </div>
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">To</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">
              Sacco Account ~ {userData.shukranId}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4 h-12">
        <label className="text-subtle text-sm">Deposit Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-lg text-black">
              Ksh {depositData ? depositData.amount : 500}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-8">
        {!depositState.results.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            disabled={!depositState.isLoading ? false : true}
            onClick={() => handleDeposit()}
          >
            PAY
          </button>
        )}
      </div>
    </div>
  );

  const DepositTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(depositActions.depositLoadStart({}));
          dispatch(depositActions.checkTransactionError(""));
          // addShukranId({})
          navigate("/");
        }}
      >
        X
      </button>
      {depositState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1`}
        />
      )}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-32" />
      )}
      {(depositState.errorTransactionStatusMessage ||
        depositState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-32" />
      )}
      {depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          Input your PIN on the Mpesa prompt to make a deposit
        </p>
      )}
      {/* {depositState.results.success && depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )} */}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )}
      {depositState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Deposit transaction{" "}
          {depositState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${depositState.errorMessage.success ? "relative" : ""}
      ${depositState.errorMessage.error ? "relative" : ""}
      ${depositState.isLoading ? "relative" : ""}
      ${depositState.results.success ? "relative" : ""}
      ${depositState.errorTransactionStatusMessage ? "relative" : ""}  ${
        phoneNo ? "relative" : ""
      } w-full flex flex-col
          `}
    >
      <div
        className={`
          ${depositState.errorMessage.success ? "blur-sm" : ""}
          ${depositState.errorMessage.error ? "blur-sm" : ""}
          ${depositState.results.success ? "blur-sm" : ""}
          ${depositState.isLoading ? "blur-sm" : ""}
          ${depositState.errorTransactionStatusMessage ? "blur-sm" : ""} 
           ${phoneNo ? "blur-sm" : ""}
          w-full flex flex-col px-3`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="mx-2 mt-11 h-full flex flex-col justify-between">
          <form onSubmit={handleSubmit(handlePhone)}>
            <div className="flex flex-col">
              <span className="text-3xl font-semibold text-primary-pwa">
                Mpesa phone number
              </span>
              <span className="text-base font-semibold ">
                Please confirm your mpesa phone number for deposit
              </span>

              <label className="mt-8 text-[#929FB1] text-sm italic">
                Phone Number{" "}
              </label>
              <input
                className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12placeholder:text-base  w-full py-2 text-black"
                type="number"
                placeholder="0700000000"
                {...register("phoneNumber", {
                  required: true,
                  // maxLength: 15,
                  // pattern:
                  //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                })}
              />
              {errors.phoneNumber && (
                <p className="text-red-600 text-sm">
                  Phone number required
                </p>
              )}
            </div>
            <div className="w-full flex flex-row-reverse items-center">
              <div onClick={() => setShowHelpMsg(!showHelpMsg)}>
                <img src={QuestionIcon} className="m-2" alt="question-mark" />
              </div>
            </div>
            {showHelpMsg && (
              <div className="mt-3 flex flex-col py-5 px-5 bg-[#F3F0FF] rounded-sm">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-base">How does it work</span>
                </div>
                <span className="text-sm mt-2">
                  You will receive an STK Push anytime you want to deposit when
                  you choose the Mpesa option
                </span>
              </div>
            )}
            <button
              className="rounded-full py-3 bg-accent px-3 text-white text-md mx-auto mt-24 mb-12 w-full"
              type="submit"
            >
              PROCEED
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          phoneNo && !depositState.isLoading && !depositState.results.success
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <ConfirmDepositPopup />
      </div>
      <div
        className={`${
          depositState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            An Mpesa prompt will appear on your phone shortly please to input
            pin to complete transaction
          </span>
        </div>
      </div>
      <div
        className={`${
          depositState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          depositState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      {/* <div
        className={`${
          depositState.results.success ? "absolute blur-none" : "hidden"
        } w-full flex flex-col justify-center items-center`}
      >
        <div className="shadow pb-8 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
          <div
            className="self-end mt-4 mr-4"
            onClick={() => {
              dispatch(balanceActions.transactionLoadSuccess({}));
              // dispatch(searchActions.searchLoadSuccess([]));
              addShukranId({});
              addNonShukranId({});
              navigate("/");
            }}
          >
            <img src={cross} alt="cross-icon" className="h-6" />
          </div>
          <img src={popup} alt="error-img" className="h-32 mt-8" />
          <h2 className="text-center text-black	text-lg mt-10">
            Payment successful!
          </h2>
          {nonShukranId ? (
            <p className="text-base text-primary text-center mt-3 bg px-8">
              {`Ksh. ${tipData.tipAmount} sent to ${tipData.nonShukranId}`}
            </p>
          ) : (
            <p className="text-base text-primary text-center mt-3 bg px-8">
              {`Ksh. ${tipData.tipAmount} sent to ${
                tipData.receiverName + " " + tipData.receiverLastName
              }`}
            </p>
          )}
        </div>
      </div> */}
      <div
        className={`${
          depositState.errorMessage.success || depositState.errorMessage.error
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10 drop-shadow-lg w-4/5 flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(
                depositActions.depositLoadError({
                  error: false,
                  msg: "",
                  data: "",
                })
              );
              addNonShukranId({});
              addShukranId({});
              navigate("/");
            }}
          >
            X
          </button>
          <img src={NotFoundIcon} alt="error-img" className="h-12" />

          <h2
            className={
              depositState.errorMessage.msg || depositState.errorMessage.data
                ? "text-center font-bold text-lg mt-2"
                : "hidden"
            }
          >
            Deposit Error
          </h2>
          <p
            className={
              depositState.errorMessage.msg || depositState.errorMessage.error
                ? "text-lg text-center py-1"
                : "hidden"
            }
          >
            {depositState.errorMessage.msg}
          </p>
        </div>
      </div>
    </div>
  );
}
