import React from "react";

export default function Desc({ text, fontSize, fontWeight, color }) {
  return (
    <p
      className=""
      style={{
        color: color ? color : "#A0ABBB",
        fontSize: fontSize ?? "10px",
        fontWeight: fontWeight ?? "400",
        textAlign: "center",
      }}
    >
      {text}
    </p>
  );
}
