import moment from "moment";
import offStar from "../../../assets/Icons/Rating/off_star.svg";
import onStar from "../../../assets/Icons/Rating/on_star.svg";

const ReviewCard = (props) => {
  const review = props.data;
  // const propKey = props.uniqKey;
  const initials = review.rater_id?.split("", 2);
  const date = moment(review.created_at).format("D/M/Y");

  const StarRating = ({ count }) => {
    return Array(count)
      .fill(1)
      .map((_, i) => i + 1)
      .map((idx, i) => (
        <div key={i}>
          <img src={onStar} alt="Star" className="h-3 w-3" />
        </div>
      ));
  };
  const NonRating = ({ count }) => {
    return Array(5 - count)
      .fill(1)
      .map((_, i) => i + 1)
      .map((idx, i) => (
        <div key={i}>
          <img src={offStar} alt="Star" className="h-3 w-3" />
        </div>
      ));
  };
  return (
    <div className="flex flex-row items-start bg-accent-light-2 rounded-lg my-1 p-2">
      <div className="flex flex-col justify-start items-start w-12">
        <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
          <p className="text-white text-xs">{initials}</p>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col">
            <p className="text-sm text-header">{review.rated_name}</p>
            <p className="text-xs py-1 text-slate-300">{review.rater_id}</p>
          </div>
          <div className="flex flex-col items-end">
            <div className="text-sm text-header flex flex-row">
              <StarRating count={review.rating_level} />
              <NonRating count={review.rating_level} />
            </div>
            <p className="text-xs py-1 text-slate-300">{date}</p>
          </div>
        </div>
        <div>
          <p className="text-sm text-slate-400 italic"> {review.comment} </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
