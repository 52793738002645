import { actionTypes } from "../user/user.actions";

const initialState = {
  isLoading: false,
  transactions: 0,
  disbursements: [],
  errorMessage: "",
  cardTransactions: "",
};

const transactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.TRANSACTION_LOAD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        transactions: [],
      };
    case actionTypes.TRANSACTION_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactions: payload,
      };
    case actionTypes.TRANSACTION_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TRANSACTION_TIPJAR_LOAD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        disbursements: [],
      };
    case actionTypes.TRANSACTION_TIPJAR_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        disbursements: payload,
      };
    case actionTypes.TRANSACTION_TIPJAR_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.CARD_TRANSACTION_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        cardTransactions: [],
      };
    case actionTypes.CARD_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardTransactions: payload,
      };
    case actionTypes.CARD_TRANSACTION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
export default transactionReducer;
