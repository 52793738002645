import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import actions from "../../state/index";
import { useForm } from "react-hook-form";

import successIcon from "../../assets/Icons/Rating/success.svg";
import clearIcon from "../../assets/Icons/Rating/clear.svg";
import searchIcon from "../../assets/Icons/Rating/search.svg";
import ExclamationIcon from "../../assets/Icons/Tipping/exclamation.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";

import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";

import {
  loadMoreSearchResults,
  loadSearchResults,
} from "../../state/reducers/search/search.thunk";
import { searchActions } from "../../state/reducers/search/search.actions";
import { testUrl } from "../../helper";
import { debounce } from "lodash";
import axios from "axios";
import BackButton from "../globals/BackButton";

const API_URL = testUrl + "accounts/auth/search/?shukran_id=";
const DEBOUNCE = 1000;

const searchFun = (queryParam, dispatch, token) => {
  // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}${queryParam}`)
    .then((res) => {
      console.log("searchFun res", res.data);
      dispatch(searchActions.searchLoadSuccess(res.data));
      // setResults(res.data.data);
    })
    .catch((err) => {
      let errorMsg = "";
      if (err.response.status === 403) {
        errorMsg = err.response.status;
      } else {
        errorMsg = err.response.data;
      }
      console.log("errorMsg", errorMsg);
      dispatch(searchActions.searchLoadError(errorMsg));
    });
};

/**
 * This is the debounced function that we will run once the user hit a key
 * lodash debounce return a function, that can be invoked any time
 * this function takes a function, searchFunction, and a debounce time
 * in this way we guarantee that the we only fetch after certain time and we don't spam the api with calls every time the user hits a key
 */
const debouncedSearch = debounce(searchFun, DEBOUNCE);

export default function Tip() {
  const [defaultView] = useState(true);
  const [errorView] = useState();
  const [searchPhone, setSearchPhone] = useState(true);
  const [searchId, setSearchId] = useState("");
  const [redirectTo, setRedirectTo] = useState("");

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { addShukranId, addNonShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  let userState = useSelector((state) => state.account.userDetails);
  const nonShukranId = useSelector((state) => state.tipData.nonShukranId);

  // user input handler
  const [searchParams] = useSearchParams();
  useEffect(() => {
    console.log("isLoading", searchState.isLoading);
    const redirect = searchParams.get("redirect");
    setRedirectTo(redirect);
    return () => {};
  }, [searchState.isLoading]);

  useEffect(() => {
    if (nonShukranId) {
      setValue("phoneNumber", nonShukranId);
    } else if (searchState.searchId) {
      setSearchId(searchState.searchId);
      setSearchPhone(false);
    } else {
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    return () => {};
  }, [searchState, dispatch, navigate]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        type === "change" &&
        name === "phoneNumber" &&
        searchState.errorMessage?.msg
      ) {
        console.log(value, name, type);
        dispatch(searchActions.searchLoadError(""));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, searchState, dispatch]);

  const handleBack = () => {
    navigate(-1);
  };

  // handle tip receiver
  const handleSelectRecipient = (payload) => {
    console.log("tip receiver", payload);
    addNonShukranId("");
    addShukranId(payload);
    dispatch(searchActions.updateSearchQuery(searchId));
    if (redirectTo === "bill") {
      navigate(`/tip?redirect=bill`);
    } else {
      navigate("/tip");
    }
  };

  const handlePhoneProceed = (data) => {
    console.log("handlePhoneProceed submit", data);
    let userPhone = userState.phoneNumber;
    userPhone = userPhone.substring(3);
    let submittedPhone = data.phoneNumber;
    submittedPhone =
      submittedPhone.length === 9
        ? submittedPhone
        : submittedPhone.substring(1);
    if (userPhone === submittedPhone) {
      dispatch(
        searchActions.searchLoadError({
          error: true,
          msg: "A User cannot tip themselves",
          title: "Unacceptable Phone No.",
        })
      );
    } else {
      addNonShukranId({
        non_shukran_id: data.phoneNumber,
      });
      addShukranId({});
      dispatch(searchActions.searchLoadSuccess(""));
      dispatch(searchActions.searchLoadError(""));
      if (redirectTo === "bill") {
        navigate(`/tip?redirect=bill`);
      } else {
        navigate("/tip");
      }
    }
  };

  const handlePrevPage = (link) => {
    dispatch(loadMoreSearchResults(link, userState.token));
  };

  const handleNextPage = (link) => {
    dispatch(loadMoreSearchResults(link, userState.token));
  };

  //Handle enter key input
  function handleEnterKey(e) {
    if (e.keyCode === 13) {
      // setIsFocused(false);
      // setToggleSearch(true)
      let userId = userState.shukranId;
      if (userId === searchId) {
        dispatch(
          searchActions.searchLoadError({
            error: true,
            msg: "A User cannot tip their own Shukran ID",
            title: "Unacceptable Shukran ID",
          })
        );
      } else {
        console.log("search empty", searchId);

        if (searchId) {
          dispatch(loadSearchResults(searchId, userState.token));
        } else {
          dispatch(
            searchActions.searchLoadError({
              error: true,
              msg: "Please enter a shukran id",
              title: "Empty Field",
            })
          );
        }
      }
    }
  }

  function handleSearch() {
    // setIsFocused(false);
    // setToggleSearch(true)
    let userId = userState.shukranId;
    if (userId === searchId) {
      dispatch(loadSearchResults([]));
      dispatch(
        searchActions.searchLoadError({
          error: true,
          msg: "A User cannot tip their own Shukran ID",
          title: "Unacceptable Shukran ID",
        })
      );
    } else {
      console.log("search empty", searchId);
      dispatch(loadSearchResults(searchId, userState.token));
    }
  }

  //   handleChangePin
  const handleChangePin = (e) => {
    setSearchId(e.target.value);
    let v = e.target.value;
    const search = debouncedSearch;
    if (!v) {
      // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
      debouncedSearch.cancel();
      dispatch(searchActions.searchLoadSuccess([]));
      // setIsLoading(false);
    } else {
      dispatch(searchActions.searchLoadStart());
      search(v, dispatch, userState.token);
    }
  };

  const SearchHelpText = () => (
    <div className="bg-white mt-64 rounded-md p-1 drop-shadow-md flex flex-row">
      <div className="flex flex-row">
        <img src={ExclamationIcon} alt="Not found icon" className="" />
      </div>
      <div className="flex flex-col ml-1">
        <span className="text-sm">
          {searchPhone ? "How does it work" : "What is a Shukran ID?"}
        </span>
        <span className="text-xs text-slate-400">
          {searchPhone
            ? "Service worker will receive a notification from Shukran of the amount sent and a a link to access and withdraw the tip."
            : `A unique identifier of a user on the Shukran App.`}
        </span>
      </div>
    </div>
  );

  const PhoneHelpText = () => (
    <div className="bg-white mt-56 rounded-md p-1 drop-shadow-md flex flex-row">
      <div className="flex flex-row">
        <img src={ExclamationIcon} alt="Not found icon" className="" />
      </div>
      <div className="flex flex-col ml-1">
        <span className="text-sm">How does it work</span>
        <span className="text-xs text-slate-400">
          Service worker will receive an SMS from Shukran to access the tip.
        </span>
      </div>
    </div>
  );

  const ShukranUserText = () => (
    <div className="pt-4 flex flex-row items-center text-sm">
      <span className="text-primary">OR enter</span>
      <div
        className="border-none rounded-md px-1 py-1 bg-primary-green text-white ml-2"
        onClick={(e) => {
          setSearchPhone(false);
          dispatch(searchActions.searchLoadError(""));
        }}
      >
        their Shukran ID
      </div>
    </div>
  );

  const NonShukranUserText = () => (
    <div className="py-4 flex flex-row items-center text-sm">
      <span className="text-primary">OR enter</span>
      <div
        className="border-none rounded-md px-1 py-1 bg-primary-green text-white ml-2"
        onClick={(e) => {
          setSearchPhone(true);
          dispatch(searchActions.searchLoadError(""));
        }}
      >
        their Phone Number
      </div>
    </div>
  );

  const PhoneOptionSearchError = () => (
    <div className="pt-8 flex flex-row items-center text-sm">
      <span className="text-primary">Click here to enter </span>
      <div
        className="border-none rounded-md px-1 py-1 bg-primary-green text-white ml-2"
        onClick={(e) => {
          setSearchPhone(true);
          dispatch(searchActions.searchLoadError(""));
        }}
      >
        their Phone number
      </div>
    </div>
  );

  const SearchError = () => (
    <div className="flex flex-col">
      <div className="text-[#EF5F89] my-3 bg-white rounded-md flex flex-col justify-between items-center">
        Looks like this Shukran ID does not exist. Please confirm its in the
        format MW0000 or use their phone number.
      </div>
      <PhoneOptionSearchError />
    </div>
  );

  const PhoneSubmitError = () => (
    <div className="flex flex-col">
      <div className="text-[#EF5F89] my-3 bg-white rounded-md flex flex-col items-start">
        {searchState.errorMessage.msg ? searchState.errorMessage.msg : ""}
      </div>
      <ShukranUserText />
    </div>
  );

  return (
    <div className="flex flex-col px-3 w-full h-screen">
      {/* <div className="flex flex-row items-center mt-9">
        <div
          className={`flex flex-row items-center py-2 pl-2 pr-6`}
          onClick={() => handleBack()}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div> */}
      <div className="w-screen p-3">
        <BackButton onPress={handleBack} />
      </div>
      {!searchPhone && (
        <form className="mx-4 mt-11 h-full">
          <label className="text-3xl font-semibold text-primary-pwa">
            Enter Service Worker's Shukran ID
          </label>
          <div
            className={`
              flex flex-row drop-shadow-xl mt-5 justify-between relative 
              ${defaultView ? "focus:outline-primary-pwa" : ""}
              `}
          >
            <div className="p-4 flex justify-center items-center absolute inset-y-0 left-0 pointer-events-none z-50">
              {defaultView && !searchState.errorMessage ? (
                <div onClick={() => handleSearch()}>
                  <img src={searchIcon} alt="search icon" className="" />
                </div>
              ) : searchState.results.length ? (
                <div>
                  <img src={successIcon} alt="Success Icon" className="" />
                </div>
              ) : (
                ""
              )}
            </div>
            <input
              value={searchId}
              onChange={(e) => handleChangePin(e)}
              onKeyDown={(e) => handleEnterKey(e)}
              placeholder={searchPhone ? "Phone Number" : "Search Shukran ID"}
              className={`text-sm block p-4 pl-10 w-full ${
                searchState.errorMessage &&
                !searchState.isLoading &&
                !searchState.results.length
                  ? "focus:outline-[#EF5F89]"
                  : ""
              }`}
              onFocus={(e) => {
                if (e.currentTarget === e.target) {
                  // setIsFocused(true);
                  dispatch(searchActions.searchLoadError(""));
                }
              }}
              onBlur={(e) => {
                // setIsFocused(false);
              }}
            />
            {/* <div className="flex justify-center items-center absolute inset-y-0 right-0 pointer-events-none z-50">
              <img src={searchBeer} alt="search icon" className="" />
             </div> */}
            <div className="flex justify-center items-center">
              {searchState.errorMessage &&
              !searchState.isLoading &&
              !searchState.results.length ? (
                <div
                  onClick={() => {
                    console.log("clicked clear");
                    setSearchId("");
                    dispatch(searchActions.searchLoadError(""));
                  }}
                  className="p-4"
                >
                  <img src={clearIcon} alt="Clear Icon" className="h-5" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!searchState.isLoading &&
            !searchState.results.length &&
            !searchState.errorMessage &&
            !searchPhone && <NonShukranUserText />}
          {!searchState.isLoading &&
            !searchState.errorMessage &&
            !searchState.results.length && <SearchHelpText />}
          {!!searchState.results.length && (
            <div className="flex flex-col mt-7 pb-3 w-full">
              <span className="text-sm">Search Results:</span>
              <ul className="bg-[#F0FEFF] pt-1 px-2 mb-2 mt-2 rounded-md">
                {searchState.results?.[0].results?.map((result, idx) => (
                  <li
                    key={idx}
                    className="flex flex-row items-center h-16 border-b-2 border-b-[#B9F5F947]"
                    onClick={() => handleSelectRecipient(result)}
                  >
                    <div className="w-7 h-7 text-xs bg-accent flex justify-center items-center rounded-full">
                      <p className="text-white text-xs">
                        {result.shukran_id.split("", 2)}
                      </p>
                    </div>
                    <div className="flex flex-col justify-between ml-4">
                      <span className="text-sm">
                        {result.first_name + " " + result.last_name}
                      </span>
                      <span className="text-xs text-gray-500">
                        {result.shukran_id}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="flex flex-row justify-between my-2 pt-3">
                <div
                  className={`flex flex-row items-center ${
                    searchState.results?.[0].previous ? "visible" : "invisible"
                  }`}
                  onClick={() =>
                    handlePrevPage(searchState.results?.[0].previous)
                  }
                >
                  <img
                    src={ArrowButtonPrev}
                    className="w-12 h-12"
                    alt="Previous-search-page"
                  />
                  <span className="ml-2">Previous</span>
                </div>
                <div
                  className={`flex flex-row items-center ${
                    searchState.results?.[0].next ? "visible" : "invisible"
                  }`}
                  onClick={() => handleNextPage(searchState.results?.[0].next)}
                >
                  <span className="mr-2">Next</span>
                  <img
                    src={ArrowButtonNext}
                    className="w-12 h-12"
                    alt="Next-search-page"
                  />
                </div>
              </div>
            </div>
          )}
          {searchState.isLoading && (
            <img
              src={loader}
              alt="loader"
              className="animate-spin h-8 text-base mt-7 mx-auto"
            />
          )}
          {searchState.errorMessage &&
            !searchState.isLoading &&
            !searchState.results.length && <SearchError />}
        </form>
      )}
      {searchPhone && (
        <form
          onSubmit={handleSubmit(handlePhoneProceed)}
          className="mx-4 mt-11 h-full"
        >
          <label className="text-3xl font-semibold text-primary-pwa">
            Enter Service Worker's Phone Number
          </label>
          <div className={`flex flex-col justify-between`}>
            <div
              className={`flex flex-row drop-shadow-xl mt-5 justify-between relative`}
            >
              <input
                className={`
                  ${defaultView ? "focus:outline-primary-pwa" : ""}
                  ${errorView ? "focus:outline-red-300" : ""} 
                  text-sm w-full p-4`}
                type="number"
                placeholder="Phone Number"
                onFocus={(e) => {
                  if (e.currentTarget === e.target) {
                    // setIsFocused(true);
                    dispatch(searchActions.searchLoadError(""));
                  }
                }}
                {...register("phoneNumber", {
                  required: true,
                  // maxLength: 10,
                  // pattern:
                  //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                })}
              />
              {/* <div className="flex justify-center items-center absolute inset-y-0 right-0 pointer-events-none z-50">
                <img src={searchBeer} alt="search icon" className="" />
              </div> */}
            </div>
            {errors.phoneNumber && (
              <p className="text-red-600 pt-2 text-sm">
                Please fill in a valid phone number
              </p>
            )}
            {!searchState.isLoading &&
              !searchState.results.length &&
              !searchState.errorMessage &&
              searchPhone && <ShukranUserText />}
          </div>
          {!searchState.isLoading &&
            !searchState.errorMessage &&
            !searchState.results.length && <PhoneHelpText />}
          {searchState.errorMessage && <PhoneSubmitError />}
          <button
            className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-full"
            // onClick={() => handlePhoneProceed()}
            type="submit"
          >
            PROCEED
          </button>
        </form>
      )}
    </div>
  );
}
