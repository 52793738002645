import React from "react";
import PageImage from "../../../assets/Images/onboarding/saveSw.svg";
import BaseOnboard from "../BoilerPlate/BaseOnboard";

export default function onboardingPage() {
  const textContent =
    "Save received tips or deposit into your Shukran account and plan for your future";
  const contentTitle = "SAVE";

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/getLoans"
      navigatePrev="/receiveTips"
    />
  );
}
