import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import cross from "../../assets/Icons/Tipping/cross.svg";
import { useForm } from "react-hook-form";
import actions from "../../state/index";
import searchIcon from "../../assets/Icons/Rating/search.svg";
import contactIcon from "../../assets/Icons/Referrals/phone_book.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";

import back from "../../assets/Icons/Rating/back_icon.svg";
import {
  generateTokenOtp,
  redeemReward,
  sendTip,
} from "../../state/reducers/user/user.thunk";
import { searchActions } from "../../state/reducers/search/search.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

export default function Rewards() {
  const [phoneNo, setPhoneNo] = useState("");
  const [showPopUp, setShowPopUp] = useState("");
  const [cardOption, setCardOption] = useState(false);

  const dispatch = useDispatch();
  const { loginUser, } = bindActionCreators(actions, dispatch);
  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  const balanceState = useSelector((state) => state.balance);
  const senderData = useSelector((state) => state.account.userDetails);
  const tippingState = useSelector((state) => state.tipping);
  const tipData = useSelector((state) => state.tipData);
  const userAccount = useSelector((state) => state.account);
  const saccoState = useSelector((state) => state.sacco);

  let receiverShukranId = tipData.shukranId;
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  let queryName = tipData.queryName;
  // values from react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleBack = () => {
    navigate(-1);
  };

  // user input handler
  useEffect(() => {
    console.log("isLoading", searchState.isLoading);
    return () => {};
  }, [searchState.isLoading]);

  useEffect(() => {
    if (searchState.errorMessage === 403) {
      navigate("/login");
      dispatch(searchActions.searchLoadError(""));
    }
    return () => {};
  }, [searchState, dispatch, navigate]);

  useEffect(() => {
    if (balanceState.errorMessage.msg) {
      setTimeout(() => {
        dispatch(balanceActions.generateTokenError(""));
      }, 4000);
    }
    if (balanceState.success) {
      console.log("balance state", balanceState);
      setTimeout(() => {
        // dispatch(balanceActions.generateTokenReset());
        // handleConfirmPopup(balanceState.userInfo);
        setShowPopUp(true);
      }, 3000);
    }
    return () => {};
  }, [balanceState, dispatch, navigate]);

  const handlePhone = (data) => {
    console.log("mpesa no submit", data);
    // let payload = {
    //   phone: data.phoneNumber,
    //   where_from: reservationState.restaurant?reservationState.restaurant:''
    // };
    dispatch(redeemReward(userAccount.userDetails.token))
    setPhoneNo(data.phoneNumber);
  };

  const ErrorCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-8 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.redeemRewardError(""));
        }}
      >
        X
      </button>
      <img src={cross} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.errorMessage.msg}
      </p>
    </div>
  );

  const TippingTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {!tippingState.isTransactionLoading && (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(saccoActions.redeemRewardError({}));
            // navigate("/");
          }}
        >
          X
        </button>
      )}
      {tippingState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
      )}
      {saccoState.redeemRes.success &&
        !saccoState.isLoading && (
          <img src={checkCircle} alt="error-img" className="h-24" />
        )
      }
      {saccoState.redeemRes?.success &&
        !saccoState.isLoading && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {saccoState.redeemRes?.msg}
          </p>
        )}
       {!saccoState.redeemRes?.success &&
        !saccoState.isLoading && (
          <p className="justify-self-center	text-base text-center py-2 px-12">
            {saccoState.redeemRes?.msg}
          </p>
        )}
      {saccoState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2 px-12">
          {saccoState?.errorMessage?.msg}
        </p>
      )}
    </div>
  );

  const TippingTransactionError = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(balanceActions.checkTippingError(""));
          dispatch(balanceActions.tippingError(""));
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
      {tippingState?.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState?.errorMessage?.msg}
        </p>
      )}

      {/* Check status of express tipping error responses */}

      {tippingState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip to {receiverShukranId ? receiverShukranId : nonShukranId}{" "}
          Cancelled
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Tip transaction pending. Kindly be patient as we process your tip
        </p>
      )}
      {tippingState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {tippingState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${saccoState.errorMessage?.msg ? "relative" : ""}
        ${saccoState.redeemReward?.success ? "relative" : ""}
        ${saccoState.isLoading ? "relative" : ""}
         w-full flex flex-col`}
    >
      <div
        className={`
          ${saccoState.errorMessage?.msg ? "blur-sm" : ""}
          ${saccoState.redeemReward?.success ? "blur-sm" : ""}
          ${saccoState.isLoading ? "blur-sm" : ""}
          ${showPopUp && !balanceState.success ? "blur-sm" : ""}
          w-full flex flex-col px-3`}
      >
        <div className="flex flex-row items-center mt-9">
          <div
            className={`flex flex-row items-center`}
            onClick={() => handleBack()}
          >
            <img src={back} className="" alt="rate-star" />
          </div>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="mt-11 h-full flex flex-col justify-between">
          <form onSubmit={handleSubmit(handlePhone)} className="flex flex-col w-full">
            <div className="flex flex-col items-center w-full">
              <p className="text-2xl font-semibold text-primary-pwa ">
                Enter Your Phone Number To Get Reward
              </p>
              <div className="relative drop-shadow-xl px-4 h-12 rounded-md bg-white focus:outline-primary-pwa mt-7 w-full">
                <div className="flex justify-center items-center absolute inset-y-0 left-3 pointer-events-none">
                  <img src={searchIcon} alt="search icon" className="h-7 w-7" />                 
                </div>
                <input
                  className="h-full placeholder:text-base p-2 text-black outline-none ml-8"
                  type="number"
                  placeholder="0700000000"
                  {...register("phoneNumber", {
                    required: true,
                    // maxLength: 15,
                    // pattern: /^(?:\+254|0)[1-9]\d{8}$/,
                    // /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                  })}
                />
                <div className="flex justify-center items-center absolute inset-y-0 right-3 pointer-events-none">
                  <img src={contactIcon} alt="search icon" className="h-7 w-7" />                 
                </div>
              </div>
              {errors.phoneNumber && (
                <p className="text-red-600 text-sm mt-2">
                  Please enter a valid number
                </p>
              )}
            </div>
            
            <button
              className="rounded-lg py-3 bg-[#E75480] px-3 text-white text-md mx-auto mt-24 mb-12 w-4/5"
              type="submit"
            >
              Receive Reward
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          saccoState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-20 mx-auto text-sm mb-2`}
        />
      </div>
     
      <div
        className={`${
          saccoState.redeemRes.success
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <TippingTransactionCard />
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <ErrorCard />
      </div>
    </div>
  );
}
