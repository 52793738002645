import React from "react";
import BaseOnboard from "./BoilerPlate/BaseOnboard";
import PageImage from "../../assets/Images/onboarding/payments.svg";

export default function OnboardFund() {
  const textContent =
    "No need to deposit, tip directly from your M-pesa or Card";
  const contentTitle = "SELECT";

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/find"
      navigatePrev="/onboarding"
    />
  );
}
