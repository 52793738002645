import React, { useState } from "react";
import PrimaryGlobalText from "../../globals/PrimaryGlobalText";
import visa from "../../../assets/Icons/card/visa.png";
import master from "../../../assets/Icons/card/mastercard.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendTip } from "../../../state/reducers/user/user.thunk";

export default function CardPayment({ setCardPopup }) {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const cardTransactions = useSelector(
    (state) => state.transactions.cardTransactions
  );

  const tipData = useSelector((state) => state.tipData);
  let receiverShukranId = tipData.shukranId;

  let amount = tipData.tipAmount;
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  let queryName = tipData.queryName;
  const [isPayingTransaction, setIsPayingTransaction] = useState(
    receiverShukranId === "CL2428" || queryShukranId === "CL2428" ? true : false
  );
  let currency = tipData.currency;
  const userInfo = useSelector((state) => state.balance.userInfo);
  let searchState = useSelector((state) => state.search);
  const senderData = useSelector((state) => state.account.userDetails);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  let loginState = localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : "false";
  let isLoggedIn = JSON.parse(loginState);

  const PayUsingSavedCard = () => {
    let cardEmail = !!cardTransactions?.data.length
      ? cardTransactions?.data[0].customer_json.email
      : "";
    let cardId =
      cardTransactions?.data.length > 0 ? cardTransactions?.data[0].id : "";
    console.log(cardTransactions?.data, "Check cards");
    let depositPhone = userInfo.phone ? userInfo.phone : senderData.phoneNumber;
    if (depositPhone?.length > 10) {
      depositPhone = "0" + depositPhone.slice(3);
    }
    let payload = {
      amount: tipData.tipAmount,
      tipper_phone_number: depositPhone
        ? depositPhone
        : balanceState.generateResponse.phone,
      charge_tipper: isPayingTransaction,
      currency: currency === "USD" ? "USD" : "KES",
    };
    if (nonShukranId) {
      //console.log(cardTransactions);
      payload = {
        ...payload,
        receiver_shukran_id: nonShukranId,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId,
        currency: currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, account?.userDetails?.token));
      setCardPopup(false);
    } else if (receiverShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: receiverShukranId,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId,
        currency: currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, account?.userDetails?.token));
      setCardPopup(false);
    } else if (queryShukranId) {
      payload = {
        ...payload,
        receiver_shukran_id: queryShukranId,
        email: cardEmail,
        method: "paystack",
        user_card_id: cardId,
        currency: currency === "USD" ? "USD" : "KES",
      };

      dispatch(sendTip(payload, account?.userDetails?.token));
      // setCardOption("");
    } else {
    }
  };

  return (
    <div className="w-full h-full bg-transparent flex items-center justify-center">
      <div className="flex flex-col items-center py-4 px-4 rounded-lg shadow-2xl justify-start  absolute bg-white w-4/5 h-2/5">
        <div className="w-full flex justify-end">
          <i
            onClick={() => setCardPopup(false)}
            class="fa-solid fa-xmark"
            style={{ fontSize: "22px" }}
          ></i>
        </div>
        <div className="mt-5">
          <PrimaryGlobalText
            text="Pay using this card?"
            fontSize={"20px"}
            fontWeight={"800"}
          />
        </div>
        <div
          style={{
            border: "1px solid #E0E0E0",
          }}
          className="w-full mt-8 bg-[#FFFAF0] h-20 flex items-center px-4 flex-row rounded-lg mx-4"
        >
          <div className="w-12 h-12 bg-[#F1F2F6] h-50 rounded-full flex items-center justify-center">
            <img
              src={
                cardTransactions?.data[0]?.authorization_json?.brand === "visa"
                  ? visa
                  : master
              }
              width={"25px"}
              height={"25px"}
            />
          </div>
          <div className="ml-6">
            <p>
              **** **** ****{" "}
              {cardTransactions?.data[0]?.authorization_json?.last4}
            </p>
            {cardTransactions?.data[0]?.authorization_json?.brand === "visa" ? (
              <p>VISA Card</p>
            ) : (
              <p>MASTERCARD</p>
            )}
          </div>
        </div>
        <div className="w-full mt-10 px-10 items-center flex flex-row justify-between ">
          <div
            style={{ width: "80px" }}
            className="rounded-xl flex items-center text-white justify-center h-10 bg-[#E75480]"
            onClick={() => navigate("/creditInputCard")}
          >
            No
          </div>
          <div
            style={{ width: "80px" }}
            className=" rounded-xl text-white flex items-center justify-center h-10 bg-[#64C8CE]"
            onClick={() => PayUsingSavedCard()}
          >
            Yes
          </div>
        </div>
      </div>
    </div>
  );
}
