import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import back from "../../assets/Icons/Rating/back_icon.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { getFromSessionStorage } from "../../Services/Storage";
import {
  loadBalanceResults,
  withdraw,
} from "../../state/reducers/user/user.thunk";
import { withdrawActions } from "../../state/reducers/withdraw/withdraw.actions";

export default function FinalWithdrawal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const withdrawState = useSelector((state) => state.withdraw);
  // user global state values
  const userAccount = useSelector((state) => state.account);
  const isAuthenticated = userAccount.isAuthenticated;

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!isAuthenticated && isLoggedIn) {
      navigate("/login");
    }

    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };

    dispatch(loadBalanceResults(params, userAccount.userDetails.token));
    dispatch(withdrawActions.withdrawLoadSuccess({}));
    dispatch(withdrawActions.checkTransactionError(""));
  }, []);
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceState.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    return () => {};
  }, [balanceState, navigate]);

  useEffect(() => {
    if (withdrawState.navigate.navigate === "/") {
      navigate("/");
      dispatch(withdrawActions.checkTransactionRedirect(""));
    }
    return () => {};
  }, [withdrawState]);

  const handleWithdrawal = () => {
    let withdrawAmt = balanceState.balance;
    // let withdrawAmt = 10;
    let payload = {
      shukran_id: userAccount.userDetails.shukranId,
      amount: Number(withdrawAmt),
      phone: userAccount.userDetails.phoneNumber,
      token: userAccount.userDetails.token,
    };
    dispatch(withdraw(payload, userAccount.userDetails.token));
  };

  const WithdrawTransactionCard = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(withdrawActions.withdrawLoadSuccess({}));
          dispatch(withdrawActions.withdrawLoadError(""));
          // addShukranId({})
          navigate("/");
        }}
      >
        X
      </button>
      {withdrawState.isTransactionLoading &&
        !withdrawState.errorTransactionStatusMessage && (
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-10 mx-auto text-sm mt-1`}
          />
        )}
      {withdrawState.results.success && !withdrawState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-40" />
      )}
      {(withdrawState.errorTransactionStatusMessage ||
        withdrawState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {withdrawState.results.success && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.results.msg}
        </p>
      )}
      {withdrawState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.errorMessage.msg}
        </p>
      )}
      {withdrawState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {withdrawState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Withdraw transaction{" "}
          {withdrawState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {withdrawState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`${withdrawState.errorMessage.error ? "relative" : ""}
      ${withdrawState.isLoading ? "relative" : ""}
      ${withdrawState.results.success ? "relative" : ""}
      ${withdrawState.errorTransactionStatusMessage ? "relative" : ""}
      w-full flex flex-col
    `}
    >
      <div
        className={`${withdrawState.errorMessage.error ? "blur-sm" : ""}
        ${withdrawState.results.success ? "blur-sm" : ""}
        ${withdrawState.isLoading ? "blur-sm" : ""}
        ${
          withdrawState.errorTransactionStatusMessage ? "blur-sm" : ""
        } flex flex-col px-5 w-full h-screen`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="flex flex-col justify-between h-full pb-5">
          <div className="mt-11">
            <span className="text-3xl font-semibold text-primary-pwa">
              Withdraw your Shukran Balance
            </span>
            <div className="flex mt-8 flex-col bg-[#F0FEFF] rounded-md py-6 px-5">
              <span className="text-3xl">{balanceState.balance} KES</span>
              <span className="text-xs text-gray-400 mt-2">
                current balance
              </span>
            </div>
          </div>
          <button
            className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
            type="submit"
            onClick={() => handleWithdrawal()}
          >
            Withdraw
          </button>
        </div>
      </div>
      <div
        className={`${
          withdrawState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          withdrawState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <WithdrawTransactionCard />
      </div>
      <div
        className={`${
          withdrawState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <WithdrawTransactionCard />
      </div>
      <div
        className={`${
          withdrawState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <WithdrawTransactionCard />
      </div>
    </div>
  );
}
