import React from "react";
import "./global_css/amountInput.scss";
import { globalTheme } from "./global_css/globalsTheme";

export default function AmountInput({ type, placeholder, value, setValue }) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value === 0 ? "" : value}
      style={{
        backgroundColor: globalTheme.global.amountInput,
        border: `1px solid ${globalTheme.global.borderInput}`,
      }}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      multiple={true}
      className="amountInput"
    />
  );
}
