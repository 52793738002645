import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../../assets/Logos/shukran-logo-dark.svg";
import oneOfTen from "../../assets/Illustrations/sacco/one_of_10.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

const Requirements = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`${isLoading ? "relative" : ""} w-full flex flex-col h-screen`}
    >
      <div
        className={`
          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-4 flex flex-row items-center justify-between">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <></>
        </div>
        <div className="flex flex-col justify-between pt-4 h-full bg-white w-full rounded-t-3xl px-5 h-full">
          <div>
            <h2 className="text-2xl text-primary-green">
              Requirements for SACCO application
            </h2>
            <ul className="list-disc px-5 mt-4">
              <li>KRA PIN number</li>
              <li>Soft copy of KRA PIN</li>
              <li>Soft copy of National ID or Passport</li>
              <li>Soft copy of Passport Photo</li>
              {/* <li>Your postal address and code</li>
            <li>Your physical address </li>
            <li>
              At least 3 beneficiaries (with details of their name, phone
              number, national ID, date of birth, their relationship to you,
              percentage of savings assigned to them)
            </li>{" "}
            <li>Indicate if you are a member of another SACCO</li>
            <li>Your occupation, where you work and a range of your income</li> */}
            </ul>
          </div>
          <div>
            <div className="flex flex-row justify-between items-center my-2">
              <div
                className={`w-12 h-12 flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                {/* <span className="ml-2">Previous</span> */}
                <></>
              </div>
              <></>
            </div>
            <button
              className="rounded-full py-2 mb-4 bg-primary-green text-white text-base mt-5 w-full"
              type="button"
              onClick={() => navigate("/ninthForm")}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default Requirements;
