import React, { useEffect } from "react";
import DashBoardHeader from "../Dashboard/DashBoardHeader";
import loader from "../../assets/Icons/Registration/loader.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withdrawActions } from "../../state/reducers/withdraw/withdraw.actions";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import cross from "../../assets/Icons/Rating/not_found.svg";
import {
  loadBalanceResults,
  withdraw,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

export default function ConfirmWithdraw() {
  const withdrawData = useSelector((state) => state.account.withdrawAmount);
  const withdrawState = useSelector((state) => state.withdraw);
  const phoneNumber = useSelector((state) => {
    let phone = state.account.userDetails.depositNumber;
    return phone ? phone : "";
  });
  const userAccount = useSelector((state) => state.account);
  const userData = userAccount.userDetails;
  const balanceData = useSelector((state) => state.balance);

  const { isAuthenticated } = userAccount;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (withdrawState.navigate.navigate === "/") {
      navigate("/");
      dispatch(withdrawActions.checkTransactionRedirect(""));
    }
    return () => {};
  }, [withdrawState]);

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    // fetchData();
    dispatch(loadBalanceResults(params, userData.token));
  }, [dispatch, userData.token]);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceData.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (balanceData.errorMessage === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {};
  }, [balanceData, navigate]);

  const handleWithdrawal = () => {
    dispatch(withdraw(withdrawData, userData.token));
  };

  const WithdrawTransactionCard = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(withdrawActions.withdrawLoadSuccess({}));
          dispatch(withdrawActions.checkTransactionError(""));
          // addShukranId({})
          navigate("/");
        }}
      >
        X
      </button>
      {withdrawState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-10 mx-auto text-sm mt-1`}
        />
      )}
      {withdrawState.results.success && !withdrawState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-10" />
      )}
      {(withdrawState.errorTransactionStatusMessage ||
        withdrawState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-20" />
      )}
      {withdrawState.results.success && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.results.msg}
        </p>
      )}
      {withdrawState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.errorMessage.msg}
        </p>
      )}
      {withdrawState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {withdrawState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Withdraw transaction{" "}
          {withdrawState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {withdrawState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {withdrawState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`${withdrawState.errorMessage.error ? "relative" : ""}
      ${withdrawState.isLoading ? "relative" : ""}
      ${withdrawState.results.success ? "relative" : ""}
      ${withdrawState.errorTransactionStatusMessage ? "relative" : ""}
      w-full flex flex-col
    `}
    >
      <div
        className={`
        ${withdrawState.errorMessage.error ? "blur-sm" : ""}
        ${withdrawState.results.success ? "blur-sm" : ""}
        ${withdrawState.isLoading ? "blur-sm" : ""}
        ${withdrawState.errorTransactionStatusMessage ? "blur-sm" : ""}
        flex flex-col bg-imag-dash w-full
      `}
      >
        <DashBoardHeader navigateLink={"withdraw"} />
        <div className="bg-white rounded-t-3xl flex flex-col px-8">
          <div className="flex flex-col">
            <h1 className="mt-11 text-black text-center text-base pb-2">
              Confirm Withdrawal
            </h1>
            <div className="flex flex-row mt-7 items-center justify-between w-full h-10">
              <span className="text-subtle text-sm">Mpesa Number</span>
              <div className="flex flex-row items-center font-bold">
                +{phoneNumber}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-1 w-full h-10">
              <span className="text-subtle text-sm">Amount</span>
              <div className="flex flex-row items-center font-bold">
                {"KES " + withdrawData?.amount}
              </div>
            </div>
            <div className="flex flex-row items-center justify-between mt-1 w-full h-10 mb-2">
              <span className="text-subtle text-sm">Name</span>
              <div className="flex flex-row items-center font-bold">
                {userData.firstName}
              </div>
            </div>
          </div>
          <button
            className="rounded-full bg-accent h-12 text-white text-xl w-full mt-28
                  flex justify-center items-center
                "
            type="submit"
            disabled={!withdrawState.isLoading ? false : true}
            onClick={() => handleWithdrawal()}
          >
            CONFIRM
          </button>
        </div>
      </div>
      <div
        className={`${
          withdrawState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          withdrawState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <WithdrawTransactionCard />
      </div>
      <div
        className={`${
          withdrawState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <WithdrawTransactionCard />
      </div>
      <div
        className={`${
          withdrawState.errorMessage.error ? "absolute blur-none" : "hidden"
        }
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(
                withdrawActions.withdrawLoadError({
                  error: false,
                  msg: "",
                  data: "",
                })
              );
              // navigate("/rate");
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />

          <h2
            className={
              withdrawState.errorMessage.data
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            {withdrawState.errorMessage.data}
          </h2>
          <p
            className={
              withdrawState.errorMessage.error
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {withdrawState.errorMessage.msg}
          </p>
        </div>
      </div>
    </div>
  );
}
