import React from "react";
import BaseOnboard from "../BoilerPlate/BaseOnboard";
import PageImage from "../../../assets/Images/jijenge.svg";

export default function OnboardTip() {
  const textContent = "Receive tips easily into your Shukran account";
  const contentTitle = "JIJENGE";

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/saveTips"
      navigatePrev="/onboardingSw"
    />
  );
}
