import { actionTypes } from "./user.actions";
import {
  getFromSessionStorage,
  saveToSessionStorage,
} from "../../../Services/Storage";

const initialState = {
  isLoading: false,
  isUserInfoLoading: false,
  balance: getFromSessionStorage("balance"),
  userInfo: getFromSessionStorage("userInfo"),
  tipjar: getFromSessionStorage("tipjar"),
  userType: localStorage.getItem("userType")
    ? localStorage.getItem("userType")
    : "",
  saccoType: localStorage.getItem("saccoType")
    ? localStorage.getItem("saccoType")
    : "",
  errorMessage: "",
  navigate: "",
  success: "",
  generateResponse: {},
  verifyResponse: [],
  sendNotificationRes: {},
};

const balanceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.BALANCE_LOAD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        balance: 0,
      };
    case actionTypes.BALANCE_LOAD_SUCCESS:
      saveToSessionStorage("balance", payload.balance);
      return {
        ...state,
        isLoading: false,
        balance: payload.balance,
      };
    case actionTypes.BALANCE_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.GENERATE_TOKEN_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        success: false,
      };
    case actionTypes.GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        generateResponse: payload,
        success: true,
      };
    case actionTypes.GENERATE_TOKEN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        success: false,
      };
    case actionTypes.GENERATE_TOKEN_RESET:
      return {
        ...state,
        success: false,
      };
    case actionTypes.SEND_NOTIFICATION_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        success: false,
      };
    case actionTypes.SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sendNotificationRes: payload,
        success: true,
      };
    case actionTypes.SEND_NOTIFICATION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        success: false,
      };
    case actionTypes.VERIFY_TOKEN_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        verifyResponse: [],
      };
    case actionTypes.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyResponse: payload,
      };
    case actionTypes.VERIFY_TOKEN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.USER_DATA_START:
      return {
        ...state,
        isUserInfoLoading: true,
        errorMessage: "",
      };
    case actionTypes.USER_DATA_SUCCESS:
      saveToSessionStorage("userInfo", payload);
      return {
        ...state,
        isUserInfoLoading: false,
        userInfo: payload,
      };
    case actionTypes.USER_DATA_ERROR:
      return {
        ...state,
        isUserInfoLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TIPJAR_DATA_START:
      return {
        ...state,
        isUserInfoLoading: true,
        errorMessage: "",
      };
    case actionTypes.TIPJAR_DATA_SUCCESS:
      saveToSessionStorage("tipjar", payload);
      return {
        ...state,
        isUserInfoLoading: false,
        tipjar: payload,
      };
    case actionTypes.TIPJAR_DATA_ERROR:
      return {
        ...state,
        isUserInfoLoading: false,
        errorMessage: payload,
      };

    case actionTypes.USER_TYPE:
      localStorage.setItem("userType", payload);
      return {
        ...state,
        isUserInfoLoading: false,
        userType: payload,
      };
    case actionTypes.SACCO_TYPE:
      // saveToSessionStorage("saccoType", payload);
      localStorage.setItem("saccoType", payload);
      return {
        ...state,
        isUserInfoLoading: false,
        saccoType: payload,
      };
    default:
      return state;
  }
};
export default balanceReducer;
