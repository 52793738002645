import React, { useState } from "react";
import Header from "../globals/Header";
import AmountInput from "../globals/AmountInput";
import waiters from "../../assets/billjar/images/waiters.png";
import tipjarIcon from "../../assets/billjar/icons/tipjar.png";

import "./styles/billjar.scss";
import PrimaryGlobalText from "../globals/PrimaryGlobalText";
import Desc from "../globals/Desc";
import PercSelector from "./components/Perc/PercSelector";
import CustomAmount from "./components/CustomAmount/CustomAmount";
import DestinationBtn from "./components/DestinationBtn/DestinationBtn";
import { globalTheme } from "../globals/global_css/globalsTheme";
import Explainer from "./components/Explainer/Explainer";
import { motion } from "framer-motion";
import GlobalBtn from "../globals/GlobalBtn";
import { useNavigate } from "react-router-dom";
import RateRestaurant from "./RateRestaurant";

export default function PayBill() {
  const [amount, setAmount] = useState(0);
  const [active, setActive] = useState(false);
  const [selectedPerc, setSelectedPerc] = useState("5");
  const [custom, setCustom] = useState();
  const [dest, setDest] = useState(0);
  const navigate = useNavigate();

  return (
    <div style={{ overflowY: "scroll" }} className="mb-6">
      <div className="h-full flex items-center flex flex-col">
        <div className="mx-6 p-4 w-full">
          <Header title="Pay Bill at Honey and Drew" to={-1} />
          <div className="mt-6 w-full flex items-center flex-col ">
            <AmountInput
              type={"number"}
              placeholder={"Enter restaurant bill"}
              value={amount}
              setValue={setAmount}
            />
          </div>
        </div>
        {/**<i class="fa-solid fa-paper-plane"></i> */}

        <div className="separator mt-4 w-full" />
        <div className="mt-3 w-full">
          <div className="header_bill flex flex-row  p-4 items-center justify-evenly">
            <div>
              <img src={waiters} className="w-20 h-20" alt="waiters" />
            </div>
            <div className="flex flex-col ">
              <div>
                <PrimaryGlobalText
                  text={"Tipping Changes Lives"}
                  fontSize={"15px"}
                  fontWeight={"700"}
                />
              </div>
              <div>
                <Desc
                  text="Appreciate your waiter with a tip"
                  fontSize={"13px"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-evenly w-full mt-3">
          <PercSelector
            active={selectedPerc === "5" ? true : false}
            setActive={setSelectedPerc}
            value="5"
          />
          <PercSelector
            active={selectedPerc === "10" ? true : false}
            setActive={setSelectedPerc}
            value="10"
          />
          <PercSelector
            active={selectedPerc === "0" ? true : false}
            setActive={setSelectedPerc}
            value="0"
          />
        </div>
        <div className="w-full p-4 mt-4">
          <CustomAmount
            value={custom}
            setValue={setCustom}
            placeholder="Custom amount"
            type="number"
          />
        </div>
        {selectedPerc !== "0" && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            layoutId={1}
            transition={{ duration: 0.5 }}
            className="mt-2 mb-2 w-full p-4"
          >
            <div className="flex flex-start w-full">
              <PrimaryGlobalText
                text="Select tip destination"
                fontSize="20px"
                fontWeight="700"
              />
            </div>
            <div className="flex flex-row w-full items-center justify-evenly mt-3">
              <DestinationBtn
                title="Tipjar"
                active={dest === 0 ? true : false}
                Icon={
                  <i
                    class="fa-solid fa-database"
                    style={{
                      color: dest === 1 ? globalTheme.global.descText : "white",
                    }}
                  ></i>
                }
                dest={dest}
                onPress={() => {
                  setDest(0);
                }}
              />
              <DestinationBtn
                title="Direct Tip"
                active={dest === 1 ? true : false}
                Icon={
                  <i
                    class="fa-solid fa-paper-plane"
                    style={{
                      color: dest === 0 ? globalTheme.global.descText : "white",
                    }}
                  ></i>
                }
                dest={dest}
                onPress={() => {
                  setDest(1);
                }}
              />
            </div>
          </motion.div>
        )}
        <div className="mb-2 w-full p-4">
          <Explainer title="Total (Bill + Tip)" amount={2000} />
        </div>
        <div className="p-4 w-full">
          <GlobalBtn
            title="Make Payment"
            fontSize="16px"
            onPress={() => {
              if (dest === 1) {
                navigate(`/search?redirect=bill`);
              } else {
                console.log("working");
              }
            }}
          />
        </div>
        <RateRestaurant />
      </div>
    </div>
  );
}
