import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import oneOfTen from "../../assets/Illustrations/sacco/one_of_10.svg";
import oneOfFour from "../../assets/Illustrations/sacco/one_of_four.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";

import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import {
  anonActivateSacco,
  getUserData,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";
import { phoneLines } from "../../util";
import AutoComplete from "../Reservations/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import { motion } from "framer-motion";
import axios from "axios";
import { testUrl } from "../../helper";
import { searchActions } from "../../state/reducers/search/search.actions";
import { debounce } from "lodash";

const FirstPersonalInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formStage, setFormStage] = useState(0);
  const [date, setDate] = useState();
  const [defaultDate, setDefaultDate] = useState(new Date("1998-05-16"));
  const [parsedDate, setParsedDate] = useState();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [fullName, setFullName] = useState("");
  const inputRef = useRef();
  const [businessError, setBusinessError] = useState("");
  const [place, setPlace] = useState();
  const [autocompleteVal, setAutocompleteVal] = useState();
  const [isConsent, setIsConsent] = useState(false);
  const [payloadToSend, setPayloadToSend] = useState();
  const [showIdField, setShowIdField] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [referral, setRefferal] = useState(false);
  const saccoState = useSelector((state) => state.sacco);
  const userAccount = useSelector((state) => state.account);
  const [referralType, setReferralType] = useState("shukran-staff");
  const [referralCode, setReferralCode] = useState("");
  const [searchId, setSearchId] = useState("");
  const userState = useSelector((state) => state.account.userDetails);
  const search = useSelector((state) => state.search);

  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDateChange = (e) => {
    let dateStr =
      e.getDate() + "/" + (e.getMonth() + 1) + "/" + e.getFullYear();
    console.log("date", dateStr);
    // console.log("year", e.fullYear());
    setDate(e);
    setParsedDate(dateStr);
    setOpenCalendar(false);
  };
  const handleCalendarOpen = () => {
    setOpenCalendar(true);
  };

  const API_URL = testUrl + "accounts/auth/search/?shukran_id=";
  const DEBOUNCE = 1000;
  const searchFun = (queryParam, dispatch, token) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios
      .get(`${API_URL}${queryParam}`)
      .then((res) => {
        console.log("searchFun res", res.data);
        dispatch(searchActions.searchLoadSuccess(res.data));
        // setResults(res.data.data);
      })
      .catch((err) => {
        let errorMsg = "";
        if (err.response.status === 403) {
          errorMsg = err.response.status;
        } else {
          errorMsg = err.response.data;
        }
        console.log("errorMsg", errorMsg);
        dispatch(searchActions.searchLoadError(errorMsg));
      });
  };
  const debouncedSearch = debounce(searchFun, DEBOUNCE);
  const handleChangePin = (e) => {
    console.log(e);
    setReferralCode(e.target.value);
    // setValue("staffNumber", e.target.value);
    let v = e.target.value;

    const search = debouncedSearch;
    if (!v) {
      // when the user clear the field we don't want to perform a search, we need to clear the state and do nothing else
      debouncedSearch.cancel();
      dispatch(searchActions.searchLoadSuccess([]));
      // setIsLoading(false);
    } else {
      dispatch(searchActions.searchLoadStart());

      setTimeout(() => {
        search(v, dispatch, userState.token);
      }, 100);
    }
  };
  useEffect(() => {
    if (referralType === "shukran-staff" || referralType === "sacco-member") {
      setShowIdField(true);
      setShowOtherField(false);
      setValue("referralType", referralType);
    } else if (referralType === "other") {
      setShowIdField(false);
      setShowOtherField(true);
      setValue("referralType", referralType);
    } else {
      setShowIdField(false);
      setShowOtherField(false);
      setValue("referralType", referralType);
    }
  }, [referralType]);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "primaryNumber" && value.primaryNumber.length === 4) {
        let phone = phoneLines.includes(value.primaryNumber);
        console.log("is there", phone, type);
        if (!phone) {
          let errorMessage = value.primaryNumber.includes("254")
            ? "Ensure the phone number begins with 07XX/01XX"
            : "Enter a valid Kenyan phone number starting with 07XX/01XX";

          setError("primaryNumber", { message: errorMessage });
          console.log("number error msg", errorMessage);
        } else {
          setError("primaryNumber", "");
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setError]);

  const formatUser = (details) => {
    if (details.date_of_birth) {
      return {
        ...details,
        primary_dob_day: details.date_of_birth.split("-")[2],
        primary_dob_month: details.date_of_birth.split("-")[1],
        primary_dob_year: details.date_of_birth.split("-")[0],
      };
    } else {
      return details;
    }
  };
  useEffect(() => {
    const shukran_id = localStorage.getItem("referrer");
    //setReferralCode(shukran_id);
    // setValue("staffNumber", shukran_id);
    if (shukran_id) {
      setReferralCode(shukran_id ?? "");
    }
  }, []);
  useEffect(() => {
    if (saccoState.saccoDetails) {
      let formattedUser = formatUser(saccoState.saccoDetails);
      let userPhone = balanceState.userInfo.phone;
      userPhone = userPhone?.substring(3);
      userPhone = "0" + userPhone;
      console.log("sacco info", saccoState.saccoDetails);
      setValue("nationalId", formattedUser.national_id_passport);
      setValue("email", balanceState.userInfo?.email);
      setValue("dobDay", formattedUser.primary_dob_day);
      setValue("dobYear", formattedUser.primary_dob_year);
      setValue("primaryNumber", userPhone);
      setValue("firstName", balanceState.userInfo.first_name);
      setValue("lastName", balanceState.userInfo.last_name);
      setValue("middleName", formattedUser.middle_name);
      setPlace({
        name: saccoState.saccoDetails.business_name,
        address: saccoState.saccoDetails.business_address,
      });
      // inputRef.current = saccoState.saccoDetails.business_address
    }
    if (
      !saccoState.saccoDetails.surname &&
      !saccoState.saccoDetails.first_name
    ) {
      let userPhone = balanceState.userInfo.phone;
      userPhone = userPhone?.substring(3);
      userPhone = "0" + userPhone;
      setValue("primaryNumber", userPhone);
      setValue("firstName", balanceState.userInfo.first_name);
      setValue("lastName", balanceState.userInfo.last_name);
    }
    return () => {};
  }, [saccoState.saccoDetails, setValue]);

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    let params = {
      shukran_id: shukran_id,
    };
    if (!originState) {
      dispatch(getUserData(params, userAccount.userDetails.token));
    }

    return () => {};
  }, []);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    if (balanceState?.errorMessage?.status === 403 && !originState) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    if (saccoState?.errorMessage?.status === 403 && !originState) {
      dispatch(saccoActions.saccoActivateError(""));
      navigate("/");
    }
    return () => {};
  }, [saccoState]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState?.results?.success && isNext) {
      //navigate("/");
      //dispatch(saccoActions.saccoActivateSuccess({}));
      // setIsNext(false);
    }
    return () => {};
  }, [saccoState, isNext, dispatch, navigate]);

  const handleFirstPersonalInfo = (data) => {
    console.log("sacco details submit", data);
    let submittedPhone = data.primaryNumber.slice(0, 4);
    // console.log('submittedPhone', submittedPhone);
    let phone = phoneLines.includes(submittedPhone);
    let errorMessage = "";
    let noPlaceSubmitted = autocompleteVal
      ? !autocompleteVal
      : Object.values(place).includes("");
    console.log("business", place);
    console.log("autocompleteVal", !autocompleteVal);
    if (noPlaceSubmitted) {
      errorMessage = "Ensure you have selected a place of business";
      setBusinessError(errorMessage);
    } else if (data.primaryNumber.length < 10) {
      errorMessage = "Ensure field begins with 07XX/01XX and has 10 characters";
      setError("primaryNumber", { message: errorMessage });
    } else if (!phone && data.primaryNumber.length === 10) {
      errorMessage = data.primaryNumber.includes("254")
        ? "Ensure the phone number begins with 07XX/01XX"
        : "Enter a valid Kenyan phone number starting with 07XX/01XX";

      setError("primaryNumber", { message: errorMessage });
      console.log("number error msg", errorMessage);
    } else {
      setError("primaryNumber", "");
      console.log("sacco details submit", data);
      let payload = {
        nationalId: data.nationalId,
        primaryNumber: data.primaryNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName ? data.middleName : "N/A",
        email: data.email,
        date_of_birth: data.dob,
        business_name: place.name ? place.name : autocompleteVal,
        business_address: place.address,
        referral_type: referralType,
        referral_code: referralCode,
        kyc_stage: 1,
      };
      setPayloadToSend(payload);
      //
      //setIsNext(true);
      setIsConsent(true);
    }
  };

  const handleSavePartially = (data) => {
    console.log(data, "partial save");
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    let formDetails = saccoState.saccoDetails;
    delete formDetails.business_address;
    let dateOfBirth = formDetails.date_of_birth
      ? formDetails.date_of_birth
      : "1994-08-16";

    let payload = {
      ...formDetails,
      national_id_passport: data.nationalId,
      phone_number: data.primaryNumber,
      first_name: data.firstName,
      surname: data.lastName,
      middle_name: data.middleName ? data.middleName : "N/A",
      email: data.email,
      date_of_birth: data.date_of_birth,
      business_name: place.name,
      business_address: place.address,
      referral_type: referralType,
      referral_code: referralCode,
      other_referral: "",
      kyc_stage: 8,
      gross_monthly_income: formDetails.gross_monthly_income
        ? formDetails.gross_monthly_income
        : 0,
      monthly_contribution: formDetails.monthly_contribution
        ? formDetails.monthly_contribution
        : 0,
    };

    let payloadData = new FormData();
    for (const key in payload) {
      if (
        key === "kra_pin_photo" ||
        key === "passport_photo" ||
        key === "id_passport_photo" ||
        key === "e_signature_photo" ||
        key === "id_passport_back_photo"
      ) {
        // payloadData.append(key, payload[key][0]?.file);
      } else if (
        key.includes("primary_") ||
        key.includes("dob") ||
        key === "guardian_mn" ||
        key === "user_count" ||
        key === "is_under_eighteen"
      ) {
        console.log("primary", key);
      } else {
        payloadData.append(key, payload[key]);
      }
    }
    console.log("form details", payload);
    console.log("origin state", originState);
    if (originState) {
      dispatch(anonActivateSacco(payloadData));
    } else {
      dispatch(postActivateSacco(payloadData, userAccount.userDetails.token));
    }
  };

  const SaccoActivationError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage?.msg}
        </p>
      )}
      {saccoState?.errorMessage?.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState?.errorMessage?.data?.data)[0]
            ? Object.entries(saccoState?.errorMessage?.data?.data)[0]
            : saccoState.errorMessage.data?.msg}
        </p>
      )}
      {saccoState?.errorMessage?.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          //dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/");
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState?.results?.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState?.results?.success
          ? "Your activation details have been partially submitted"
          : ""}
      </p>
      {isNext ? (
        <></>
      ) : (
        <button
          className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
          type="submit"
          onClick={() => {
            //dispatch(saccoActions.saccoActivateSuccess({}));
            navigate("/");
          }}
        >
          Home
        </button>
      )}
    </div>
  );

  const ReferralModal = () => {
    return (
      <div className="my-auto px-4  bg-white w-4/5 pt-10 flex flex-col pb-4 rounded-3xl z-50">
        <button
          onClick={() => setRefferal(false)}
          className="absolute right-20 top-30  text-[black] text-2xl"
        >
          x
        </button>
        <form
          className="flex flex-col justify-between h-2/3 mt-5"
          onSubmit={handleSubmit(handleFirstPersonalInfo)}
        >
          <div>
            <div className="flex flex-col mt-1">
              <label className="mt-2 text-label text-xs">Referral type *</label>
              <select
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full py-2 pr-20 text-black outline-none"
                {...register("referralType", {
                  required: true,
                })}
                onChange={(e) => {
                  setReferralType(e.target.value);
                  console.log(e.target.value);
                }}
                placeholder="Shukran Staff"
              >
                <option value="shukran-staff" className="w-10/12">
                  Shukran Staff
                </option>
                <option value="social-media" className="w-10/12">
                  Social Media
                </option>
                <option value="sacco-member" className="w-10/12">
                  Sacco Member
                </option>
                <option value="friend" className="w-10/12">
                  Friend
                </option>
                <option value="family" className="w-10/12">
                  Family
                </option>
                <option value="other" className="w-10/12">
                  Other
                </option>
              </select>
              {errors.referralType && (
                <p className="text-red-600 text-sm">Please select an option</p>
              )}
            </div>
            {showIdField && (
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Member ID / Staff Number *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="3103XXX"
                  value={referralCode}
                  onChange={(e) => handleChangePin(e)}
                  autoFocus={true}
                />
                {/* {errors.staffNumber && (
                  <p className="text-red-600 text-sm">
                    Please enter a member / staff ID
                  </p>
                )} */}
              </div>
            )}
            <motion.div
              key={search?.isLoading}
              initial={{ opacity: 0, scaleY: 0.8 }}
              animate={{ opacity: 1, scaleY: 1 }}
              transition={{ duration: 0.4, type: "spring" }}
              className={`flex flex-col bg-[white] w-3/4 p-1 mt-1 overflow-scroll ${
                search?.results[0]?.results?.length > 0 || search?.isLoading
                  ? "h-200"
                  : "h-0"
              } absolute z-100`}
            >
              {search.isLoading ? (
                <div className="w-full flex items-center justify-center">
                  <img
                    src={loader}
                    className={`animate-spin h-10 w-10 mx-auto text-sm mt-1`}
                  />
                </div>
              ) : (
                <div className="mb-4">
                  {search?.results[0]?.results?.map((searchRes) => {
                    return (
                      <div
                        key={searchRes?.shukran_id}
                        onClick={() => {
                          setReferralCode(searchRes?.shukran_id);
                          dispatch(searchActions.searchLoadSuccess(""));
                        }}
                        className="p-2 bg-[#F0FEFF] mt-2 drop-shadow-md flex flex-col w-full items-start justify-start"
                      >
                        <p className="text-md">
                          {searchRes?.first_name?.split("")?.length > 0
                            ? searchRes?.first_name
                            : searchRes?.shukran_id}{" "}
                          {searchRes?.last_name}
                        </p>
                        <p className="text-xs">{searchRes?.shukran_id}</p>
                        {/* <button>
                          <i class="fa-regular fa-circle-check"></i>
                        </button> */}
                      </div>
                    );
                  })}
                </div>
              )}
            </motion.div>
            {showOtherField && (
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Please Specify *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:italic placeholder:pl-2 placeholder:text-sm w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="learnt about shukran at an event"
                  {...register("staffNumber", {
                    required: true,
                  })}
                />
                {errors.staffNumber && (
                  <p className="text-red-600 text-sm">Please specify other</p>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between my-2 pt-3">
            <div
              className={`flex flex-row items-center`}
              onClick={() => setRefferal(false)}
            >
              <img src={ArrowButtonPrev} className="w-12 h-12" alt="Previous" />
              <span className="ml-2">Previous</span>
            </div>
            <button
              type="button"
              onClick={() => {
                let payload = {
                  referral_type: referralType,
                  referral_code: referralCode,
                  other_referral: "",
                };
                dispatch(saccoActions.updateSaccoDetails(payload));
                if (
                  fullName?.split("")?.length >=
                  payloadToSend?.firstName?.split("").length +
                    payloadToSend?.lastName?.split("").length
                ) {
                  //console.log(payloadToSend);
                  setRefferal(false);
                  dispatch(saccoActions.updateSaccoDetails(payloadToSend));
                  handleSavePartially(payloadToSend);
                }
              }}
              className="flex flex-row items-center"
            >
              <span className="mr-2">Submit</span>
              <img
                src={ArrowButtonNext}
                className="w-12 h-12"
                alt="Next Page"
                type="button"
              />
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div
      className={`
        ${isLoading ? "relative" : ""} 
        ${saccoState?.isLoading ? "relative" : ""}
        ${saccoState?.errorMessage ? "relative" : ""}
        ${saccoState?.results?.success ? "relative" : ""}
        ${referral ? "relative " : ""}
        w-full flex flex-col h-screen`}
    >
      <div
        className={`
          ${isLoading ? "blur-sm" : ""}
          ${saccoState?.isLoading ? "blur-sm" : ""}
          ${saccoState?.errorMessage ? "blur-sm" : ""}
          ${saccoState?.results?.success ? "blur-sm" : ""}
       
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-4 flex flex-row items-center justify-between">
          <img
            src={ShukranLogoSacco}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={oneOfFour}
            alt="one of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        {isConsent ? (
          <div className="flex flex-col pt-4 h-full bg-white w-full rounded-t-3xl px-5 mb-10">
            <h2 className="text-2xl text-primary-green">
              Submit Signature Document
            </h2>
            <p className="text-base my-1">
              I declare that all the particulars given by me are true. I confirm
              that I have read the terms and conditions governing the opening,
              operating and closure of membership of Shukran Savings and Credit
              Cooperative Society and agree to be bound by them. I further
              unequivocally consent that my personal data, collected in
              connection with such terms and conditions , may from time to time
              be used and disclosed for such lawful purposes and to such persons
              as may be in accordance with the Shukran Sacco’s prevailing
              Privacy Policy, and the relevant laws, as amended from time to
              time. View our{" "}
              <a
                href="https://www.shukransacco.com/terms/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://www.shukransacco.com/privacy/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                Data Privacy
              </a>{" "}
              to learn more.
            </p>
            <p className="mt-2" style={{ color: "black", fontSize: "17px" }}>
              Enter your full name to agree to the above
            </p>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-label text-xs">Full name</label>
              <input
                className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                type="text"
                placeholder="John Doe"
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
              />
              {errors.lastName && (
                <p className="text-red-600 text-sm	">Surname required</p>
              )}
            </div>
            <div className="flex flex-row justify-between my-2 py-3">
              <div
                className={`w-12 h-12 flex flex-row items-center`}
                onClick={() => setIsConsent(false)}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <a href="#activationLoader">
                <button
                  type="button"
                  onClick={() => {
                    if (
                      fullName?.split("")?.length >=
                      payloadToSend?.firstName?.split("").length +
                        payloadToSend?.lastName?.split("").length
                    ) {
                      setRefferal(true);
                    } else {
                      toast.warning("Please enter your full name!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    }
                    // if (
                    //   fullName?.split("")?.length >=
                    //   payloadToSend?.firstName?.split("").length +
                    //     payloadToSend?.lastName?.split("").length
                    // ) {
                    //   //console.log(payloadToSend);
                    //   dispatch(saccoActions.updateSaccoDetails(payloadToSend));
                    //   handleSavePartially(payloadToSend);
                    // } else {
                    //   toast.warning("Please enter your full name!", {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "colored",
                    //   });
                    // }
                  }}
                  className="flex flex-row items-center"
                >
                  <span className="mr-2">Submit</span>
                  <img
                    src={ArrowButtonNext}
                    className="w-12 h-12"
                    alt="Next Page"
                    type="button"
                  />
                </button>
              </a>
            </div>
          </div>
        ) : (
          <div className="flex flex-col pt-4 h-full bg-white w-full rounded-t-3xl px-5">
            <h2 className="text-2xl text-primary-green">
              Tell Us About Yourself
            </h2>
            <p className="text-base my-1">
              This details will be used for your SACCO account and linked to
              your Shukran account.
            </p>
            <form
              className="flex flex-col justify-between h-4/5 mt-5"
              onSubmit={handleSubmit(handleFirstPersonalInfo)}
            >
              <div className="flex flex-row mt-1">
                <div className="w-1/2 mr-2 ">
                  <label className="mt-2 text-label text-xs">
                    First Name *
                  </label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="Joe"
                    {...register("firstName", {
                      required: true,
                    })}
                  />
                  {errors.firstName && (
                    <p className="text-red-600 text-sm">First Name required</p>
                  )}
                </div>
                <div className="w-1/2">
                  <label className="mt-2 text-label text-xs">
                    Middle Name (optional)
                  </label>
                  <input
                    className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                    type="text"
                    placeholder="Doe"
                    {...register("middleName", { required: false })}
                  />
                  {/* {errors.middleName && (
                  <p className="text-red-600 text-sm	">Middle Name required</p>
                )} */}
                </div>
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">Surname *</label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Doe"
                  {...register("lastName", {
                    required: true,
                  })}
                />
                {errors.lastName && (
                  <p className="text-red-600 text-sm	">Surname required</p>
                )}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Email address *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="email"
                  placeholder="jd@gmail.com"
                  {...register("email", {
                    required: true,
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  })}
                />
                {errors.email && (
                  <p className="text-red-600 text-sm">Email required</p>
                )}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Date Of Birth *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="date"
                  placeholder="12/04/1967"
                  {...register("dob", {
                    required: true,
                  })}
                />
                {errors.dob && (
                  <p className="text-red-600 text-sm">
                    Date of birth is required
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Primary Number *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="number"
                  placeholder="0700000000"
                  {...register("primaryNumber", {
                    required: "primary number is required",
                    // maxLength: {
                    //   value: 10,
                    //   message: 'Ensure the number submitted is valid'
                    // }
                  })}
                />
                {errors.primaryNumber?.message && (
                  <p className="text-red-600 text-sm">
                    {errors.primaryNumber?.message}
                  </p>
                )}
                {/* {errors.primaryNumber && (
                <p className="text-red-600 text-sm">Primary number required</p>
              )} */}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">National ID *</label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="number"
                  placeholder="3103XXX"
                  {...register("nationalId", {
                    required: true,
                    pattern: /^[0-9]{7,8}$|^[A-Z]{1,2}[0-9]{6,8}$/,
                  })}
                />
                {errors.nationalId && (
                  <p className="text-red-600 text-sm">
                    Please enter national ID
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Where do you work?
                </label>
                {/* <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Fogo"
                  {...register("businessName", {
                    required: true,
                  })}
                /> */}
                <AutoComplete
                  setPlace={setPlace}
                  place={place}
                  setBusinessError={setBusinessError}
                  setAutocompleteVal={setAutocompleteVal}
                  inputRef={inputRef}
                  isReservation={false}
                  search
                />
                {businessError && (
                  <p className="text-red-600 text-sm">{businessError}</p>
                )}
              </div>
              <div className="flex flex-row justify-between my-2 py-3">
                <div
                  className={`w-12 h-12 flex flex-row items-center`}
                  onClick={() => handleBack()}
                >
                  <img
                    src={ArrowButtonPrev}
                    className="w-12 h-12"
                    alt="Previous"
                  />
                  <span className="ml-2">Previous</span>
                </div>
                <button type="submit" className="flex flex-row items-center">
                  <span className="mr-2">Next</span>
                  <img
                    src={ArrowButtonNext}
                    className="w-12 h-12"
                    alt="Next Page"
                    type="button"
                  />
                </button>
              </div>
              {/* <button
              className="rounded-full py-2 mb-4 bg-primary-green text-white text-base w-full"
              type="button"
              onClick={handleSubmit(handleSavePartially)}
            >
              SAVE AND CONTINUE LATER
            </button> */}
            </form>
          </div>
        )}
      </div>
      <div
        className={`${
          saccoState?.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState?.results?.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState?.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
        id="activationLoader"
      >
        <div className="bg-white py-5 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
      {referral && (
        <div className="w-screen h-screen  absolute flex backdrop-blur-sm">
          <div
            className={`${
              referral ? "absolute drop-shadow-2xl " : "hidden"
            } flex justify-center items-center w-full h-full z-50`}
          >
            <ReferralModal />
          </div>
        </div>
      )}
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default FirstPersonalInfo;
