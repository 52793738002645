import React from "react";
import { useNavigate } from "react-router-dom";
import Div from "./global/Div";
import { useSelector } from "react-redux";

const TransactionCard = ({ transaction, isHistory, type, detail }) => {
  const navigate = useNavigate();
  const UserInfo = useSelector((state) => state.balance.userInfo);
  return (
    <div
      className={`my-1 shadow-xl w-full rounded-xl p-2.5 flex flex-row items-start justify-start
      ${
        transaction?.status === "Success" &&
        transaction?.type !== "tipjar_disbursement" &&
        transaction?.type !== "Debit"
          ? transaction?.shukran_id !== UserInfo?.shukran_id
            ? "bg-[#CFFFF1]"
            : "bg-[#F5FEFF]"
          : transaction?.status === "Pending"
          ? "bg-[#FFFCF5]"
          : transaction?.type === "Debit"
          ? "bg-[#D2F8FA]"
          : "bg-[#FCF7EA]"
      }
       
      `}
      onClick={() => {
        console.log(transaction);
        if (detail) {
          navigate("/transactions/detail/disbursement", {
            state: transaction,
          });
        } else {
          navigate("/transactions/detail", {
            state: transaction,
            type: type === "Disbursement" ? "disbursement" : "",
            transactions:
              type === "Disbursement" && transaction?.disbursement_detail,
          });
        }
      }}
    >
      {/* 'bg-[#FCF7EA]': 'bg-[#FFFAF0]' */}
      <div className="flex flex-col justify-start items-start">
        {transaction?.type === "Credit" && ( // DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-black">D</p>
          </Div>
        )}
        {type === "Disbursement" && ( // DONE
          <div className="w-10 h-10 bg-amber-400 flex justify-center items-center rounded-full">
            <p className="text-md text-black">DT</p>
          </div>
        )}
        {transaction?.type === "Debit" && ( // DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-black">W</p>
          </Div>
        )}
        {transaction?.type === "contribution" && ( // credit - DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">C</p>
          </Div>
        )}
        {transaction?.type === "pay_share_capital" && ( // credit - DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-black">PSC</p>
          </Div>
        )}
        {transaction?.type === "pay_membership_fee" && ( // credit - DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">PMF</p>
          </Div>
        )}
        {transaction?.type === "loan_repayment" && ( // credit
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">LR</p>
          </Div>
        )}
        {transaction?.type === "Access Tip" && ( // debit
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">AT</p>
          </Div>
        )}
        {transaction?.type === "access_transfer" && ( // debit
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">AT</p>
          </Div>
        )}
        {transaction?.type === "sacco_transfer" && ( // credit
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">ST</p>
          </Div>
        )}
        {transaction?.type === "redeem_akiba_100" && ( // credit
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="text-md text-white">R</p>
          </Div>
        )}

        {transaction?.type === "sacco_tip" && ( // debit - DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="">{transaction?.sender_id?.split("", 2)}</p>
          </Div>
        )}
        {transaction?.type === "Tip" && ( //debit - DONE
          <Div type={transaction?.type} status={transaction?.status}>
            <p className="">{transaction?.receiver_shukran_id?.split("", 2)}</p>
          </Div>
        )}
        {transaction?.type === "tipjar_disbursement" &&
          !detail && ( //debit - DONE
            <div
              onClick={() => console.log(transaction)}
              className="w-10 h-10 bg-border flex justify-center items-center rounded-full"
            >
              <p className="">T J</p>
            </div>
          )}
        {transaction?.type === "tipjar_disbursement" &&
          detail && ( //debit - DONE
            <div
              onClick={() => console.log(transaction)}
              className="w-10 h-10 bg-border flex justify-center items-center rounded-full"
            >
              <p className="">{transaction?.shukran_id?.split("", 2)}</p>
            </div>
          )}
        {transaction?.type === "tip_match" && ( // credit - DONE
          <div className="w-10 h-10 bg-green-300 flex justify-center items-center rounded-full">
            <p className="">TP</p>
          </div>
        )}
      </div>
      <div className="w-full flex flex-col items-end">
        <div className="flex flex-row w-full justify-between px-8">
          <div className="flex flex-col">
            <p className="text-sm">
              {transaction?.type === "sacco_tip"
                ? "Sacco Tip"
                : transaction?.type === "redeem_akiba_100"
                ? "Akiba Reward"
                : transaction?.type === "tip_match"
                ? "Tip Match"
                : transaction?.type === "tipjar_disbursement" && !detail
                ? "From Tipjar"
                : transaction?.type === "tipjar_disbursement" && detail
                ? "Disbursement Detail"
                : type === "Disbursement"
                ? "Disbursements"
                : transaction?.type === "Tip" &&
                  transaction?.tip_type === "Mpesa"
                ? "Wallet Tip"
                : transaction?.type === "Tip" &&
                  transaction?.tip_type === "Paystack"
                ? "Card Payments"
                : transaction?.type === "loan_repayment"
                ? "Loan Repayment"
                : transaction?.type === "Access Tip"
                ? "Access Tip"
                : transaction?.type === "access_transfer"
                ? "Access Transfer"
                : transaction?.type === "sacco_transfer"
                ? "Sacco Transfer"
                : transaction?.sender_name === "N/A"
                ? "New User"
                : transaction?.sender_name}
            </p>
            <p className="text-sm py-1 text-slate-400">
              {transaction?.type === "sacco_tip" ||
              transaction?.type === "Tip" ||
              transaction?.type === "tip_amount" ||
              transaction?.type === "loan_repayment" ||
              transaction?.type === "Access Tip"
                ? transaction?.status === "Pending"
                  ? "Tip Processing"
                  : transaction?.shukran_id !== UserInfo?.shukran_id
                  ? "Tip Received"
                  : "Tip Sent"
                : transaction?.type === "Debit"
                ? "Success"
                : transaction?.sender_id
                ? transaction?.status
                : ""}
              {type === "Disbursement" && "View All"}
            </p>
          </div>
          <div className="flex flex-col">
            {transaction?.type === "pay_share_capital" ||
            transaction?.type === "contribution" ||
            transaction?.type === "pay_membership_fee" ? (
              <p className="text-sm">{"+ KES " + transaction?.amount}</p>
            ) : (
              <p className="text-sm">
                {transaction?.type === "tipjar_disbursement"
                  ? "Ksh " + transaction?.amount
                  : transaction?.type === "Tip"
                  ? transaction.amount
                  : ""}
              </p>
            )}
            {type === "Disbursement" && (
              <p className="text-sm">-{"Ksh " + transaction?.total_amount}</p>
            )}

            <p
              style={{ fontSize: "10px" }}
              className="text-xs py-1 text-slate-400"
            >
              {transaction?.date
                ? transaction?.date
                : transaction?.created_at?.split("T")[0]}
            </p>
          </div>
        </div>
        <div className="text-sm">
          <p>{transaction?.message ? transaction?.message : ""}</p>
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
