import React, { useState, useEffect } from "react";
import DashBoardHeader from "../Dashboard/DashBoardHeader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import actions from "../../state/index";

import dropDownIcon from "../../assets/Icons/Dashboard/dropdown_icon.svg";
import { loadBalanceResults } from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

export default function WithdrawAmount() {
  const navigate = useNavigate();
  const withdrawAmount = useSelector((state) => state.account.withdrawAmount);
  const withdrawState = useSelector((state) => state.withdraw);
  const userData = useSelector((state) => state.account.userDetails);
  const balanceData = useSelector((state) => state.balance);
  const dispatch = useDispatch();

  const { setWithdrawAmount } = bindActionCreators(actions, dispatch);

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    // fetchData();
    console.log("payload token");
    dispatch(loadBalanceResults(params, userData.token));
  }, [dispatch, userData.token]);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (balanceData.errorMessage === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (balanceData.errorMessage === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {};
  }, [balanceData, navigate]);

  const handleWithdrawal = (data) => {
    let payload = {
      shukran_id: userData.shukranId,
      amount: Number(data.amount),
      phone: userData.phoneNumber,
      token: userData.token,
    };
    console.log("payload", payload);
    setWithdrawAmount(payload);
    // dispatch(withdraw(payload));
    navigate("/confirmWithdraw");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("amount", withdrawAmount?.amount);
    return () => {};
  }, []);

  const [openDropdown, setOpenDropdown] = useState(false);

  const withdrawal = {
    label: "Withdraw",
    title: "How much would like to withdraw ?",
  };

  const WithdrawForm = () => (
    <form
      onSubmit={handleSubmit(handleWithdrawal)}
      className="bg-white rounded-t-3xl px-8"
    >
      <div className="flex flex-col">
        <h1 className="mt-11 text-base text-black pb-2">{withdrawal.title}</h1>
        <div className="flex flex-col mt-7 h-14 mb-2">
          <label className="text-subtle text-sm">Amount</label>
          <input
            type="number"
            className="border-solid border-b-2 border-b-[#ddedee]-600 outline-none w-full text-base py-2 text-grey"
            {...register("amount", {
              required: true,
              validate: {
                checkZero: (val) => val > 0 || "Amount has to be larger than 0",
                checkBal: (val) =>
                  val <= balanceData.balance ||
                  "Amount has to be within balance limit",
              },
            })}
            autoFocus
          />
        </div>
        {errors.amount?.message && (
          <div className="p-3 mt-2 text-center rounded-md bg-red-300">
            <span className="text-white text-base font-bold">
              {errors.amount.message}
            </span>
          </div>
        )}
        <div className="flex flex-col h-20 mt-7">
          <label className="pb-2 text-sm text-subtle">{withdrawal.label}</label>
          <div className="flex flex-col">
            <div
              onClick={() => setOpenDropdown(!openDropdown)}
              className="flex flex-row justify-between items-center bg-white mt-3 rounded-full px-4 h-12 focus:outline-primary-pwa outline outline-offset-2 outline-gray-200 outline-1"
            >
              <span className="text-primary text-base">MPESA</span>
              <img src={dropDownIcon} alt="down-arrow" className="block" />
            </div>
            <div
              className={`
            ${openDropdown ? "" : "hidden"}
            flex flex-col mt-2 rounded-lg text-base z-10
            bg-white border-gray-200 border-2
            `}
            >
              <span
                className="px-4 py-2"
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                MPESA
              </span>
            </div>
          </div>
        </div>
      </div>
      <button
        className="rounded-full bg-accent text-white text-xl 
        mt-10 py-4 w-full h-12 flex justify-center items-center"
        type="submit"
      >
        NEXT
      </button>
    </form>
  );

  return (
    // <div>
    // <div className="flex flex-col bg-imag-dash w-96">
    <div className={`w-full flex flex-col`}>
      <div className={`w-full flex flex-col bg-imag-dash`}>
        <DashBoardHeader navigateLink={""} />
        <WithdrawForm />
      </div>
    </div>
  );
}
