import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getFromSessionStorage } from "../../../Services/Storage";
import {
  getUserData,
  loadBalanceResults,
  updateProfile,
} from "../../../state/reducers/user/user.thunk";
import { resetActions } from "../../../state/reducers/reset/reset.actions";

import ArrowButtonNext from "../../../assets/Icons/Onboarding/ArrowButtonNext.svg";
import loader from "../../../assets/Icons/Registration/loader.svg";
import smiley from "../../../assets/smiley.svg";
import checkCircle from "../../../assets/Icons/Popups/check_circle.svg";
import ArrowButtonPrev from "../../../assets/Icons/Onboarding/ArrowButtonPrev.svg";
import { ActiveIndicator } from "../../Indicators";

import "./BaseOnboard.css";

export default function BaseOnboard(props) {
  const [firstTimeLogIn, setFirstTimeLogIn] = useState("");
  const { pageImage, textContent, contentTitle, navigateNext, navigatePrev } =
    props;
  const userData = useSelector((state) => state.balance);
  // navigate function
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const profileState = useSelector((state) => state.profile);
  const userAccount = useSelector((state) => state.account);
  const userInfo = useSelector((state) => state.balance.userInfo);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const { userType, saccoType } = balanceState;

  const { isAuthenticated } = userAccount;

  useEffect(() => {
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    console.log("logged in check", isLoggedIn);
    setFirstTimeLogIn(isLoggedIn);
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    if (isLoggedIn) {
      // dispatch(loadBalanceResults(params, userAccount.userDetails.token));
      // dispatch(getUserData(params, userAccount.userDetails.token));
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (profileState.errorMessage === 403 && firstTimeLogIn) {
      localStorage.setItem("hasSeenOnboarding", false);
      navigate("/login");
    }
    return () => {};
  }, [profileState, navigate, firstTimeLogIn]);

  useEffect(() => {
    if (balanceState.errorMessage === 403 && firstTimeLogIn) {
      localStorage.setItem("hasSeenOnboarding", false);
      navigate("/login");
    }

    return () => {};
  }, [balanceState, navigate, firstTimeLogIn]);

  const handleUpper = (STRing) => {
    return STRing.toUpperCase();
  };

  const handleUpdateProfile = () => {
    let payload = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email ? userInfo.email : "user@gmail.com",
      role: userType,
      sacco_type: handleUpper(saccoType),
      user_type: handleUpper(userType),
    };
    console.log("userInfo", userInfo);
    console.log("payload update", payload);
    dispatch(updateProfile(payload, userAccount.userDetails.token));
  };

  const handleStartTipper = () => {
    if (firstTimeLogIn) {
      handleUpdateProfile();
    } else {
      navigate(navigateNext);
    }
  };

  const SuccessRating = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
          navigate(navigateNext);
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-base text-center py-2">
        {profileState.results.msg}
      </p>
      <button
        className="drop-shadow-md rounded-full bg-accent h-12 text-primary-pwa text-lg w-full mt-3 py-2 flex justify-center items-center"
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
          navigate(navigateNext);
        }}
      >
        Proceed
      </button>
    </div>
  );

  return (
    <div
      className={`
      ${profileState.isLoading ? "relative" : ""}
      ${profileState.results.success ? "relative" : ""}
      w-full flex flex-col justify-center items-center drop-shadow-2xl
    `}
    >
      <div
        className={`
       ${profileState.isLoading ? "blur-sm" : ""}
        ${profileState.results.success ? "blur-sm" : ""}
      flex flex-col h-screen w-screen`}
      >
        <div className="w-1/6 flex skip_link flex-row-reverse">
          {contentTitle !== "TIP" && (
            <Link
              to={
                userData.userType !== "tipper"
                  ? "/receiveDividends"
                  : "/startTipping"
              }
              className="text-base text-white bg-pink-400 rounded-2xl py-1 px-3 m-3"
            >
              Skip
            </Link>
          )}
        </div>
        <img
          src={pageImage}
          alt="A service worker waving"
          className={`w-auto ${contentTitle === "JIJENGE" ? "h-2/3" : ""}`}
        />

        <div className="mx-4 flex flex-col justify-start">
          <div className="flex flex-col justify-center text-center mb-3">
            <h1 className="text-2xl mb-1 mt-8">{contentTitle}</h1>

            <div className="text-sm mx-8">{textContent}</div>
            {contentTitle === "TIP" && (
              <div className="w-auto mx-auto">
                <img
                  src={smiley}
                  alt="loader"
                  className={`h-28 mx-auto text-sm`}
                />
              </div>
            )}
          </div>
          <div
            // className={
            //   navigatePrev? " pt-2 fixed left-10 bottom-8 ": "pt-2 fixed left-16 bottom-8"
            // }
            className="my-1"
          >
            <div className="flex flex-row justify-between items-center">
              {contentTitle === "HELLO" ||
              contentTitle === "KARIBU" ||
              contentTitle === "TIP" ||
              contentTitle === "DIVIDENDS" ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    navigate(navigatePrev);
                  }}
                >
                  <img
                    src={ArrowButtonPrev}
                    className={navigatePrev ? "w-12 h-12" : "hidden"}
                    alt="Next Page"
                  />
                </button>
              )}

              <span className="flex flex-row justify-center	items-center w-full">
                {contentTitle === "HELLO" || contentTitle === "KARIBU" ? (
                  <ActiveIndicator />
                ) : (
                  <Link
                    to="/onboarding"
                    className="border-2 rounded-full border-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1"
                  />
                )}
                {contentTitle === "SELECT" || contentTitle === "JIJENGE" ? (
                  <ActiveIndicator />
                ) : (
                  <Link
                    to={`${
                      userData.userType === "tipper" ? "/fund" : "/receiveTips"
                    }`}
                    className="border-2 rounded-full border-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1"
                  >
                    {/* <Indicator /> */}
                  </Link>
                )}
                {contentTitle === "FIND" || contentTitle === "SAVE" ? (
                  <ActiveIndicator />
                ) : (
                  <Link
                    to={`${
                      userData.userType === "tipper" ? "/find" : "/saveTips"
                    }`}
                    className="border-2 rounded-full border-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1"
                  />
                )}
                {contentTitle === "CHOOSE AMOUNT" ||
                contentTitle === "GET LOANS" ? (
                  <ActiveIndicator />
                ) : (
                  <Link
                    to={`${
                      userData.userType === "tipper"
                        ? "/tipOnboard"
                        : "/getLoans"
                    }`}
                    className="border-2 rounded-full border-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1"
                  />
                )}
                {contentTitle === "TIP" || contentTitle === "DIVIDENDS" ? (
                  <ActiveIndicator />
                ) : (
                  <Link
                    to={`${
                      userData.userType === "tipper"
                        ? "/startTipping"
                        : "/receiveDividends"
                    }`}
                    className="border-2 rounded-full border-[#F2C773]  w-2 h-2 ml-2 mr-2 p-1"
                  />
                )}
              </span>

              {contentTitle === "TIP" || contentTitle === "DIVIDENDS" ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    navigate(navigateNext);
                  }}
                >
                  <img
                    src={ArrowButtonNext}
                    className="w-12 h-12"
                    alt="Next Page"
                  />
                </button>
              )}
            </div>
          </div>
          {(contentTitle === "TIP" || contentTitle === "DIVIDENDS") &&
            !profileState.results.success && (
              <div>
                <button
                  className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full mb-4 mt-3"
                  onClick={handleStartTipper}
                >
                  {contentTitle === "TIP" && "START TIPPING"}
                  {contentTitle === "DIVIDENDS" && "JOIN SHUKRAN"}
                </button>
              </div>
            )}
        </div>
      </div>
      <div
        className={`${
          profileState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-4/5 h-full`}
      >
        <SuccessRating />
      </div>
      <div
        className={`${
          profileState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
    </div>
  );
}
