import React from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import chevron from "../../assets/Icons/tipjar/chevron.svg";
import "./members.css";
import {
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
} from "../../state/reducers/user/user.thunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import BackButton from "../globals/BackButton";
import { useQuery } from "@tanstack/react-query";
import { Button } from "../../components-shadcn/ui/button";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
export default function ConfirmGroupPerc() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tipData = useSelector((state) => state.tipData);
  const handleBack = () => {
    navigate("/tipjar/amount");
  };
  const tipjar = useSelector((state) => state.tipjar);
  const { isLoading } = useSelector((state) => state.tipjar);

  // const calculateDisbursementMutation
  const userAccount = useSelector((state) => state.account);
  function getCount(group) {
    let countof = tipjar?.members.filter((memb) => {
      if (memb?.group?.id === group?.id) {
        return true;
      } else {
        return false;
      }
    });
    return countof.length;
  }
  useEffect(() => {
    // dispatch(getTipjarMembers({}, userAccount?.userDetails?.token));
    // dispatch(getTipjarData({}, userAccount.userDetails.token));
  }, []);
  useEffect(() => {
    if (tipjar?.tipjarId) {
      dispatch(
        getTipjarGroups(tipjar?.tipjarId, userAccount?.userDetails?.token)
      );
    }
  }, [tipjar?.tipjarId]);
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="flex flex-col px-2 w-full h-screen ">
      {/* <div
        className="flex flex-row items-center mt-9 w-full"
        style={{ width: "100%" }}
      >
        <div
          className={`flex flex-row items-center py-2 pl-2 pr-6`}
          onClick={() => handleBack()}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div> */}
      <div className="w-screen p-2">
        <BackButton onPress={handleBack} />
      </div>
      <div
        className=" px-2 mt-6 w-full"
        style={{ width: "100%", overflow: "scroll" }}
      >
        <h2 className="text-3xl font-semibold text-primary-pwa">
          Confirm group percents
        </h2>
        <p className="text-md font-semibold text-gray-400">
          Change group distribution percents for TipJar amount of KES.{" "}
          {tipData?.tipAmount}
        </p>

        <div className="mt-6">
          {tipjar?.groups?.length > 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h5>Groups ({tipjar?.groups?.length})</h5>
            </div>
          ) : (
            <h5>Groups </h5>
          )}
          <div className="mt-6 empty">
            {isLoading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Skeleton className="w-full h-14 my-2" />
                </div>
              ))
            ) : (
              <div className=" w-full">
                {tipjar?.groups?.length > 0 ? (
                  <div className=" w-full">
                    {tipjar?.groups?.map((group, index) => (
                      <>
                        <div
                          key={group?.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <div
                            className="border-2 border-gray w-full h-65 mb-3"
                            style={{
                              height: "60px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              className="w-1/2 px-2"
                            >
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "15px",
                                  backgroundColor: "#50A2A7 ",
                                }}
                              ></div>
                              <div className="ml-2">
                                <p
                                  style={{ color: "#404B5A", fontSize: "12px" }}
                                >
                                  {group?.name}
                                </p>
                                <p
                                  style={{ color: "#929FB1", fontSize: "10px" }}
                                >
                                  {getCount(group)} Members
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                              className="w-1/2 px-2"
                            >
                              <div
                                className="ml-1"
                                style={{
                                  border: "1px solid #6CCBD1",
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "12.5px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "#F0FEFF",
                                  color: "#6CCBD1",
                                  fontSize: "8px",
                                }}
                              >
                                {group?.percentage}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  <button
                    onClick={() => navigate("/tipjar/groups")}
                    className="add_member "
                  >
                    <img src={add} className="add" alt="add member" />
                    <h6 className="ml-2 ">Add Group</h6>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4 flex flex-col">
        <button
          className="rounded-full py-3 bg-white border-2 text-accent border-accent px-3 text-white text-m mx-auto mt-4 w-full"
          onClick={() => navigate("/tipjar/groups/distribution")}
          type="button"
        >
          <p className="text-center text-accent"> CHANGE GROUP DISTRIBUTION</p>
        </button>
        <button
          className="rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-full"
          onClick={() => navigate("/tipjar/confirm/members")}
          type="button"
        >
          PROCEED
        </button>
      </div>
    </div>
  );
}
