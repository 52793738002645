import React, { useState, useEffect } from "react";
import logo from "../../assets/Logos/ShukranIcon.png";
import InputComp from "../GeneralUtils/input.js";
import loader from "../../assets/Icons/Registration/loader.svg";
import { useNavigate } from "react-router-dom";
import cross from "../../assets/Icons/Rating/not_found.svg";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import {
  confirmOtpCode,
  requestOtpCode,
  verifyOtpCode,
} from "../../state/reducers/user/user.thunk";
import { registrationActions } from "../../state/reducers/registration/registration.actions";
import { useForm } from "react-hook-form";

export default function ConfirmCode() {
  // state for application
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [isrequestedOTP, setRequestOTP] = useState(false);

  // navigate function
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const userData = useSelector((state) => state.account.user);

  const resetState = useSelector((state) => state.reset);
  const registrationState = useSelector((state) => state.registration);
  const balanceData = useSelector((state) => state.balance);
  const { userType } = balanceData;

  function resendCode() {
    let params = {
      phone: `${userData?.phone ? userData?.phone :''}`,
      token: `${userData.token}`,
    };
    console.log("resend params", params);
    setValue("otpCode", "");
    dispatch(requestOtpCode(params, userData.token));
  }

  // parse data
  const getCodeData = () => {
    return {
      otp: firstCode + secondCode + thirdCode + fourthCode,
    };
  };

  // updates data to redux store
  const { fetchOTP } = bindActionCreators(actions, dispatch);
  // set token to local storage
  const setToken = (userData) => {
    localStorage.setItem("token", userData.token);
    localStorage.setItem("shukranId", userData.shukran_id);
    localStorage.setItem("phoneNumber", userData.phone);
  };

  useEffect(() => {
    localStorage.setItem("confirmCode", "pre-confirm");
    return () => {};
  }, []);

  useEffect(() => {
    return () => {
      dispatch(registrationActions.confirmOtpSuccess([]));
      dispatch(registrationActions.requestOtpSuccess({}));
      dispatch(registrationActions.confirmOtpError([]));
      dispatch(registrationActions.requestOtpError([]));
    };
  }, [dispatch]);

  const handleFormSubmit = (data) => {
    console.log("data confirm code", data);
    if (data.otpCode) {
      let params = {
        nonce: data.otpCode,
        phone: userData?.phone ? userData?.phone : "0",
      };
      dispatch(confirmOtpCode(params));
    }
  };

  //Automove Cursor

  const handleBackspace = (e) => {
    const { value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    console.log("e.key", e.key);
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (e.key === "Backspace" && fieldIntIndex > 1 && value.length === 0) {
      const nextField = document.querySelector(
        `input[name=inputCode-${fieldIntIndex - 1}]`
      );
      nextField.focus();
    }
  };
  const handleKeyDown = (e) => {
    const { value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    console.log("e.key", e.key);
    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (value.length === 1 && fieldIntIndex < 5) {
      const nextField = document.querySelector(
        `input[name=inputCode-${fieldIntIndex + 1}]`
      );
      nextField.focus();
    }
  };

  useEffect(() => {
    if (registrationState.errorMessage.msg) {
      setTimeout(() => {
        dispatch(registrationActions.confirmOtpError([]));
        dispatch(registrationActions.requestOtpError([]));
      }, 4000);
    }
    if (registrationState.confirmRes.success) {
      setTimeout(() => {
        setToken(registrationState.confirmRes.data);
        // make the user logged in
        localStorage.setItem("confirmCode", "post-confirm");
        localStorage.setItem("isLoggedIn", true);
        dispatch(registrationActions.confirmOtpSuccess([]));
        dispatch(registrationActions.requestOtpSuccess({}));
        navigate("/", { replace: true });
      }, 3000);
    }
    if (registrationState.requestRes.success) {
      setTimeout(() => {
        dispatch(registrationActions.confirmOtpSuccess([]));
        dispatch(registrationActions.requestOtpSuccess({}));
      }, 3000);
    }
    return () => {};
  }, [
    registrationState,
    dispatch,
    navigate
  ]);

  return (
    <div
      className={`
      ${registrationState.isLoading ? "relative" : ""}
      ${registrationState.requestRes.success ? "relative" : ""}
      ${registrationState.confirmRes.success ? "relative" : ""}
      ${registrationState.errorMessage?.msg ? "relative" : ""}
      w-full flex flex-col
  `}
    >
      <div
        className={`
        ${registrationState.requestRes.success ? "blur-sm" : ""}
        ${registrationState.confirmRes.success ? "blur-sm" : ""}
        ${registrationState.errorMessage?.msg ? "blur-sm" : ""}
        ${
          registrationState.isLoading ? "blur-sm" : ""
        } flex flex-col items-center h-full mt-40 px-6 w-full`}
      >
        <img src={logo} className="size mx-auto mb-7 h-10" alt="logo" />
        <h3 className="text-lg text-black mb-4 text-center">
          {" "}
          Confirm your phone number
        </h3>
        <p className="text-base mb-4 text-black text-center">
          Enter sms verification code sent to 07********
        </p>

        <div className="flex flex-row justify-between pt-1 w-fit">
          <form className="flex flex-col">
            <input
              className="border-solid border border-[#95BD75] rounded-md focus:border-[#50A2A7] placeholder:text-base text-black outline-none text-xl h-12 placeholder:text-center w-20 mx-auto px-3"
              type="number"
              placeholder="XXXX"
              {...register("otpCode", {
                required: true,
                maxLength: 4,
              })}
            />
            {errors.otpCode?.type === "maxLength" && (
              <p className="text-red-600 text-sm text-center">
                OTP code has to be 4 numbers
              </p>
            )}
            {errors.otpCode?.type === "required" && (
              <p className="text-red-600 text-sm text-center">
                OTP code required
              </p>
            )}
            <button
              className="rounded-full bg-[#F2C773] p-2 text-white text-base w-full mx-auto mt-8"
              type="submit" // onClick={() => handleFormSubmit(e)}
              onClick={handleSubmit(handleFormSubmit)}
            >
              SUBMIT OTP CODE
            </button>
          </form>
        </div>
        <div className="mt-2 pt-4">
          {registrationState.isLoading && (
            <span className="flex">
              <img src={loader} alt="Loader" className="rounded animate-spin" />
              <p className="pl-3"> Confirming code</p>
            </span>
          )}
        </div>
        <p className="pt-4 flex flex-row mx-auto">
          <span className="text-primary">Didn't receive code? </span>
          <button
            className="border-none underline uppercase text-primary-pwa ml-3"
            onClick={(e) => {
              resendCode();
            }}
          >
            RESEND CODE
          </button>
        </p>
      </div>
      <div
        className={`${
          registrationState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          registrationState.requestRes.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(registrationActions.requestOtpSuccess([]));
            }}
          >
            X
          </button>
          <img src={checkCircle} alt="error-img" className="h-12" />
          <h2
            className={
              registrationState.requestRes.success
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            Success
          </h2>
          <p
            className={
              registrationState.requestRes?.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {registrationState.requestRes?.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          registrationState.confirmRes.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
      <div
        className={`${
          registrationState.errorMessage?.msg ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <div className="p-4 bg-white pt-10 w-4/5 drop-shadow-xl flex flex-col relative pb-4 rounded-3xl z-10">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              // setIsclicked(!isClicked);
              dispatch(registrationActions.confirmOtpError([]));

              dispatch(registrationActions.requestOtpError([]));
              // navigate("/login");
            }}
          >
            X
          </button>
          <img src={cross} alt="error-img" className="h-12" />
          <h2
            className={
              registrationState.errorMessage?.msg
                ? "text-center font-bold	text-lg"
                : "hidden"
            }
          >
            Fail
          </h2>
          <p
            className={
              registrationState.errorMessage?.msg
                ? "justify-self-center	text-lg text-center py-2"
                : "hidden"
            }
          >
            {registrationState.errorMessage?.msg}
          </p>
        </div>
      </div>
    </div>
  );
}
