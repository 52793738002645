import React, { useState, useEffect, useRef } from "react";
import { useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import reserveIcon from "../../assets/Icons/Reservations/reservation.svg";
import clockIcon from "../../assets/Icons/clock.svg";
import dropDownIcon from "../../assets/Icons/Dashboard/dropdown_icon.svg";
import prevIcon from "../../assets/Icons/Reservations/backBut.svg";
import locationIcon from "../../assets/Icons/location.svg";
import { getUserData } from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";
import { reservationActions } from "../../state/reducers/reservation/reservation.actions";

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useForm } from "react-hook-form";
import { DUARA_KEY } from "../../helper";

// Make Reservation JSON body

// "google_restaurant": "0bd5a53c-5464-4a65-838e-30821435a38d",
// "number_of_people": 4,
// "reservation_time": "2023-06-01 13:00"
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function MakeReservation() {
  const [toggleShow, setToggleShow] = useState(false);
  const [cardOption,] = useState(false);
  const [dateString, setDateString] = useState('');
  const [generatedWeek, setGeneratedWeek] = useState([]);
  const dateRef = useRef([])
  const timeRef = useRef([])
  const [generatedDates, setGeneratedDates] = useState([]);
  const [chosenTime, setChosenTime] = useState('');
  const [chosenPeople, setChosenPeople] = useState(0);
  const [chosenPeopleErr, setChosenPeopleErr] = useState('');
  const [dateConverted, setDateConverted] = useState();
  const [periodDays, setPeriodDays] = useState([
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ]);
  const [windowSize, setWindowSize] = useState({})
  const [timeIntervals, setTimeIntervals] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locale = 'en-GB';

  const userAccount = useSelector((state) => state.account);
  let searchState = useSelector((state) => state.search);
  const balanceState = useSelector((state) => {
    return state.balance;
  });

  const reservationState = useSelector((state) => state?.reservations);

  const {
    setValue,
  } = useForm({});

  //Handle enter key input
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollTimeTop, setScrollTimeTop] = useState(0);

  const handleDateIntervals = (value) => {
    let convrtedVal = new Intl.DateTimeFormat(
      locale,
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(value)
    let weekDay = new Intl.DateTimeFormat(
      locale,
      {
        weekday: "long",
      }).format(value)
    // console.log('periods', convrtedVal)
    // console.log('week day', weekDay)
    let weekDayOpenings = reservationState.restaurants?.opening_hours?.weekday_text;
    weekDayOpenings = weekDayOpenings.filter((day, idx) => {
      return day.includes(weekDay)
    })
    // console.log('week day openings', weekDayOpenings)
    if (weekDayOpenings[0].includes('Open')) {
      const timeIntervals = [];
      let i = 0,
        j = 0;
      for (i = 0; i < 24; i++) {
        for (j = 0; j < 4; j++) {
          timeIntervals.push(`${i}:${j === 0 ? `00` : 15 * j}`);
        }
      }
      setTimeIntervals(timeIntervals)
    } else {
      let periods = reservationState.restaurants?.opening_hours?.periods[periodDays.indexOf(weekDay)];
      let truncatedOpenTime = '',
        truncatedCloseTime = '', 
        calTruncatedOpenTime = '', 
        calTruncatedCloseTime = '';

        // console.log('periods ', periods)
      if (periods) {
        if (periods.close.day > periods.open.day) {
          truncatedCloseTime = Number(periods.close.time) + 2400
          truncatedCloseTime = truncatedCloseTime / 100
          calTruncatedCloseTime = truncatedCloseTime - Math.round(truncatedCloseTime)
          calTruncatedCloseTime = calTruncatedCloseTime / 0.6
          calTruncatedCloseTime = calTruncatedCloseTime * 4
        } else {
          truncatedCloseTime = Number(periods.close.time) / 100
          calTruncatedCloseTime = truncatedCloseTime - Math.round(truncatedCloseTime)
          calTruncatedCloseTime = calTruncatedCloseTime / 0.6
          calTruncatedCloseTime = calTruncatedCloseTime * 4
        }
        truncatedOpenTime = Number(periods.open.time)+''
        truncatedOpenTime = Number(periods.open.time) / 100;
  
        calTruncatedOpenTime = truncatedOpenTime - Math.round(truncatedOpenTime)
  
        calTruncatedOpenTime = calTruncatedOpenTime / 0.6
        calTruncatedOpenTime = calTruncatedOpenTime * 4
        const timeIntervals = [];
        let j = 0;
        // console.log('timeIntervals', timeIntervals)
        for (let i = Math.round(truncatedOpenTime); i < Math.round(truncatedCloseTime); i++) {
          j = i === Math.round(truncatedOpenTime)
            ? Math.round(calTruncatedOpenTime)
            : 0
          let endTime = i === Math.round(truncatedCloseTime)
            ? Math.round(truncatedCloseTime)
            : 4
          for (j; j < endTime; j++) {
            timeIntervals.push(`${i>=24?i-24:i}:${j === 0 ? `00` : 15 * j}`);
          }
        }
        setTimeIntervals(timeIntervals)
        setChosenTime(timeIntervals[0])
      } else {
        setTimeIntervals(['Closed'])
        setChosenTime('Closed')
      }
      
    }
    setDateConverted(convrtedVal)
  }


  function divideHeight(listLen, scrollHeight, scrollVal, limit){
    // console.log('scroll height', scrollHeight)
    // console.log('scroll val', scrollVal)
    let idx = Math.round(scrollHeight / (listLen + 3)),
      selected = 0;
    for(let i=0; i < limit; i++){
      let lower = i*idx,
        upper = (i+1)*idx;
      if(scrollVal > lower && scrollVal < upper){
        selected = i
      } 
    }
    return selected
  }

  const listDayScroll = event => {
    let selectedTime = divideHeight(
      generatedDates.length, 
      event.currentTarget.scrollHeight, 
      event.currentTarget.scrollTop,
      40
    )
    console.log('scroll idx', selectedTime)
    // setScrollTimeTop(selectedTime)
    // setChosenTime(timeIntervals[selectedTime])
    setScrollTop(selectedTime)
    handleDateIntervals(generatedDates[selectedTime])
    let generatedArray = generatedDates.map((dateVal, idx) => {
      return new Intl.DateTimeFormat(
        locale,
        {
          month: "short",
          day: "2-digit",
          weekday: 'short'
        }).format(dateVal)
    })
    setDateString(generatedArray[selectedTime])
    setChosenPeopleErr('')
  };

  const listTimeScroll = event => {
    let selectedTime = divideHeight(
      timeIntervals.length, 
      event.currentTarget.scrollHeight, 
      event.currentTarget.scrollTop,
      60
    )
    setScrollTimeTop(selectedTime)
    setChosenTime(timeIntervals[selectedTime])
    setChosenPeopleErr('')
  };

  const scrollToMiddle = (idx) => {
    dateRef.current[idx-1].scrollIntoView()
    setScrollTop(idx)
    handleDateIntervals(generatedDates[idx])
    let generatedArray = generatedDates.map((dateVal, i) => {
      return new Intl.DateTimeFormat(
        locale,
        {
          month: "short",
          day: "2-digit",
          weekday: 'short'
        }).format(dateVal)
    })
    setDateString(generatedArray[idx])
    setChosenPeopleErr('')
  }
  const scrollTimeToMiddle = (idx) => {
    timeRef.current[idx-1].scrollIntoView()
    setScrollTimeTop(idx)
    setChosenTime(timeIntervals[idx])
    setChosenPeopleErr('')
  }

  useEffect(() => {
    function handleWindowResize() {
      // setWindowSize(getWindowSize());
      let res = getWindowSize();
      setWindowSize(res);
      console.log("height", res.innerHeight);
      console.log("width", res.innerWidth);
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize, { passive: false });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    let params = {
      shukran_id: shukran_id,
    };
    if (isLoggedIn) {
      dispatch(getUserData(params, userAccount.userDetails.token));
    }
    
    return () => { };
  }, [])
  

  useEffect(() => {
    if (reservationState.restaurants?.photos?.length) {
      if (reservationState.restaurants?.opening_hours) {
        const datesArray = [];
        for (let i = 0; i < 21; i++) {
          const date = new Date(); // current date
          const nextDate = new Date(date.setDate(date.getDate() + i)); // add 'i' days to current date
          datesArray.push(nextDate);
        }
        handleDateIntervals(datesArray[0])
        let generatedArray = datesArray.map((dateVal, idx) => {
          return new Intl.DateTimeFormat(
            locale,
            {
              month: "short",
              day: "2-digit",
              weekday: 'short'
            }).format(dateVal)
        })
        // console.log('dates array', datesArray)
        // generatedArray = structureUltraColumns(generatedArray, timeIntervals)
        setGeneratedWeek(generatedArray)
        setDateString(generatedArray[0])
        setGeneratedDates(datesArray)
        let periodsArray = reservationState.restaurants?.opening_hours.periods.map((openings, idx) => {
          return openings.open.day === 0 
            ? 'Sunday'
            : openings.open.day === 1 
            ? 'Monday'
            : openings.open.day === 2 
            ? 'Tuesday'
            : openings.open.day === 3 
            ? 'Wednesday'
            : openings.open.day === 4 
            ? 'Thursday'
            : openings.open.day === 5 
            ? 'Friday'
            : openings.open.day === 6 
            ? 'Saturday'
            : ''
        })
        
        setPeriodDays(periodsArray)
      }
    }
    return () => { };
  }, [reservationState]);

  useEffect(() => {
    if (balanceState.errorMessage === 403) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  useEffect(() => {
    if (reservationState.errorMessage.status === 403) {
      navigate("/login");
      dispatch(reservationActions.getReservationHistoryError(''))
    }
    return () => { };
  }, [reservationState]);

  useEffect(() => {
    if (balanceState.userInfo) {
      let formattedUser =  balanceState.userInfo?.first_name + ' ' + balanceState.userInfo?.last_name;
      let depositPhone = balanceState.userInfo?.phone;
      if (depositPhone) {
        depositPhone = "0" + depositPhone.slice(3);
      }
      setValue("name", formattedUser);
      setValue('phone', depositPhone)
    }
    return () => {};
  }, [balanceState, setValue]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleContinue = () => {
    if (!dateConverted) {
      setChosenPeopleErr('Please select date')
    }
    else if (chosenPeople <= 0) {
      setChosenPeopleErr('Please select number of people')
    }  
    else if (chosenTime === 'Closed') {
      setChosenPeopleErr('Restaurant closed on selected day')
    }
    else {
      let reservationTime = dateConverted.split('/').reverse().join('-')
      reservationTime = reservationTime + ' ' + chosenTime
      let payload = {
        google_restaurant: reservationState.restaurants.id,
        number_of_people: Number(chosenPeople),
        reservation_time: reservationTime,
        chosen_time: chosenTime,
        chosen_day: dateString
      }
      console.log('payload', payload)
      dispatch(reservationActions.updatePayloadDetails(payload))
      // setPayload(payload)
      navigate("/submitReservation")
    }
  }

  const handleSelectPeople = (val) => {
    setChosenPeopleErr('')
    setChosenPeople(val)
  }

  return (
    <div
      className={`
        ${reservationState?.errorMessage?.error ? "relative" : ""}
        ${reservationState?.errorMessage?.msg ? "relative" : ""}
        ${reservationState?.reservationResponse?.created_at ? "relative" : ""}
        ${reservationState?.isLoading ? "relative" : ""}
        ${reservationState?.errorTransactionStatusMessage ? "relative" : ""} 
        ${cardOption ? "relative" : ""}
         w-full flex flex-col h-screen`}
    >
      <div
        className={` ${reservationState?.errorMessage?.error ? "blur-sm" : ""}
          ${reservationState?.errorMessage?.msg ? "blur-sm" : ""}
          ${reservationState?.errorMessage?.detail ? "blur-sm" : ""}
          ${reservationState?.reservationResponse?.created_at ? "blur-sm" : ""}
          ${reservationState?.errorTransactionStatusMessage ? "blur-sm" : ""} 
          ${reservationState?.isLoading ? "blur-sm" : ""}
          ${cardOption ? "blur-sm" : ""}
          flex flex-col w-full h-screen`}
      >
        <div className="">
          {/* <span className="text-sm font-bold mt-8">{reservationState?.placeDetails.name}</span> */}
          <div
            className={`flex flex-row items-center absolute top-10 left-3`}
            onClick={() => handleBack()}
          >
            <img src={prevIcon} className="text-primary-pwa drop-shadow-xl" alt="rate-star" />
          </div>
          {
          windowSize.innerWidth && !!reservationState.restaurants?.photos?.length && 
            <img
              alt="google map"
              className="object-cover h-40 w-full"
              src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=200&photo_reference=${reservationState.restaurants.photos[0].photo_reference}&key=${DUARA_KEY}`}
            />
          }
        </div>
        <div className="rounded-t-lg bg-white -mt-4 relative z-10 px-4 flex flex-col justify-between">
          <div className="text-3xl font-bold mt-4">{reservationState.placeDetails.name}</div>
          {reservationState.restaurants.place_rating && <div className="text-sm font-bold mt-2">
            {reservationState.restaurants.place_rating} Rating
          </div>}
          <div className="flex flex-col items-start justify-between mb-2">
            <span className="text-base text-primary-pwa flex flex-row items-start mt-2 mb-1">
              <img
                src={locationIcon}
                alt="clock-icon"
                className={`w-4 h-4 mr-2`}
              />{reservationState.restaurants.address}
            </span>
            {reservationState.restaurants?.opening_hours?.periods[0].hasOwnProperty('close') ? (
              <div className="text-base text-primary-pwa flex flex-row items-center">
                <img
                  src={clockIcon}
                  alt="clock-icon"
                  className={`w-4 h-4 mr-2`}
                />{reservationState.restaurants.opening_hours.periods[0].open.time} - {reservationState.restaurants.opening_hours.periods[0]?.close?.time} hrs
              </div>
            ) : (
              <span className="text-xs text-primary-pwa pt-2">

              </span>
            )}
          </div>
          <div className="mb-2">
              <div className="flex flex-col justify-between pb-4 mt-3">
                <label className="text-lg font-semibold">
                  Party Size
                </label>
                <div className="flex flex-col w-full">
                  <ul className="mx-auto w-full flex flex-row overflow-x-scroll py-2 no-scrollbar">
                    {
                      Array.from({length: 20}, (_, i) => i + 1).map((person, idx) => (
                        <li 
                          className={`
                            py-3 px-5 text-sm rounded-full border-2 mx-2 text-base
                            ${chosenPeople === (person)? 'bg-accent text-white border-accent':'border-black'}
                          `}
                          key={idx}
                          onClick={() => handleSelectPeople(person)}
                        >
                          {person}
                        </li>
                      ))
                    }
                  </ul>
                  {chosenPeopleErr && (
                    <p className="text-red-600 text-sm mx-auto">
                     {chosenPeopleErr}
                    </p>
                  )}
                </div>
              </div>
              <div className={`flex flex-row justify-between mt-5 ${chosenPeople? '':'hidden'}`}>
                <div className="flex flex-col items-start w-1/2 h-40">
                  <label className="text-base pb-4 text-center w-11/12 rounded-md pt-2">
                    Date *
                  </label>
                  <ul 
                    className="mx-auto w-full flex flex-col overflow-y-scroll px-2 no-scrollbar"
                    onScroll={listDayScroll}
                  >
                    {/* <li className="py-3 border-accent border-b-2 opacity-25"> </li> */}
                    <li className="py-4 border-accent border-b-2"> </li>
                    {
                      generatedWeek.map((weekDay, idx) => (
                        <li 
                          className={`
                            py-2 text-base border-b-2 border-accent text-center h-12
                            ${scrollTop === idx ? 'opacity-100 bg-accent font-bold rounded-lg': 'opacity-75'}
                          `} 
                          key={idx}
                          onClick={() => scrollToMiddle(idx)}
                          ref={(element) => dateRef.current[idx] = element}
                        >
                          {weekDay}
                        </li>
                      ))
                    }
                    <li className="py-4 border-accent border-b-2"> </li>
                    {/* <li className="py-3 border-accent border-b-2 opacity-25"> </li> */}
                  </ul>
                </div>
                <div className="flex flex-col items-end w-1/2 h-40 bg-accent-200">
                  <label className="text-base pt-2 pb-4 text-center w-11/12 rounded-md">
                    Time *
                  </label>
                  <ul 
                    className="mx-auto w-full flex flex-col overflow-y-scroll px-2 no-scrollbar"
                    onScroll={listTimeScroll}  
                  >
                    {/* <li className="py-3 border-accent border-b-2 opacity-25"> </li> */}
                    <li className="py-4 border-accent border-b-2"> </li>
                    {
                      timeIntervals.map((time, idx) => (
                        <li 
                          className={`
                            py-2 text-base border-accent text-center border-b-2
                            ${scrollTimeTop === idx ? 'opacity-100 bg-accent font-bold rounded-lg': 'opacity-75'}
                          `} 
                          onClick={() => scrollTimeToMiddle(idx)}
                          ref={(element) => timeRef.current[idx] = element}
                          key={idx}
                        >
                          {time}
                        </li>
                      ))
                    }
                    <li className="py-4 border-accent border-b-2"> </li>
                    {/* <li className="py-3 border-accent border-b-2 opacity-25"> </li> */}
                  </ul>
                </div>
              </div>
          </div>
         
          <div className={`mt-9 flex flex-row items-center justify-between ${chosenPeople? '':'hidden'}`}>
            <button
              type="button"
              onClick={() => handleContinue()}
              className="rounded-lg py-2 bg-accent text-white text-base w-full flex flex-row justify-center items-center"
            >
              Complete Reservation <img
                src={reserveIcon}
                alt="reserve-icon"
                className={`w-4 h-4 ml-1`}
              />
            </button>
          </div>
          <div className={`mt-6 ${chosenPeople? '':'hidden'}`}>
            <div className="flex flex-row items-center w-full justify-start">
              <div 
                className="text-primary-pwa self-end flex flex-row items-center text-base"
                onClick={() => setToggleShow(!toggleShow)}
              >
               {!toggleShow? 'Show Opening Hours':'Show Less'}
                <img
                  src={dropDownIcon}
                  alt="clock-icon"
                  className={`
                    w-3 h-3 text-white ml-2
                    ${!toggleShow? '':'rotate-180'}
                  `}
                />
              </div>
            </div>
            <div>
              {
                toggleShow ? reservationState.restaurants?.opening_hours?.weekday_text.map((time, idx) => (
                  <div className="w-full mt-3" key={idx}>
                    {!reservationState.restaurants.opening_hours.periods[0].hasOwnProperty('close') ?
                      (<div className="flex flex-row items-center justify-between w-full">
                        <div className="text-sm">{time.split(':')[0]}</div>
                        <div className="text-sm">{time.split(':')[1]}</div>
                      </div>)
                      : (
                      <div className="flex flex-row items-center justify-between w-full">
                        <div className="text-sm">{time.split(/:(.*)/s)[0]}</div>
                        <div className="text-sm text-primary-pwa">{time.split(/:(.*)/s)[1]}</div>
                      </div>
                      )}
                  </div>
                )) : (
                    <>
                    </>
                  )
                }
              {!reservationState.restaurants.opening_hours ? <div className="text-sm my-7 text-slate-500">opening times are not available</div>:<></>}
            </div>
            {/* <div className="mt-2 px-6 flex flex-col items-center">
               <button
                  className="rounded-full p-2 my-2 bg-red-300 text-white text-base"
                  type="button"
                  onClick={() => {
                    handleBack()
                    dispatch(reservationActions.updatePlaceDetails({}))
                    dispatch(reservationActions.searchRestaurantSuccess({}))
                  }}
                >
                  <img src={circleCross} alt="cross-icon" className="h-10" />
                </button>
                <span className="text-red-300 pb-8">Cancel Reservation</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
