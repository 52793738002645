import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import loader from "../../assets/Icons/Registration/loader.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";

import { Link, useNavigate } from "react-router-dom";

import TransactionCard from "../Card/TransactionCard";
import {
  getSaccoBalances,
  getSaccoLoans,
  loadBalanceResults,
  loadTransactionResults,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

import { bindActionCreators } from "redux";
import actions from "../../state";
import useIsIOS from "../popups/useIsIos";
import moment from "moment";

const AccessTips = () => {
  const [accessTipError, setAccessTipError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAccount = useSelector((state) => state.account);
  const saccoState = useSelector((state) => state.sacco);
  const ShukranId = userAccount.userDetails.shukranId;
  const isAuthenticated = userAccount.isAuthenticated;
  // user global state values
  const transactions = useSelector((state) => {
    // show transactions from latest to earliest
    let stateArr = state.sacco?.loans?.loans
      ? state.sacco?.loans?.loans.slice(0, 10)
      : 0;
    const appendAdditionOperator = (amount) => {
      return "+ Ksh" + amount;
    };

    const appendSubtractionOperator = (amount) => {
      return "- Ksh" + amount;
    };
    const parseType = (type) => {
      let newType = type?.split("_");
      newType = newType.map((type, idx) => {
        return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
      });
      return newType.join(" ");
    };
    stateArr = stateArr
      ? stateArr.map((trans) => {
          let dataFormat = {
            id: trans.id,
            amount: trans.amount,
            type: trans.type,
            date: moment(trans.created_at).format("D/M/Y"),
            status: trans.status,
            sender_name: trans.receiver_first_name,
            sender_id: trans.receiver_shukran_id,
          };

          if (trans.type === "loan_withdrawal") {
            //Else display the receivers ID
            dataFormat = {
              ...dataFormat,
              sender_name: trans?.sender_first_name,
              sender_id: trans?.shukran_id,
              amount: appendSubtractionOperator(trans.amount),
              type: "Access Tip",
            };
            return dataFormat;
          } else {
            return dataFormat;
          }
        })
      : [];

    stateArr.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    return stateArr;
  });

  const [isIOS, setIsIOS] = useIsIOS();

  const { addNonShukranId, addShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  // const balance = userAccount.balance;

  useEffect(() => {
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);

    if (!isAuthenticated && isLoggedIn) {
      navigate("/login");
    }
    if (!isAuthenticated && !isLoggedIn) {
      navigate("/splashScreen");
    }

    dispatch(getSaccoLoans(userAccount.userDetails.token));
    dispatch(getSaccoBalances(userAccount.userDetails.token));
  }, []);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");

    if (saccoState.errorMessage.status === 403 && isLoggedIn) {
      navigate("/login");
    }
    return () => {};
  }, [saccoState, navigate]);

  const handleAccessTips = () => {
    navigate("/accessAmount");
  };

  // Complete your share capital to access tips
  return (
    <div
      className={`
        ${accessTipError ? "relative" : ""}
        w-full flex flex-col h-full ${saccoState.isLoading ? "h-screen" : ""}
    `}
    >
      <div
        className={`
          ${accessTipError ? "blur-sm" : ""} 
          px-4 w-full flex flex-col h-full pb-20`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
        </div>
        <p className="mt-5 text-3xl font-semibold text-primary-pwa">
          Available Today
        </p>
        <div className="flex flex-col mt-5 py-5 px-2 w-full mx-auto bg-[#F0FEFF]">
          <p className="text-3xl font-bold">
            KES{" "}
            {saccoState.balance?.data?.access_balance
              ? saccoState.balance?.data?.access_balance
              : 0}
          </p>
          <span className="text-sm text-primary-light">Access Balance</span>
          <div className="flex flex-row items-center">
            <span className="text-sm text-primary-light">Sacco Balance:</span>
            <span className="font-bold ml-2">
              KES{" "}
              {saccoState.balance?.data?.sacco_balance
                ? saccoState.balance?.data?.sacco_balance
                : 0}
            </span>
          </div>
        </div>
        <button
          className="rounded-full py-2 bg-primary-green text-white text-base w-full mt-4 mb-4"
          onClick={handleAccessTips}
        >
          ACCESS YOUR FUNDS
        </button>
        <div className="bg-[#FFFAF0] p-3 text-sm my-7">
          To adjust percentage of access to daily tips, go to settings
        </div>

        <div className="px-5 mt-6 pb-2 flex flex-col">
          <p className="text-sm">HIGHLIGHTS</p>
          {!!transactions &&
            transactions.map((transaction) => {
              if (transaction?.type === "Access Tip") {
                return (
                  <TransactionCard
                    key={transaction?.id}
                    transaction={transaction}
                    isHistory={false}
                  />
                );
              } else {
                return <div key={transaction?.id}></div>;
              }
            })}
          {saccoState.isLoading && (
            <div className="my-1bg-[#FFFAF0] rounded-xl p-2.5 w-full flex flex-row justify-center">
              <img
                src={loader}
                alt="loader"
                className={`animate-spin h-8 mx-auto text-sm mt-1`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessTips;
