// action Types
export const actionTypes = {
  WITHDRAW_START: "WITHDRAW_START",
  WITHDRAW_SUCCESS: "WITHDRAW_SUCCESS",
  WITHDRAW_ERROR: "WITHDRAW_ERROR",
  WITHDRAW_CLEAR: "WITHDRAW_CLEAR",
  CHECK_WITHDRAW_START: "CHECK_WITHDRAW_START",
  CHECK_WITHDRAW_SUCCESS: "CHECK_WITHDRAW_SUCCESS",
  CHECK_WITHDRAW_STOP: "CHECK_WITHDRAW_STOP",
  CHECK_WITHDRAW_ERROR: "CHECK_WITHDRAW_ERROR",
  CHECK_WITHDRAW_REDIRECT: "CHECK_WITHDRAW_REDIRECT",
};

// actions
const withdrawLoadStart = () => ({
  type: actionTypes.WITHDRAW_START,
});

const withdrawLoadSuccess = (results) => ({
  type: actionTypes.WITHDRAW_SUCCESS,
  payload: results,
});

const clearTransactionStatus = () => ({
  type: actionTypes.WITHDRAW_CLEAR,
  payload: {},
});

const withdrawLoadError = (errorMsg) => ({
  type: actionTypes.WITHDRAW_ERROR,
  payload: errorMsg,
});

const checkTransactionStart = () => ({
  type: actionTypes.CHECK_WITHDRAW_START,
});

const checkTransactionStop = () => ({
  type: actionTypes.CHECK_WITHDRAW_STOP,
});

const checkTransactionSuccess = (results) => ({
  type: actionTypes.CHECK_WITHDRAW_SUCCESS,
  payload: results,
});

const checkTransactionError = (errorMsg) => ({
  type: actionTypes.CHECK_WITHDRAW_ERROR,
  payload: errorMsg,
});

const checkTransactionRedirect = (msg) => ({
  type: actionTypes.CHECK_DEPOSIT_REDIRECT,
  payload: msg,
});

export const withdrawActions = {
  withdrawLoadStart,
  withdrawLoadSuccess,
  withdrawLoadError,
  clearTransactionStatus,
  checkTransactionStart,
  checkTransactionSuccess,
  checkTransactionError,
  checkTransactionStop,
  checkTransactionRedirect,
};
