import React from "react";

export default function PrimaryGlobalText({ text, fontSize, fontWeight }) {
  return (
    <p
      className="text-primary-pwa"
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight ?? "600",
        textAlign: "center",
      }}
    >
      {text}
    </p>
  );
}
