import React from "react";
import PrimaryGlobalText from "../../../globals/PrimaryGlobalText";
import { globalTheme } from "../../../globals/global_css/globalsTheme";

export default function Explainer({ title, amount }) {
  return (
    <div
      className="flex flex-row items-center justify-between p-3 w-full"
      style={{ backgroundColor: globalTheme.global.explainer }}
    >
      <p>{title}</p>
      <PrimaryGlobalText
        text={`KSH ${amount}`}
        fontSize={"15px"}
        fontWeight={"700"}
      />
    </div>
  );
}
