import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";

import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import "./members.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
  postTipjarGroups,
} from "../../state/reducers/user/user.thunk";
import { testUrl } from "../../helper";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import BackButton from "../globals/BackButton";
export default function ChangeDistribution() {
  const [group, setGroup] = useState("");
  const [percentage, setPercentage] = useState([]);
  const [name, setName] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const userAccount = useSelector((state) => state.account);
  const tipjar = useSelector((state) => state.tipjar);
  const [perc, setPerc] = useState(0);
  const [updatePerc, setUpdatePerc] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedName, setIsFocusedName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataQueue, setDataQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  // useEffect(() => {
  //   dispatch(getTipjarData({}, userAccount.userDetails.token));
  //   dispatch(getTipjarMembers({}, userAccount.userDetails.token));
  // }, []);
  // useEffect(() => {
  //   if (tipjar?.tipjarId) {
  //     dispatch(
  //       getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
  //     );
  //   }
  // }, [tipjar?.tipjarId]);

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, []);
  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
  }, []);
  const handlePerc = (value, getI) => {
    if (value >= 0) {
      let count = 0;
      percentage?.map((group, index) => {
        if (index !== getI) count += group;
      });
      if (isNaN(percentage[getI]) || percentage[getI] === undefined) {
        percentage[getI] = 0;
      } else {
        const add = count + parseInt(value);
        const minValue = 0;
        const maxValue = 100 - count;
        const limitedValue = Math.min(Math.max(value, minValue), maxValue);
        const newValues = [...percentage];

        newValues[getI] = limitedValue;
        setPercentage(newValues);
      }
    }
  };
  const handleSetName = (value, index) => {
    const newValues = [...name];
    newValues[index] = value;
    setName(newValues);
  };
  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, [updatePerc]);
  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, [navigate]);
  useEffect(() => {
    if (tipjar.tipjargroupResponse.id) {
      navigate(`/tipjar/groups/edit?group=${tipjar.tipjargroupResponse.id}`);
      tipjar.tipjargroupResponse = {};
    }
  }, [tipjar.tipjargroupResponse]);

  const addPerc = (indexA) => {
    setIsFocused(true);

    if (isNaN(percentage[indexA]) || percentage[indexA] === null) {
      tipjar?.groups?.map((group, index) => {
        percentage[index] = 0;
      });
    } else {
      let count = 0;
      percentage?.map((group, index) => {
        if (index !== indexA) count += group;
      });
      const minValue = 0;
      const maxValue = 100 - count;
      const limitedValue = Math.min(
        Math.max(percentage[indexA] + 10, minValue),
        maxValue
      );
      const newValues = [...percentage];
      newValues[indexA] = limitedValue;
      setPercentage(newValues);
    }
  };
  const substractPerc = (indexS) => {
    setIsFocused(true);
    let count = 0;
    percentage?.map((group, index) => {
      if (index !== indexS) count += group;
    });
    if (isNaN(percentage[indexS]) || percentage[indexS] === null) {
      percentage[indexS] = 0;
    }
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(
      Math.max(percentage[indexS] - 10, minValue),
      maxValue
    );
    const newValues = [...percentage];
    newValues[indexS] = limitedValue;
    setPercentage(newValues);
  };

  const updateAllGroups = () => {
    setIsLoading(true);
    tipjar?.groups?.map((group, index) => {
      const body = {
        tipjar_id: tipjar?.tipjarId,
        name: name[index] ? name[index] : group?.name,
        percentage: percentage[index],
      };
      axios
        .put(`${testUrl}tipjar/groups/${group?.id}/`, body, {
          headers: {
            Authorization: `Bearer ${userAccount.userDetails.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          dispatch(
            getTipjarGroups(tipjar?.tipjarId, userAccount.userDetails.token)
          );
          setConfirmUpdate(true);
        })
        .catch((err) => {
          setConfirmUpdate(false);
        })
        .finally(() => {
          setIsLoading(false);
          setIsFocused(false);
          setIsFocusedName(false);

          // toast.success("groups updated!", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
        });
    });
    if (confirmUpdate) {
      toast.success("groups are updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    navigate(-1);
  };

  return (
    <div
      className="flex flex-col px-3 w-full h-screen mb-6"
      style={{ width: "100%" }}
    >
      {/* <div
        className="flex flex-row items-center mt-9 w-full"
        style={{ width: "100%" }}
      >
        <div
          className={`flex flex-row items-center py-2 pl-2 pr-6`}
          onClick={() => handleBack()}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div> */}
      <div className="w-screen p-3">
        <BackButton onPress={handleBack} />
      </div>
      <div
        className=" mt-6 w-full px-3"
        style={{ width: "100%", overflow: "scroll" }}
      >
        <h2 className="text-3xl font-semibold text-primary-pwa">
          Group Distributions
        </h2>
        <p
          className="text-md font-semibold text-gray-400"
          style={{ width: "100%" }}
        >
          Change the distribution percentages for groups
        </p>

        {tipjar?.groups?.map((group, index) => (
          <div
            className={`
              flex flex-row mt-8 justify-between relative 
              focus:outline-primary-pwa 
              `}
            key={group?.id}
            style={{ width: "100%", flexDirection: "row" }}
          >
            <div style={{ flexDirection: "column" }} className="w-1/2">
              <h6 style={{ color: "#ADB6C0" }} className="mb-2">
                Group
              </h6>
              <input
                value={isFocusedName ? name[index] : group?.name}
                onChange={(e) => handleSetName(e.target.value, index)}
                //   onKeyDown={(e) => handleEnterKey(e)}
                placeholder={"Enter group name"}
                className={`text-sm block p-4 pl-4 w-full focus:outline-none drop-shadow-xl`}
                onFocus={(e) => {
                  if (e.currentTarget === e.target) {
                    setIsFocusedName(true);
                    //dispatch(searchActions.searchLoadError(""));
                  }
                }}
                onBlur={(e) => {
                  // setIsFocused(false);
                }}
              />
            </div>
            <div style={{ flexDirection: "column" }} className="w-1/2 ml-2">
              <h6 style={{ color: "#ADB6C0" }} className="mb-2 w-full">
                Percentage
              </h6>
              <input
                value={
                  isFocused
                    ? percentage[index]
                      ? percentage[index].toString().replace(/^0+/, "")
                      : 0
                    : group?.percentage
                }
                onChange={(e) => {
                  handlePerc(e.target.value, index);
                }}
                //   onKeyDown={(e) => handleEnterKey(e)}
                placeholder={"0%"}
                className={`text-sm block p-4 pl-4 w-full focus:outline-none drop-shadow-xl`}
                onFocus={(e) => {
                  if (e.currentTarget === e.target) {
                    setIsFocused(true);
                    percentage[index] = group?.percentage;

                    // setIsFocused(true);
                    //dispatch(searchActions.searchLoadError(""));
                  } else {
                    setIsFocused(false);
                  }
                }}
                onBlur={(e) => {
                  // setIsFocused(false);
                }}
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                type="number"
              />
              <div className="flex flex-row" style={{ width: "95%" }}>
                <button
                  className="py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-1/3"
                  onClick={() => {
                    substractPerc(index);
                  }}
                  type="button"
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  -
                </button>

                <button
                  className="py-3 bg-accent px-3 text-white text-m mx-auto mt-4 w-1/3"
                  onClick={() => {
                    addPerc(index);
                  }}
                  type="button"
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        ))}

        <div
          onClick={() => setIsOpen(!isOpen)}
          style={isOpen ? { width: "100%" } : { width: "100%", height: "12%" }}
          className="mt-8 mb-5 flex flex-col py-5 px-5 bg-[#FFF9ED] rounded-sm"
        >
          <div className="flex flex-row justify-between items-center">
            <span className="text-base">How does it work</span>
            <img src={QuestionIcon} className="" alt="question-mark" />
          </div>
          {isOpen && (
            <span className="text-sm mt-2">
              The distribution percent is the portion from the total that is
              assigned to this group. Example, a 20% distribution means this
              group will get 20% of the total tips which is then divided across
              the members.
            </span>
          )}
        </div>
        <div
          className="mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isLoading ? (
            <button
              className={`rounded-full py-3 bg-accent px-3 text-white text-m mx-auto mt-4 mb-4 w-full`}
              onClick={() => {
                updateAllGroups();
              }}
              // disabled={perc >= 100}
              type="button"
            >
              CHANGE DISTRIBUTION
            </button>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MoonLoader size={25} loading={true} color="#F2C773" />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
