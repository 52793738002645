import React, { useState, useRef, useEffect } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import { useNavigate, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";

import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { useForm } from "react-hook-form";
import { accessTips } from "../../state/reducers/user/user.thunk";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

export default function AccessTipAmount() {
  const [accessAmount, setAccessAmount] = useState();
  const [isInsufficient, setIsInsufficient] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});
  // state for popup
  const [isClicked, setIsClicked] = useState(false);
  const [togglePopup, setTogglePopup] = useState(false);
  const userData = useSelector((state) => state.account.userDetails);
  const saccoState = useSelector((state) => state.sacco);
  const balanceData = useSelector((state) => state.balance);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleAccessTip = (data) => {
    setAccessAmount(data.accessTip);
    setTogglePopup(true);
  };
  const handleAccess = () => {
    let payload = {
      amount: accessAmount,
    };
    dispatch(accessTips(payload, userData.token));
  };

  useEffect(() => {
    if (saccoState.navigate.navigate === "/") {
      navigate("/");
      dispatch(saccoActions.checkAccessTipRedirect(""));
    }
    return () => {};
  }, [saccoState]);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (saccoState.errorMessage.status === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (saccoState.errorMessage.status === 403 && !isLoggedIn) {
      navigate("/registration");
    }
    return () => {};
  }, [saccoState, navigate]);

  const ConfirmDepositPopup = () => (
    <div className="shadow py-8 px-6 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg">Confirm Amount</span>
        <div
          onClick={() => {
            setTogglePopup(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">Sending To:</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">
              {userData.depositNumber}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">Access Amount</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">{accessAmount}</span>
          </div>
        </div>
      </div>
      <div className="mt-8">
        {!saccoState.results.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            disabled={!saccoState.isLoading ? false : true}
            onClick={() => {
              setTogglePopup(false);
              handleAccess();
            }}
          >
            SEND REQUEST
          </button>
        )}
      </div>
    </div>
  );
  const DepositTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.accessTipSuccess({}));
          dispatch(saccoActions.checkAccessTipError(""));
          // addShukranId({})
          navigate("/");
        }}
      >
        X
      </button>
      {saccoState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1`}
        />
      )}
      {saccoState.results.success && !saccoState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-32" />
      )}
      {(saccoState.errorTransactionStatusMessage ||
        saccoState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-32" />
      )}
      {/* {saccoState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          Input your PIN on the Mpesa prompt to make a deposit
        </p>
      )} */}
      {/* {saccoState.results.success && saccoState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.results.msg}
        </p>
      )} */}
      {saccoState.results.success && !saccoState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.results.msg}
        </p>
      )}
      {saccoState.errorMessage?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage?.msg}
        </p>
      )}
      {saccoState.errorTransactionStatusMessage?.data?.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorTransactionStatusMessage?.data?.msg}
        </p>
      )}
      {saccoState.errorTransactionStatusMessage?.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          Access tip transaction cancelled
        </p>
      )}
      {saccoState.errorTransactionStatusMessage?.data?.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorTransactionStatusMessage?.data?.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
        ${isInsufficient ? "relative items-center" : ""}
        ${saccoState.errorMessage ? "relative" : ""}
        ${saccoState.errorTransactionStatusMessage ? "relative" : ""} 
        ${
          togglePopup &&
          !saccoState.isLoading &&
          !saccoState.results.success &&
          !saccoState.errorMessage &&
          !saccoState.errorTransactionStatusMessage
            ? "relative"
            : ""
        }
        w-screen flex flex-col h-full`}
    >
      <div
        className={`
          ${isInsufficient ? "blur-sm" : ""}
          ${saccoState.errorMessage ? "blur-sm" : ""}
          ${
            togglePopup &&
            !saccoState.isLoading &&
            !saccoState.results.success &&
            !saccoState.errorMessage &&
            !saccoState.errorTransactionStatusMessage
              ? "blur-sm"
              : ""
          }
          
          ${saccoState.errorTransactionStatusMessage ? "blur-sm" : ""} 
          flex flex-col w-full h-full px-5`}
      >
        {/* <DashBoardHeader /> */}
        <div className="flex flex-row items-center mt-9">
          <Link to={"/accessTips"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="bg-white flex flex-col h-full">
          <p className="mt-5 text-3xl font-semibold text-primary-pwa">
            How much would you like today?
          </p>

          <form
            className="flex flex-col mt-5 h-full"
            onSubmit={handleSubmit(handleAccessTip)}
          >
            <label className="text-base mb-1">Amount</label>
            <input
              type="number"
              className="border-solid border-2 border-[#ddedee]-600 outline-none rounded-md h-12 w-full p-2 text-black italic"
              placeholder="300"
              {...register("accessTip", {
                required: true,
                maxLength: 5,
              })}
            />
            {errors.accessTip && (
              <p className="text-red-600 text-sm">
                Ensure you have entered an amount
              </p>
            )}
            <button
              type="submit"
              className="rounded-lg py-2 bg-primary-green text-white text-base w-full mt-20"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
      <div
        className={`${
          togglePopup &&
          !saccoState.isLoading &&
          !saccoState.results.success &&
          !saccoState.errorMessage
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col pt-16 items-center w-full h-full
        `}
      >
        <ConfirmDepositPopup />
      </div>
      <div
        className={`${
          saccoState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
        </div>
      </div>
      <div
        className={`${saccoState.errorMessage ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <div className="p-4 bg-white pt-10 drop-shadow-lg w-4/5 flex flex-col relative pb-4 rounded-3xl z-50">
          <button
            className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
            onClick={() => {
              dispatch(saccoActions.accessTipError(""));
            }}
          >
            X
          </button>
          <img src={NotFoundIcon} alt="error-img" className="h-16" />

          <h2
            className={
              saccoState.errorMessage
                ? "text-center font-bold text-lg mt-2"
                : "hidden"
            }
          >
            Access Tip Error
          </h2>
          <p
            className={
              saccoState.errorMessage ? "text-lg text-center py-1" : "hidden"
            }
          >
            {saccoState.errorMessage?.data?.msg}
          </p>
        </div>
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          saccoState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
    </div>
  );
}
