import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { LoginSuccessResponse } from "../types/Auth";

// TODO: Add user type
type User = LoginSuccessResponse["data"];

interface UserStore {
  onboarded: boolean;
  setOnboarded: (onboarded: boolean) => void;
  user: User | null;

  accessTipsActivated?: boolean;
  setAccessTipsActivated?: (accessTipsActivated: boolean) => void;
  canAccessDashboard: boolean;
  biometricsDetails: {
    isBiometricAvailable: boolean;
    biometricType: "Face ID" | "Touch ID" | string | number;
  } | null;
  setBiometricsDetails: (biometricsDetails: {
    isBiometricAvailable: boolean;
    biometricType: string | number;
  }) => void;
  hasEnabledBiometrics: boolean;
  setHasEnabledBiometrics: (hasEnabledBiometrics: boolean) => void;
  setCanAccessDashboard: (canAccessDashboard: boolean) => void;
  setUserInfo: (user: User) => void;
  getUserInfo: () => User;
  tokens: {
    accessToken: string;
    refreshToken: string;
  } | null;
  setTokens: ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) => void;
  getTokens: () => { accessToken: string; refreshToken: string };
  logout: () => void;
  resetAll: () => void;
}

export const userStore = create(
  persist<UserStore>(
    (set, get) => ({
      canAccessDashboard: false,
      setCanAccessDashboard: (canAccessDashboard: boolean) => {
        set({ canAccessDashboard });
      },
      onboarded: false,
      setOnboarded: (onboarded: boolean) => {
        set({ onboarded });
      },
      user: null,
      setUserInfo: (user: User) => {
        set({ user: user });
      },
      getUserInfo: () => {
        return get().user as User;
      },
      tokens: null,
      setTokens: ({ accessToken, refreshToken }) => {
        set({ tokens: { accessToken, refreshToken } });
      },
      getTokens: () => {
        return get().tokens as { accessToken: string; refreshToken: string };
      },
      accessTipsActivated: false,
      hasEnabledBiometrics: false,
      biometricsDetails: null,
      setBiometricsDetails(biometricsDetails) {
        set({ biometricsDetails });
      },
      setHasEnabledBiometrics: (hasEnabledBiometrics: boolean) => {
        set({ hasEnabledBiometrics });
      },
      setAccessTipsActivated: (accessTipsActivated: boolean) => {
        set({ accessTipsActivated });
      },
      logout: () => {
        set({ tokens: null, user: null });
      },
      resetAll: () => {
        set({
          onboarded: false,
          user: null,
          tokens: null,
          accessTipsActivated: false,
          hasEnabledBiometrics: false,
          biometricsDetails: null,
        });
      },
    }),
    {
      name: "user",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default userStore;
