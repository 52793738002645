import React, { useRef, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";

import { getFromSessionStorage } from "../../Services/Storage";
import actions from "../../state/index";
import logoutIcon from "../../assets/Icons/Profile/log_out.svg";
import saccoIcon from "../../assets/Icons/Profile/sacco.svg";
import accessIcon from "../../assets/Icons/Profile/access_tips.svg";
import RightIcon from "../../assets/Icons/Profile/right_icon.svg";
import feedbackIcon from "../../assets/Icons/Profile/feedback.svg";
import arrowButtonPrev from "../../assets/Icons/Onboarding/ArrowButtonPrev.svg";
import customerSupport from "../../assets/Icons/Profile/customer_care.svg";
import cardIcon from "../../assets/Icons/Profile/card.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import accountIcon from "../../assets/Icons/Profile/my_account.svg";
import squareEdit from "../../assets/Icons/Profile/square_edit.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  changePassword,
  getUserData,
  updateProfile,
} from "../../state/reducers/user/user.thunk";

import { resetActions } from "../../state/reducers/reset/reset.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";

const ProfileAndroidPage = () => {
  const [isPasswordForm, setIsPasswordForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [confirmError, setConfirmError] = useState("");
  const [logOutPrompt, setLogOutPrompt] = useState("");
  const [encodeUrl, setEncodeUrl] = useState("");
  const navigate = useNavigate();

  const subscriptionState = useSelector((state) => state.subscription);
  const userAccount = useSelector((state) => state.account);
  const userInfo = useSelector((state) => state.balance.userInfo);
  const profileState = useSelector((state) => state.profile);

  console.log("userInfo", JSON.stringify(userInfo));
  const {
    // userDetails: { firstName, lastName, shukranId, phoneNumber },
    isAuthenticated,
  } = userAccount;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // password form state

  const dispatch = useDispatch();
  const { logOut, addShukranId } = bindActionCreators(actions, dispatch);

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    let confirmCode = localStorage.getItem("confirmCode");
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);

    console.log("isLoggedIn", isLoggedIn);

    if (!isAuthenticated && isLoggedIn) {
      navigate("/login");
    }
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    let encodeUrl = "https://wa.link/jui9hq";
    encodeUrl = encodeURI(encodeUrl);
    setEncodeUrl(encodeUrl);
    dispatch(getUserData(params, userAccount.userDetails.token));
  }, []);

  useEffect(() => {
    if (profileState.errorMessage === 403) {
      navigate("/login");
    }
    return () => {};
  }, [profileState]);

  const LogoutPrompt = () => (
    <div className="px-16 drop-shadow-xl w-11/12 bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setLogOutPrompt(false);
        }}
      >
        X
      </button>
      <p className="justify-self-center text-lg text-center py-2">
        Logging Out
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        Are you sure you want to log out of your Shukran account
      </p>
      <button
        type="button"
        onClick={() => {
          navigate("/login");
          logOut();
          localStorage.setItem("isLoggedIn", JSON.stringify(false));
          balanceActions.getUserDataSuccess({});
          setLogOutPrompt(false);
        }}
        className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-2 px-20 py-4"
      >
        Yes
      </button>
    </div>
  );

  const handleProfileSave = (data) => {
    let payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      role: userInfo.user_type,
      email: data.email ? data.email : "user@shukran.co",
    };
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    console.log("profile token", userAccount.userDetails.token);
    dispatch(updateProfile(payload, userAccount.userDetails.token));
    dispatch(getUserData(params, userAccount.userDetails.token));
  };

  const handlePasswordSave = (data) => {
    if (data.newPass !== data.confirmPass) {
      setConfirmError("New and Confirm values don't match");
    } else {
      let params = {
        // shukran_id: getFromSessionStorage("ShukranId"),
        old_password: data.currPass,
        new_password: data.newPass,
        // token: getFromSessionStorage("token"),
      };
      console.log("change password", params);
      dispatch(changePassword(params, userAccount.userDetails.token));
    }
  };

  const EditForm = () => (
    <form onSubmit={handleSubmit(handleProfileSave)} className="mt-8">
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">First Name</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("first_name", {
              required: true,
              value: userInfo.first_name,
            })}
            placeholder="Jane"
          />
        </div>
      </div>
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Last Name</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("last_name", {
              required: true,
              value: userInfo.last_name,
            })}
            placeholder="Doe"
          />
        </div>
      </div>
      {/* <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Role</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("role", {
              required: true,
              value: userInfo.user_type,
            })}
            placeholder="Tipper"
          />
        </div>
      </div> */}
      <div className="flex flex-col mt-7 h-14 mb-2">
        <label className="text-subtle italic text-sm">Email</label>
        <div className="flex flex-row items-center">
          <input
            type="text"
            className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
            {...register("email", {
              required: false,
              value: userInfo.email,
            })}
            placeholder="joe@gmail.com"
          />
        </div>
      </div>
      <button
        type="submit"
        className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-7 px-20 py-4"
      >
        Save
      </button>
    </form>
  );

  const SuccessRating = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-lg text-center py-2">
        {profileState.results.msg}
      </p>
    </div>
  );

  return (
    <div
      className={`
      ${isPasswordForm ? "relative" : ""}
      ${logOutPrompt ? "relative" : ""}
      ${profileState.isLoading ? "relative" : ""}
      ${profileState.results.success ? "relative" : ""}
      ${
        subscriptionState.subscriptionRes?.subscription_status ? "" : "relative"
      }
      w-full h-screen flex flex-col justify-center items-center
    `}
    >
      <div
        className={`
          ${isPasswordForm ? "blur-sm" : ""}
          ${logOutPrompt ? "blur-sm" : ""}
          ${profileState.isLoading ? "blur-sm" : ""}
          ${profileState.results.success ? "blur-sm" : ""}
          w-full px-5 h-full bg-imag-dash
        `}
      >
        {/* Top navigation bar */}
        <div className="flex flex-row justify-between mt-8 items-center">
          <Link to={"/"} className="">
            <img
              src={arrowButtonPrev}
              className="rounded-full"
              alt="rate-star"
            />
          </Link>

          <p className="text-primary text-lg">Profile</p>
          <span></span>
          {/* <Link to="/login">
            <img
              
              src={logoutIcon}
              alt="Logout"
              className="iconSize"
            />
          </Link> */}
        </div>
        {/* User Details */}
        <div className="flex flex-row items-center bg-primary-green justify-around mt-7 p-5 rounded-lg">
          <p className="text-white bg-black rounded-full p-5">
            {" "}
            {userInfo.shukran_id ? userInfo.shukran_id.split("", 2) : ""}{" "}
          </p>
          <div className="flex flex-col items-start justify-around text-sm">
            <span className="pb-1 text-white">
              {userInfo.first_name} {userInfo.last_name}
            </span>
            <span className="pb-1 text-white">{userInfo.shukran_id}</span>
            {/* <span className="pb-1">{userInfo.phone}</span>
            <span className="pb-1">{userInfo.user_type}</span> */}
          </div>
          <div
            className=""
            onClick={() => {
              navigate("/profile/account");
            }}
          >
            <img src={squareEdit} alt="Star" className="pr-3" />
          </div>
        </div>
        <div className=" bg-white rounded-xl p-5 flex flex-col mt-5">
          <div className="flex flex-row mb-7">
            <img src={accountIcon} className="h-12 w-12" alt="profile-icon" />
            <div
              onClick={() => {
                navigate("/profile/account");
              }}
              className="flex flex-row justify-between w-full cursor-pointer"
            >
              <div className="flex flex-col justify-between pl-3">
                <span className="font-bold text-lg">My Account</span>
                <span className="font-light text-sm">
                  Make changes to your account
                </span>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img src={RightIcon} className="h-6 w-6" alt="profile-icon" />
              </div>
            </div>
          </div>
          {userInfo.sacco_stage === "pay_membership_fee" ||
            (userInfo.sacco_stage === "approval_pending" && (
              <div className="flex flex-row mb-7">
                <img src={saccoIcon} className="h-12 w-12" alt="profile-icon" />
                <div
                  onClick={() => {
                    navigate("/profile/sacco");
                  }}
                  className="flex flex-row justify-between w-full cursor-pointer "
                >
                  <div className="flex flex-col justify-between pl-3">
                    <span className="font-bold text-lg">SACCO Information</span>
                    <span className="font-light text-sm">
                      Check your sacco information
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={RightIcon}
                      className="h-6 w-6"
                      alt="profile-icon"
                    />
                  </div>
                </div>
              </div>
            ))}
          {userInfo.sacco_stage === "pay_membership_fee" && (
            <div
              onClick={() => {
                navigate("/adjustPercentage");
              }}
              className="flex flex-row mb-7 cursor-pointer"
            >
              <img src={accessIcon} className="h-12 w-12" alt="profile-icon" />
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col justify-between pl-3">
                  <span className="font-bold text-lg">Access Tips</span>
                  <span className="font-light text-sm">
                    Adjust your access tips
                  </span>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <img src={RightIcon} className="h-6 w-6" alt="profile-icon" />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-row mb-7">
            <img src={cardIcon} className="h-12 w-12" alt="card-icon" />
            <div
              onClick={() => {
                navigate("/creditOpt");
              }}
              className="flex flex-row justify-between w-full cursor-pointer"
            >
              <div className="flex flex-col justify-between pl-3">
                <span className="font-bold text-lg">Payment Methods</span>
                <span className="font-light text-sm">
                  Change or delete cards
                </span>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img src={RightIcon} className="h-6 w-6" alt="profile-icon" />
              </div>
            </div>
          </div>
          {userInfo.user_type === "TIPPER" && (
            <a
              href={encodeUrl}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer flex flex-row mb-7"
            >
              <img
                src={customerSupport}
                className="h-12 w-12"
                alt="card-icon"
              />
              <div className="flex flex-row justify-between w-full cursor-pointer">
                <div className="flex flex-col justify-between pl-3">
                  <span className="font-bold text-lg">Customer Support</span>
                  <span className="font-light text-sm">
                    Contact us for any query
                  </span>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <img src={RightIcon} className="h-6 w-6" alt="profile-icon" />
                </div>
              </div>
            </a>
          )}
          <div className="flex flex-row mb-7">
            <img src={feedbackIcon} className="h-12 w-12" alt="profile-icon" />
            <div
              onClick={() => {
                addShukranId({});
                navigate("/rate");
              }}
              className="flex flex-row justify-between w-full cursor-pointer"
            >
              <div className="flex flex-col justify-between pl-3">
                <span className="font-bold text-lg">Feedback & Rating</span>
                <span className="font-light text-sm">
                  Help us rate our services
                </span>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img src={RightIcon} className="h-6 w-6" alt="profile-icon" />
              </div>
            </div>
          </div>
          <div className="flex flex-row mb-7">
            <img src={logoutIcon} className="h-12 w-12" alt="profile-icon" />
            <div
              onClick={() => {
                setLogOutPrompt(true);
              }}
              className="flex flex-row justify-between w-full cursor-pointer"
            >
              <div className="flex flex-col justify-between pl-3">
                <span className="font-bold text-lg">Logout</span>
                <span className="font-light text-sm">
                  Further secure your account for safety
                </span>
              </div>
              <div className="flex flex-col justify-center items-center">
                <img src={RightIcon} className="h-6 w-6" alt="profile-icon" />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center mt-7 mb-4">
            <span
              className="text-primary-pwa underline text-lg cursor-pointer"
              onClick={() => {
                navigate("/finalWithdrawal");
              }}
            >
              Withdraw
            </span>
          </div>
        </div>
        {isEditForm && <EditForm />}
      </div>
      {/* {!isIOS.isIOS && (
        <NotificationPrompt
          isSubscribed={subscriptionState.subscriptionRes?.subscription_status}
          userToken={userAccount.userDetails.token}
        />
      )} */}
      <div
        className={`${
          logOutPrompt ? "absolute blur-none" : "hidden"
        } flex flex-col items-center pt-36 w-full h-full`}
      >
        <LogoutPrompt />
      </div>
      <div
        className={`${
          profileState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-4/5 h-full`}
      >
        <SuccessRating />
      </div>
      <div
        className={`${
          profileState.isLoading ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mb-1`}
        />
      </div>
    </div>
  );
};

export default ProfileAndroidPage;
