import React, { useRef, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";

import { getFromSessionStorage } from "../../Services/Storage";
import actions from "../../state/index";
import back from "../../assets/Icons/Rating/back_icon.svg";
import squareEdit from "../../assets/Icons/Profile/green_square_edit.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  getSaccoInfo,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";

import useIsIOS from "../../components/popups/useIsIos";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

let sampleReviews = [];

const SaccoInfo = () => {
  const [isPasswordForm, setIsPasswordForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [confirmError, setConfirmError] = useState("");

  const [dayOfBirth, setDayOfBirth] = useState("");
  const [monthOfBirth, setMonthOfBirth] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [isIOS] = useIsIOS();
  const navigate = useNavigate();

  const subscriptionState = useSelector((state) => state.subscription);
  const userAccount = useSelector((state) => state.account);
  const userInfo = useSelector((state) => state.balance.userInfo);
  const saccoState = useSelector((state) => state.sacco);
  const profileState = useSelector((state) => state.profile);
  let ratingsState = useSelector((state) => state.rating);
  let ratings =
    ratingsState.ratings?.rateddata?.length > 0
      ? ratingsState.ratings.rateddata.slice(0, 6)
      : sampleReviews;
  console.log("ratings", ratings);
  const {
    // userDetails: { firstName, lastName, shukranId, phoneNumber },
    isAuthenticated,
  } = userAccount;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // password form state
  const currPasswordRef = useRef();

  const dispatch = useDispatch();
  const { logOut } = bindActionCreators(actions, dispatch);

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);
    if (!isAuthenticated) {
      navigate("/login");
    }

    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    let hasSubscribedBool = localStorage.getItem("hasSubscribed");
    console.log("profile user data token", userAccount.userDetails.token);
    dispatch(getSaccoInfo(userAccount.userDetails.token));
    if (isAuthenticated) {
    }
  }, []);

  useEffect(() => {
    if (profileState.errorMessage === 403) {
      navigate("/login");
    }
    return () => {};
  }, [profileState]);
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (saccoState.errorMessage.status === 403 && isLoggedIn) {
      navigate("/login");
    }
    if (saccoState.errorMessage.status === 403 && !isLoggedIn) {
      navigate("/splashScreen");
    }
    return () => {};
  }, [saccoState, navigate]);

  const handleUpper = (STRing) => {
    return STRing.toUpperCase();
  };

  const handleBirthDate = (dob) => {
    let splitDob = dob.split("-");
    setDayOfBirth(splitDob[2]);
    setMonthOfBirth(splitDob[1]);
    setYearOfBirth(splitDob[0]);
  };

  const formatDob = (beneficiary) => {
    let dob =
      beneficiary.dobYear +
      "-" +
      beneficiary.dobMonth +
      "-" +
      beneficiary.dobDay;
    return dob;
  };
  const handleSaccoSave = (data) => {
    console.log("beneficiaries info", data);
    let userCount = data.nominees.length;
    console.log("beneficiaries count", userCount);
    let formattedBeneficiaries = data.nominees.slice(0, userCount);
    let dob = formatDob(data);
    console.log("formatted", formattedBeneficiaries);
    let formDetails = saccoState.saccoInfo;
    console.log("formDetails", formDetails);
    formattedBeneficiaries = formattedBeneficiaries.map((nominee, idx) => {
      let selectedNominee = formDetails.nominees[idx];
      return {
        ...selectedNominee,
        name: nominee.name,
        phone_number: nominee.phone_number,
      };
    });
    formDetails = {
      ...formDetails,
      first_name: data.firstName,
      surname: data.surname,
      email: data.email,
      phone_number: data.primaryNumber,
      gender: data.gender,
      date_of_birth: dob,
      national_id_passport: data.nationalId,
      physical_address: data.physical_address ? data.physical_address : "N/A",
      nominees: JSON.stringify(formattedBeneficiaries),
    };
    console.log("form details", formDetails);
    let payloadData = new FormData();
    for (const key in formDetails) {
      if (
        key === "kra_pin_photo" ||
        key === "passport_photo" ||
        key === "id_passport_photo" ||
        key === "e_signature_photo"
      ) {
        // payloadData.append(key, new File(formDetails[key], key));
        // console.log("img/file[0]", formDetails[key][0].file);
        // console.log("img/file", formDetails[key][0]);
      } else if (
        key.includes("primary_") ||
        key.includes("dob") ||
        key === "guardian_mn" ||
        key === "user_count"
      ) {
        console.log("primary", key);
      } else {
        payloadData.append(key, formDetails[key]);
      }
    }
    console.log("primary", payloadData);
    dispatch(postActivateSacco(payloadData, userAccount.userDetails.token));
  };

  const EditForm = () => (
    <form
      onSubmit={handleSubmit(handleSaccoSave)}
      className="mt-3 w-full h-full"
    >
      <span className="text-lg font-semibold">Personal Details</span>
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">First Name</label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="text"
          placeholder="Doe"
          {...register("firstName", {
            required: true,
            value: saccoState.saccoInfo?.first_name,
          })}
        />
        {errors.firstName && (
          <p className="text-red-600 text-sm">First Name required</p>
        )}
      </div>
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">Surname </label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="text"
          placeholder="Doe"
          {...register("surname", {
            required: true,
            value: saccoState.saccoInfo?.surname,
          })}
        />
        {errors.surname && (
          <p className="text-red-600 text-sm">surname required</p>
        )}
      </div>
      {/* <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">
          Shukran ID/ Unique No.
        </label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="text"
          placeholder="MW0003"
          {...register("shukranId", {
            required: true,
            value: saccoState.saccoInfo?.shukran_id,
          })}
        />
        {errors.shukranId && (
          <p className="text-red-600 text-sm	">Last Name required</p>
        )}
      </div> */}
      {/* <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">Account No.</label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="text"
          placeholder="Doe"
          {...register("accountNo", {
            required: true,
            value: saccoState.saccoInfo?.account_number,
          })}
        />
        {errors.accountNo && (
          <p className="text-red-600 text-sm	">account Number required</p>
        )}
      </div> */}
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">Email address</label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="email"
          placeholder="jd@gmail.com"
          {...register("email", {
            required: true,
            value: saccoState.saccoInfo?.email,
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          })}
        />
        {errors.email && <p className="text-red-600 text-sm">Email required</p>}
      </div>
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">Primary Number </label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="number"
          placeholder="0700000000"
          {...register("primaryNumber", {
            required: true,
            // maxLength: 10,
            value: saccoState.saccoInfo?.phone_number,
            // pattern:
            //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
          })}
        />
        {errors.primaryNumber && (
          <p className="text-red-600 text-sm">
            Ensure you have entered in a valid phone number
          </p>
        )}
      </div>
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">National ID</label>
        <input
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          type="number"
          placeholder="3103XXX"
          {...register("nationalId", {
            required: true,
            value: saccoState.saccoInfo?.national_id_passport,
            pattern: /^[0-9]{7,8}$|^[A-Z]{1,2}[0-9]{6,8}$/,
          })}
        />
        {errors.nationalId && (
          <p className="text-red-600 text-sm">Please enter national ID</p>
        )}
      </div>
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">Gender</label>
        <select
          className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
          {...register("gender", {
            required: true,
            value: saccoState.saccoInfo?.gender,
          })}
          placeholder="Male"
        >
          <option value="female" className="w-10/12">
            Female
          </option>
          <option value="male" className="w-10/12">
            Male
          </option>
          <option value="other" className="w-10/12">
            Other
          </option>
        </select>
        {errors.gender && (
          <p className="text-red-600 text-sm">Please select a gender</p>
        )}
      </div>
      <div className="flex flex-col mt-1">
        <label className="mt-2 text-label text-xs">Date of Birth</label>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-col w-1/3">
            <label className="text-label text-xs my-1">Month</label>
            <select
              className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
              {...register("dobMonth", {
                required: true,
                value: monthOfBirth,
              })}
              placeholder="April"
            >
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            {errors["dobMonth"] && (
              <p className="text-red-600 text-sm">Please select a month</p>
            )}
          </div>
          <div className="flex flex-col w-1/4">
            <label className="text-label text-xs my-1">Day </label>
            <input
              className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
              type="number"
              placeholder="12"
              {...register("dobDay", {
                required: true,
                maxLength: 2,
                min: 1,
                max: 31,
                value: dayOfBirth,
              })}
            />
            {errors["dobDay"] && (
              <p className="text-red-600 text-sm">
                Ensure you have entered in a date
              </p>
            )}
          </div>
          <div className="flex flex-col w-1/4">
            <label className="text-label text-xs my-1">Year </label>
            <input
              className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
              type="number"
              placeholder="1990"
              {...register("dobYear", {
                required: true,
                value: yearOfBirth,
                maxLength: 4,
                min: 1963,
                max: 2004,
              })}
            />
            {errors["dobYear"] && (
              <p className="text-red-600 text-sm">
                Ensure you have entered in a valid Year
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="text-lg font-semibold mt-5 mb-2">
          Next Of Kin Details
        </div>
        {saccoState.saccoInfo?.nominees.map((nominee, idx) => {
          return (
            <div key={idx} className="mb-6">
              <span className="mt-2 bg-gray-400 rounded-sm text-white px-2 py-1 text-sm">
                Nominee {idx + 1}
              </span>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Primary Number{" "}
                </label>
                <input
                  className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
                  type="number"
                  placeholder="0700000000"
                  {...register(`nominees.${idx}.phone_number`, {
                    required: true,
                    value: nominee.phone_number,
                    // maxLength: 10,
                    // pattern:
                    //   /^(0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/,
                  })}
                />
                {errors.nominees?.[idx]?.phone_number && (
                  <p className="text-red-600 text-sm">
                    Ensure you have entered in a phone number
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-1">
                <label className="mt-2 text-label text-xs">
                  Name {nominee.name}
                </label>
                <input
                  className="border-solid border-b-2 border-b-[#ddedee]-600 focus:border-b-[#64C8CE] outline-none h-12 w-full py-2 text-black"
                  type="text"
                  placeholder="Doe"
                  {...register(`nominees.${idx}.name`, {
                    required: true,
                    value: nominee.name,
                  })}
                />
                {errors.nominees?.[idx]?.name && (
                  <p className="text-red-600 text-sm">Nominee name required</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <button
        type="submit"
        className="rounded-full bg-[#64C8CE] text-white text-lg w-full mt-7 px-20 py-4"
      >
        Save
      </button>
    </form>
  );

  const SaccoActivationError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage.msg}
        </p>
      )}
      {saccoState.errorMessage.status === 404 && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage.data.msg}
        </p>
      )}
      {saccoState.errorMessage.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage.data.data)[0]}
        </p>
      )}
      {saccoState.errorMessage.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/");
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation has been sent for approval. You will be contact in 2 to 5 business days"
          : ""}
      </p>
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          dispatch(saccoActions.saccoActivateSuccess({}));
          navigate("/saccoDepositOpt");
        }}
      >
        Pay Membership Fee
      </button>
    </div>
  );

  return (
    <div
      className={`
      ${isPasswordForm ? "relative" : ""}
      ${saccoState.isLoading ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""}
      w-full flex flex-col justify-center items-center
    `}
    >
      <div
        className={`
        ${isPasswordForm ? "blur-sm" : ""}
       ${saccoState.isLoading ? "blur-sm" : ""}
        ${saccoState.errorMessage ? "blur-sm" : ""}
        w-full h-full bg-imag-dash
      `}
      >
        {/* Top navigation bar */}
        <div className="flex flex-row justify-between mt-8 items-center px-5">
          <Link to={"/profile/android"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          <p className="text-primary text-lg">Account</p>
          <span></span>
        </div>
        {/* User Details */}
        <div className=" bg-white h-full rounded-t-3xl p-5 flex flex-col items-center mt-40 w-full relative">
          <p className="text-white bg-black rounded-full p-5 absolute -top-6">
            {" "}
            {userInfo.shukran_id ? userInfo.shukran_id.split("", 2) : ""}{" "}
          </p>
          {!isEditForm ? (
            <div className="flex flex-col h-4/5 items-start justify-between w-full pt-5">
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between items-center w-full mb-3">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold">
                      Personal Details
                    </span>
                  </div>
                  <div
                    className="flex flex-col items-center"
                    onClick={() => {
                      handleBirthDate(saccoState.saccoInfo?.date_of_birth);
                      setIsEditForm(true);
                    }}
                  >
                    <img src={squareEdit} className="" alt="rate-star" />
                  </div>
                </div>
                <span className="text-sm mb-2 font-light">Name</span>
                <span className="pb-4 text-base font-semibold">
                  {saccoState.saccoInfo?.first_name +
                    " " +
                    saccoState.saccoInfo?.middle_name +
                    " " +
                    saccoState.saccoInfo?.surname}
                </span>
                <span className="text-sm mb-2 font-light">Shukran ID</span>
                <span className="pb-4 text-base font-semibold">
                  {userInfo.shukran_id}
                </span>
                <span className="text-sm mb-2 font-light">
                  Sacco Account Number
                </span>
                <span className="pb-4 text-base font-semibold">
                  {userInfo.shukran_id}
                </span>
                <span className="text-sm mb-2 font-light">Shukran ID</span>
                <span className="pb-4 text-base font-semibold">
                  {userInfo.shukran_id}
                </span>
                <span className="text-sm mb-2 font-light">Phone Number</span>
                <span className="pb-4 text-base font-semibold">
                  {saccoState.saccoInfo?.phone_number}
                </span>
                <span className="text-sm mb-2 font-light">Email</span>
                <span className="pb-4 text-base font-semibold">
                  {saccoState.saccoInfo?.email}
                </span>
                <span className="text-sm mb-2 font-light">
                  National ID or Passport No
                </span>
                <span className="pb-4 text-base font-semibold">
                  {saccoState.saccoInfo?.national_id_passport}
                </span>
                <span className="text-sm mb-2 font-light">Gender</span>
                <span className="pb-4 text-base font-semibold">
                  {saccoState.saccoInfo?.gender}
                </span>
                <span className="text-sm mb-2 font-light">Date of Birth</span>
                <span className="pb-4 text-base font-semibold">
                  {saccoState.saccoInfo?.date_of_birth}
                </span>
                <span className="text-sm mb-2 font-light">Shukran ID</span>
                <span className="pb-4 text-base font-semibold">
                  {userInfo.shukran_id}
                </span>

                <span className="text-lg font-semibold mt-5 mb-2">
                  Next Of Kin Details
                </span>
                {saccoState.saccoInfo?.nominees.map((nominee, idx) => (
                  <>
                    <span className="my-2 w-1/5 underline rounded-sm px-1 py-1 text-sm">
                      Nominee {idx + 1}
                    </span>
                    <span className="text-sm mb-2 font-light">Name</span>
                    <span className="pb-4 text-base font-semibold">
                      {nominee.name}
                    </span>
                    <span className="text-sm mb-2 font-light">
                      Phone Number
                    </span>
                    <span className="pb-4 text-base font-semibold">
                      {nominee.phone_number}
                    </span>
                  </>
                ))}
              </div>
            </div>
          ) : (
            <EditForm />
          )}
        </div>
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <div className="bg-white py-5 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
    </div>
  );
};

export default SaccoInfo;
