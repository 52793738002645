import { actionTypes } from "./registration.actions";

const registrationState = {
  isLoading: false,
  phone: "",
  results: [],
  errorMessage: "",
  registrationError: "",
  requestRes: [],
  confirmRes: [],
};

const checkIfObj = (payload) => {
  if (payload.constructor.name === "Object") {
    let madeArr = [payload];
    console.log("array payload", madeArr);
    return madeArr;
  } else {
    return payload;
  }
};

const registrationReducer = (state = registrationState, { type, payload }) => {
  switch (type) {
    case actionTypes.REQUEST_OTP_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        requestRes: [],
      };
    case actionTypes.REQUEST_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestRes: payload,
      };
    case actionTypes.REQUEST_OTP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
		case actionTypes.CONFIRM_OTP_START:
			return {
				...state,
				isLoading: true,
				errorMessage: "",
				confirmRes: [],
			};
		case actionTypes.CONFIRM_OTP_SUCCESS:
			return {
				...state,
				isLoading: false,
				confirmRes: payload,
			};
		case actionTypes.CONFIRM_OTP_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: payload,
			};
    default:
      return state;
  }
};
export default registrationReducer;
