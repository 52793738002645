import { useState, useEffect } from "react";

import moment from "moment"; // <-- new

export function checkForIOS() {
  const today = moment().toDate();
  const lastPrompt = moment(localStorage.getItem("installPrompt"));
  const days = moment(today).diff(lastPrompt, "days");
  let dailyDiff = moment().startOf("day").fromNow();
  dailyDiff = dailyDiff.split(" ")[0];

  let hidePrompt = localStorage.getItem("hidePrompt")
    ? localStorage.getItem("hidePrompt")
    : "false";
  hidePrompt = JSON.parse(hidePrompt);

  const ua = window.navigator.userAgent;
  console.log('ua', ua)
  const isAndroidAppWebview = /Android.*(?:wv).*/i.test(ua);
  const isiPhoneWebBrowser = /iPhone.*Safari(?!.wv)/i.test(ua);
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  const isIOS = isIPad || isIPhone;
 
  if (navigator.standalone) {
    return { isIOS: isIOS };
  }
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);
  const device = !isIOS ? 'android' :'ios';
  const platform = isAndroidAppWebview && device === 'android'
    ? 'app'
    : !isAndroidAppWebview && device === 'android'
    ? 'web'
    : !isiPhoneWebBrowser && device === 'ios'
    ? 'app'
    : isiPhoneWebBrowser && device === 'ios'
    ? 'web'
    : ''

  // console.log("prompt", dailyDiff.split(" ")[0]);
  const prompt =
    // (isNaN(days) || days > 1)
    dailyDiff > 15 && isIOS && isSafari && !hidePrompt;

  const androidPrompt =
    // (isNaN(days) || days > 1)
    dailyDiff > 8 && !isIOS && !hidePrompt;

  if ((prompt || androidPrompt) && "localStorage" in window) {
    localStorage.setItem("installPrompt", today);
  }

  const setHidePrompt = () => {
    localStorage.setItem("hidePrompt", true);
  };
  // console.log("prompt", prompt);
  // console.log("android prompt", androidPrompt);
  return { 
    platform,
    device,
    isIOS,
    isSafari, 
    prompt, 
    androidPrompt, 
    hidePrompt,
    setHidePrompt 
  };
}

export default function useIsIOS() {
  const [isIOS, setIsIOS] = useState({});

  useEffect(() => {
    setIsIOS(checkForIOS());
    return () => console.log("CLEANUP INSTALL PROMPT", isIOS);
  }, []);

  return [isIOS, setIsIOS];
}
