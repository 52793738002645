// action Types
export const actionTypes = {
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  UPDATE_PROFILE_START: "UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  VERIFY_OTP_START: "VERIFY_OTP_START",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",
  SET_RECOVERY_PHONE: "SET_RECOVERY_PHONE"
};

// actions
const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START,
});

const resetPasswordSuccess = (results) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  payload: results,
});

const resetPasswordError = (errorMsg) => ({
  type: actionTypes.RESET_PASSWORD_ERROR,
  payload: errorMsg,
});

const forgotPasswordStart = () => ({
  type: actionTypes.FORGOT_PASSWORD_START,
});

const forgotPasswordSuccess = (results) => ({
  type: actionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: results,
});

const forgotPasswordError = (errorMsg) => ({
  type: actionTypes.FORGOT_PASSWORD_ERROR,
  payload: errorMsg,
});

const changePasswordStart = () => ({
  type: actionTypes.CHANGE_PASSWORD_START,
});

const changePasswordSuccess = (results) => ({
  type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  payload: results,
});

const changePasswordError = (errorMsg) => ({
  type: actionTypes.CHANGE_PASSWORD_ERROR,
  payload: errorMsg,
});

const verifyOtpStart = () => ({
  type: actionTypes.VERIFY_OTP_START,
});

const verifyOtpSuccess = (results) => ({
  type: actionTypes.VERIFY_OTP_SUCCESS,
  payload: results,
});

const verifyOtpError = (errorMsg) => ({
  type: actionTypes.VERIFY_OTP_ERROR,
  payload: errorMsg,
});

const updateProfileStart = () => ({
  type: actionTypes.UPDATE_PROFILE_START,
});

const updateProfileSuccess = (results) => ({
  type: actionTypes.UPDATE_PROFILE_SUCCESS,
  payload: results,
});

const updateProfileError = (errorMsg) => ({
  type: actionTypes.UPDATE_PROFILE_ERROR,
  payload: errorMsg,
});

const setRecoveryPhone = (phoneNo) => ({
  type: actionTypes.SET_RECOVERY_PHONE,
  payload: phoneNo,
});

export const resetActions = {
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordError,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordError,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileError,
  verifyOtpStart,
  verifyOtpSuccess,
  verifyOtpError,
  setRecoveryPhone,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordError
};
