import React from "react";
import "./customAmount.scss";

export default function CustomAmount({ type, placeholder, value, setValue }) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      className="customAmount"
    />
  );
}
