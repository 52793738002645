import React from "react";
import BaseOnboard from "./BoilerPlate/BaseOnboard";
import PageImage from "../../assets/Images/onboarding/Tipping.png";

export default function OnboardTip() {
  const textContent = "Select amount and tip the service worker";
  const contentTitle = "CHOOSE AMOUNT";

  return (
    <BaseOnboard
      pageImage={PageImage}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext="/startTipping"
      navigatePrev="/find"
    />
  );
}
