import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import VisaIcon from "../../assets/Icons/Tipping/visa.svg";
import MpesaIcon from "../../assets/Icons/Tipping/mpesa.svg";
import RightIcon from "../../assets/Icons/Tipping/right_icon.svg";

import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";

import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { depositActions } from "../../state/reducers/deposit/deposit.actions";
import { depositAmount } from "../../state/reducers/user/user.thunk";

export default function SaccoDepositOptions() {
  const [togglePopup, setTogglePopup] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [amounts, setAmounts] = useState([
    {
      option: "card",
      label: '"Credit/Debit card"',
    },
    {
      option: "mpesa",
      label: '"Mpesa"',
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let searchState = useSelector((state) => state.search);
  const userData = useSelector((state) => state.account.userDetails);
  const depositState = useSelector((state) => state.deposit);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const { isActivated, userType, saccoType } = balanceState;

  // user input handler
  useEffect(() => {
    console.log("isLoading", searchState.isLoading);
    return () => {};
  }, [searchState.isLoading]);

  //Handle enter key input

  useEffect(() => {
    if (depositState.errorMessage.status === 403) {
      navigate("/login");
      dispatch(depositActions.depositLoadError(""));
    }
    return () => {};
  }, [depositState]);

  useEffect(() => {
    if (depositState.navigate.navigate === "/") {
      navigate("/");
      dispatch(depositActions.checkTransactionRedirect(""));
    }
    return () => {};
  }, [depositState]);

  useEffect(() => {
    let depositNo = localStorage.getItem("depositNumber")
      ? localStorage.getItem("depositNumber")
      : "false";
    setPhoneNumber(depositNo);
    return () => {};
  }, []);

  const handleSetOption = (amount) => {
    console.log("option", amount);
    if (amount.option === "card") {
      // navigate("/depositCard");
    } else {
      if (phoneNumber) {
        setTogglePopup(true);
      } else {
        navigate("/depositMpesa");
      }
    }
  };
  const handleDeposit = () => {
    console.log("userData.token");
    let payload = {
      shukran_id: userData.shukranId,
      // amount: depositAmount.amount,
      amount: Number(500),
      phone: phoneNumber,
      token: userData.token,
    };

    dispatch(depositAmount(payload, userData.token));
  };

  const ConfirmDepositPopup = () => (
    <div className="shadow py-8 px-6 drop-shadow-lg w-4/5 mt-36 bg-white flex flex-col rounded-3xl z-50">
      <div className="flex flex-row items-center justify-between">
        <span className="text-lg">Confirm Deposit</span>
        <div
          onClick={() => {
            setTogglePopup(false);
          }}
        >
          <img src={cross} alt="cross-icon" className="h-6" />
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">From</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black w-36">{phoneNumber}</span>
          </div>
        </div>
      </div>
      <div className="mt-5 h-12">
        <label className="text-subtle text-sm">To</label>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center justify-between">
            <span className="text-lg text-black">
              Sacco Account ~ {userData.shukranId}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4 h-12">
        <label className="text-subtle text-sm">Deposit Amount</label>
        <div className="flex flex-row">
          <div className="flex flex-row items-center">
            <span className="text-lg text-black">Ksh 500</span>
          </div>
        </div>
      </div>

      <div className="mt-8">
        {!depositState.results.success && (
          <button
            className="mt-28 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full "
            disabled={!depositState.isLoading ? false : true}
            onClick={() => handleDeposit()}
          >
            PAY
          </button>
        )}
      </div>
    </div>
  );

  const DepositTransactionCard = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(depositActions.depositLoadStart({}));
          dispatch(depositActions.checkTransactionError(""));
          // addShukranId({})
          setTogglePopup(false);
          navigate("/");
        }}
      >
        X
      </button>
      {depositState.isTransactionLoading && (
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1`}
        />
      )}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <img src={checkCircle} alt="error-img" className="h-32" />
      )}
      {(depositState.errorTransactionStatusMessage ||
        depositState.errorMessage) && (
        <img src={NotFoundIcon} alt="error-img" className="h-32" />
      )}
      {depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          Input your PIN on the Mpesa prompt to make a deposit
        </p>
      )}
      {/* {depositState.results.success && depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg}
        </p>
      )} */}
      {depositState.results.success && !depositState.isTransactionLoading && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.results.msg +
            ".Our staff will contact you soon. Thank you!"}
        </p>
      )}
      {depositState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.msg}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.txn_status && (
        <p className="justify-self-center	text-base text-center py-2">
          Deposit transaction{" "}
          {depositState.errorTransactionStatusMessage.txn_status}
        </p>
      )}
      {depositState.errorTransactionStatusMessage.detail && (
        <p className="justify-self-center	text-base text-center py-2">
          {depositState.errorTransactionStatusMessage.detail}
        </p>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${depositState.errorMessage.success ? "relative" : ""}
      ${depositState.errorMessage.error ? "relative" : ""}
      ${depositState.isLoading ? "relative" : ""}
      ${depositState.results.success ? "relative" : ""}
      ${
        togglePopup && !depositState.isLoading && !depositState.results.success
          ? "relative"
          : ""
      }
      ${
        depositState.errorTransactionStatusMessage ? "relative" : ""
      } w-full flex flex-col
          `}
    >
      <div
        className={` ${depositState.errorMessage.success ? "blur-sm" : ""}
        
          ${
            togglePopup &&
            !depositState.isLoading &&
            !depositState.results.success
              ? "blur-sm"
              : ""
          }
          ${depositState.errorMessage.error ? "blur-sm" : ""}
          ${depositState.results.success ? "blur-sm" : ""}
          ${depositState.isLoading ? "blur-sm" : ""}
          ${depositState.errorTransactionStatusMessage ? "blur-sm" : ""}
          flex flex-col px-3 w-full h-screen`}
      >
        <div className="flex flex-row items-center mt-9">
          <Link to={"/"} className="">
            <img src={back} className="" alt="rate-star" />
          </Link>
          {/* <span className="text-base">Go back home</span> */}
        </div>
        <div className="mx-2 mt-5 h-full flex flex-col">
          <span className="text-3xl font-semibold text-primary-pwa">
            {isActivated
              ? "How would you like to Deposit to account?"
              : "Pay Ksh 500 membership fee to join SACCO"}
          </span>
          <span className="text-base mt-7">Deposit From:</span>
          <ul className="mt-4 bg-white rounded-md border-2 border-gray">
            {amounts.map((amount, idx) => (
              <li
                className={`flex flex-row justify-between items-center p-4 bg-accent-light-2 ${
                  idx === 0 ? "border-b-2" : ""
                } border-gray`}
                key={idx}
                onClick={() => handleSetOption(amount)}
              >
                <div className="flex flex-row items-center">
                  <img src={idx === 0 ? VisaIcon : MpesaIcon} alt="icon" />
                  <span className="ml-4">{amount.label}</span>
                </div>
                <img src={RightIcon} alt="icon" />
              </li>
            ))}
          </ul>
          <div className="mt-3 flex flex-col py-5 px-5 bg-[#F3F0FF] rounded-sm">
            <div className="flex flex-row justify-between items-center">
              <span className="text-base">How does it work</span>
              <img src={QuestionIcon} className="" alt="question-mark" />
            </div>
            <span className="text-sm mt-2">
              You can lipa pole pole for your membership fee, share capital and
              SACCO balance. Start with as little as 100 shillings.
            </span>
          </div>
        </div>
        <button
          className="rounded-full py-2 mb-4 bg-primary-green text-white text-base w-full"
          type="submit"
          onClick={() => {
            navigate("/");
          }}
        >
          Go To Dashboard
        </button>
      </div>
      <div
        className={`${
          togglePopup &&
          !depositState.isLoading &&
          !depositState.results.success &&
          !depositState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        }
        flex flex-col pt-16 items-center w-full h-full
        `}
      >
        <ConfirmDepositPopup />
      </div>
      <div
        className={`${
          depositState.isLoading ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center h-full w-full`}
      >
        <div className="shadow py-16 px-5 mt-20 drop-shadow-lg w-4/5 bg-white rounded-3xl z-50 flex flex-col items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-23 mx-auto text-sm mb-2`}
          />
          <span className="text-base text-center">
            An Mpesa prompt will appear on your phone shortly please to input
            pin to complete transaction
          </span>
        </div>
      </div>
      <div
        className={`${
          depositState.results.success ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
      <div
        className={`${
          depositState.errorTransactionStatusMessage
            ? "absolute blur-none"
            : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <DepositTransactionCard />
      </div>
    </div>
  );
}
