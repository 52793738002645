import { actionTypes } from "./reservation.actions";

const reservationState = {
  isLoading: false,
  isSearchLoading: false,
  isHistoryLoading: false,
  results: [],
  reservations: [],
  restaurants: [],
  restaurant: '',
  errorMessage: "",
  placeDetails: {},
  payloadDetails: {},
  reservationResponse: {}
}

const reservationReducer = (state = reservationState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_PLACE_DATA:
      return {
        ...state,
        placeDetails: {
          ...state.placeDetails,
          ...payload,
        },
      };
    case actionTypes.UPDATE_PAYLOAD_DETAILS:
      return {
        ...state,
        payloadDetails: {
          ...state.payloadDetails,
          ...payload,
        },
      };
      case actionTypes.UPDATE_RESTAURANT:
        return {
          ...state,
          restaurant: payload
        };
    case actionTypes.GET_RESERVATION_HISTORY_START:
      return {
        ...state,
        isHistoryLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.GET_RESERVATION_HISTORY_SUCCESS:
      return {
        ...state,
        isHistoryLoading: false,
        reservations: payload,
      };
    case actionTypes.GET_RESERVATION_HISTORY_ERROR:
      return {
        ...state,
        isHistoryLoading: false,
        errorMessage: payload,
      };
    case actionTypes.SEARCH_RESTAURANTS_START:
      return {
        ...state,
        isSearchLoading: true,
        errorMessage: "",
        restaurants: [],
      };
    case actionTypes.SEARCH_RESTAURANTS_SUCCESS:
      return {
        ...state,
        isSearchLoading: false,
        restaurants: payload,
      };
    case actionTypes.SEARCH_RESTAURANTS_ERROR:
      return {
        ...state,
        isSearchLoading: false,
        errorMessage: payload,
      };
    case actionTypes.MAKE_RESERVATION_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.MAKE_RESERVATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservationResponse: payload,
      };
    case actionTypes.MAKE_RESERVATION_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
       return state;
    }
};
export default reservationReducer;