// action Types
export const actionTypes = {
  SEARCH_BUSINESS_START: "SEARCH_BUSINESS_START",
  SEARCH_BUSINESS_SUCCESS: "SEARCH_BUSINESS_SUCCESS",
  SEARCH_BUSINESS_ERROR: "SEARCH_BUSINESS_ERROR",
  SEARCH_BUSINESS_CLEAR: "SEARCH_BUSINESS_CLEAR",
  UPDATE_SW_BUSINESS_START: "UPDATE_SW_BUSINESS_START",
  UPDATE_SW_BUSINESS_SUCCESS: "UPDATE_SW_BUSINESS_SUCCESS",
  UPDATE_SW_BUSINESS_ERROR: "UPDATE_SW_BUSINESS_ERROR",
  GET_SW_BUSINESS_START: "GET_SW_BUSINESS_START",
  GET_SW_BUSINESS_SUCCESS: "GET_SW_BUSINESS_SUCCESS",
  GET_SW_BUSINESS_ERROR: "GET_SW_BUSINESS_ERROR",
};

// actions
const searchBusinessStart = () => ({
  type: actionTypes.SEARCH_BUSINESS_START,
});

const searchBusinessSuccess = (results) => ({
  type: actionTypes.SEARCH_BUSINESS_SUCCESS,
  payload: results,
});

const searchBusinessError = (errorMsg) => ({
  type: actionTypes.SEARCH_BUSINESS_ERROR,
  payload: errorMsg,
});

const searchBusinessClear = () => ({
  type: actionTypes.SEARCH_BUSINESS_CLEAR,
  payload: "",
});

const getUserBusinessStart = () => ({
  type: actionTypes.GET_SW_BUSINESS_START,
});

const getUserBusinessSuccess = (results) => ({
  type: actionTypes.GET_SW_BUSINESS_SUCCESS,
  payload: results,
});

const getUserBusinessError = (errorMsg) => ({
  type: actionTypes.GET_SW_BUSINESS_ERROR,
  payload: errorMsg,
});

const updateServiceWorkerBusinessStart = () => ({
  type: actionTypes.UPDATE_SW_BUSINESS_START,
});

const updateServiceWorkerBusinessSuccess = (results) => ({
  type: actionTypes.UPDATE_SW_BUSINESS_SUCCESS,
  payload: results,
});

const updateServiceWorkerBusinessError = (errorMsg) => ({
  type: actionTypes.UPDATE_SW_BUSINESS_ERROR,
  payload: errorMsg,
});

export const businessActions = {
  searchBusinessStart,
  searchBusinessSuccess,
  searchBusinessError,
  updateServiceWorkerBusinessStart,
  updateServiceWorkerBusinessSuccess,
  updateServiceWorkerBusinessError,
  getUserBusinessStart,
  getUserBusinessSuccess,
  getUserBusinessError,
  searchBusinessClear,
};
