import { actionTypes } from "./rating.actions";

const searchState = {
	isLoading: false,
	results: {},
	ratings: [],
	errorMessage: {
		data:'',
		msg: ''
	},
	isLoadingBusinessReviews: false,
	businessReviews: {
		ratings: []
	}
}

const ratingsReducer = (state = searchState, { type, payload}) => {
	switch (type) {
		case actionTypes.SUBMIT_RATING_START:
			return {
				...state,
				isLoading: true,
				errorMessage: '',
				results: [] 
			}
		case actionTypes.SUBMIT_RATING_SUCCESS:
			return {
				...state,
				isLoading: false,
				results:  payload 
			}
		case actionTypes.SUBMIT_RATING_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: payload,
			}
			case actionTypes.GET_RATING_START:
				return {
					...state,
					isLoading: true,
					errorMessage: '',
					results: [] 
				}
			case actionTypes.GET_RATING_SUCCESS:
				return {
					...state,
					isLoading: false,
					ratings:  payload 
				}
			case actionTypes.GET_RATING_ERROR:
				return {
					...state,
					isLoading: false,
					errorMessage: payload,
				}
		case actionTypes.GET_BUSINESS_RATING_START:
			return {
				...state,
				isLoadingBusinessReviews: true,
				errorMessage: '',
				businessReviews: {
					ratings: []
				}
			}
		case actionTypes.GET_BUSINESS_RATING_SUCCESS:
			return {
				...state,
				isLoadingBusinessReviews: false,
				businessReviews:  payload 
			}
		case actionTypes.GET_BUSINESS_RATING_ERROR:
			return {
				...state,
				isLoadingBusinessReviews: false,
				errorMessage: payload,
			}
		default:
			return state;
	}
}
export default ratingsReducer;