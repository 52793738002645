import { actionTypes } from "./reset.actions";

const resetState = {
  isLoading: false,
  phone: "",
  results: [],
  errorMessage: "",
  resetError: "",
  forgotResponse: [],
  verifyResponse: [],
};

const checkIfObj = (payload) => {
  if (payload.constructor.name === "Object") {
    let madeArr = [payload];
    console.log("array payload", madeArr);
    return madeArr;
  } else {
    return payload;
  }
};

const resetReducer = (state = resetState, { type, payload }) => {
  switch (type) {
    case actionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        resetError: payload,
      };
    case actionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        results: [],
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    case actionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        resetError: payload,
      };
    case actionTypes.FORGOT_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        forgotResponse: [],
      };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        forgotResponse: payload,
      };
    case actionTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.VERIFY_OTP_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: "",
        verifyResponse: [],
      };
    case actionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyResponse: payload,
      };
    case actionTypes.VERIFY_OTP_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.SET_RECOVERY_PHONE:
      return {
        ...state,
        phone: payload,
      };
    default:
      return state;
  }
};
export default resetReducer;
