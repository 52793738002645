import React from "react";
import { globalTheme } from "../../../globals/global_css/globalsTheme";
import Select, { components } from "react-select";

function SearchInput() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "chocolate2", label: "Chocolate" },
    { value: "strawberry2", label: "Strawberry" },
    { value: "vanilla2", label: "Vanilla" },
    { value: "chocolate3", label: "Chocolate" },
    { value: "strawberry3", label: "Strawberry" },
    { value: "vanilla3", label: "Vanilla" },
  ];
  const customDropdownIndicator = (props) => {
    return null; // This renders no icon
  };
  const customStyles = {
    // menu: (provided) => ({
    //   ...provided,
    //   maxHeight: "200px", // Maximum height of the dropdown menu
    // }),
    // control: (provided) => ({
    //   ...provided,
    //   height: "60px", // Adjust the height here
    // }),
    // option: (provided) => ({
    //   ...provided,
    //   lineHeight: "24px", // Adjust the line height if needed
    // }),
    // multiValue: (provided) => ({
    //   ...provided,
    //   backgroundColor: "#e2e8f0", // Background color of the selected value box
    //   borderRadius: "0.375rem", // Border radius of the selected value box
    //   padding: "10px", // Padding of the selected value box
    // }),
    // multiValueLabel: (provided) => ({
    //   ...provided,
    //   color: "#4a5568", // Text color of the selected value
    // }),
    // valueContainer: (provided) => ({
    //   ...provided,
    //   maxHeight: "200px", // Maximum height of the value container
    //   overflowY: "auto", // Enable vertical scrolling if content exceeds the container height
    // }),
    control: (provided) => ({
      ...provided,
      height: "100%", // Adjust the height here
      padding: "10px",
    }),
    option: (provided) => ({
      ...provided,
      lineHeight: "24px", //Adjust the line height if needed
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e2e8f0", //Background color of the selected value box
      borderRadius: "0.375rem", //Border radius of the selected value box
      padding: "10px", //Padding of the selected value box
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#4a5568", //Text color of the selected value
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: window.screen.height, // Maximum height of the dropdown menu
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e2e8f0",
      borderRadius: "0.375rem",
      padding: "2px 6px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#4a5568",
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: window.screen.height, // Maximum height of the value container
      overflowY: "auto", // Enable vertical scrolling if content exceeds the container height
    }),
  };
  return (
    <div className="w-full h-full flex flex-row flex-wrap shadow-lg mt-6">
      <Select
        options={options}
        className="w-full h-full border-0"
        components={{ DropdownIndicator: customDropdownIndicator }}
        styles={customStyles}
        isMulti={true}
      />
    </div>
  );
}

export default SearchInput;
