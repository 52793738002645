import React from "react";

export default function GlobalBtn({ title, onPress, fontSize }) {
  return (
    <button
      className="w-full  bg-accent text-white"
      onClick={() => onPress()}
      style={{
        fontSize: fontSize ?? "14px",
        height: "60px",
        textTransform: "uppercase",
        borderRadius: "30px",
      }}
    >
      {title}
    </button>
  );
}
