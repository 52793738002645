import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OnboardWelcome from "../../assets/Images/onboarding/Welcome.png";
import BaseOnboard from "./BoilerPlate/BaseOnboard";
import { getFromSessionStorage } from "../../Services/Storage";
import { useSelector } from "react-redux";

export default function OnboardingPage() {
  const userRole = getFromSessionStorage("role");
  const userData = useSelector((state) => state.balance);
  const { userType } = userData;
  const textContent = "Welcome back to Shukran Your Digital Tipping Partner";
  const contentTitle = "HELLO";

  return (
    <BaseOnboard
      pageImage={OnboardWelcome}
      textContent={textContent}
      contentTitle={contentTitle}
      navigateNext={userType === "tipper" ? "/fund" : "/receiveTips"}
      navigatePrev="/userRole"
    />
  );
}
