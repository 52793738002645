import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import sixOfTen from "../../assets/Illustrations/sacco/six_of_ten.svg";
import twoOfFour from "../../assets/Illustrations/sacco/two_of_four.svg";
import UserIcon from "../../assets/Icons/user_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import moment from "moment";
import {
  anonActivateSacco,
  getUserData,
  postActivateSacco,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";

const SixthDependantsInfo = () => {
  const [userCount, setUserCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [needsGuardian, setNeedsGuardian] = useState(false);
  const [paError, setPaError] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isUnderEighteen, setIsUnderEighteen] = useState(false);
  const {
    handleSubmit,
    register,
    resetField,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saccoState = useSelector((state) => state.sacco);
  const userAccount = useSelector((state) => state.account);

  const balanceState = useSelector((state) => {
    return state.balance;
  });

  useEffect(() => {
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    dispatch(getUserData(params, userAccount.userDetails.token));

    return () => {};
  }, []);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    if (balanceState.errorMessage === 403 && !originState) {
      navigate("/");
    }
    return () => {};
  }, [balanceState]);

  const handleBack = () => {
    navigate(-1);
  };

  const formatNominees = (arrList) => {
    let arr = arrList.map((nominee, idx) => {
      let nomineeUpdate = {
        ...nominee,
        firstName: nominee.name.split(" ")[0],
        lastName: nominee.name.split(" ")[1],
        nationalId: nominee.id_passport_number,
        dobDay: nominee.date_of_birth.split("-")[2],
        dobMonth: nominee.date_of_birth.split("-")[1],
        dobYear: nominee.date_of_birth.split("-")[0],
        primaryNumber: nominee.phone_number,
        pa: nominee.percentage_assigned,
      };
      if (nominee.hasOwnProperty("id")) {
        nomineeUpdate["id"] = nominee.id;
      }
      // console.log("nominee", nominee);
      return nomineeUpdate;
    });
    return arr;
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      if (saccoState.saccoDetails?.nominees) {
        let formattedArray = formatNominees(saccoState.saccoDetails.nominees);
        formattedArray = formattedArray.reverse();
        setValue("beneficiaries", formattedArray);

        console.log("formattedArray", formattedArray);
      }
      setUserCount(
        saccoState.saccoDetails.user_count
          ? saccoState.saccoDetails.user_count
          : 1
      );
    }
    return () => {};
  }, []);

  const formatDob = (beneficiariesList) => {
    let currentDate = moment().format("YYYY-MM-DD");
    console.log("moment()", currentDate);
    // let finishedArray = beneficiariesList.filter(function (element) {
    //   return element !== undefined;
    // });
    let finishedArray = beneficiariesList;
    finishedArray = beneficiariesList.map((beneficiary, idx) => {
      let dobYear = beneficiary.dobYear ? beneficiary.dobYear : "1994";
      let dobMonth = beneficiary.dobMonth ? beneficiary.dobMonth : "08";
      let dobDay = beneficiary.dobDay ? beneficiary.dobDay : "16";
      let dob = dobYear + "-" + dobMonth + "-" + dobDay;
      let age = moment(dob);
      let limit = moment(currentDate);
      let underEighteen = limit.diff(age, "years");
      // console.log("under 18", underEighteen);
      underEighteen = underEighteen >= 18 ? false : true;
      return {
        ...beneficiary,
        dob: dob,
        underEighteen,
        pa: 10,
      };
    });
    return finishedArray;
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    if (saccoState.errorMessage.status === 403 && !originState) {
      dispatch(saccoActions.saccoActivateError(""));
      navigate("/");
    }
    return () => {};
  }, [saccoState]);

  useEffect(() => {
    if (saccoState.results.success && isNext) {
      dispatch(saccoActions.saccoActivateSuccess({}));
      setIsNext(false);
      console.log("isUnderEighteen", isUnderEighteen);
      console.log("needsGuardian", needsGuardian);
      if (isUnderEighteen || needsGuardian) {
        navigate("/seventhForm");
      } else {
        navigate("/eighthForm");
        // navigate("/eighthForm");
      }
    }
    return () => {};
  }, [saccoState, isNext, needsGuardian, isUnderEighteen, dispatch, navigate]);

  const clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const checkEmptyFields = (data) => {
    let missingFields = [];
    let presentFields = [];
    let allFields = [
      "lastName",
      "firstName",
      "primaryNumber",
      "nationalId",
      "relationship",
      // "pa",
      "dobMonth",
      "dobDay",
      "dobYear",
    ];
    console.log("beneficiaries", data);
    let initialArr = data;
    data.forEach((benefactor, idx) => {
      let arr = clean(benefactor);
      console.log("Object.keys(benefactor) " + idx, Object.keys(arr).length);
      console.log("Object.values(benefactor) " + idx, Object.values(arr));
      if (Object.keys(arr).length > 0) {
        presentFields[idx] = Object.keys(arr);
      }
    });
    let diff = userCount - presentFields.length;
    console.log("userCount", userCount);
    console.log("presentFields.length", presentFields.length);

    // console.log("diff", diff);
    console.log("presentFields", presentFields);
    let isIdPresent = presentFields.indexOf("nationalId"),
      userNeedsGuardian = false;
    console.log("isIdPresent", isIdPresent);
    if (!isIdPresent) {
      userNeedsGuardian = true;
    } else {
      userNeedsGuardian = false;
    }
    setNeedsGuardian(userNeedsGuardian);
    for (let i = 0; i < diff; i++) {
      presentFields.push([]);
    }
    for (let i = 0; i < userCount; i++) {
      missingFields[i] = allFields.filter((x) => !presentFields[i].includes(x));
    }
    missingFields = missingFields.filter((field) => field === "nationalId");
    console.log("missingFields", missingFields);

    let allFilledArr = [];

    for (let i = 0; i < userCount; i++) {
      // console.log("missingFields " + i, missingFields[i].length);
      allFilledArr.push(missingFields[i]?.length > 0 ? false : true);
    }
    let allFilled = allFilledArr.every((field) => field === true);
    console.log("allFilledArr", allFilledArr);
    return {
      allFilled,
      missingFields,
      userNeedsGuardian,
    };
  };

  const handleFirstPersonalInfo = (data, e) => {
    let reqBody = data.beneficiaries;
    reqBody = reqBody.map((item, idx) => {
      if (item !== undefined) {
        return {
          ...item,
          position: idx,
        };
      } else {
        return item;
      }
    });
    reqBody = reqBody.filter((item) => item !== undefined);
    let completelyFilled = checkEmptyFields(reqBody);

    if (reqBody.length >= 1 && completelyFilled.allFilled) {
      let formattedBeneficiaries = data.beneficiaries.slice(0, userCount);
      formattedBeneficiaries = formatDob(formattedBeneficiaries);
      let anyUnder18 = formattedBeneficiaries.find((val) => {
        return val.underEighteen;
      });

      console.log("formated Dobs", formattedBeneficiaries);
      console.log("completelyFilled", completelyFilled);
      let sum = formattedBeneficiaries.reduce((previousValue, currentValue) => {
        return Number(previousValue) + Number(currentValue.pa);
      }, 0);

      formattedBeneficiaries = formattedBeneficiaries.map((nominee, idx) => {
        let updatedNominee = {
          name: nominee.firstName + " " + nominee.lastName,
          id_passport_number: nominee.nationalId,
          phone_number: nominee.primaryNumber,
          date_of_birth: nominee.dob,
          relationship: nominee.relationship,
          percentage_assigned: Number(nominee.pa),
        };
        if (nominee.hasOwnProperty("id")) {
          updatedNominee["id"] = nominee.id;
        }
        return updatedNominee;
      });
      if (sum <= 100) {
        let payload = {
          nominees: formattedBeneficiaries,
          user_count: userCount,
          is_under_eighteen: anyUnder18 ? true : false,
        };
        dispatch(saccoActions.updateSaccoDetails(payload));
        setIsNext(true);
        setIsUnderEighteen(anyUnder18);
        handleSavePartially(data);
      } else {
        setError(`beneficiaries.${formattedBeneficiaries.length - 1}.pa`, {
          value: formattedBeneficiaries[formattedBeneficiaries.length - 1].pa,
          message: `The total ${sum}% assigned to your ${formattedBeneficiaries.length} beneficiaries cannot be over 100%`,
        });
      }
    } else {
      completelyFilled.missingFields.forEach((fieldArr, idx) => {
        fieldArr.forEach((field) => {
          let fieldName = `beneficiaries.${idx}.${field}`;
          setError(fieldName, {
            message: `The ${field} field is required`,
          });
        });
      });
    }
  };

  const handleSavePartially = (data) => {
    let originState = localStorage.getItem("origin")
      ? localStorage.getItem("origin")
      : "false";
    originState = JSON.parse(originState);
    let reqBody = data.beneficiaries;
    reqBody = reqBody.filter((item) => item !== undefined);
    console.log("save partially", reqBody);
    if (reqBody.length >= 1) {
      let formattedBeneficiaries = reqBody.slice(0, userCount);
      formattedBeneficiaries = formatDob(formattedBeneficiaries);

      let sum = formattedBeneficiaries.reduce((previousValue, currentValue) => {
        return Number(previousValue) + Number(currentValue.pa);
      }, 0);

      if (sum <= 100) {
        formattedBeneficiaries = formattedBeneficiaries.map((nominee, idx) => {
          return {
            name: nominee.firstName + " " + nominee.lastName,
            id_passport_number: nominee.nationalId,
            phone_number: nominee.primaryNumber,
            date_of_birth: nominee.dob,
            relationship: nominee.relationship,
            percentage_assigned: Number(nominee.pa),
          };
        });
        console.log("formattedBeneficiaries", formattedBeneficiaries);
        let payload = {
          nominees: JSON.stringify(formattedBeneficiaries),
          kyc_stage: 6,
        };
        let payloadData = new FormData();
        for (const key in payload) {
          if (
            key === "kra_pin_photo" ||
            key === "passport_photo" ||
            key === "id_passport_photo" ||
            key === "e_signature_photo" ||
            key === "id_passport_back_photo"
          ) {
            payloadData.append(key, payload[key][0].file);
          } else if (
            key.includes("primary_") ||
            key.includes("dob") ||
            key === "guardian_mn" ||
            key === "user_count"
          ) {
            console.log("primary", key);
          } else {
            payloadData.append(key, payload[key]);
          }
        }
        console.log("form details", payload);
        console.log("origin state", JSON.parse(originState));
        if (originState) {
          dispatch(anonActivateSacco(payloadData));
        } else {
          dispatch(
            postActivateSacco(payloadData, userAccount.userDetails.token)
          );
        }
      } else {
        setError(`beneficiaries.${formattedBeneficiaries.length - 1}.pa`, {
          value: formattedBeneficiaries[formattedBeneficiaries.length - 1].pa,
          message: `The total ${sum}% assigned to your ${formattedBeneficiaries.length} beneficiaries cannot be over 100%`,
        });
      }
    } else if (reqBody.length > 0 && reqBody.length < 3) {
      let fillerPassObj = {
        name: " ",
        id_passport_number: "",
        phone_number: "",
        date_of_birth: "2009-01-06",
        relationship: "",
        percentage_assigned: 20,
      };

      const handleTwice = function (arrParse) {
        arrParse[0].date_of_birth = arrParse[0].date_of_birth
          ? arrParse[0].date_of_birth
          : "1994-08-16";
        arrParse[0].percentage_assigned = arrParse[0].percentage_assigned
          ? arrParse[0].percentage_assigned
          : 20;
        arrParse.push(fillerPassObj);
        let secondObj = { ...fillerPassObj, date_of_birth: "2001-09-11" };
        arrParse.push(secondObj);
        return arrParse;
      };

      const handleOnce = function (arrParse) {
        arrParse[0].date_of_birth = arrParse[0].date_of_birth
          ? arrParse[0].date_of_birth
          : "1994-08-16";
        arrParse[0].percentage_assigned = arrParse[0].percentage_assigned
          ? arrParse[0].percentage_assigned
          : 20;
        arrParse[1].date_of_birth = arrParse[1].date_of_birth
          ? arrParse[1].date_of_birth
          : "2001-09-11";
        arrParse[1].percentage_assigned = arrParse[1].percentage_assigned
          ? arrParse[1].percentage_assigned
          : 20;
        arrParse.push(fillerPassObj);
        return arrParse;
      };

      let formattedBeneficiaries = reqBody,
        formattedArrayLength = reqBody.length;
      console.log(`form details partial before formatting`, reqBody);
      formattedBeneficiaries = formattedBeneficiaries.map((nominee, idx) => {
        return {
          name:
            (nominee?.firstName ? nominee?.firstName : "") +
            " " +
            (nominee?.lastName ? nominee?.lastName : ""),
          id_passport_number: nominee?.nationalId ? nominee?.nationalId : "",
          phone_number: nominee?.primaryNumber ? nominee?.primaryNumber : "",
          date_of_birth: nominee?.dob ? nominee?.dob : "",
          relationship: nominee?.relationship ? nominee?.relationship : "",
          percentage_assigned: nominee?.pa ? Number(nominee?.pa) : "",
        };
      });
      // console.log(
      //   `form details partial after formatting`,
      //   formattedBeneficiaries
      // );

      formattedBeneficiaries =
        formattedBeneficiaries.length === 1
          ? handleTwice(formattedBeneficiaries)
          : handleOnce(formattedBeneficiaries);
      console.log(
        `form details partial before stringify`,
        formattedBeneficiaries
      );
      let payload = {
        nominees: JSON.stringify(formattedBeneficiaries),
        kyc_stage: 6,
      };
      console.log(`form details partial ${formattedArrayLength}`, payload);
      let payloadData = new FormData();
      for (const key in payload) {
        if (
          key === "kra_pin_photo" ||
          key === "passport_photo" ||
          key === "id_passport_photo" ||
          key === "e_signature_photo" ||
          key === "id_passport_back_photo"
        ) {
          payloadData.append(key, payload[key][0].file);
        } else if (
          key.includes("primary_") ||
          key.includes("dob") ||
          key === "guardian_mn" ||
          key === "user_count"
        ) {
          console.log("primary", key);
        } else {
          payloadData.append(key, payload[key]);
        }
      }
      console.log("form details", payload);
      console.log("origin state 2", typeof originState);
      if (originState) {
        dispatch(anonActivateSacco(payloadData));
      } else {
        dispatch(postActivateSacco(payloadData, userAccount.userDetails.token));
      }
    } else {
      dispatch(
        saccoActions.saccoActivateError({
          msg: "You cannot partially submit without at least one field entered",
        })
      );
    }
  };

  const handleAddUser = () => {
    let currNo = userCount;
    if (currNo > 7) return;
    currNo++;
    setUserCount(currNo);
  };
  const handleRemove = (idx) => {
    let currNo = userCount;
    if (currNo <= 1) return;
    currNo--;
    setUserCount(currNo);
    resetField(`beneficiaries.${idx}`);
  };

  const PercentageAssignedError = () => (
    <div
      className={`my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setPaError("");
        }}
      >
        X
      </button>

      <img src={NotFoundIcon} alt="error-img" className="h-40" />

      <p className="justify-self-center	text-base text-center py-2">
        Total percentage assigned can not be over 100%
      </p>
    </div>
  );

  const SaccoActivationError = () => (
    <div
      className={`my-auto mb-28 px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50`}
    >
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(saccoActions.saccoActivateError(""));
        }}
      >
        X
      </button>
      {saccoState.errorMessage && (
        <img src={NotFoundIcon} alt="error-img" className="h-40" />
      )}
      {typeof saccoState.errorMessage === "XMLHttpRequest" && (
        <p className="justify-self-center	text-base text-center py-2">
          CORS error
        </p>
      )}
      {saccoState.errorMessage.msg && (
        <p className="justify-self-center	text-base text-center py-2">
          {saccoState.errorMessage.msg}
        </p>
      )}
      {saccoState.errorMessage.status === 400 && (
        <p className="justify-self-center	text-base text-center py-2">
          {Object.entries(saccoState.errorMessage.data.data)[0]}
        </p>
      )}
      {saccoState.errorMessage.status === 500 && (
        <p className="justify-self-center	text-base text-center py-2">
          System downtime. Try again in few moments
        </p>
      )}
      <button
        className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
        type="submit"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back To Dashboard
      </button>
    </div>
  );

  const SuccessActivation = () => (
    <div className="my-auto px-4 mb-28 drop-shadow-2xl bg-white w-4/5 pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      {isNext ? (
        <></>
      ) : (
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(saccoActions.saccoActivateSuccess({}));
            navigate("/");
          }}
        >
          X
        </button>
      )}
      <img src={checkCircle} alt="error-img" className="h-28" />
      <p className="justify-self-center	text-lg text-center py-2">
        {saccoState.results.success ? "Submission Successful!" : ""}
      </p>
      <p className="justify-self-center	text-base text-center py-2">
        {saccoState.results.success
          ? "Your activation details have been partially submitted"
          : ""}
      </p>
      {isNext ? (
        <></>
      ) : (
        <button
          className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full"
          type="submit"
          onClick={() => {
            dispatch(saccoActions.saccoActivateSuccess({}));
            navigate("/");
          }}
        >
          Home
        </button>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${isLoading ? "relative" : ""}
      ${paError ? "relative" : ""}
      ${saccoState.isLoading ? "relative" : ""}
      ${saccoState.results.success ? "relative" : ""}
      ${saccoState.errorMessage ? "relative" : ""} 
      w-full flex flex-col h-full`}
    >
      <div
        className={`
          ${paError ? "blur-sm" : ""} 
          ${saccoState.isLoading ? "blur-sm" : ""}
          ${saccoState.errorMessage ? "blur-sm" : ""}
          ${isLoading ? "blur-sm" : ""}
          ${saccoState.results.success ? "blur-sm" : ""}
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={ShukranLogoSacco}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={twoOfFour}
            alt="six of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col py-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            Tell us about your next of Kin
          </h2>
          <p className="text-base my-1">
            This details will be used for your SACCO account and linked to your
            Shukran account.
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-5 pb-4"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div>
              {[...Array(userCount)].map((e, idx) => (
                <div
                  className="flex flex-col mt-2 border-b-2 pb-5 border-gray-200"
                  key={idx}
                >
                  <div className="flex flex-row items-center justify-between">
                    <span className="text-base font-bold">
                      {idx === 0
                        ? "First"
                        : idx === 1
                        ? "Second"
                        : idx === 2
                        ? "Third"
                        : idx === 3
                        ? "Fourth"
                        : idx === 4
                        ? "Fifth"
                        : idx === 5
                        ? "Sixth"
                        : idx === 6
                        ? "Seventh"
                        : idx === 7
                        ? "Eighth"
                        : ""}{" "}
                      beneficiary
                    </span>
                    <span
                      className={`${
                        idx === userCount - 1 && userCount > 1
                          ? ""
                          : "invisible"
                      } text-white bg-primary-green rounded-full py-2 px-3 text-xs cursor-pointer`}
                      onClick={() => handleRemove(idx)}
                    >
                      X
                    </span>
                  </div>
                  <div className="flex flex-row items-center mt-1">
                    <div className="w-1/2 mr-2 ">
                      <label className="mt-2 text-label text-xs">
                        First Name *
                      </label>
                      <input
                        className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                        type="text"
                        placeholder="Joe"
                        {...register(`beneficiaries.${idx}.firstName`, {
                          required: false,
                        })}
                      />
                      {errors.beneficiaries?.[idx]?.firstName && (
                        <p className="text-red-600 text-sm">
                          First Name required
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col mt-1 w-1/2">
                      <label className="mt-2 text-label text-xs">
                        Last Name *
                      </label>
                      <input
                        className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                        type="text"
                        placeholder="Doe"
                        {...register(`beneficiaries.${idx}.lastName`, {
                          required: false,
                        })}
                      />
                      {errors.beneficiaries?.[idx]?.lastName && (
                        <p className="text-red-600 text-sm	">
                          Last Name required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col mt-1 hidden">
                    <label className="mt-2 text-label text-xs">
                      Primary Id
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                      type="number"
                      {...register(`beneficiaries.${idx}.id`, {
                        required: false,
                      })}
                    />
                    {errors.beneficiaries?.[idx]?.primaryNumber && (
                      <p className="text-red-600 text-sm">
                        Ensure you have entered in a valid phone number
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      Primary Number *
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                      type="number"
                      placeholder="0700000000"
                      {...register(`beneficiaries.${idx}.primaryNumber`, {
                        required: true,
                        // maxLength: 10,
                        // pattern:
                        //   /^(?:254|\+254|0)?((?:(?:7(?:(?:3[0-9])|([01249][0-9])|(?:5[0-9])|(8[5-9])))|(?:1(?:[1][0-5]))|(?:1(?:[0][0-2]))|(?:2(?:[0][0-9])))[0-9]{6})$/i,
                      })}
                    />
                    {errors.beneficiaries?.[idx]?.primaryNumber && (
                      <p className="text-red-600 text-sm">
                        Ensure you have entered in a valid phone number
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      National ID
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                      type="number"
                      placeholder="3103XXX"
                      {...register(`beneficiaries.${idx}.nationalId`, {
                        required: false,
                        pattern: /^[0-9]{7,8}$|^[A-Z]{1,2}[0-9]{6,8}$/,
                      })}
                    />
                    {errors.beneficiaries?.[idx]?.nationalId && (
                      <p className="text-red-600 text-sm">
                        Please enter national ID
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      Relationship *
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                      type="text"
                      placeholder="Father"
                      {...register(`beneficiaries.${idx}.relationship`, {
                        required: false,
                      })}
                    />
                    {errors.beneficiaries?.[idx]?.relationship && (
                      <p className="text-red-600 text-sm	">
                        Ensure you enter relationship
                      </p>
                    )}
                  </div>
                  {/* <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      Percentage assigned *
                    </label>
                    <input
                      className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                      type="number"
                      placeholder="10"
                      {...register(`beneficiaries.${idx}.pa`, {
                        required: {
                          value: false,
                          message: "Please enter percentage",
                        },
                      })}
                    />
                    {errors.beneficiaries?.[idx]?.pa && (
                      <p className="text-red-600 text-sm">
                        {errors.beneficiaries?.[idx]?.pa.message}
                      </p>
                    )}
                  </div> */}
                  <div className="flex flex-col mt-1">
                    <label className="mt-2 text-label text-xs">
                      Date of Birth
                    </label>
                    <div className="flex flex-row items-start justify-between">
                      <div className="flex flex-col w-1/3">
                        <label className="text-label text-xs my-1">
                          Month *
                        </label>
                        <select
                          className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base p-2 text-black outline-none"
                          {...register(`beneficiaries.${idx}.dobMonth`, {
                            required: false,
                          })}
                          // value="01"
                          placeholder="April"
                        >
                          <option
                            value=""
                            className="placeholder:text-gray-200"
                          >
                            ...
                          </option>
                          <option value="01">January</option>
                          <option value="02">February</option>
                          <option value="03">March</option>
                          <option value="04">April</option>
                          <option value="05">May</option>
                          <option value="06">June</option>
                          <option value="07">July</option>
                          <option value="08">August</option>
                          <option value="09">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        {errors.beneficiaries?.[idx]?.dobMonth && (
                          <p className="text-red-600 text-sm">
                            Please select a month
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col w-1/4">
                        <label className="text-label text-xs my-1">Day *</label>
                        <input
                          className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base p-2 text-black outline-none"
                          type="number"
                          placeholder="12"
                          {...register(`beneficiaries.${idx}.dobDay`, {
                            required: false,
                            maxLength: 2,
                            min: 1,
                            max: 31,
                          })}
                        />
                        {errors.beneficiaries?.[idx]?.dobDay && (
                          <p className="text-red-600 text-sm">
                            Ensure you have entered in a date
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col w-1/4">
                        <label className="text-label text-xs my-1">
                          Year *
                        </label>
                        <input
                          className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base p-2 text-black outline-none"
                          type="number"
                          placeholder="1990"
                          {...register(`beneficiaries.${idx}.dobYear`, {
                            required: false,
                            maxLength: 4,
                            min: 1930,
                            max: 2023,
                          })}
                        />
                        {errors.beneficiaries?.[idx]?.dobYear && (
                          <p className="text-red-600 text-sm">
                            Ensure you have entered in a valid Year
                          </p>
                        )}
                      </div>
                    </div>
                    {errors.dob && (
                      <p className="text-red-600 text-sm">select birth date</p>
                    )}
                  </div>
                </div>
              ))}
              <div className="flex flex-row-reverse mt-3">
                <div
                  className="flex flex-row items-center justify-between bg-primary-green rounded-md py-2 px-2 w-1/2 cursor-pointer"
                  onClick={() => handleAddUser()}
                >
                  <img src={UserIcon} className="w-4 h-4" alt="Next Page" />
                  <span className="text-white text-sm">Add a beneficiary</span>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between my-2 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button
                type="submit"
                id="next"
                className="flex flex-row items-center"
              >
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
            {/* <div className="py-4">
              <button
                className="rounded-full py-2 bg-primary-green text-white text-base w-full"
                type="button"
                onClick={handleSubmit(handleSavePartially)}
                id="save"
              >
                SAVE AND CONTINUE LATER
              </button>
            </div> */}
          </form>
        </div>
      </div>
      <div
        className={`${
          paError ? "absolute blur-none" : "hidden"
        } flex justify-center items-center w-full h-full`}
      >
        <PercentageAssignedError />
      </div>
      <div
        className={`${
          saccoState.errorMessage ? "absolute blur-none" : "hidden"
        } flex flex-col-reverse items-center w-full h-full`}
      >
        <SaccoActivationError />
      </div>
      <div
        className={`${
          saccoState.results.success ? "absolute blur-none" : "hidden"
        } flex flex-col-reverse items-center w-full h-full`}
      >
        <SuccessActivation />
      </div>
      <div
        className={`
          ${saccoState.isLoading ? "absolute blur-none" : "hidden"}
        flex flex-col-reverse items-center w-full h-full
        `}
      >
        <div className="bg-white mb-28 py-16 w-4/5 rounded-md drop-shadow-2xl flex flex-col justify-center items-center">
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-20 mx-auto text-sm mt-1 z-10`}
          />
          <span>Processing submitted details</span>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default SixthDependantsInfo;
