export const registerUser = (responseData) => {
  return (dispatch) => {
    dispatch({
      type: "registerUser",
      payload: responseData,
    });
  };
};

export const registerToken = (responseData) => {
  return (dispatch) => {
    dispatch({
      type: "registerToken",
      payload: responseData,
    });
  };
};

export const addMpesaNo = (responseData) => {
  return (dispatch) => {
    dispatch({
      type: "addMpesaNo",
      payload: responseData,
    });
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: "userLoggedIn",
      payload: data,
    });
  };
};

export const fetchUser = (data) => {
  return (dispatch) => {
    dispatch({
      type: "fetchUser",
      payload: data,
    });
  };
};

export const logOut = (data) => {
  return (dispatch) => {
    dispatch({
      type: "userLogOut",
    });
  };
};

export const fetchOTP = (data) => {
  return (dispatch) => {
    dispatch({
      type: "fetchOTP",
      payload: data.success,
    });
  };
};

export const submitOTP = () => {
  return (dispatch) => {
    dispatch({
      type: "submitOTP",
    });
  };
};

export const toggleBalance = () => {
  return (dispatch) => {
    dispatch({
      type: "toggelBal",
    });
  };
};

export const togglePassword = () => {
  return (dispatch) => {
    dispatch({
      type: "toggelPassword",
    });
  };
};

export const keepLoggedIn = () => {
  return (dispatch) => {
    dispatch({
      type: "keepLoggeIn",
    });
  };
};

export const Login = (data) => {
  return (dispatch) => {
    dispatch({
      type: "login",
      data,
    });
  };
};

export function setDepositAmount(amount) {
  return (dispatch) => {
    dispatch({
      type: "setDepositAmount",
      payload: amount,
    });
  };
}

export function setWithdrawAmount(amount) {
  return (dispatch) => {
    dispatch({
      type: "setWithdrawAmount",
      payload: amount,
    });
  };
}

export const tip = (amount) => {
  return (dispatch) => {
    dispatch({
      type: "tip",
      amount,
    });
  };
};

export const allTransactions = (arr) => {
  return (dispatch) => {
    dispatch({
      type: "getAllTransactions",
      payload: arr,
    });
  };
};

// update
export function upDateBalance(balance) {
  return (dispatch) => {
    dispatch({
      type: "upDateBalance",
      balance,
    });
  };
}

export function depositMpesaNumber(phone) {
  return (dispatch) => {
    dispatch({
      type: "updateDepositNumber",
      payload: phone,
    });
  };
}
export function updateDepositedAmount(amount) {
  return (dispatch) => {
    dispatch({
      type: "updateDepositedAmount",
      payload: amount,
    });
  };
}

export function withdrawMpesaNumber(phone) {
  return (dispatch) => {
    dispatch({
      type: "updateWithdrawNumber",
      payload: phone,
    });
  };
}

// tipping data
export function addTipAmount(amount) {
  return (dispatch) => {
    dispatch({
      type: "addTipAmount",
      amount,
    });
  };
}

export function addCurrency(currency) {
  return (dispatch) => {
    dispatch({
      type: "addCurrency",
      currency,
    });
  };
}

export function addShukranId(receiverDetails) {
  return (dispatch) => {
    dispatch({
      type: "TipAddId",
      payload: receiverDetails,
    });
  };
}

export function addNonShukranId(receiverDetails) {
  return (dispatch) => {
    dispatch({
      type: "addNonShukranId",
      payload: receiverDetails,
    });
  };
}

export function addQueryShukranId(receiverDetails) {
  return (dispatch) => {
    dispatch({
      type: "addQueryShukranId",
      payload: receiverDetails,
    });
  };
}

export function addReferrerId(receiverDetails) {
  return (dispatch) => {
    dispatch({
      type: "addReferrerId",
      payload: receiverDetails,
    });
  };
}

// Transactions
export function userTransactions(data) {
  return (dispatch) => {
    dispatch({
      type: "allUserTransactions",
      payload: data,
    });
  };
}
//Reviews
export function userReviews(data) {
  return (dispatch) => {
    dispatch({
      type: "userReviews",
      payload: data,
    });
  };
}
