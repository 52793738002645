import React from "react";
import loader from "../../assets/Icons/Tipping/loaderLg.svg";

export default function Loader({ desc }) {
  return (
    <div className="backdrop-blur-sm absolute top-[2%] w-screen h-screen self-center flex flex-col items-center justify-center">
      <div className="w-[80%] h-2/5 bg-gray-50 flex rounded-lg flex-col items-center justify-center rounded-xl">
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-32 mx-auto text-sm mt-1 mb-5`}
        />
        <p>{desc}</p>
      </div>
    </div>
  );
}
