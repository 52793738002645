import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import sendIcon from "../../assets/Icons/send_icon.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import actions from "../../state";
import { searchActions } from "../../state/reducers/search/search.actions";
import TransactionCard from "../Card/TransactionCard";

const TransactionDetailDisbursement = () => {
  const [transaction, setTransaction] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const transactionDetail = location.state;
  const type = location.type;
  const disbursements = location.transactions;
  const balance = useSelector((state) => state.balance);

  const { addShukranId, addNonShukranId } = bindActionCreators(
    actions,
    dispatch
  );
  // user global state values
  const transactionState = useSelector((state) => state.transactions);

  useEffect(() => {
    // console.log('state', transactionState.transactions)
    console.log(transactionDetail);
    if (transactionDetail.id && !transactionDetail?.disbursement_detail) {
      let fullRes = transactionState?.transactions?.results?.filter(
        (item) => item.id === transactionDetail.id
      );
      setTransaction(fullRes[0]);
    }
    return () => {};
  }, []);

  return (
    <div
      className={`mx-auto w-full flex flex-col px-2 h-screen bg-imag-dash-2 pb-20`}
    >
      <div onClick={() => navigate(-1)} className="m-5">
        <img src={back} className="" alt="rate-star" />
      </div>
      <div className="flex flex-row justify-between items-center mb-8 px-5">
        <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
      </div>
      <div className="relative w-full bg-white rounded-[20px]  mx-auto flex flex-col items-center pb-8">
        <div className="bg-primary-pwa rounded-full flex justify-center items-center h-16 w-16 absolute left-0 right-0 -top-6 mx-auto">
          <p className="text-2xl text-white">
            {transactionDetail.shuran_id
              ? transactionDetail.shukran_id?.split("", 2)
              : ""}
          </p>
        </div>
        <div className="text-primary-green text-2xl mt-16">
          {/* {transaction?.receiver_first_name
            ? transaction?.receiver_first_name
            : balance?.userInfo?.first_name + " "}
          {transaction?.receiver_last_name
            ? transaction?.receiver_last_name
            : balance?.userInfo?.last_name} */}
          Tipjar Disbursement
        </div>
        <div className="text-sm mt-1">
          Shukran ID:{" "}
          {transactionDetail?.shukran_id
            ? transactionDetail?.shukran_id
            : transaction?.shukran_id}
        </div>
        <div className="text-3xl font-semibold mt-7">
          {transactionDetail?.type === "tipjar_disbursement" && "-"}
          {transactionDetail?.amount}
        </div>
        <div className="text-sm text-gray-400 mt-1">
          Transaction cost: KSH 0.00
        </div>
        <div className="text-sm text-gray-400 mt-1 mb-7">
          Transaction ID: {transactionDetail?.id}
        </div>
        <button
          className="flex flex-row items-center justify-center rounded-full bg-primary-green p-2 text-white text-base w-4/5 mx-auto mt-6"
          type="button"
          onClick={() => {
            addNonShukranId("");
            addShukranId({
              first_name: transaction?.receiver_first_name,
              shukran_id: transactionDetail?.receiver_shukran_id,
            });
            dispatch(
              searchActions.updateSearchQuery(
                transactionDetail?.receiver_shukran_id
              )
            );
            navigate("/tip");
          }}
        >
          Tip Again <img src={sendIcon} alt="Logo" className="w-4 h-4 ml-3" />
        </button>

        <button
          className="rounded-full bg-[#F2C773] p-2 text-white text-base w-4/5 mx-auto mt-1"
          type="button"
          onClick={() => {
            navigate("/transactions/history", {
              state: transactionDetail?.receiver_shukran_id,
            });
          }}
        >
          View History
        </button>
      </div>
    </div>
  );
};

export default TransactionDetailDisbursement;
