import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import logo from "../../assets/Logos/shukran-logo-dark.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowNextOrange.svg";
import ArrowButtonPrev from "../../assets/Icons/Onboarding/ArrowBackOrange.svg";
import threeOfTen from "../../assets/Illustrations/sacco/three_of_ten.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";

const ThirdBusinessInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({});

  const saccoState = useSelector((state) => state.sacco);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (saccoState.saccoDetails) {
      setValue("businessName", saccoState.saccoDetails.business_name);
      setValue("businessType", saccoState.saccoDetails.business_type);
      setValue("businessAdd", saccoState.saccoDetails.business_address);
      setValue("employment", saccoState.saccoDetails.employment_type);
    }
    return () => {};
  }, []);
  const handleFirstPersonalInfo = (data) => {
    console.log("sacco details submit", data);
    let payload = {
      employment_type: data.employment,
      business_name: data.businessName,
      business_type: data.businessType,
      business_address: data.businessAdd,
    };
    dispatch(saccoActions.updateSaccoDetails(payload));
    navigate("/fourthForm");
  };
  return (
    <div
      className={`
      
    ${isLoading ? "relative" : ""} w-screen flex flex-col h-screen`}
    >
      <div
        className={`
       
          ${isLoading ? "blur-sm" : ""}
          mx-auto h-full w-full text-black 
          flex flex-col bg-imag-dash`}
      >
        <div className="header mt-2 px-6 flex flex-row items-center justify-between">
          <img
            src={logo}
            alt="logo of shukran"
            className="rounded py-3 h-16 mt-16 mb-4"
          />
          <img
            src={threeOfTen}
            alt="three of ten"
            className="rounded py-3 h-32 mt-16 mb-4"
          />
        </div>
        <div className="flex flex-col pt-4 h-full bg-white rounded-t-3xl px-6">
          <h2 className="text-2xl text-primary-green">
            Tell us about your occupation
          </h2>
          <p className="text-base my-1">
            This details will be used for your SACCO account and linked to your
            Shukran account.
          </p>
          <form
            className="flex flex-col justify-between h-4/5 mt-5"
            onSubmit={handleSubmit(handleFirstPersonalInfo)}
          >
            <div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Employment Type *
                </label>
                <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("employment", {
                    required: true,
                  })}
                  placeholder="Employed"
                >
                  <option value="employed">Employed</option>
                  <option value="self-employed">Self-employed</option>
                  <option value="unemployed">Unemployed</option>
                </select>
                {errors.employment && (
                  <p className="text-red-600 text-sm">
                    Please select an employment type
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Business Name *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Fogo"
                  {...register("businessName", {
                    required: true,
                  })}
                />
                {errors.businessName && (
                  <p className="text-red-600 text-sm">
                    Please enter a business name
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Business Type *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Restaurant"
                  {...register("businessType", {
                    required: true,
                  })}
                />
                {/* <select
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  {...register("businessType", {
                    required: true,
                  })}
                  placeholder="Fogo"
                >
                  <option value="restaurant">Restaurant</option>
                  <option value="club">Club</option>
                  <option value="bar">Bar</option>
                </select> */}
                {errors.businessType && (
                  <p className="text-red-600 text-sm">
                    Please enter a business type
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-2">
                <label className="mt-2 text-label text-xs">
                  Business Address *
                </label>
                <input
                  className="bg-[#CCD3DB]/50 rounded-lg border-solid border-2 focus:border-[#50a3a7]/70 h-12 placeholder:text-base w-full p-2 text-black outline-none"
                  type="text"
                  placeholder="Kikoli Rd"
                  {...register("businessAdd", {
                    required: true,
                  })}
                />
                {errors.businessAdd && (
                  <p className="text-red-600 text-sm">
                    Enter your business Address
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between my-2 pt-3">
              <div
                className={`flex flex-row items-center`}
                onClick={() => handleBack()}
              >
                <img
                  src={ArrowButtonPrev}
                  className="w-12 h-12"
                  alt="Previous"
                />
                <span className="ml-2">Previous</span>
              </div>
              <button type="submit" className="flex flex-row items-center">
                <span className="mr-2">Next</span>
                <img
                  src={ArrowButtonNext}
                  className="w-12 h-12"
                  alt="Next Page"
                  type="button"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`${isLoading ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default ThirdBusinessInfo;
